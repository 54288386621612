import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/AppConstants';
import { EscalatePackageComponent } from '../escalate-package/escalate-package.component';
import { FeedbackComponent } from '../feedback/feedback.component';
import { EscalatePackageService } from 'src/app/services/escalate-package.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { AppEnv } from 'src/app/GlobalConstants';
import { AuthService } from '@auth0/auth0-angular';
import { UserEventLog } from 'src/app/models/userEventLog';
import { UserEvent } from 'src/app/models/userEvent';
import { UserService } from 'src/app/services/user.service';

interface Language {
  languageCode: string;
  languageName: string;
  englishName: string
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation:ViewEncapsulation.None
  
})
export class HeaderComponent implements OnInit {
  language: Language[] = [
    {languageCode  : 'en', languageName : 'English' , englishName : 'English'},
    {languageCode  : 'es',languageName : 'Español', englishName : 'Spanish'},
    {languageCode  : 'fr',languageName : 'Français', englishName : 'French'},
    {languageCode  : 'de',languageName : 'Deutsch', englishName : 'German'},
    {languageCode  : 'pl',languageName : 'Polski', englishName : 'Polish'},
    {languageCode  : 'pt',languageName : 'Português', englishName : 'Portuguese'},
    {languageCode  : 'fr-CA',languageName : 'Français Canadien', englishName : 'French Canadian'},
    {languageCode  : 'it',languageName : 'Italiano', englishName : 'Italian'},
    {languageCode  : 'ja',languageName : '日本語', englishName : 'Japanese'},
    {languageCode  : 'ko',languageName : '한국어', englishName : 'Korean'},
    {languageCode  : 'zh-Ha',languageName : '简体中文', englishName : 'Chinese Simplified'}
  ];

  @Input('sidenavMenu') sidenavMenu: any;
  @Input('sidenavProfile') sidenavProfile: any;
  FullName: string = "";
  RoleName: string = "";
  appTitle = 'Intelligent Analytics'; // 'Data Manager';
  hasAccess: boolean = false;
  appUrl: string = "";
  redirect_url: string = "";
  isProfileExpanded:boolean=false;
  isHelpExpanded:boolean=false;
  NinedotsimageSource: string = "../assets/logos/applications.svg";
  CVAimageSource: string  = "../assets/logos/app-cva-icon-white.svg";
  CVDMimageSource : string  = "../assets/logos/app-cvdm-icon-white.svg";
  PickupimageSource : string  = "../assets/logos/app-pickup-manager-icon-white.svg";
  ReturnsimageSource :  string  = "../assets/logos/app-returns-icon-white.svg";
  TrailerimageSource :  string  = "../assets/logos/app-trailer-icon-white.svg";
  CVSimageSource : string  = "../assets/logos/app-signature-icon-white.svg";
  RatingimageSource : string  = "../assets/logos/icon-app-rating-white.svg";
  LanguageimageSource : string = "../assets/logos/globe-solid.svg";
  SettingsimageSource:string  = "../assets/logos/settings.svg";
  HelpimageSource : string  = "../assets/logos/help.svg";
  ProfileimageSource: string  = "../assets/logos/profile.svg";
  isSettingExpanded:boolean=false;
  isApplicationExpanded:boolean=false;
  isGlobeExpanded:boolean=false;
  isLocaleExpanded:boolean=false;

  constructor(public auth: AuthService, public router: Router,public dialog: MatDialog, private escalatePackageService: EscalatePackageService , private dataService:DataService,private userService:UserService) { }

  ngOnInit(): void {
    this.CheckEscalationAccess();
    this.AddUserLoginDetailsAudit();
    this.appUrl = environment.cvsRedirect_Url;
    this.redirect_url = environment.redirectUri;
  }

  AddUserLoginDetailsAudit() {
    this.dataService.AddUserLoginDetailsAudit(AppConstants.gblLoggedInUserId).subscribe(() => {});
    let userEventLog:UserEventLog={
      userEvent:UserEvent.LogOn,
      userId:AppConstants.gblLoggedInUserId
    }
    this.userService.logUserEvent(userEventLog).subscribe(() => {});
  }

  openReleaseNotes():void{
    this.router.navigateByUrl('/menu/releasenotes');
    }
    
  CheckEscalationAccess() {
    this.hasAccess = false;
    this.escalatePackageService.checkEscalationAccess(AppConstants.gblLoggedInUserId).subscribe((data) => {
      this.hasAccess = data as boolean;
    });
  }
  openFeedbackDialog() {
    try {

      const dialogRef = this.dialog.open(FeedbackComponent,{ panelClass: 'custom-feedback-container'});

      dialogRef.afterClosed().subscribe(result => {

      });
    } catch (error) {
      console.log(error);
    }
  }


   logout() {
    let userEventLog:UserEventLog={
      userEvent:UserEvent.LogOff,
      userId:AppConstants.gblLoggedInUserId
    }
    this.userService.logUserEvent(userEventLog).subscribe(() => {});
    AppConstants.gblClientId = 0;
    AppConstants.gblLoggedInUserId = 0;
    AppConstants.gblLoggedInUserFName="";
    AppConstants.gblLoggedInUserLName="";
    AppConstants.gblLoggedInUserEmailid="";
    AppConstants.gbluserLevelId=5;
    AppConstants.gbloperatorId=3;
    AppConstants.gblscreenGroup=1;
    AppConstants.gblRoleName="";
    AppConstants.glbScreenId=0;
    AppConstants.glbRoleId=0;
    sessionStorage.clear();   
    localStorage.clear();
    setTimeout(() => {
      this.logoff();
    }, 2000);
    window.location.href = AppEnv.logoutUri;
   }


   logoff() {
    this.auth.logout({ logoutParams: { returnTo: AppEnv.redirectUri }, clientId: AppEnv.clientId });
  }

  openEscalatePackageDialog() {
    try {

      const dialogRef = this.dialog.open(EscalatePackageComponent, {
        width: '600px',
        data: null
      });
      dialogRef.afterClosed().subscribe(result => {

      });

    } catch (error) {
      console.log(error);
    }
  }

  redirectUrl(app: string) {
    switch (app) {
      case 'cva':
        this.appUrl = environment.redirectUri;
        break;

      case 'cvdm':
        this.appUrl = environment.cvdmRedirect_Url;
        break;

      case 'pickup':
        this.appUrl = environment.pickupRedirect_Url;
        break;

      case 'returns':
        this.appUrl = environment.returnsRedirect_Url;
        break;

      case 'trailer':
        this.appUrl = environment.trailerRedirect_Url;
        break;

      case 'cvs':
        this.appUrl = environment.cvsRedirect_Url;
        break;

      case 'rating':
        this.appUrl = environment.rating_Url;
        break;

    }
    window.open(this.appUrl, '_blank');
  }
  openUserGuide():void{
    window.open('../assets/User_Guide/HTML5/Content/Application/Introduction.htm');
    
    }
  openProfileDialog() {
    this.FullName = AppConstants.gblLoggedInUserFName + " " + AppConstants.gblLoggedInUserLName;
    this.RoleName = AppConstants.gblRoleName;
  }

  profileExpanded(){
    this.isProfileExpanded=true;
  }
  profileCollapsed(){
    this.isProfileExpanded=false;
  }
  helpExpanded(){
    this.isHelpExpanded=true;
  }
  helpCollapsed(){
    this.isHelpExpanded=false;
  }

  settingExpanded(){
    this.isSettingExpanded=true;
  }
  settingCollapsed(){
    this.isSettingExpanded=false;
  }

  globeExpanded(){
    this.isGlobeExpanded=true;
  }
  globeCollapsed(){
    this.isGlobeExpanded=false;
  }

  applicationExpanded(){
    this.isApplicationExpanded=true;
  }
  applicationCollapsed(){
    this.isApplicationExpanded=false;
  }

  localeExpanded(){    
    this.isLocaleExpanded=true;
  }
  localeCollapsed(){
    this.isLocaleExpanded=false;    
  }

  changeLocale(locale: string) {
    localStorage.setItem('changelocale', '1');
    localStorage.setItem('locale',locale);
    location.reload();
    
  }
}
