import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AddEditTenantComponent } from 'src/app/components/tenant-add-edit/tenant-add-edit.compononent';
import { Tenant,ITenant} from 'src/app/models/tenant.model';
import { TenantService } from 'src/app/services/tenant.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { AppConstants } from 'src/app/AppConstants';
import { Router } from '@angular/router';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
/**
 * @title Table with expandable rows
 */
@Component({
  selector: 'app-tenant',
  styleUrls: ['./tenant.component.scss'],
  templateUrl: './tenant.component.html',
  encapsulation:ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
    trigger('fadeInAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class TenantComponent implements OnInit {
  selectedTenantId:number=0;
  selectedClientId:number=0;
  selectedRole:number=0;
  isEdit: boolean = false;
  dataSource: MatTableDataSource<Tenant>;
  placeholder : string = "Ex. Tenant Name, Tenant Link, etc.";
  mappedClientsByTenant:any[]=[];
  noData :string = 'No data found';
  Itemsperpage : string = 'Items per page';
  Nextpage : string = 'Next page';
  Previouspage : string = 'Previous page';
  MappedClients : string  = 'Mapped Clients';
  AllClients : string  = 'All Clients';
  Confirmdelete : string = 'Confirm delete';
  Areyousuretodeletetenant :  string  = 'Are you sure to delete tenant?';
  Deletefailed :string  ='Delete failed';
  TenantDeletionFailed :  string = 'Tenant Deletion Failed.';
  Areyousuretodeleteallclientsmapping : string  = 'Are you sure to delete all clients mapping?';
  MappingDeletionFaileds : string  = 'Mapping Deletion Failed : ';
  MappingDeletionFailed : string  = 'Mapping Deletion Failed.';
  Areyousuretodeleteclientsmapping : string  = 'Are you sure to delete clients mapping?';
  Savefailed : string  = 'Save failed';
  MappingFailed : string  = 'Mapping Failed';
  Clients : string  = 'Clients';
  of : string = 'of';
  mappedClients : any []= [];
  mappedClients_Master:any[]=[];
  clientsString:string='';
  allClientsString:string='';
  unMappedClients:any[]=[];
  unMappedClients_Master:any[]=[];
 mappedClientsCount:number=0;
 unMappedClientsCount:number=0;
AddAllRemoveAllFlag: boolean=true;
AddSelectedFlag: boolean=true;
  
  columnsToDisplay = [
    'tenantName',
    'tenantLink',
    'webIntegrationId',
    'theme',
    'actions'
  ];
  expandedElement: Tenant | null | undefined;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  
  constructor(public dialog: MatDialog,private tenantService:TenantService,private commonService:CommonService,private router:Router) {
     this.dataSource=new MatTableDataSource<Tenant>();
  }
  ngOnInit() {
 
    this.bindTenantList();
    this.selectedRole=AppConstants.glbRoleId;
  }


 //bind tenant list
  bindTenantList(){
    this.tenantService.getTenants(AppConstants.gblAppId).then((res)=>{;
    
    this.dataSource = new MatTableDataSource(res as Tenant[]);  
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;   
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;
     const rangelabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${filterPipe.transform(this.of)} ${length}`;
      }
    
      length = Math.max(length, 0);
    
      const startIndex = page * pageSize;
    
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
    
      return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
    };

   
     this.dataSource.sort = this.sort;
     const filterPipe = new TranslationPipe();
     this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
    this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
    this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
    this.paginator._intl.getRangeLabel = rangelabel;
    this.dataSource.paginator = this.paginator;
  }

  RefreshTenantList() {
    this.ngOnInit();
  }


  //open a dialog on Add Tenant
  openAddTenantDialog() {
    const dialogRef = this.dialog.open(AddEditTenantComponent, {
      width: '600px',
      data: null,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.bindTenantList();
    });
  }

  
  //open a dialog on edit Tenant
  openEditTenantDialog( row:ITenant) {
    
    const dialogRef = this.dialog.open(AddEditTenantComponent, {
      width: '600px',
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.bindTenantList();
    });
  }
  
  
  //delete tenat
  deleteTenant(tenantId:number){
    const filterPipe = new TranslationPipe();
    const dialogRef=this.dialog.open(ConfirmDialogComponent,{
      data: {
        title: filterPipe.transform(this.Confirmdelete),
        text: filterPipe.transform(this.Areyousuretodeletetenant),
      },
    }); 
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        const modifiedBy:number=1;
    this.tenantService.deleteTenant(tenantId,modifiedBy).then((data)=>{     

      this.bindTenantList();         
    }).catch((error)=>{    
      this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed),filterPipe.transform(this.TenantDeletionFailed));
    });
    }
    });

    
  }

  //filter for tenants
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
 
  clearFilter(){
    this.clientsString='';
    this.allClientsString='';
    this.mappedClients=this.mappedClients_Master.filter( i => i.clientName.toLowerCase().indexOf('') >= 0 );
    this.unMappedClients=this.unMappedClients_Master.filter( i => i.clientName.toLowerCase().indexOf('') >= 0 );
   }

  applyMappedFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.mappedClients =this.mappedClients_Master.filter( i => i.clientName.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 );
   
  }
  applyUnMappedFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.unMappedClients =this.unMappedClients_Master.filter( i => i.clientName.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 );

   }
  //bind mapped and unmapped client lists after expanding user 
  bindDetails(expandedElement:any,tenantId: number){
    
    this.clientsString='';
    this.allClientsString='';
    if(expandedElement)
    {
      this.bindMappedClients(tenantId);
      this.bindUnMappedClients(tenantId);
      this.selectedTenantId=tenantId;
    }
    else{
      this.selectedTenantId=0;
      
      
    }
    if(this.isEdit ){
      this.isEdit=!this.isEdit;
    }
  }
//bind mapped client list
  async bindMappedClients(tenantId:number){
     this.mappedClients=this.mappedClients_Master= <any[]>await this.tenantService.getMappedClients(tenantId); 
  }

//bind unmapped client list
  async bindUnMappedClients(tenantId:number){
    this.unMappedClients=this.unMappedClients_Master= <any[]>await this.tenantService.getUnmappedClients(tenantId);     
 }
 //delete all mapping of client and tenant at once
 deleteAllClientsTenantMapping(selectedTenantId:number){
  const filterPipe = new TranslationPipe();
  const dialogRef=this.dialog.open(ConfirmDialogComponent,{
    data: {
      title: filterPipe.transform(this.Confirmdelete),
      text: filterPipe.transform(this.Areyousuretodeleteallclientsmapping),
    },
  }); 
  
  dialogRef.afterClosed().subscribe(result => {
    if(result) {
  this.tenantService.deleteAllTenantClients(selectedTenantId).then((data)=>{       
  this.bindMappedClients(this.selectedTenantId)  ; 
  this.bindUnMappedClients(this.selectedTenantId)  ;    
  }).catch((error)=>{
      this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed),filterPipe.transform(this.MappingDeletionFailed));   
  });
}});
}

//delete single mapping of tenant and client
deleteSingleclientTenantMapping(selectedTenantId:number,selectedClientId:number){
  const filterPipe = new TranslationPipe();
  const dialogRef=this.dialog.open(ConfirmDialogComponent,{
    data: {
      title: filterPipe.transform(this.Confirmdelete),
      text:  filterPipe.transform(this.Areyousuretodeleteclientsmapping),
    },
  }); 
  
  dialogRef.afterClosed().subscribe(result => {
    if(result) {
  this.tenantService.deleteSingleTenantClientMapping(selectedTenantId,selectedClientId).then((data)=>{       
  this.bindMappedClients(this.selectedTenantId)  ; 
  this.bindUnMappedClients(this.selectedTenantId)  ;    
  }).catch((error)=>{
    if(error){
      this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed), filterPipe.transform(this.MappingDeletionFailed));
    }
    else
    {
      this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed), filterPipe.transform(this.MappingDeletionFailed))
    }     
  });
}});
}


  //Map single client to tenant
  mapSingleClient(selectedTenantId:number,selectedClientId:number){
    const filterPipe = new TranslationPipe();
    let mapObj: any = {
      TenantId:selectedTenantId,
      ClientId:selectedClientId,
      LoggedinUserId:AppConstants.gblLoggedInUserId
     }
    this.tenantService.postSingleTenantClientMapping(mapObj).subscribe((data) => {    
      this.bindMappedClients(this.selectedTenantId)  ; 
      this.bindUnMappedClients(this.selectedTenantId)  ;   
                                        
       },
        (error) => {                                                                       
    this.commonService.openMessageDialog(filterPipe.transform(this.Savefailed),filterPipe.transform(this.MappingFailed));     
                                                         
       })
                                  
  }
  
//Map all clients to tenant at once
  mapAllClients(selectedTenantId:number){
    const filterPipe = new TranslationPipe();
    let mapObj: any = {
      tenantId:selectedTenantId,
      LoggedinUserId:AppConstants.gblLoggedInUserId
     }

    this.tenantService.postAllTenantClientsMapping(mapObj).subscribe((data) => {    
      this.bindMappedClients(this.selectedTenantId)  ; 
      this.bindUnMappedClients(this.selectedTenantId)  ;   
                                        
                                    },
                                    (error) => {                                                                       
    this.commonService.openMessageDialog(filterPipe.transform(this.Savefailed),filterPipe.transform(this.MappingFailed));     
                                                         
                                    })
  }

}


