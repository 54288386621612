import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ScreenService } from 'src/app/services/screen.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
import { INotification } from 'src/app/models/notification.model';
import { NotificationService } from 'src/app/services/notification.service';
import { AddEditNotificationComponent } from 'src/app/components/notification-add-edit/notification-add-edit.component';
import { AddNotificationClientComponent } from 'src/app/components/add-notificationclient/add-notificationclient.component';
import { DeleteNotificationClientComponent } from 'src/app/components/delete-notificationclient/delete-notificationclient.component';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent  implements OnInit {
  displayedColumns: string[] = ['notificationTitle', 'notificationDescription', 'createdDate' ,'expireDate', 'action'];
  dataSource: MatTableDataSource<INotification>;
  noData :string = 'No data found';
  Itemsperpage : string = 'Items per page';
  Nextpage : string = 'Next page';
  Previouspage : string = 'Previous page';
  of : string = 'of';
  placeholder : string = "Ex. Notification Title, Description, etc.";
  success : string  = "Success";
  failed : string = "Failed";
  purgeComplete : string  ="Purge Process Complete";
  PurgeFailed : string = "Purge Process Failed";
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public dialog: MatDialog,
    public screenService: ScreenService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    public router: Router
  ) {
    
    this.dataSource = new MatTableDataSource<INotification>();
  }

  ngOnInit() {
    this.bindNotification();
  }
  

  bindNotification() {
    this.notificationService.getNotification().then((data) => {
      this.dataSource = new MatTableDataSource(data as INotification[]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const rangelabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${filterPipe.transform(this.of)} ${length}`;
      }
    
      length = Math.max(length, 0);
    
      const startIndex = page * pageSize;
    
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
    
      return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
    };


     this.dataSource.sort = this.sort;
     const filterPipe = new TranslationPipe();
     this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
     this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
     this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
     this.paginator._intl.getRangeLabel = rangelabel;
     this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

   //open a dialog on Add Notification
   openAddNotificationDialog() {
    const dialogRef = this.dialog.open(AddEditNotificationComponent , {
      width: '600px',      
      data: null,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.bindNotification();
    });
  }


  
  //open a dialog on edit notification
   openEditNotificationDialog( row: Notification) {
    const dialogRef = this.dialog.open(AddEditNotificationComponent, {
      width: '600px',
      data: row,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.bindNotification();
    });
   }

   //purge notification
  purgeNotification(){
    const filterPipe = new TranslationPipe();
   this.notificationService.purgeNotification(AppConstants.gblLoggedInUserId).then(()=>{ 
    this.commonService.openMessageDialog(filterPipe.transform(this.success),filterPipe.transform(this.purgeComplete));    
    this.bindNotification();         
  }).catch(()=>{    
    this.commonService.openMessageDialog(filterPipe.transform(this.failed),filterPipe.transform(this.PurgeFailed));
  });
  }

  //Refresh notification
  RefreshNotificationList() {
    this.ngOnInit();
  }

  //Map notification to client 
  openMapNotificationDialog(){
    const dialogRef = this.dialog.open(AddNotificationClientComponent, {
      width: '600px',
      height : '500px',
      data: null,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.bindNotification();
    });
  }


  //Delete notification to client 
  openDeleteNotificationDialog(){
    const dialogRef = this.dialog.open(DeleteNotificationClientComponent, {
      width: '600px',
      height : '500px',
      data: null,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.bindNotification();
    });
  }
 



  
}

