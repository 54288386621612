<mat-toolbar class="pair-new-device-toolbar">
    &nbsp;&nbsp;
    <span i18n="@@IATconfigurationscreen">IAT Configuration Screen</span>
    <span class="flex-spacer"></span>
  </mat-toolbar>
  
  <div class="table-container">
    <!--Data Table-->

    <mat-form-field  class="filter-field col-auto">
      <mat-label i18n="@@IATClients">IAT Clients</mat-label>
      <mat-select [(ngModel)]="clientId" (selectionChange)="onClientChange($event)" class="selectClient" id="selectClient">
        <mat-option *ngFor="let IATclient of IATclients" [value]="IATclient.clientId">
          {{ IATclient.clientName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    &nbsp;&nbsp;
    <mat-form-field class="filter-field">
      <mat-label i18n="@@filter">Filter</mat-label>
      <input 
        matInput
        (keyup)="applyFilter($event)"
        placeholder={{placeholder|translation}} 
        #input
      />
    </mat-form-field>
    &nbsp;&nbsp;
    <div class = "right">
      <button mat-stroked-button (click)="$event.stopPropagation();openAddDialog()">
        <mat-icon>add</mat-icon><span i18n="@@Add New">Add New</span>
      </button>
      &nbsp;&nbsp;
    <button  mat-stroked-button (click)="$event.stopPropagation();replicateAlertsDialog()">
      <span i18n="@@replicatealerts">Replicate Alerts</span>
    </button>
    &nbsp; &nbsp;
    <button mat-stroked-button (click)="RefreshList()">
      <span  i18n="@@refreshAlertsList">Refresh Alerts List</span>
    </button>
  </div>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
        <caption [hidden]="true"> Description </caption>
        <ng-container matColumnDef="deviceName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@devicename">Device Name</th>
          <td mat-cell *matCellDef="let row">{{ row.deviceName}}</td>
        </ng-container>

        <ng-container matColumnDef="alertDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@alertdescription">Alert Description</th>
          <td mat-cell *matCellDef="let row">{{ row.alertDescription}}</td>
        </ng-container>
  
        <ng-container matColumnDef="alertDefaultValue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@alertdefaultvalue" >Alert Default Value</th>
          <td mat-cell *matCellDef="let row">{{ row.alertDefaultValue }}</td>
        </ng-container>
  
        <ng-container matColumnDef="maxValue">
         <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@maxvalue">Max Value</th>
         <td mat-cell *matCellDef="let row">{{ row.maxValue }}</td>
        </ng-container>

        <ng-container matColumnDef="minValue">
         <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@minvalue">Min Value</th>
         <td mat-cell *matCellDef="let row">{{ row.minValue }}</td>
        </ng-container>

        <ng-container matColumnDef="uom">
         <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@uom">UOM</th>
         <td mat-cell *matCellDef="let row">{{ row.uom }}</td>
        </ng-container>

         <!-- Action Column -->
         <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div class="text-right">
            <button  class="edit-btn" mat-flat-button (click)="$event.stopPropagation();addeditAlertConfigurationDialog(row)">
              <mat-icon>edit</mat-icon> <span i18n="@@Edit">Edit</span> 
            </button>
            <button  mat-flat-button color="warn" (click)="$event.stopPropagation(); deleteAlertConfiguration(row.alertConfigurationID)">
              <mat-icon>delete</mat-icon><span i18n="@@Delete">Delete</span>
            </button>
          </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            {{noData|translation}}{{ input.value }}
          </td>
        </tr>
      </table>
  
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>
  