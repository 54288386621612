import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportAddEditComponent } from 'src/app/components/report-add-edit/report-add-edit.component';
import { IReport } from 'src/app/models/Report.model';
import { ReportService } from 'src/app/services/report.service';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';



@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ReportComponent implements OnInit {
  displayedColumns: string[] = ['reportName','reportDescription' ,'action'];
  dataSource: MatTableDataSource<IReport>;
  ReportNameDescription : string  = "Report Name,Description.";
  noData :string = 'No data found';
  Itemsperpage : string = 'Items per page';
  Nextpage : string = 'Next page';
  Previouspage : string = 'Previous page';
  of : string = 'of';
  Confirmdelete: string  = 'Confirm delete';
  AreyousuretodeleteReport : string  = "Are you sure to delete Report?";
Deletefailed : string  = "Delete failed";
ReportDeletionFailed : string  = "Report Deletion Failed";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public dialog: MatDialog,public reportService:ReportService,private commonService:CommonService,public router: Router) {
    // Create 100 users
  //  const bgroups = Array.from({ length: 10 }, (_, k) => createNewBGroup(k + 1));

    // Assign the data to the data source for the table to render
        this.dataSource=new MatTableDataSource<IReport>();
      

  }
  ngOnInit() {
    this.bindReport();
  }

   
   //bind Alert Rule Data to table
   bindReport(){
    this.reportService.getReport(AppConstants.gblAppId, AppConstants.gblClientId).then((data)=>{ 
    this.dataSource = new MatTableDataSource(data as IReport[]);  
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;    
  })
}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const rangelabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${filterPipe.transform(this.of)} ${length}`;
      }
    
      length = Math.max(length, 0);
    
      const startIndex = page * pageSize;
    
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
    
      return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
    };

     this.dataSource.sort = this.sort;
     const filterPipe = new TranslationPipe();
     this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
     this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
     this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
     this.paginator._intl.getRangeLabel = rangelabel;
     this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteReport(reportId:number){
    const filterPipe = new TranslationPipe();  
    const dialogRef=this.dialog.open(ConfirmDialogComponent,{
      data: {
        title: filterPipe.transform(this.Confirmdelete),
        text: filterPipe.transform(this.AreyousuretodeleteReport),
      },
    }); 
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        const modifiedBy:number=AppConstants.gblLoggedInUserId;
    this.reportService.deleteReport(reportId,modifiedBy).then((data)=>{
      
      this.bindReport();         
    }).catch((error)=>{      
      this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed),filterPipe.transform(this.ReportDeletionFailed));

    });
      }
    });

    
  }

  openReportAddDialog() {
   const dialogRef = this.dialog.open(ReportAddEditComponent, {
      width: '600px',
      data: null
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }
  openReportEditDialog(row:IReport) {
    console.log(row);
    const dialogRef = this.dialog.open(ReportAddEditComponent, {
      width: '600px',
      data: row
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

}

