<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="utf-8">
        <title>403-Forbidden</title>
    </head>
    <body>
        <h1>403-Forbidden</h1>
        <h2>You don't have permission to access this resource.</h2>
    </body>
</html>