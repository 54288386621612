import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AppConstants } from 'src/app/AppConstants';
import { IReport } from '../models/Report.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private httpService: HttpService) { }

  getReport(ApplicationId: number,ClientId:number){
    return this.httpService.get(AppConstants.apiUrl+"Report/GetReportByClient?ApplicationId="+ApplicationId+"&ClientId="+ClientId);     
 }

 getAllReport(ApplicationId: number,ClientId:number){
  return this.httpService.get(AppConstants.apiUrl+"Report/GetAllReport?ApplicationId="+ApplicationId+"&ClientId="+ClientId);     
}

 getReportByUser(ApplicationId: number,UserId:number,clientId:number){
  return this.httpService.get(AppConstants.apiUrl+"Report/GetReportByUser?ApplicationId="+ApplicationId+"&UserId="+UserId+"&clientId="+clientId);     
}
 
 postRoleData(reportObj:any): Observable<any>{
   return this.httpService.post(AppConstants.apiUrl+"Report/AddReport" ,JSON.stringify(reportObj));
 }
 putReportData(reportId:number,reportObj:any): Observable<any>{
  return this.httpService.put(AppConstants.apiUrl+"Report/UpdateReport?ReportId="+reportId, JSON.stringify(reportObj)) ;   
}
deleteReport(reportId:number,modifiedBy:number){
  return this.httpService.delete(AppConstants.apiUrl+"Report/DeleteReport?ReportId="+reportId+"&ModifiedBy="+modifiedBy);
 
 }
}
