<form  [formGroup]="escalatePackageForm" (ngSubmit)="onSubmit()">
<div mat-dialog-title class="escalatepkg-dialog-header color-green a">
    <!-- <mat-icon></mat-icon> &nbsp;&nbsp; Escalate a Package -->
    <span  i18n="@@Escalate a Package">Escalate a Package</span>
</div>

<mat-dialog-content class="mat-typography">   

    <mat-divider></mat-divider>
    <br />
    <mat-form-field>
        <mat-label i18n="@@Requester Email-ID">Requester Email-ID</mat-label>
        <input matInput type="text" value= {{glbUserEmailID}} readonly="true" />
    </mat-form-field>


    <mat-form-field>
        <mat-label i18n="@@Tracking Number">Tracking Number</mat-label>
        <input matInput type="text" formControlName="trackingNumber" />
        <mat-error *ngIf="escalatePackageForm.get('trackingNumber')?.touched && escalatePackageForm.get('trackingNumber')?.errors?.required">
            <span i18n="@@Required">Required</span>
        </mat-error> 
        <mat-error  *ngIf="escalatePackageForm.get('trackingNumber')?.errors?.pattern ">
            <span i18n="@@Invalid. Should contain numbers and characters upto 18 length.">Invalid. Should contain numbers and characters upto 18 length.</span>
        </mat-error>
        <button mat-icon-button matSuffix matTooltip="{{Help|translation}}" type="button"  (click)="openHelpDialog()" class="material-icons-outlined">
            <mat-icon>help_outline</mat-icon></button>
    </mat-form-field>
    
    <mat-form-field appearance="outline" class="escalate__text-input">
        <mat-label i18n="@@Description">Description</mat-label>
        <textarea formControlName="description" matInput rows="3" placeholder="{{Provideamoredetaileddescription |  translation}}" formControlName="description"></textarea>
        <mat-error *ngIf="escalatePackageForm.get('description')?.touched && escalatePackageForm.get('description')?.errors?.required">
            <span i18n="@@Required">Required</span>
        </mat-error> 
    </mat-form-field>

    
    
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close><span i18n="@@Cancel">Cancel</span></button>
    <button mat-flat-button type="submit" cdkFocusInitial color="primary"  [disabled]="!escalatePackageForm.valid">
        <span i18n="@@Submit">Submit</span>
    </button>
     
</mat-dialog-actions>
  </form>
