import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { QlikReportService } from 'src/app/services/qlik-report.service';
import { AppConstants } from 'src/app/AppConstants';

declare const qlikLogin: any;

@Component({
  selector: 'app-qlik-report',
  templateUrl: './qlik-report.component.html',
  styleUrls: ['./qlik-report.component.scss']
})



export class QlikReportComponent implements OnInit {

  moduleId: number;
  qlikData$: any;
  qlikDetails: any;
  qlikApplicationId: any;
  flag: any;
  TomsOnly : boolean = false;
  current_url!: SafeResourceUrl;
  displaybtns:boolean=false;
  supportedlanguage : string [] = ["de","en","es","fr","pl","pt","en-US","fr-CA","it","ja","ko","zh-Ha"];
  qlikSheetId: string='';
  constructor(private qlikReportService: QlikReportService, private router: Router, private sanitizer: DomSanitizer) {
    this.moduleId = Number(this.router.getCurrentNavigation()?.extras.state);
  }

  ngOnInit(): void {
    this.flag = false;
   this.getReport(this.moduleId);
  }

  
  
  getReport(moduleId: number) {
    this.qlikReportService.getByModuleId(moduleId).then((data) => {
      this.flag = false;
      this.qlikData$ = "";
      this.qlikData$ = JSON.stringify(data);
      let myObj = JSON.parse(this.qlikData$);
      
      // de serialize json data coming from api and pass qlik jwt token to qlik service

      qlikLogin(myObj.token, myObj.qlikDetails.tenantLink , myObj.qlikDetails.webIntegrationId).then((value: any) => {   // Once user will authenticate, pass required details to html 
      {    
          this.qlikDetails = myObj.qlikDetails;
          this.qlikApplicationId = this.qlikDetails.qlikApplicationId;
          this.qlikSheetId = this.qlikDetails.qlikSheetId;
          this.flag = true;
          this.updateUrl(this.qlikDetails.tenantLink,this.qlikDetails.theme);
        }

      });

    });
  }

 
  

  updateUrl(tenanturl :  string,theme : string) {
      this.displaybtns=true;
      if (!tenanturl.endsWith("/")) tenanturl += '/';
      if(this.supportedlanguage.includes(AppConstants.LanguageText))
        {
          if(localStorage.getItem('locale')?.includes("zh-Ha"))
          {
            this.current_url = this.sanitizer.bypassSecurityTrustResourceUrl(
              tenanturl+"sense/app/" + this.qlikApplicationId + "/sheet/" + this.qlikSheetId + "/state/analysis/select/_Language/zh-CN/options/Language/zh-CN/theme/"+theme);
          }
          else
          {
            this.current_url = this.sanitizer.bypassSecurityTrustResourceUrl(
              tenanturl+"sense/app/" + this.qlikApplicationId + "/sheet/" + this.qlikSheetId + "/state/analysis/select/_Language/" + localStorage.getItem('locale') +"/options/Language/"+localStorage.getItem('locale') + "/theme/"+theme);
          }
        }
      else
        {
          this.current_url = this.sanitizer.bypassSecurityTrustResourceUrl(
            tenanturl+"sense/app/" + this.qlikApplicationId + "/sheet/" + this.qlikSheetId + "/state/analysis/select/_Language/en/options/Language/en/theme/"+theme);
        }
 
    }
  }



