import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/AppConstants';
import { AddRolescreenComponent } from 'src/app/components/add-rolescreen/add-rolescreen.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
import { IRoleScreen } from 'src/app/models/Rolescreen.model';
import { CommonService } from 'src/app/services/common.service';
import { RoleScreenService } from 'src/app/services/rolescreen.service';


@Component({
  selector: 'app-userroles-cvascreens',
  templateUrl: './userroles-cvascreens.component.html',
  styleUrls: ['./userroles-cvascreens.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class UserrolesCvascreensComponent implements OnInit {
  displayedColumns: string[] = ['screenName', 'roleName', 'action'];
  dataSource: MatTableDataSource<IRoleScreen>;
  ScreenRoleName: string = "Screen,Role Name.";
  noData: string = 'No data found';
  Itemsperpage: string = 'Items per page';
  Nextpage: string = 'Next page';
  Previouspage: string = 'Previous page';
  of: string = 'of';
  Confirmdelete: string = "Confirm delete";
  AreyousuretodeletethisMapping: string = "Are you sure to delete this Mapping?";
  Deletefailed: string = "Delete failed";
  RoleScreenDeletionFailed: string = "Role Screen Deletion Failed";
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public dialog: MatDialog,
    public rolescreenService: RoleScreenService,
    private commonService: CommonService
  ) {

    this.dataSource = new MatTableDataSource<IRoleScreen>();
  }


  bindRoleScreen() {
    this.rolescreenService.getRoleScreen(AppConstants.gblLoggedInUserId).then((data) => {
      this.dataSource = new MatTableDataSource(data as IRoleScreen[]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    const rangelabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${filterPipe.transform(this.of)} ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
    };


    this.dataSource.sort = this.sort;
    const filterPipe = new TranslationPipe();
    this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
    this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
    this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
    this.paginator._intl.getRangeLabel = rangelabel;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteRoleScreen(roleScreenId: number) {
    const filterPipe = new TranslationPipe();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: filterPipe.transform(this.Confirmdelete),
        text: filterPipe.transform(this.AreyousuretodeletethisMapping),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.rolescreenService
          .deleteRoleScreen(roleScreenId)
          .then((data) => {
            this.bindRoleScreen();
          })
          .catch((error) => {
            this.commonService.openMessageDialog(
              filterPipe.transform(this.Deletefailed),
              filterPipe.transform(this.RoleScreenDeletionFailed)
            );
          });
      }
    });
  }

  ngOnInit() {

    this.bindRoleScreen();
  }

  addRolescreenDialog() {
    const filterPipe = new TranslationPipe();
    const dialogRef = this.dialog.open(AddRolescreenComponent, { 
      panelClass: 'custom-role-container',
      width:'430px',
      data: {
        title: filterPipe.transform(this.Confirmdelete),
        text: filterPipe.transform(this.AreyousuretodeletethisMapping),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.bindRoleScreen();
      this.ngOnInit();
    });
  }
}

