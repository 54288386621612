<form [formGroup]="userbgMapForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-title class="color-green" *ngIf="flag==1">
    <mat-icon>add</mat-icon> &nbsp;&nbsp; 
    <span  i18n="@@Add User BusinessGroup Mapping">Add User BusinessGroup Mapping</span>
    
  </div>
  
  <div mat-dialog-title class="color-voilet" *ngIf="flag==2">
    <mat-icon>delete</mat-icon> &nbsp;&nbsp; 
    <span  i18n="@@Delete User BusinessGroup Mapping">Delete User BusinessGroup Mapping</span>
  </div>
  
  <mat-dialog-content class="class-17">
    <mat-divider></mat-divider>
  
    <mat-card class="class-17">
      
      <ng-multiselect-dropdown 
      [placeholder]=SelectClient|translation
      [settings]="dropdownSettings"
      [data]="custsMaster"  
      formControlName="myItems"
      (onSelect)="onClientSelect($event)" 
      (onDeSelect)="onClientSelect($event)" 
      name="custsMaster"
      
      required>
  </ng-multiselect-dropdown>
  <mat-error *ngIf=" userbgMapForm.get('myItems')?.touched && userbgMapForm.get('myItems')?.errors?.required">
    <span i18n="@@Required">Required</span>
  </mat-error>
  <br/>
  <br/>
  
  
  <ng-multiselect-dropdown 
      [placeholder]=SelectBusinessGroup|translation
      [settings]="bgdropdownSettings"
      [data]="UnmappedBg"  
      formControlName="bgItems"
      (onSelect)="onBGSelect($event)" 
      (onDeSelect)="onBGSelect($event)" 
      (onSelectAll)="onSelectAllBG($event)"       
  
      name="UnmappedBg"
      
      required>
  </ng-multiselect-dropdown>
  <mat-error *ngIf=" userbgMapForm.get('bgItems')?.touched && userbgMapForm.get('bgItems')?.errors?.required">
    <span i18n="@@Required">Required</span>
  </mat-error>
  
    </mat-card>
    <br />
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
      <span i18n="@@Cancel">Cancel</span>
    </button>
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
      type="submit"
      [disabled]="!userbgMapForm.valid"
    >
    <span i18n="@@Submit">Submit</span>
    </button>
  </mat-dialog-actions>
  </form>