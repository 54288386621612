import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { Device } from 'src/app/models/device.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-pair-new-device',
  templateUrl: './pair-new-device.component.html',
  styleUrls: ['./pair-new-device.component.scss'],
})
export class PairNewDeviceComponent implements OnInit {
  pairNewDeviceForm = new FormGroup({
    scoutId: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]{6,8}$'),
    ]),
    proNo: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]{10,11}$'),
    ]),
    ssccNo: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]{20}$'),
    ]),
  });

  constructor(public dialog: MatDialog, private dataService: DataService) {}

  async ngOnInit() {
    this.setFocusOnFirstControl();
  }

  setFocusOnFirstControl() {
    const invalidControl = <HTMLElement>(
      document.querySelector('input[formControlName="scoutId"]')
    );
    invalidControl?.focus();
  }

  async onSubmit() {
    if (this.pairNewDeviceForm.valid) {
      console.log(this.pairNewDeviceForm.value);

      const deviceInfo: Device = {
        scoutDeviceId: this.pairNewDeviceForm.value.scoutId as string,
        proNumber: this.pairNewDeviceForm.value.proNo as string,
        ssccNumber: this.pairNewDeviceForm.value.ssccNo as string,
        customerId: 'Medtronic',
      };
      let res: any = await this.dataService.pairDevice(deviceInfo);
     
      if (res.isPairDeviceSucess) {
        this.pairNewDeviceForm.reset();
        this.setFocusOnFirstControl();
      }

      await this.dialog.open(MsgDialogComponent, {
        data: {
          title: res.isPairDeviceSucess ? 'Pairing Success' : 'Pairing Failed',
          text: res.message,
        },
      });
    }
  }

  onKeyEnter() {
    const invalidControl = <HTMLElement>(
      document.querySelector('input.ng-invalid')
    );
    invalidControl?.focus();
  }
}
