<div class="container1">
  <div *ngIf="loaderService.visibility | async" class="loader-container">
    <mat-spinner color="accent"></mat-spinner>
  </div>

  <div class="main-section">
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>

  <app-footer></app-footer>
</div>
