<mat-toolbar class="pair-new-device-toolbar">
  &nbsp;&nbsp;
  <span i18n="@@Users">Users</span>
  <span class="flex-spacer"></span>
</mat-toolbar>
<div class="table-container">
  <mat-form-field class="filter-field col-auto" *ngIf="showInternalUser">
    <mat-label i18n="@@Clients">Clients</mat-label>
    <mat-select class="selectClient" id="selectClient" disableOptionCentering [disabled]="custs.length == 1" [(ngModel)]="selectedClientValue"
      placeholder={{SelectClient|translation}} #custSelect (selectionChange)="onClientChange($event)">
        <ngx-mat-select-search id="search" class="search-color" [(ngModel)]="searchTxt" matInput noEntriesFoundLabel={{Nodataavailable|translation}} placeholderLabel={{Search|translation}} autocomplete="off" >
        </ngx-mat-select-search>
      <mat-option *ngFor="let item of custs  | clientSearch : searchTxt" [value]="item.clientId">
        {{ item.clientName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  &nbsp;&nbsp;
  <mat-form-field class="filter-field">
    <mat-label i18n="@@filter">Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filterName" placeholder={{placeholder|translation}}
      #input />
  </mat-form-field>
  &nbsp;&nbsp;
  <div class="right">
    <button mat-stroked-button (click)="RefreshUserList()">
      <span i18n="@@Refresh User List">Refresh User List</span>
    </button>&nbsp; &nbsp;
    <button mat-stroked-button (click)="openInviteNewUserDialog()">
      <mat-icon>person_add</mat-icon>
      <span i18n="@@Invite New User">Invite New User</span>
    </button>
  </div>

</div>


<div class="table-container">
  <!--Filter-->
  <!--Data Table-->
  <table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
    <caption [hidden]="true"> Description </caption>
    <!-- NAME Column -->
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@NAME">NAME</th>
      <td mat-cell *matCellDef="let row">{{ row.fullName }}</td>
    </ng-container>

    <!-- EMAIL Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@EMAIL">EMAIL</th>
      <td mat-cell *matCellDef="let row">{{ row.email }}</td>
    </ng-container>

    <!-- USER ROLE Column -->
    <ng-container matColumnDef="roleName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@USER ROLE">USER ROLE</th>
      <td mat-cell *matCellDef="let row">{{ row.roleName }}</td>
    </ng-container>

    <!-- BUSINESS GROUPS Column -->
    <ng-container matColumnDef="businessGroupCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@BUSINESS GROUP Count">BUSINESS GROUP Count</th>
      <td mat-cell *matCellDef="let row">{{ row.businessGroupCount }}</td>
    </ng-container>

    <!-- CLIENTS Column -->
    <ng-container matColumnDef="clientCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@CLIENTS Count">CLIENTS Count</th>
      <td mat-cell *matCellDef="let row">{{ row.clientCount }}</td>
    </ng-container>

    <!-- ACTIONS Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row">
        <div class="text-right">
          <button mat-flat-button (click)="$event.stopPropagation(); openUserEditDialog(row)" class="edit-btn">
            <mat-icon>edit</mat-icon>
            <span i18n="@@Edit">Edit</span>
          </button>
          <button mat-flat-button color="warn"
            (click)="$event.stopPropagation();deleteUser(row.userId , row.objectId , row.roleName)">
            <mat-icon>delete</mat-icon>
            <span i18n="@@Delete">Delete</span>
          </button>
        </div>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail" [@detailExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          ">
          <div class="expand-container">
            <mat-card class="example-card">
              <mat-card-content>
                <div class="grid-warpper" [ngClass]="isEdit ? 'grid-2-col' : 'grid-1-col'">
                  <div>
                    <div class="flex-space-between">
                      <span class="header-text color-voilet">{{MappedBusinessGroups|translation}}
                        ({{mappedBGCount}})</span>
                      <button mat-icon-button color="accent" class="edit-style" *ngIf="!isEdit" (click)="isEdit = true">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </div>
                    <!--Filter-->
                    <div class="margin-x">
                      <mat-form-field *ngIf="mappedBGByUser_Master.length>0">
                        <mat-label i18n="@@filter">Filter</mat-label>
                        <input matInput placeholder="{{BusinessGroups|translation}}" [(ngModel)]="bgString"
                          (keyup)="applyMappedBGFilter($event)" />
                      </mat-form-field>
                    </div>
                    <div>

                      <button class="reverse"
                        *ngIf="AddAllRemoveAllFlag && isEdit && mappedBGByUser.length>0 && mappedBGCount!=1"
                        mat-raised-button (click)="deleteAllBGMapping(selectedUserId)">
                        <span i18n="@@Remove All">Remove All</span>
                        &nbsp; <mat-icon color="warn">delete</mat-icon></button> &nbsp;
                      <button class="reverse" mat-raised-button (click)="openAddSelective(selectedUserId,2)"
                        *ngIf="AddAllRemoveAllFlag  && isEdit && mappedBGByUser.length>0 && mappedBGCount!=1">
                        <span i18n="@@Remove Selected">Remove Selected</span>
                        &nbsp; <mat-icon color="warn">delete</mat-icon> </button>

                    </div>

                    <mat-list class="group-list">
                      <div *ngFor="let bgItem of mappedBGByUser">
                        <div mat-subheader class="clientHeader">{{ bgItem.clientName }}</div>
                        <mat-list-item *ngFor="let item of bgItem.businessGroups ">
                          <mat-icon matListItemIcon class="color-blue"></mat-icon>
                          <div mat-line matListItemLine class="bgName">{{ item.businessGroupName }}</div>
                          <button matListItemMeta *ngIf="isEdit  && mappedBGCount!=1" mat-icon-button
                            class="color-warn " (click)="deleteSingleBGMapping(selectedUserId,item.businessGroupId)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </mat-list-item>
                      </div>

                    </mat-list>
                    <div class="no-search-results" *ngIf="isEdit && mappedBGByUser.length==0">
                      {{noData|translation}}
                    </div>
                    <div class="no-search-results" *ngIf="!isEdit && mappedBGByUser.length==0">
                      {{noData|translation}}
                    </div>
                  </div>
                  <div *ngIf="isEdit" [@fadeInAnimation]>
                    <div class="flex-space-between">
                      <span class="header-text color-blue">{{AllBusinessGroups|translation}}
                        ({{unMappedBGCount}})</span>
                      <button mat-icon-button class="class-180" (click)="isEdit = false;clearFilter();">
                        <mat-icon>check</mat-icon>
                      </button>
                    </div>
                    <!--Filter-->
                    <div class="margin-x">
                      <mat-form-field *ngIf="unMappedBGByUser_Master.length>0">
                        <mat-label i18n="@@filter">Filter</mat-label>
                        <input matInput placeholder="{{BusinessGroups|translation}}" [(ngModel)]="allBGString"
                          (keyup)="applyUnMappedBGFilter($event)" />
                      </mat-form-field>
                    </div>

                    <span></span>
                    <div id="style-190">
                      <button mat-raised-button (click)="mapAllBG(selectedUserId)"
                        *ngIf="AddAllRemoveAllFlag && unMappedBGByUser.length>0">
                        <span i18n="@@Add All">Add All</span>
                        <mat-icon class="color-green">add_circle</mat-icon> </button>
                      &nbsp;<button mat-raised-button class="reverse" (click)="openAddSelective(selectedUserId,1)"
                        [disabled]="!AddSelectedFlag" *ngIf="AddAllRemoveAllFlag && unMappedBGByUser.length>0">
                        <span i18n="@@Add Selected">Add Selected</span>
                        &nbsp;
                        <mat-icon class="color-green">add_circle</mat-icon> </button>


                    </div>
                    <mat-list class="group-list">
                      <div *ngFor="let bgItem of unMappedBGByUser">
                        <div mat-subheader>{{ bgItem.clientName }}</div>
                        <mat-list-item *ngFor="let item of bgItem.businessGroups ">
                          <mat-icon matListItemIcon class="color-blue">groups</mat-icon>
                          <div matListItemLine class="color-blue">{{ item.businessGroupName }}</div>
                          <button matListItemMeta class="color-green"
                            (click)="mapSingleBG(selectedUserId,item.businessGroupId);">
                            <mat-icon>add_circle</mat-icon>
                          </button>
                        </mat-list-item>
                      </div>

                    </mat-list>
                    <div class="no-search-results" *ngIf="isEdit && unMappedBGByUser.length==0">
                      {{noData|translation}}
                    </div>
                    <div class="no-search-results" *ngIf="!isEdit && unMappedBGByUser.length==0">
                      {{noData|translation}}
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element;bindDetails(expandedElement,element.userId, element.roleName);">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        {{noData|translation}} {{ input.value }}
      </td>
    </tr>

  </table>

  <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>