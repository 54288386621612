import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AppConstants } from 'src/app/AppConstants';
import { Observable } from 'rxjs';
import { IRoleScreen, RoleScreen } from '../models/Rolescreen.model';

@Injectable({
  providedIn: 'root'
})
export class RoleScreenService {

  constructor(private httpService: HttpService) { }

  getRoleScreen(UserId: number){
    return this.httpService.get(AppConstants.apiUrl+"RoleScreen/GetRoleScreen?UserId="+ UserId);     
 }

 postRoleScreenData(mapObj:RoleScreen): Observable<any>{
  return this.httpService.post(AppConstants.apiUrl+"RoleScreen/AddRoleScreen", JSON.stringify(mapObj)) ;   
}
 
 deleteRoleScreen(roleId:number){
  return this.httpService.delete(AppConstants.apiUrl+"RoleScreen/DeleteRoleScreen?RoleScreenId="+roleId+"&LoggedinUserId="+AppConstants.gblLoggedInUserId);
 }

}
