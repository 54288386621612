import {Pipe, PipeTransform} from '@angular/core'

@Pipe ({ name:'translation' })

export class TranslationPipe implements PipeTransform {
  transform (value:string) {
    const localize = $localize;
   return localize(<any>{ '0': `:@@${value}:${value}`, 'raw': [':'] });
   }
  
}
