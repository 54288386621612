import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor.service';

// Material Modules
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './pages/menu/menu.component';
import { PairNewDeviceComponent } from './pages/menu/pair-new-device/pair-new-device.component';
import { PairedDevicesComponent } from './pages/menu/paired-devices/paired-devices.component';
import { AlertRulesComponent } from './pages/menu/alert-rules/alert-rules.component';
import { ManageAlertRulesComponent } from './pages/menu/manage-alert-rules/manage-alert-rules.component';
import { MsgDialogComponent } from './components/msg-dialog/msg-dialog.component';
import { UserListComponent } from './pages/menu/user-list/user-list.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { InviteNewUserComponent } from './components/invite-new-user/invite-new-user.component';
import { ReleaseNotesComponent } from './pages/menu/release-notes/release-notes.component';
import { BusinessGroupsComponent } from './pages/menu/business-groups/business-groups.component';
import { EscalatePackageComponent } from './components/escalate-package/escalate-package.component';
import { EscalationguideDialogComponent } from './components/escalationguide-dialog/escalationguide-dialog.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { UserRolesComponent } from './pages/menu/user-roles/user-roles.component';
import { BusinessgroupEditComponent } from './components/businessgroup-edit/businessgroup-edit.component';
import { CvaScreensComponent } from './pages/menu/cva-screens/cva-screens.component';
import { ReportComponent } from './pages/menu/Report/report.component';
import { UserRoleAddEditComponent } from './components/user-role-add-edit/user-role-add-edit.component';
import { HomeScreenComponent } from './pages/menu/home-screen/home-screen.component';
import { AccountnoBusinessgroupComponent } from './pages/menu/accountno-businessgroup/accountno-businessgroup.component';
import { ReportAddEditComponent } from './components/report-add-edit/report-add-edit.component';
import { UserrolesCvascreensComponent } from './pages/menu/userroles-cvascreens/userroles-cvascreens.component';
import { AddRolescreenComponent } from './components/add-rolescreen/add-rolescreen.component';
import { AddeditCvascreenComponent } from './components/addedit-cvascreen/addedit-cvascreen.component';
import { ModuleListComponent } from './pages/menu/module-list/module-list.component';
import { ModuleEditComponent } from './components/module-edit/module-edit.component';
import { ClientComponent } from './pages/menu/client/client.component';
import { TenantComponent } from './pages/menu/tenant/tenant.component';
import { AddeditClientComponent } from './components/client-add-edit/client-add-edit.compononent';
import { AddEditTenantComponent } from './components/tenant-add-edit/tenant-add-edit.compononent';
import { ProcessBusinessgroupComponent } from './pages/menu/process-businessgroup/process-businessgroup.component';
import { UserFeedbackComponent } from './pages/menu/user-feedback/user-feedback.component';
import { FavoriteModuleListComponent } from './pages/menu/favorite-module-list/favorite-module-list.component';
import { FavoriteModuleEditComponent } from './components/favorite-module-edit/favorite-module-edit.component';
import { ClientSearchPipe } from './pipes/client-search.pipe';
import { AdminGuideComponent } from './pages/menu/admin-guide/admin-guide.component';
import { RedirectingComponent } from './pages/menu/redirecting/redirecting.component';
import { QlikReportComponent } from './components/qlik-report/qlik-report.component';
import { ClientDialogComponent } from './components/client-dialog/client-dialog.component';
import { AddUserBGMapComponent } from './components/add-userbgmap/add-userbgmap.component';
import { UserNameDialogComponent } from './components/user-name-dialog/user-name-dialog.component';
import { AddBgAccMapComponentComponent } from './components/add-bg-acc-map-component/add-bg-acc-map-component.component';
import { DeleteBgAccMapComponent } from './components/delete-bg-acc-map/delete-bg-acc-map.component';
import { RedemptionComponent } from './pages/menu/redemption/redemption.component';
import { CustomerrorComponent } from './pages/menu/customerror/customerror.component';
import { I18nModule } from './locale/i18n.module';
import { TranslationPipe } from './locale/translation.pipe';
import { IatConfiguartionScreenComponent } from './pages/menu/iat-configuration-screen/iat-configuration-screen.component';
import { AddeditAlertConfigurationComponent } from './components/addedit-alertconfiguration/addedit-alertconfiguration.component';
import { ReplicateAlertConfigurationComponent } from './components/replicate-alertconfiguration/replicate-alertconfiguration.component';
import { AuthConfigModule } from './auth_config.module';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { ScriptLoaderModule } from "ngx-script-loader";
import { CallbackComponent } from './components/callback/callback.component';
import { QlikWrapperModule } from "./components/qlik-report/qlik-wrapper.module";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ErrorHandlerService } from './services/error-handler.service';
import { ForbiddenComponent } from './pages/menu/forbidden/forbidden.component';
import { NotificationComponent } from './pages/menu/notification/notification.component';
import { AddEditNotificationComponent } from './components/notification-add-edit/notification-add-edit.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AddNotificationClientComponent } from './components/add-notificationclient/add-notificationclient.component';
import { DeleteNotificationClientComponent } from './components/delete-notificationclient/delete-notificationclient.component';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FeedbackComponent,
    HomeComponent,
    MenuComponent,
    PairNewDeviceComponent,
    PairedDevicesComponent,
    AlertRulesComponent,
    ManageAlertRulesComponent,
    MsgDialogComponent,
    UserListComponent,
    ConfirmDialogComponent,
    InviteNewUserComponent,
    BusinessGroupsComponent,
    EscalatePackageComponent,
    EscalationguideDialogComponent,
    UserEditComponent,
    UserRolesComponent,
    BusinessgroupEditComponent,
    CvaScreensComponent,
    ReportComponent,
    UserRoleAddEditComponent,
    HomeScreenComponent,
    ReportAddEditComponent,
    AccountnoBusinessgroupComponent,
    UserrolesCvascreensComponent,
    AddRolescreenComponent,
    AddUserBGMapComponent,
    AddeditCvascreenComponent,
    ModuleListComponent,
    ModuleEditComponent,
    ClientComponent,
    TenantComponent,
    AddeditClientComponent,
    AddEditTenantComponent,
    ProcessBusinessgroupComponent,
    UserFeedbackComponent,
    FavoriteModuleListComponent,
    FavoriteModuleEditComponent,
    ClientSearchPipe,
    AdminGuideComponent,
    RedirectingComponent,
    ClientDialogComponent,
    UserNameDialogComponent,
    AddBgAccMapComponentComponent,
    DeleteBgAccMapComponent,
    RedemptionComponent,
    CustomerrorComponent,
    TranslationPipe,
    ReleaseNotesComponent,
    IatConfiguartionScreenComponent,
    AddeditAlertConfigurationComponent,
    ReplicateAlertConfigurationComponent,
    CallbackComponent,
    ForbiddenComponent,
    NotificationComponent,
    AddEditNotificationComponent,
    AddNotificationClientComponent,
    DeleteNotificationClientComponent,
    NotificationDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AuthConfigModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatCardModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatGridListModule,
    NgMultiSelectDropDownModule,
    ScriptLoaderModule,
    QlikWrapperModule,
    NgxMatSelectSearchModule,
    MatNativeDateModule,
    MatDatepickerModule
  ],
  providers: [
    [I18nModule.setLocale(), I18nModule.setLocaleId()],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService }

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
