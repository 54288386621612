
<mat-toolbar class="pair-new-device-toolbar">
  &nbsp;&nbsp;
  <span i18n="@@Tenants">Tenants</span>
  <span class="flex-spacer"></span>
  <button mat-stroked-button (click)="RefreshTenantList()">
    <span i18n="@@Refresh Tenant List">Refresh Tenant List</span>
  </button>&nbsp; &nbsp;
  <button *ngIf="selectedRole==5" mat-stroked-button (click)="openAddTenantDialog()">
    <mat-icon>person_add</mat-icon>
    <span i18n="@@Add Tenant">Add Tenant</span>
  </button>
</mat-toolbar>

<div class="table-container">
  <!--Filter-->
  <mat-form-field class="filter-field">
    <mat-label i18n="@@filter">Filter</mat-label>
    <input matInput
           (keyup)="applyFilter($event)"
           placeholder="{{placeholder | translation}}"
           #input />
  </mat-form-field>

  <!--Data Table-->
  <table mat-table
         matSort
         [dataSource]="dataSource"
         multiTemplateDataRows
         class="mat-elevation-z8">
         <caption [hidden]="true"> Description </caption>
    <ng-container matColumnDef="tenantId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Id">Id</th>
      <td mat-cell *matCellDef="let row">{{ row.tenantId }}</td>
    </ng-container>
    <!-- NAME Column -->
    <ng-container matColumnDef="tenantName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Name">Name</th>
      <td mat-cell *matCellDef="let row">{{ row.tenantName }}</td>
    </ng-container>

    <!-- Tenant Link Column -->
    <ng-container matColumnDef="tenantLink">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Link">Link</th>
      <td mat-cell *matCellDef="let row">{{ row.tenantLink }}</td>
    </ng-container>

    <ng-container matColumnDef="webIntegrationId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Web Integration Id">Web Integration Id</th>
      <td mat-cell *matCellDef="let row">{{ row.webIntegrationId }}</td>
    </ng-container>

    <ng-container matColumnDef="theme">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Theme">Theme</th>
      <td mat-cell *matCellDef="let row">{{ row.theme }}</td>
    </ng-container>

    <ng-container matColumnDef="applicationId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Application Id">Application Id</th>
      <td mat-cell *matCellDef="let row">{{ row.applicationId }}</td>
    </ng-container>

    <!-- ACTIONS Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row">
        <div *ngIf="selectedRole==5" >
          <button mat-flat-button
                  (click)="$event.stopPropagation(); openEditTenantDialog(row)"
                  class="edit-btn">
            <mat-icon>edit</mat-icon>
            <span i18n="@@Edit">Edit</span> 
          </button>
          <button mat-flat-button
                  color="warn"
                  (click)="$event.stopPropagation();deleteTenant(row.tenantId)">
            <mat-icon>delete</mat-icon>
            <span i18n="@@Delete">Delete</span>
          </button>
        </div>
      </td>
    </ng-container>


    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail"
             [@detailExpand]="
          element == expandedElement ? 'expanded' : 'collapsed'
        ">
          <div class="expand-container">
            <mat-card class="example-card">
              <mat-card-content>
                <div class="grid-warpper"
                     [ngClass]="isEdit ? 'grid-2-col' : 'grid-1-col'">
                  <div>
                    <div class="flex-space-between">
                      <span class="header-text color-voilet">{{MappedClients|translation}} ({{mappedClients_Master.length>0?mappedClients_Master.length :0}})</span>
                      <button mat-icon-button
                              color="accent"
                              class="edit-class"
                              *ngIf="!isEdit"
                              (click)="isEdit = true">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </div>
                    <!--Filter-->
                    <div class="margin-x">
                      <mat-form-field *ngIf="mappedClients_Master.length>0">
                        <mat-label i18n="@@filter">Filter</mat-label>
                        <input matInput placeholder="{{Clients | translation}}" [(ngModel)]="clientsString" (keyup)="applyMappedFilter($event)" />
                      </mat-form-field>
                    </div>
                    <div class="flex-space-between">
                      <span></span>
                      <button *ngIf="isEdit &&  mappedClients.length>0"  class="reverse" mat-raised-button (click)="deleteAllClientsTenantMapping(selectedTenantId)">
                        <span i18n="@@Remove All">Remove All</span> &nbsp;
                        <mat-icon color="warn">delete</mat-icon>
                      </button>
                    </div>

                    <mat-list class="group-list">
                      <mat-list-item *ngFor="let item of mappedClients">
                        <mat-icon matListItemIcon class="color-voilet">work</mat-icon>
                        <div matListItemLine class="color-voilet">{{ item.clientName }}</div>
                        <button *ngIf="isEdit" matListItemMeta class="color-warn" (click)="deleteSingleclientTenantMapping(selectedTenantId,item.clientId)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </mat-list-item>
                      <div class="no-search-results" *ngIf=" isEdit && mappedClients.length==0">
                        {{noData|translation}} 
                      </div>
                      <div class="no-search-results" *ngIf="!isEdit && mappedClients.length==0">
                        {{noData|translation}} 
                      </div>
                    </mat-list>


                  </div>
                  <div *ngIf="isEdit" [@fadeInAnimation]>
                    <div class="flex-space-between">
                      <span class="header-text color-blue">{{AllClients|translation}}({{unMappedClients_Master.length}})</span>

                      <button mat-icon-button
                              class="check-class"
                              (click)="isEdit = false;clearFilter();">
                        <mat-icon>check</mat-icon>
                      </button>
                    </div>

                    <!--Filter-->
                    <div class="margin-x ">
                      <mat-form-field *ngIf="unMappedClients_Master.length>0">
                        <mat-label i18n="@@filter">Filter</mat-label>
                        <input matInput placeholder="{{Clients | translation}}" [(ngModel)]="allClientsString" (keyup)="applyUnMappedFilter($event)" />
                      </mat-form-field>
                    </div>
                    <div class="flex-space-between">
                      <span></span>
                      <button mat-raised-button  class="reverse" (click)="mapAllClients(selectedTenantId)" *ngIf="unMappedClients.length>0">
                        <span i18n="@@Add All">Add All</span> &nbsp;
                        <mat-icon class="color-green">add_circle</mat-icon>
                      </button>
                    </div>
                    <mat-list class="module-list">
                      <mat-list-item *ngFor="let item of unMappedClients">
                        <mat-icon matListItemIcon class="color-blue">work</mat-icon>
                        <div  matListItemLine  class="color-blue">{{ item.clientName }}</div>
                        <button matListItemMeta class="color-green" (click)="mapSingleClient(selectedTenantId,item.clientId);">
                          <mat-icon>add_circle</mat-icon>
                        </button>
                      </mat-list-item>
                      <div class="no-search-results" *ngIf=" isEdit && unMappedClients.length==0">
                        {{noData|translation}} 
                      </div>
                      <div class="no-search-results" *ngIf="!isEdit && unMappedClients.length==0">
                        {{noData|translation}} 
                      </div>
                    </mat-list>

                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element;bindDetails(expandedElement,element.tenantId);"></tr>
    <tr mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"></tr>


    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        {{noData|translation}}  {{ input.value }}
      </td>
    </tr>

  </table>

  <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>

