import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators, FormGroup, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CvaScreensComponent } from 'src/app/pages/menu/cva-screens/cva-screens.component';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
import { IATConfigurationService } from 'src/app/services/iatconfiguration.service';
import { IATAlertConfiguration } from 'src/app/models/IAT-alertconfiguration.model';
import { MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

function rangeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const minValue = control.get('minValue');
    const maxValue = control.get('maxValue');
    const alertDefaultValue = control.get('alertDefaultValue');

   
    if (minValue !== null && maxValue !== null  && alertDefaultValue !== null) {
      if (parseFloat(minValue.value) > parseFloat(maxValue.value)) {
          return { minValueGreaterThanMaxValue: true };
      }

      if (parseFloat(maxValue.value) < parseFloat(minValue.value)) {
          return { maxValueLessThanMinValue: true };
      }

      if (parseFloat(alertDefaultValue.value) < parseFloat(minValue.value) || parseFloat(alertDefaultValue.value) > parseFloat(maxValue.value)) {
        return { defaultValueNotInRange: true };
    }
  }

  return null;
  };
}


@Component({
  selector: 'app-addedit-alertconfiguration',
  templateUrl: './addedit-alertconfiguration.component.html',
  styleUrls: ['./addedit-alertconfiguration.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class AddeditAlertConfigurationComponent implements OnInit {

  selectedAlert: any;
  alertsdefinations: any[] = [];
  devicenames: any[] = [];
  deviceId: number = 0;
  insertSuccess: string = "Insert Success";
  updateSuccess: string = "Update Success";
  failed: string = 'Failed';
  recordInsertedSucessfully: string = 'Record Inserted Sucessfully';
  recordUpdatedSucessfully: string = 'Record Updated Sucessfully';
  duplicateRecord: string = 'Duplicate Record';
  globalerror: string = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
  clientName: string = '';
  isReadOnly = false;
  maxValueValidation : string  = 'Max value should be greater than min and alert default value.';
  minValueValidation :  string  = 'Min Value cannot be greater than max and alert default value.';
  defaultValueValidation : string  = 'Alert Default Value must be between Min Value and Max Value.';
  editAlerconfigurationForm = new FormGroup({
    alertConfigurationID: new FormControl(0),
    alert: new FormControl(0, [Validators.required]),
    alertDefaultValue: new FormControl(0, [Validators.required, Validators.pattern('^[+-]?\\d+(\\.\\d{1,2})?$')]),
    maxValue: new FormControl(0, [Validators.required, Validators.pattern('^[+-]?\\d+(\\.\\d{1,2})?$')]),
    minValue: new FormControl(0, [Validators.required, Validators.pattern('^[+-]?\\d+(\\.\\d{1,2})?$')]),
    device: new FormControl(0, [Validators.required]),
    UOM: new FormControl(0, [Validators.required])
  }, { validators: rangeValidator() });


  constructor(private iatconfigurationService: IATConfigurationService, public dialog: MatDialog,
    private router: Router, @Inject(MAT_DIALOG_DATA) public data: IATAlertConfiguration,
    @Inject(MAT_DIALOG_DATA) public clientId: number,
    public dialogRef: MatDialogRef<CvaScreensComponent>) {
    this.selectedAlert = data;
    dialogRef.disableClose = true;
  }


  ngOnInit(): void {
    this.clientName = AppConstants.gblIATClientName;
    this.bindDeviceNameByClient(AppConstants.gblIATClientId);
    if (this.selectedAlert != undefined && this.selectedAlert != null) {
      this.isReadOnly = !this.isReadOnly;
      this.bindIATAlertDefinitions();
    }
    else {
      this.selectedAlert = {
        alertId: 0,
        alertConfigurationID: 0,
        clientId: 0,
        alertDefaultValue: '',
        minValue: 0,
        maxValue: 0
      };

    }


  }

  


  onDeviceChange(event: MatSelectChange) {
    this.deviceId = (event.source.selected as MatOption).value;
    if (this.selectedAlert != undefined && this.selectedAlert != null) {
      this.bindIATAlertDefinitionByClientandDevices(AppConstants.gblIATClientId, this.deviceId);
    }
  }
  onAlertChange(event: MatSelectChange) {
    this.selectedAlert.uom = this.alertsdefinations.find(x => x.alertID === (event.source.selected as MatOption).value).uom;
  }

  bindIATAlertDefinitionByClientandDevices(clientId: number, deviceid: number) {
    this.iatconfigurationService
      .getIATAlertDefinitionByClientandDevices(clientId, deviceid)
      .then((data) => {
        this.alertsdefinations = data as any[];
      })
      .catch((error) => {
        console.log('IU Promise rejected with ' + JSON.stringify(error));
      });
  }

  bindIATAlertDefinitions() {
    this.iatconfigurationService
      .getIATAlertDefinitions()
      .then((data) => {
        this.alertsdefinations = data as any[];
      })
      .catch((error) => {
        console.log('IU Promise rejected with ' + JSON.stringify(error));
      });
  }


  bindDeviceNameByClient(clientId: number) {
    this.iatconfigurationService
      .getIATDeviceNameByClient(clientId)
      .then((data) => {
        this.devicenames = data as any[];
      })
      .catch((error) => {
        console.log('IU Promise rejected with ' + JSON.stringify(error));
      });
  }


  async onSubmit() {
    const filterPipe = new TranslationPipe();
    if (this.editAlerconfigurationForm.valid) {
      const alertinsObj: any = {
        alertConfigurationID: this.editAlerconfigurationForm.value.alertConfigurationID,
        alertID: this.selectedAlert.alertID,
        deviceID: this.selectedAlert.deviceID,
        alertDefaultValue: this.editAlerconfigurationForm.value.alertDefaultValue,
        maxValue: this.editAlerconfigurationForm.value.maxValue,
        minValue: this.editAlerconfigurationForm.value.minValue,
        createdBy: AppConstants.gblLoggedInUserId,
        modifiedBy: AppConstants.gblLoggedInUserId
      };

      const alertupdObj: any = {
        alertConfigurationID: this.editAlerconfigurationForm.value.alertConfigurationID,
        alertDefaultValue: this.editAlerconfigurationForm.value.alertDefaultValue,
        maxValue: this.editAlerconfigurationForm.value.maxValue,
        minValue: this.editAlerconfigurationForm.value.minValue,
        modifiedBy: AppConstants.gblLoggedInUserId
      };

      if (alertinsObj.alertConfigurationID == 0) {
        this.iatconfigurationService.addAlertConfiguration(alertinsObj)
          .subscribe(() => {
            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.insertSuccess),
                text: filterPipe.transform(this.recordInsertedSucessfully),
              },
            });
          },
            (error) => {
              if (error.status == 409) {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.failed),
                    text: filterPipe.transform(this.duplicateRecord),
                  },
                });
              }
              else {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.failed),
                    text: filterPipe.transform(this.globalerror),
                  },
                });
              }
            });

      }

      else if (alertinsObj.alertConfigurationID > 0) {

        this.iatconfigurationService.updateAlertConfiguration(alertupdObj)
          .subscribe(() => {

            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.updateSuccess),
                text: filterPipe.transform(this.recordUpdatedSucessfully),
              },
            });
          },
            (error) => {
              if (error.status == 409) {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.failed),
                    text: filterPipe.transform(this.duplicateRecord),
                  },
                });
              }
              else {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.failed),
                    text: filterPipe.transform(this.globalerror),
                  },
                });
              }
            });
      }

    }
  }

}

