
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator,MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { BusinessgroupEditComponent } from 'src/app/components/businessgroup-edit/businessgroup-edit.component';
import { BusinessGroup } from 'src/app/models/business-group';
import { BusinessGroupService } from 'src/app/services/businessgroup.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { AddBgAccMapComponentComponent } from 'src/app/components/add-bg-acc-map-component/add-bg-acc-map-component.component';
import { DeleteBgAccMapComponent } from 'src/app/components/delete-bg-acc-map/delete-bg-acc-map.component';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
import {DataService} from '../../../services/data.service';
import { MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

interface IBusinessGroupList{
  businessGroupId:number,
  businessGroupName:string,
  userCount:number,
  accountCount:number,
  alertRuleCount:number,
  isInternal:boolean
}
interface IBusinessGroup{
  businessGroupId:number,
  businessGroupName:string,
  createdBy:number,
  modifiedBy:number,
  clientId:number
}




/**
 * @title Table with expandable rows
 */
@Component({
  selector: 'app-business-groups',
  styleUrls: ['./business-groups.component.scss'],
  templateUrl: './business-groups.component.html',
  encapsulation:ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
    trigger('fadeInAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class BusinessGroupsComponent implements OnInit {
  searchTxt: any;
  Search : string = "Search";
  SelectClient : string = "Select Client";
  selectedClientValue: number  = 0;
  selectedClientName: string = '';
  custs: any[] = [];
  filterName:string="";
  showInternalUser = true; 
  isEdit = false;
  Nodataavailable : string = 'No data available';
  selectedBusinessGroupId:number=0; 
  selectedBusinessGroupName:string=""; 
  dataSource: MatTableDataSource<IBusinessGroupList>;
  mappedAccountByBG:any[]=[];
  mappedAccountByBG_Master:any[]=[];
  accString:string='';
  allAccString:string='';
  unMappedAccountByBG:any[]=[];
  unMappedAccountByBG_Master:any[]=[];
  AddAllRemoveAllFlag: boolean=true;
  AccountNumber :  string = 'Account Number';
  MappedAccounts:  string  = 'Mapped Accounts';
  noData :string = 'No data found';
  AllAccounts: string = 'All Accounts';
  Import : string  = 'Import';
  Itemsperpage : string = 'Items per page';
  Nextpage : string = 'Next page';
  Previouspage : string = 'Previous page';
  BusinessGroup :  string = 'Business Group';
  of : string = 'of';
  Failed :  string  = 'Failed';
  globalerror : string  = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
  SaveSuccess: string  = 'Save Success';
  BusinessGroupCreatedSuccessfully: string  = 'Business Group Created Successfully';			
Duplicate :   string = 'Duplicate';
DuplicateBusinessGroup:   string = 'Duplicate Business Group';
Confirmdelete          :   string = 'Confirm delete';
AreyousuretodeleteBusinessgroup          :   string = 'Are you sure to delete Business group?';
Deletefailed           :   string = 'Delete failed';
BusinessGroupDeletionFailed       :   string = 'Business Group Deletion Failed';
AreyousuretodeleteallAccountnumbermapping:   string = 'Are you sure to delete all Account number mapping?';
MappingDeletionFailed :   string = 'Mapping Deletion Failed';
AreyousuretodeleteAccountnumbermapping  :   string = 'Are you sure to delete Account number mapping?';
Savefailed:   string = 'Save failed';
MappingFailed          :   string = 'Mapping Failed';
Erroroccurredwhilereadingfile            :   string = 'Error occurred while reading file!';
Invalidfile            :   string = 'Invalid file';
Pleaseimportvalidcsvfile      :   string = 'Please import valid .csv file.';
Accountnumbersimportfailedfor  :   string = 'Account numbers Import failed for =';
AccountnumbersimportedSuccessfully            :   string = 'Account numbers imported Successfully';
DuplicateAccountnumbers            :   string = 'Duplicate Account numbers';
Nofile   :   string = 'No file';
Pleaseselectcsvfile:   string = 'Please select .csv file.';
fileName : string  = "";
  public records: any[] = [];
  csvFileDataAfterReading: string='';
  inputAccData: boolean = false;
  textAreaInputAccDataErrorMessage: boolean = false;
  fileUploadInputAccDataErrorMessage: boolean = false;
  regExpString = /[!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/?]/;
  csvFileData: string='';
  
  
  bgForm = new FormGroup({
    bgName: new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z][a-zA-Z0-9_ ]*$')])
  });

  columnsToDisplay = [
    'businessGroupName',
    'accountCount',
    'userCount',
    'isinternal',
    'actions'
  ];
  expandedElement: BusinessGroup | null | undefined;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public dialog: MatDialog,public businessGroupService:BusinessGroupService,private commonService:CommonService,public router: Router, private dataService: DataService) {
  
    // Assign the data to the data source for the table to render
        this.dataSource=new MatTableDataSource<IBusinessGroupList>();

  }
  @ViewChild('csvReader', { static: false }) csvReader: any;

  ngOnInit() {
    this.getClientDetails();    
    this.bindBusinessGroupList(AppConstants.gblClientId);
    if(AppConstants.glbRoleId==8 || AppConstants.glbRoleId==9)
    {
     this.columnsToDisplay = [
        'businessGroupName',
        'accountCount',
        'userCount',
        'actions'
      ];
    }
    else
    {
this.columnsToDisplay = [
        'businessGroupName',
        'accountCount',
        'userCount',
        'isInternal',
        'actions'
      ];
  }


    if(AppConstants.glbRoleId==7 || AppConstants.glbRoleId==8 || AppConstants.glbRoleId==9)
    {
      this.AddAllRemoveAllFlag = false;
      this.showInternalUser = false;
    }
    else
    {
      this.AddAllRemoveAllFlag = true;
    }
  }

 
   //bind Alert Rule Data to table
   bindBusinessGroupList(id:any){
    this.businessGroupService.getBusinessGroupListByClient(id).then((data)=>{ 
    this.dataSource = new MatTableDataSource(data as IBusinessGroupList[]); 
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;        
  })
}

async onClientChange(event: MatSelectChange) {  
  let id=(event.source.selected as MatOption).value;
  this.selectedClientValue=id;  
  const clients = <any[]>await this.dataService.getClients(AppConstants.gblAppId, AppConstants.gblLoggedInUserId);
  this.selectedClientName = clients.find(x=>x.clientId === this.selectedClientValue).clientName;
  this.filterName='';
  this.bindBusinessGroupList(this.selectedClientValue);    
}


async getClientDetails()
{
  const clients = <any[]>await this.dataService.getClients(AppConstants.gblAppId, AppConstants.gblLoggedInUserId);
    
  if (clients != null) {
    
    if (clients && clients.length > 0) {
      for (const item of clients) {
        this.custs.push({
          clientId: item.clientId,
          clientName: item.clientName,
        });
      }
      this.selectedClientValue=AppConstants.gblClientId;
      var temp = this.custs.find(item =>item.clientId===AppConstants.gblClientId);         
      this.selectedClientName = temp.clientName;
    }
  }
}
 
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;
    const rangelabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${filterPipe.transform(this.of)} ${length}`;
      }
    
      length = Math.max(length, 0);
    
      const startIndex = page * pageSize;
    
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
    
      return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
    };


     this.dataSource.sort = this.sort;
     const filterPipe = new TranslationPipe();

     const paginatorIntl = new MatPaginatorIntl();
      paginatorIntl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);

        
      this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
      this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
      this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
      this.paginator._intl.getRangeLabel = rangelabel;
      this.dataSource.paginator = this.paginator;
    
  
  }

  isActionDisabled(row: any): boolean {
    return row.businessGroupName ===  this.selectedClientName + '_BG' && row.isInternal === false;
  }

  openAddSelective(selectedBusinessGroupId:number,flag:number) {   

   
            let mapObj: any = {
              businessGroupId:selectedBusinessGroupId,
              businessGroupName:this.selectedBusinessGroupName ,
              flag:flag     
             }
             if(flag==1){
    const dialogRef = this.dialog.open(AddBgAccMapComponentComponent, {
    width:'500px',
    height:'300px',
    data: mapObj     
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.bindMappedAccountNumber(this.selectedBusinessGroupId)  ; 
      this.bindUnMappedAccountNumber(this.selectedBusinessGroupId)  ; 
    });
  }
  else if(flag==2){    
    const dialogRef = this.dialog.open(DeleteBgAccMapComponent, {
      width:'500px',
      height:'300px',
      data: mapObj      
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.bindMappedAccountNumber(this.selectedBusinessGroupId)  ; 
        this.bindUnMappedAccountNumber(this.selectedBusinessGroupId)  ; 
      });
  }
  
  }
  
  RefreshList() {
    this.bindBusinessGroupList(this.selectedClientValue);    
  }


  openBGAddDialog() {
    const dialogRef = this.dialog.open(BusinessgroupEditComponent, {
      width: '600px',
      data: {
        businessGroupId : 0,
        clientId: this.selectedClientValue,
        clientName: this.selectedClientName
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.RefreshList();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  applyMappedFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.mappedAccountByBG =this.mappedAccountByBG_Master.filter( i => i.accountNumber.toLowerCase().indexOf(filterValue.trim().toLowerCase()) >= 0 );
   
  }
  applyUnMappedFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.unMappedAccountByBG =this.unMappedAccountByBG_Master.filter( i => i.accountNumber.toLowerCase().indexOf(filterValue.trim().toLowerCase()) >= 0 );
   
  }
  

  deleteBG(businessGroupId:number){
    const filterPipe = new TranslationPipe();
    const dialogRef=this.dialog.open(ConfirmDialogComponent,{
      data: {
        title: filterPipe.transform(this.Confirmdelete),
        text: filterPipe.transform(this.AreyousuretodeleteBusinessgroup),
      },
    }); 
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        const modifiedBy:number=AppConstants.gblLoggedInUserId;
    this.businessGroupService.deleteBusinessGroup(businessGroupId,modifiedBy).then((data)=>{
      
      this.bindBusinessGroupList(this.selectedClientValue);         
    }).catch((error)=>{      
      this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed),filterPipe.transform(this.BusinessGroupDeletionFailed));

    });
      }
    });

    
  }


   
  //open a dialog on edit
  openBGEditDialog( row:IBusinessGroup) {
    row.clientId = this.selectedClientValue;
    const dialogRef = this.dialog.open(BusinessgroupEditComponent, {
      width: '600px',
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.RefreshList();
    });
  }


  openMessageDialog(dialogTitle:string,dialogText:string){
    this.dialog.open(MsgDialogComponent, {
      data: {
        title: dialogTitle,
        text: dialogText,
      },
    });
  }

  //bind mapped and unmapped BG lists after expanding user 
  bindDetails(expandedElement:any,businessGroupId: number,businessGroupName:string){
    
    this.accString='';
    this.allAccString='';
    if(expandedElement)
    {
      this.bindMappedAccountNumber(businessGroupId);
      this.bindUnMappedAccountNumber(businessGroupId);
      this.selectedBusinessGroupId=businessGroupId;
      this.selectedBusinessGroupName=businessGroupName;      
    }
    else{
      this.selectedBusinessGroupId=0;
      this.selectedBusinessGroupName="";
      
    }
    if(this.isEdit ){
      this.isEdit=!this.isEdit;
    }
  }
//bind mapped BG list
  async bindMappedAccountNumber(businessGroupId:number){
     this.mappedAccountByBG=this.mappedAccountByBG_Master= <any[]>await this.businessGroupService.getMappedAccountByBG(businessGroupId); 
  }

//bind unmapped AccNo list
  async bindUnMappedAccountNumber(businessGroupId:number){
    this.unMappedAccountByBG=this.unMappedAccountByBG_Master= <any[]>await this.businessGroupService.getUnmappedAccountByBG(businessGroupId);     
 }
 //delete all mapping of BG and ACCNo at once
 deleteAllBGAccMapping(selectedBusinessGroupId:number){
  const filterPipe = new TranslationPipe();
  const dialogRef=this.dialog.open(ConfirmDialogComponent,{
    data: {
      title: filterPipe.transform(this.Confirmdelete),
      text: 
      filterPipe.transform(this.AreyousuretodeleteallAccountnumbermapping),
    },
  }); 
  
  dialogRef.afterClosed().subscribe(result => {
    if(result) {
  this.businessGroupService.deleteAllBGAcc(selectedBusinessGroupId).then((data)=>{       
  this.bindMappedAccountNumber(this.selectedBusinessGroupId)  ; 
  this.bindUnMappedAccountNumber(this.selectedBusinessGroupId)  ;    
  }).catch((error)=>{
  this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed),filterPipe.transform(this.MappingDeletionFailed));     
  });
}});
}

//delete single mapping of user and BG
deleteSingleBGAccMapping(AccountNumberBusinessGroupId:number){
  const filterPipe = new TranslationPipe();
  const dialogRef=this.dialog.open(ConfirmDialogComponent,{
    data: {
      title: filterPipe.transform(this.Confirmdelete),
      text: filterPipe.transform(this.AreyousuretodeleteAccountnumbermapping),
    },
  }); 
  
  dialogRef.afterClosed().subscribe(result => {
    if(result) {
  this.businessGroupService.deleteSingleBGAccMapping(AccountNumberBusinessGroupId).then((data)=>{       
  this.bindMappedAccountNumber(this.selectedBusinessGroupId)  ; 
  this.bindUnMappedAccountNumber(this.selectedBusinessGroupId)  ;    
  }).catch((error)=>{
  this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed),filterPipe.transform(this.MappingDeletionFailed));     
  });
}});
}


  //Map single BG to user
  mapSingleAcc(selectedBusinessGroupId:number ,accountNumberId:number){
    const filterPipe = new TranslationPipe();
    let mapObj: any = {
      businessGroupId:selectedBusinessGroupId,
      accountNumberId:accountNumberId,
      loggedinUserId: AppConstants.gblLoggedInUserId      
     }
    this.businessGroupService.postSingleBGAccMapping(mapObj).subscribe((data) => {    
      this.bindMappedAccountNumber(this.selectedBusinessGroupId)  ; 
      this.bindUnMappedAccountNumber(this.selectedBusinessGroupId)  ;   
                                        
       },
        (error) => {                                                                       
    this.commonService.openMessageDialog(filterPipe.transform(this.Savefailed),filterPipe.transform(this.MappingFailed));     
                                                         
       })
                                  
  }
  
//Map all BG to user at once
  mapAllAcc(selectedBusinessGroupId:number){
    const filterPipe = new TranslationPipe();
    let mapObj: any = {
      businessGroupId:selectedBusinessGroupId,
      loggedinUserId : AppConstants.gblLoggedInUserId         
     }

    this.businessGroupService.postAllBGAccMapping(mapObj).subscribe((data) => {    
      this.bindMappedAccountNumber(this.selectedBusinessGroupId)  ; 
      this.bindUnMappedAccountNumber(this.selectedBusinessGroupId)  ;   
                                        
                                    },
                                    (error) => {                                                                       
    this.commonService.openMessageDialog(filterPipe.transform(this.Savefailed),filterPipe.transform(this.MappingFailed));     
                                                         
                                    })
  }



  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }
  
  uploadListener($event: any): void {
    const filterPipe = new TranslationPipe();
    let text = [];
    let files = $event.srcElement.files;
    this.fileName = $event.target.files[0].name;

  
    if (this.isValidCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        this.records = csvRecordsArray;
        this.csvFileDataAfterReading = this.records.toString();
        
        if (this.csvFileDataAfterReading.length != 0) {
          this.inputAccData = true;
          this.fileUploadInputAccDataErrorMessage = false;
          if (this.csvFileDataAfterReading.match(this.regExpString)) {
            this.inputAccData = false;
            this.fileUploadInputAccDataErrorMessage = true;
          }
          else if (!this.csvFileDataAfterReading.match(this.regExpString)) {
            this.inputAccData = true;
            this.fileUploadInputAccDataErrorMessage = false;
          }
        }
      };
      reader.onerror = function () {
        alert("Error occurred while reading file!")
       
      };

    } else {
     
      this.commonService.openMessageDialog(filterPipe.transform(this.Invalidfile),filterPipe.transform(this.Pleaseimportvalidcsvfile)); 
      this.fileReset();
      this.inputAccData = false;
    }
   
  }


  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
  }

submitCSVData(){
  const filterPipe = new TranslationPipe();
  if(this.csvFileDataAfterReading){
   
  let mapObj:any={
    businessGroupId:this.selectedBusinessGroupId,
    accountNumbers:this.csvFileDataAfterReading,
    loggedinUserId: AppConstants.gblLoggedInUserId
  }
  this.businessGroupService.postCSVData(mapObj).subscribe((response) => { 
    if(response!=null && response!=""){
      this.openMessageDialog(filterPipe.transform(this.Failed),filterPipe.transform(this.Accountnumbersimportfailedfor) +String(response).replace(/,(\s+)?$/, ''));       
    }
    else{
    this.openMessageDialog(filterPipe.transform(this.SaveSuccess),filterPipe.transform(this.AccountnumbersimportedSuccessfully));
   }
   this.fileReset() ;
  this.bindMappedAccountNumber(this.selectedBusinessGroupId);
  this.bindUnMappedAccountNumber(this.selectedBusinessGroupId);

   
  },
  (error) => {   
   
     if(error.status==409){             
      this.openMessageDialog(filterPipe.transform(this.Duplicate),filterPipe.transform(this.DuplicateAccountnumbers));
     }
     else{
      this.openMessageDialog(filterPipe.transform(this.Failed),filterPipe.transform(this.globalerror));
     }
  }
)
  }
  else{
    this.commonService.openMessageDialog(filterPipe.transform(this.Nofile),filterPipe.transform(this.Pleaseselectcsvfile)); 
  }
}

clearFilter(){
  this.accString='';
  this.allAccString='';
  this.mappedAccountByBG=this.mappedAccountByBG_Master.filter( i => i.accountNumber.toLowerCase().indexOf('') >= 0 );
  this.unMappedAccountByBG=this.unMappedAccountByBG_Master.filter( i => i.accountNumber.toLowerCase().indexOf('') >= 0 );
 }
  

}
