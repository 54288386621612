<mat-toolbar class="pair-new-device-toolbar">
    
    <span>Admin guide</span>
   
  </mat-toolbar>
  <div class="col-4 cards">
    <mat-card *ngFor="let page of pageList ; let i = index">
      <mat-card-header>
        {{i+1}}. {{page.screenName}}
       </mat-card-header>
      <!-- <mat-card-content>
        <p>
          {{page.reportDescription}}
      </mat-card-content> -->      
    </mat-card>
    </div>