import { Component, OnInit, Inject,ViewEncapsulation } from '@angular/core';
import { FormControl,Validators ,FormGroup } from '@angular/forms';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
import { INotification } from 'src/app/models/notification.model';
import { NotificationComponent } from 'src/app/pages/menu/notification/notification.component';
import { NotificationService } from 'src/app/services/notification.service';
import * as moment from 'moment';

@Component({
  selector: 'app-notification-add-edit',
  templateUrl: './notification-add-edit.component.html',
  styleUrls: ['./notification-add-edit.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AddEditNotificationComponent implements OnInit {
  notifications : INotification[] = [];
  selectedNotification : any;
  placeholder: string = "Choose expire date";
  descriptionPlaceholder = "Description in html";
  insertSuccess :  string  = "Insert Success";
  notificationInsertedSuccessfully:  string  = "Notification Inserted Successfully";
  duplicate:  string  = "Duplicate";
  duplicateNotification:  string  = "Duplicate Notification";
  updateSuccess:  string  = "Update Success";
  notificationUpdatedSuccessfully:  string  = "Notification Updated Successfully";
  failed : string = 'Failed';
  minDate: Date;
  globalerror : string  = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
  editNotificationForm = new FormGroup({   
    notificationId: new FormControl(0),
    notificationTitle:new FormControl('', [Validators.required,Validators.maxLength(50),Validators.pattern('[a-zA-Z][a-zA-Z0-9_ ]*$')]),
    notificationDescription:new FormControl('', [Validators.required]),
    selectedDate: new FormControl('', [Validators.required])
  });

  constructor(private notificationService:NotificationService,public dialog: MatDialog,
    private router: Router,@Inject(MAT_DIALOG_DATA) public data:Notification,
    public dialogRef: MatDialogRef<NotificationComponent>) {
    this.minDate = new Date();
    this.selectedNotification = data;
    dialogRef.disableClose = true; 
  
    }
    ngOnInit(): void {
      this.editNotificationForm.get('selectedDate')?.setValidators([Validators.required, this.minDateValidator , this.todayDateValidator]);
      this.editNotificationForm.get('selectedDate')?.updateValueAndValidity();
      this.bindNotification();
      if (this.selectedNotification == undefined && this.selectedNotification == null) {
        this.selectedNotification = {
          notificationId:0,
          notificationTitle:'',
          notificationDescription:''
        };
      } 
    }

    minDateValidator(control: any): {[key: string]: any} | null {
      const forbidden = moment(control.value).isBefore(moment(), 'day');
      return forbidden ? {'forbiddenDate': {value: control.value}} : null;
    }

    todayDateValidator(control: any): {[key: string]: any} | null {
      const forbidden = moment(control.value).isSame(moment(), 'day');
      return forbidden ? {'forbiddenTodayDate': {value: control.value}} : null;
    }

    decodeHTML(encodedHtml: string): string {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = encodedHtml;
      return textArea.value;
    }

    bindNotification() {
      this.notificationService
        .getNotification()
        .then((data) => {
          this.notifications = data as INotification[];
        })
        .catch((error) => {
          console.log('IU Promise rejected with ' + JSON.stringify(error));
        });
    }

      encodeHTML(html: string |  null | undefined): string {
      const text = document.createTextNode(html ?? '');
      const div = document.createElement('div');
      div.appendChild(text);
      return div.innerHTML;
    }
   

  async onSubmit() {
     const filterPipe = new TranslationPipe();
    if (this.editNotificationForm.valid) {
      const notification: any = {
        notificationId: this.editNotificationForm.value.notificationId,
        notificationTitle: this.editNotificationForm.value.notificationTitle,             
        notificationDescription: this.encodeHTML(this.editNotificationForm.value.notificationDescription),
        expireDate: this.editNotificationForm.value.selectedDate,
        createdBy: AppConstants.gblLoggedInUserId,
      };
       if (notification.notificationId == 0)
      {
        
         this.notificationService
            .postNotificationData(notification).subscribe(() => {
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.insertSuccess),
                  text: filterPipe.transform(this.notificationInsertedSuccessfully),
                },
              });
            },
            (error) => {
              if(error.status==409){
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.duplicate),
                    text: filterPipe.transform(this.duplicateNotification),
                  },
                });
               }
               else{
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.failed),
                    text: filterPipe.transform(this.globalerror),
                  },
                });
               }
            });
        
      }
      
   else if (notification.notificationId > 0) {

         this.notificationService
          .putNotificationData(notification).subscribe((data) => {
            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.updateSuccess),
                text: filterPipe.transform(this.notificationUpdatedSuccessfully),
              },
            });

          },
          (error) => {
            if(error.status==409){
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.duplicate),
                  text: filterPipe.transform(this.duplicateNotification),
                },
              });
             }
             else{
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.failed),
                  text: filterPipe.transform(this.globalerror),
                },
              });
             }
          });
      }
      
    }
   }

}
