import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppConstants } from 'src/app/AppConstants';
import { AppEnv } from 'src/app/GlobalConstants';

@Component({
  selector: 'app-customerror',
  templateUrl: './customerror.component.html',
  styleUrls: ['./customerror.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CustomerrorComponent implements OnInit {

  public ErrorMessage: string="";
  public ErrorDetails: string="";
  appTitle = 'Intelligent Analytics'; 
  redirect_url: string = "";
  
  constructor() { 
  }

  ngOnInit(): void {
    this.redirect_url = AppEnv.redirectUri;
    this.ErrorMessage = AppConstants.LogError;
    this.ErrorDetails = AppConstants.LogDetails;
  }

}
