import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { AbstractControl, FormControl, FormGroup, Validators , } from '@angular/forms';
import { EscalatePackage } from 'src/app/models/escalate-package.model';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {EscalatePackageService} from 'src/app/services/escalate-package.service';
import {EscalationguideDialogComponent} from 'src/app/components/escalationguide-dialog/escalationguide-dialog.component';
import { FreshDeskService } from 'src/app/services/freshdeskservice';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { ValidatorFn} from '@angular/forms';




interface IType {
  EscalationTypeId: number;
  EscalationType: string;
}
interface ISubType {
  EscalationSubTypeId: number;
  EscalationSubType: string;
  EscalationTypeId:number;
}




@Component({
  selector: 'app-escalate-package',
  templateUrl: './escalate-package.component.html',
  styleUrls: ['./escalate-package.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class EscalatePackageComponent implements OnInit {
  Provideamoredetaileddescription :  string  = 'Provide a more detailed description';
  Help: string = 'Help';
  types: IType[] = [];
  subtypes: ISubType[] = [];
  filteredsubtypes: ISubType[] = [];
  public selection:string ='Urgent';

  public noWhitespaceValidator(control: AbstractControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  escalatePackageForm = new FormGroup({

    trackingNumber: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9 ]{1,18}$'),
    ]),



    description: new FormControl('', [
      Validators.required,
      this.noWhitespaceValidator
       
    ])

  });

  constructor(private router: Router,public dialog: MatDialog,private pkgService:FreshDeskService, public dialogRef: MatDialogRef<EscalationguideDialogComponent>) { }


  glbUserEmailID: any ;
    TrackNum!: string;

  ngOnInit() : void{
   this.glbUserEmailID =  AppConstants.gblLoggedInUserEmailid;

}



  onTypeChange(event : MatSelectChange) {
    this.filteredsubtypes = this.subtypes.filter( element => element.EscalationTypeId ==event.value);
  }

  setFocusOnFirstControl() {
    const invalidControl = <HTMLElement>(
      document.querySelector('input[formControlName="trackingNumber"]')
    );
    invalidControl?.focus();
  }

  openHelpDialog() {
    try {

      const dialogRef = this.dialog.open(EscalationguideDialogComponent, {
        width: '700px',
        data: null
      });
      dialogRef.afterClosed().subscribe(result => {
      });

    } catch (error) {
      console.log(error);
    }
  }

  openMessageDialog(dialogTitle:string,dialogText:string){
    this.dialog.open(MsgDialogComponent, {
      data: {
        title: dialogTitle,
        text: dialogText,
      },
    });
  }

  async onSubmit() {
    if (this.escalatePackageForm.valid) {

      const packageObj: any = {
        Requestor_EmailId: AppConstants.gblLoggedInUserEmailid,
        Subject: this.escalatePackageForm.value.trackingNumber,
        Description: this.escalatePackageForm.value.description,
        EscalationType: 'General Status Inquiry',
        EscalationSubType : 'Others',
        StatusId:2,
        PriorityId:1,
        GroupId : 47000656920,
        CreatedBy : AppConstants.gblLoggedInUserId

      };


      this.pkgService.GetTrackInfo(packageObj);

    this.dialogRef.close();
    }
  }

}
