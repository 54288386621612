import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportService } from 'src/app/services/report.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { IInitialUserData } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss']
})
export class HomeScreenComponent implements OnInit {


  public ReportData: any = [];
  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    public ReportService: ReportService,
    private commonService: CommonService,
    public router: Router
  ) {



  }
  initialUserData: any;

  ngOnInit(): void {
    this.userService.getInitialUserData().subscribe((data) => {
      console.log(JSON.stringify(data));
      this.initialUserData = data as IInitialUserData;
      AppConstants.gblLoggedInUserId = this.initialUserData.loggedInUserId;
      AppConstants.gbluserLevelId = this.initialUserData.userLevelId;
      AppConstants.gblLoggedInUserFName = this.initialUserData.loggedInUserFName;
      AppConstants.gblLoggedInUserLName = this.initialUserData.loggedInUserLName;
      AppConstants.gblLoggedInUserEmailid = this.initialUserData.loggedInUserEmpId;
      AppConstants.LanguageText = this.initialUserData.languageText;
      AppConstants.gblRoleName = this.initialUserData.roleName;
      AppConstants.glbRoleId = this.initialUserData.roleId;
    },
    (error)=>{
      console.log('Menu Promise rejected with ' + JSON.stringify(error));
      if(error.status==401)
      {
       window.open(environment.access_deniedUrl, '_self');
      }
      else
      {
      this.router.navigate(['redirecting']);
      }
    });

  }

  async bindReport() {
    await this.ReportService.getReportByUser(AppConstants.gblAppId, AppConstants.gblLoggedInUserId, AppConstants.gblClientId).then((data) => {
      this.ReportData = data;
    });
  }


}
