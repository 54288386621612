import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl,Validators ,FormGroup } from '@angular/forms';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {MatDialogModule } from '@angular/material/dialog';
import {CvaScreensComponent} from 'src/app/pages/menu/cva-screens/cva-screens.component';
import { AppConstants } from 'src/app/AppConstants';
import { IScreen} from 'src/app/models/screen.model';
import { ScreenService } from 'src/app/services/screen.service';
import { TranslationPipe } from 'src/app/locale/translation.pipe';

@Component({
  selector: 'app-addedit-cvascreen',
  templateUrl: './addedit-cvascreen.component.html',
  styleUrls: ['./addedit-cvascreen.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AddeditCvascreenComponent implements OnInit {
 
  selectedScreen: any;
  InsertSuccess :  string = "Insert Success";
ScreenInsertedSuccessfully:  string = "Screen Inserted Successfully";
Duplicate:  string = "Duplicate";
DuplicateScreen:  string = "Duplicate Screen";
UpdateSuccess:  string = "Update Success";
ScreenUpdatedSuccessfully:  string = "Screen Updated Successfully";
Failed : string = 'Failed';
globalerror : string  = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';

  editScreenForm = new FormGroup({   
    screenId: new FormControl(0),
    screenName:new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z ]*$')]),
    routerLink:new FormControl('', [Validators.required]),
    sequenceId:new FormControl(0, [Validators.required])
  });

  constructor(private screenService:ScreenService,public dialog: MatDialog,
    private router: Router,@Inject(MAT_DIALOG_DATA) public data:IScreen,
    public dialogRef: MatDialogRef<CvaScreensComponent>) {
    this.selectedScreen = data;
    dialogRef.disableClose = true; 
    }

    ngOnInit(): void {
     if (this.selectedScreen == undefined && this.selectedScreen == null) {
      this.selectedScreen = {
        screenId:0,
        screenName:'',
          routerLink:'' ,
          sequenceId :0 
        };
      } 
    }
    
  async onSubmit() {
    const filterPipe = new TranslationPipe();  
    if (this.editScreenForm.valid) {
      const screenObj: any = {
        screenId: this.editScreenForm.value.screenId,
        screenName: this.editScreenForm.value.screenName,             
        routerLink:this.editScreenForm.value.routerLink,
        modifiedBy: AppConstants.gblLoggedInUserId,
        ScreenGroupId:AppConstants.gblscreenGroup,
        moduleId:AppConstants.gblModuleId,
        userLevelID:AppConstants.gbluserLevelId,
        sequenceId:this.editScreenForm.value.sequenceId
      };
    
       if (screenObj.screenId == 0)
      {
        await this.screenService
            .postScreensData(screenObj)
            .subscribe((data) => {
              
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.InsertSuccess),
                  text: filterPipe.transform(this.ScreenInsertedSuccessfully),
                },
              });
      this.router.navigateByUrl('/menu/cvascreens');
  
            },
            (error) => {
              if(error.status==409){
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Duplicate),
                    text: filterPipe.transform(this.DuplicateScreen),
                  },
                });
               }
               else{
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Failed),
                    text: filterPipe.transform(this.globalerror),
                  },
                });
               }
            });
        
      }
      
   else if (screenObj.screenId > 0) {

        await this.screenService
          .putScreensData(screenObj.screenId,screenObj)
          .subscribe((data) => {
            
            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.UpdateSuccess),
                text: filterPipe.transform(this.ScreenUpdatedSuccessfully),
              },
            });
    this.router.navigateByUrl('/menu/cvascreens');

          },
          (error) => {
            if(error.status==409){
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Duplicate),
                  text: filterPipe.transform(this.DuplicateScreen),
                },
              });
             }
             else{
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.globalerror),    
                },
              });
             }
          });
      }
      
    }
  }

}

