import { Component, HostListener } from '@angular/core';
import { LoaderService } from './services/loader.service';
import { Subject, Subscription } from 'rxjs';
import { AppUser } from './models';
import { AppConstants } from './AppConstants';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {  Router } from '@angular/router';
import { ApplicationLog } from './models/applicationlog.model';
import { UserService } from './services/user.service';
import { CommonService } from './services/common.service';
import { TranslationPipe } from './locale/translation.pipe';
import { AuthService } from '@auth0/auth0-angular';
import { AppEnv } from './GlobalConstants';

export let browserRefresh: boolean = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isAuthenticated: boolean | undefined;
  userData$: AppUser | undefined;
  subscription: Subscription | undefined;

  userActivity: any;
  userForceLogout: any;
  userInactive: Subject<any> = new Subject();
  ForceLogout: Subject<any> = new Subject();
  showcustomerrorpage: boolean = false;
  sessiontimeout :  string  = "Session Timeout.";
  sessiontext :  string  = "Your session is about to expire. Do you like to continue with the session.";
  isForgotClicked : boolean =  false;

  constructor(
    public auth: AuthService,
    public loaderService: LoaderService,
    public dialog: MatDialog,
    private userService: UserService,
    private commonService: CommonService,
    private router: Router
  ) {
   
    if(window.location.href.includes('error') && window.location.href.includes('AADB2C90118')  && window.location.href.includes('forgotten'))
    {
      
           window.location.href = AppEnv.resetPassword  + AppEnv.changePasswordURL;
           alert('Change Password');
    }
    else if (window.location.href.includes('forbidden')) {
      this.showcustomerrorpage = true;
      this.router.navigate(['forbidden']);
     
    }    
    else if (window.location.href.includes('error') && window.location.href.includes('AADB2C90091') ) {
      
      let errUrl = window.location.href;
      let mapObj: ApplicationLog = {
        logName: 'unknown issues',
        logDetails: errUrl,
        logger: 'error',
        invitationIdentifier: '',
        logLevel: 0,
        logMessage: '',
        clientId: 0
      }
      this.showcustomerrorpage = false;

      this.userService.postApplicationLogData(mapObj).subscribe((data) => {
        AppConstants.LogDetails = "Error Details: Unknown Error.";
      },
        (error) => {
          console.log(error);
        })
    }
    else if (window.location.href.includes('#error=') && window.location.href.includes('51000')) {
      let hreferror = window.location.href.split('#error=')[1];
      hreferror = hreferror.replace(/\+/gi, " ").replace(/%3a/gi, ":").replace(/%2c/gi, ",");

      if (hreferror.includes('&error_description=')) {
        let errortext = hreferror.split('&error_description=')[0];
        let hrefdesc = hreferror.split('&error_description=')[1];
        if (hrefdesc.includes(', InvitationIdentifierNumber:')) {
          let desctext = hrefdesc.split(', InvitationIdentifierNumber:')[0].split('51000:')[1];
          let hrefiidentifier = hrefdesc.split(', InvitationIdentifierNumber: ')[1];
          if (hrefiidentifier.includes('%0d%0aCorrelation ID:')) {
            let iidentifiertext = hrefiidentifier.split('%0d%0aCorrelation ID:')[0];
            let hrefcorrelationid = hrefiidentifier.split('%0d%0aCorrelation ID:')[1];
            if (hrefcorrelationid.includes('%0aTimestamp:')) {
              AppConstants.LogError = errortext;
              AppConstants.LogDetails = 'Error Details: ' + desctext;
              let mapObj: ApplicationLog = {
                logName: errortext,
                logDetails: hreferror,
                logger: 'error',
                invitationIdentifier: iidentifiertext,
                logLevel: 0,
                logMessage: desctext,
                clientId: 0
              }
              this.showcustomerrorpage = true;

              this.userService.postApplicationLogData(mapObj).subscribe((data) => {
                this.router.navigate(['redirecting']);
              },
                (error) => {
                  this.commonService.openMessageDialog('Error Logging', 'Logging Failed');
                })
              this.router.navigate(['redirecting']);

            }
          }
        }
      }
    }
    else if(window.location.href.includes('error'))
      {
        let errorUrl = window.location.href;

        let mapObj: ApplicationLog = {
          logName: 'unknown issues',
          logDetails: errorUrl,
          logger: 'error',
          invitationIdentifier: '',
          logLevel: 0,
          logMessage: '',
          clientId: 0
        }
        this.showcustomerrorpage = true;

        if(!window.location.href.includes('error=access_denied&error_description=User'))
        {
        this.userService.postApplicationLogData(mapObj).subscribe((data) => {
          AppConstants.LogDetails = "Error Details: Unknown Error.";
          this.router.navigate(['redirecting']);
        },
          (error) => {
            this.commonService.openMessageDialog('Error Logging', 'Logging Failed');
          })
        }
    }
    this.setTimeout();
    this.userInactive.subscribe(() => console.log('user has been inactive for 2 minutes'));
    this.ForceLogout.subscribe(() => console.log('user will forced logout now'));

  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }


  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), AppConstants.logouttimer);
  }

  ngOnInit() {    
    if (!this.showcustomerrorpage) {
      const filterPipe = new TranslationPipe();
      this.router.navigate(['']);
      this.ForceLogout.subscribe(() => {  this.logout(); });
      this.userInactive.subscribe(() => {
        this.userForceLogout = setTimeout(() => this.ForceLogout.next(undefined), AppConstants.ForceLogoutTime);
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: filterPipe.transform(this.sessiontimeout),
            text: filterPipe.transform(this.sessiontext),
          },
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            clearTimeout(this.userForceLogout);
          }
          else {
            this.logout();
          }
        });

      });
      if(!this.isForgotClicked)
      {
       this.auth.isAuthenticated$.subscribe(data => {
      if (!data) {
        this.auth.loginWithRedirect();
      }

    });
    }
  }
  }

  logout(): void {
    AppConstants.gblClientId = 0;
    AppConstants.gblLoggedInUserId = 0;
    AppConstants.gblLoggedInUserFName="";
    AppConstants.gblLoggedInUserLName="";
    AppConstants.gblLoggedInUserEmailid="";
    AppConstants.gbluserLevelId=5;
    AppConstants.gbloperatorId=3;
    AppConstants.gblscreenGroup=1;
    AppConstants.gblRoleName="";
    AppConstants.glbScreenId=0;
    AppConstants.glbRoleId=0;
    sessionStorage.clear();   
    localStorage.clear();
    setTimeout(() => {
      this.logoff();
    }, 2000);
    window.location.href = AppEnv.logoutUri;
  }

  logoff() {
    this.auth.logout({ logoutParams: { returnTo: AppEnv.redirectUri }, clientId: AppEnv.clientId });
  }

   }
  


