import { Component, OnInit } from '@angular/core';
import { FormControl,Validators ,FormGroup } from '@angular/forms';
import { IUser} from 'src/app/models/user.model';
import { UserService} from 'src/app/services/user.service';
import { AppConstants } from 'src/app/AppConstants';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-user-name-dialog',
  templateUrl: './user-name-dialog.component.html',
  styleUrls: ['./user-name-dialog.component.scss']
})
export class UserNameDialogComponent implements OnInit {

  selectedUser: any;

  editUserForm = new FormGroup({
    userId: new FormControl(0, [Validators.required]),
    firstName:new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z ]*$')]),
    lastName:new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z ]*$')])   
  });

  constructor(public dialogRef: MatDialogRef<any>,private router: Router,private userService:UserService,public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.selectedUser = {
      userId: 0,         
      firstName: '',            
      lastName: '',
      email: '',
      mobile: ''       
    };
  }

    onSubmit() {
    if (this.editUserForm.valid) {
      
      
      const userObj: any = {
        userId: AppConstants.gblLoggedInUserId,
        firstName: this.editUserForm.value.firstName,
        lastName: this.editUserForm.value.lastName,
      };
        console.log(JSON.stringify(userObj));


      if (userObj.userId > 0) {
         this.userService
          .putUserName(userObj)
          .subscribe((data) => {
            
            if(confirm("Updated successfully")){
              this.dialogRef.close();
            }
          },
          (error) => {
            if(error.status==409){
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: 'Duplicate',
                  text: 'Duplicate User',
                },
              });
             }
             else{
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: 'Failed',
                  text: 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.',
                },
              });
             }
          });
      }
    }
  }
}
