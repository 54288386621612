<form [formGroup]="editFavModuleForm" (ngSubmit)="onSubmit()">

    <div mat-dialog-title class="editfavmodule-dialog-header  color-green">
      <mat-icon>add</mat-icon> &nbsp;&nbsp;<span  i18n="@@Favorite Module">Favorite Module</span>
    </div>
    
    <mat-dialog-content class="mat-typography">     
    <mat-card>               
        <mat-form-field >
          <mat-label i18n="@@Client Name">Client Name</mat-label>
          <mat-select  formControlName="client"  [(ngModel)]="selectedClient" 
          [disabled]="selectedClient>0"  (selectionChange)="onClientChange($event)">
          <input  formControlName="searchText" class="class-13" [(ngModel)]="searchTxt" matInput placeholder="Search" autocomplete="off">

          <mat-option *ngFor="let client of clients| clientSearch : searchTxt"  [value]="client.clientId" >
              {{ client.clientName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf=" editFavModuleForm.get('client')?.touched && editFavModuleForm.get('client')?.errors?.required">
            <span i18n="@@Required">Required</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field >
            <mat-label i18n="@@Module Name">Module Name</mat-label>
            <mat-select  formControlName="module" >
              <mat-option *ngFor="let module of modules"  [value]="module.moduleId" >
                {{ module.moduleName | translation}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf=" editFavModuleForm.get('module')?.touched && editFavModuleForm.get('module')?.errors?.required">
              <span i18n="@@Required">Required</span>
            </mat-error>
          </mat-form-field>

      </mat-card>
    
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close><span i18n="@@Cancel">Cancel</span></button>
      <button
        mat-flat-button
        color="primary"
        [mat-dialog-close]="true"
        cdkFocusInitial
        type="submit"
        [disabled]="!editFavModuleForm.valid"
      >
      <span i18n="@@Submit">Submit</span>
        
      </button>
    </mat-dialog-actions>
    </form>
