import { Component, OnInit, Inject } from '@angular/core';
import { FormControl,Validators ,FormGroup } from '@angular/forms';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {CvaScreensComponent} from 'src/app/pages/menu/cva-screens/cva-screens.component';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
import { IATConfigurationService } from 'src/app/services/iatconfiguration.service';
import { IATAlertConfiguration } from 'src/app/models/IAT-alertconfiguration.model';
import { MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';


@Component({
  selector: 'app-replicate-alertconfiguration',
  templateUrl: './replicate-alertconfiguration.component.html',
  styleUrls: ['./replicate-alertconfiguration.component.scss']
})
export class ReplicateAlertConfigurationComponent implements OnInit {
 
  alertsdefinations: any[]=[];
  toDevicenames: any[]=[];
  fromDevicenames: any[]=[];
  devicetoId : number= 0;
  devicefromId : number= 0;
  insertSuccess :  string = "Insert Success";
  failed : string = 'Failed';
  recordInsertedSucessfully : string  = 'Record Inserted Sucessfully';
  clientName : string  = '';
  duplicateRecord : string  = 'Duplicate Record';
  globalerror : string  = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';

  replicateAlerconfigurationForm = new FormGroup({  
    devicefromID : new FormControl(0),
    alerts : new FormControl(0, [Validators.required]), 
    devicetoID : new FormControl(0, [Validators.required]),
  });

  constructor(private iatconfigurationService : IATConfigurationService,public dialog: MatDialog,
    private router: Router,@Inject(MAT_DIALOG_DATA) public data:IATAlertConfiguration,
    @Inject(MAT_DIALOG_DATA) public clientId:number,
    public dialogRef: MatDialogRef<CvaScreensComponent>) {
    dialogRef.disableClose = true; 
    }

    ngOnInit(): void {
      this.clientName = AppConstants.gblIATClientName
     this.bindToDeviceNameByClient(AppConstants.gblIATClientId);
     this.bindIATAlertDefinitions();
  
    }

    onDeviceToChange(event: MatSelectChange){
      this.devicetoId =  (event.source.selected as MatOption).value;
      this.iatconfigurationService
        .getGetDeviceFrom(AppConstants.gblIATClientId , this.devicetoId)
        .then((data) => {
          this.fromDevicenames = data as any[];
        })
        .catch((error) => {
          console.log('IU Promise rejected with ' + JSON.stringify(error));
        });
    }

    onDeviceFromChange(event: MatSelectChange){
      this.devicefromId =  (event.source.selected as MatOption).value;
      this.iatconfigurationService
        .getIATDevicesAlertDefinitionDifference(this.devicetoId , this.devicefromId)
        .then((data) => {
          this.alertsdefinations = data as any[];
        })
        .catch((error) => {
          console.log('IU Promise rejected with ' + JSON.stringify(error));
        });
    }

    bindIATAlertDefinitionByClientandDevices(clientId:number ,deviceid:number) {
      this.iatconfigurationService
        .getIATAlertDefinitionByClientandDevices(clientId,deviceid)
        .then((data) => {
          this.alertsdefinations = data as any[];
        })
        .catch((error) => {
          console.log('IU Promise rejected with ' + JSON.stringify(error));
        });
    }

    bindIATAlertDefinitions() {
      this.iatconfigurationService
        .getIATAlertDefinitions()
        .then((data) => {
          this.alertsdefinations = data as any[];
        })
        .catch((error) => {
          console.log('IU Promise rejected with ' + JSON.stringify(error));
        });
    }


    bindToDeviceNameByClient(clientId : number) {
      this.iatconfigurationService
        .getIATDeviceNameByClient(clientId)
        .then((data) => {
          this.toDevicenames = data as any[];
        })
        .catch((error) => {
          console.log('IU Promise rejected with ' + JSON.stringify(error));
        });
    }


    
  async onSubmit() {
    const filterPipe = new TranslationPipe();  
    if (this.replicateAlerconfigurationForm.valid) {
      const alertinsObj: any = {
        alertIDs: [this.replicateAlerconfigurationForm.value.alerts].join(','),
        devicefromID: this.replicateAlerconfigurationForm.value.devicefromID ,
        devicetoID: this.replicateAlerconfigurationForm.value.devicetoID ,
        createdBy: AppConstants.gblLoggedInUserId,
        modifiedBy: AppConstants.gblLoggedInUserId
      };
    
         this.iatconfigurationService.addInsertAlertsDeviceMapping(alertinsObj)
            .subscribe(() => {
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.insertSuccess),
                  text: filterPipe.transform(this.recordInsertedSucessfully),
                },
              });
            },
            (error) => {
              if(error.status==409){
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.failed),
                    text: filterPipe.transform(this.duplicateRecord),
                  },
                });
               }
               else{
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.failed),
                    text: filterPipe.transform(this.globalerror),
                  },
                });
               }
            });
        
      }
    }
 
  }



