<mat-toolbar class="pair-new-device-toolbar">
  <mat-icon>queue_play_next</mat-icon>
  &nbsp;&nbsp;
  <span>Paired Deivces</span>
  <span class="flex-spacer"></span>
  <button mat-stroked-button routerLink="/menu/pair-new-device">
    <mat-icon>add_circle_outline</mat-icon>
    Pair a New Device
  </button>
</mat-toolbar>


<div class="table-container">
  <!--Data Table-->
  <mat-form-field class="filter-field">
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder=" Device ID, etc."
      #input
    />
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort class="devices-table">
      <caption [hidden]="true"> Description </caption>
      <!-- TRIP ID Column -->
      <ng-container matColumnDef="tripId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TRIP ID</th>
        <td mat-cell *matCellDef="let row">{{ row.tripId }}</td>
      </ng-container>

      <!-- SERVICE LEVEL Column -->
      <ng-container matColumnDef="serviceLevel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          SERVICE LEVEL
        </th>
        <td mat-cell *matCellDef="let row">{{ row.serviceLevel }}</td>
      </ng-container>

      <!-- DEVICE ID Column -->
      <ng-container matColumnDef="deviceId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DEVICE ID</th>
        <td mat-cell *matCellDef="let row">{{ row.deviceId }}</td>
      </ng-container>

      <!-- CARRIER Column -->
      <ng-container matColumnDef="carrier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CARRIER</th>
        <td mat-cell *matCellDef="let row">{{ row.carrier }}</td>
      </ng-container>

      <!-- TRACKING NUMBER Column -->
      <ng-container matColumnDef="trackingNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>1Z TRACKING NUMBER</th>
        <td mat-cell *matCellDef="let row">{{ row.trackingNumber }}</td>
      </ng-container>

      <!-- SSCC ID Column -->
      <ng-container matColumnDef="ssccId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>SSCC ID</th>
        <td mat-cell *matCellDef="let row">{{ row.ssccId }}</td>
      </ng-container>

      <!-- LAST UPDATE Column -->
      <ng-container matColumnDef="lastUpdate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>LAST UPDATE</th>
        <td mat-cell *matCellDef="let row">{{ row.lastUpdate }}</td>
      </ng-container>

      <!-- TRIP STAGE Column -->
      <ng-container matColumnDef="tripStage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TRIP STAGE</th>
        <td mat-cell *matCellDef="let row">{{ row.tripStage }}</td>
      </ng-container>

      <!-- ETA Column -->
      <ng-container matColumnDef="eta">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ETA</th>
        <td mat-cell *matCellDef="let row">{{ row.eta }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data found {{ input.value }}
        </td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
