<form  [formGroup]="clientForm" id="aa" (ngSubmit)="onSubmit()" class="class-1">
  <div  class="modal-title" >
    <label class="selectClient-dropdown" i18n="@@SELECT CLIENT TO PROCEED">SELECT CLIENT TO PROCEED</label>
  </div>
   
  <div mat-dialog-content class="clientModal" align="center">   
  
    <mat-form-field class="class-13">
      <input type="text" formControlName="client" formControlName="client" matInput  
      class="class-15"
             (keyup)="applyFilter($event)" 
             [matAutocomplete]="auto">
      <mat-autocomplete  [displayWith]="displayFn" #auto="matAutocomplete" class ="class-18">
        <mat-option *ngFor="let item of custs" class="option-text"  (onSelectionChange)="onSelFunc(item);" [value]="item">
          {{ item.clientName}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf=" clientForm.get('client')?.touched && clientForm.get('client')?.errors?.required">
        <span i18n="@@Required">Required</span>
        </mat-error>
    </mat-form-field>

      <div hidden>
          <mat-form-field appearance="fill" class="app-select" >
            <mat-label *ngIf="custSelect.value" class="app-select-label"
              >Application</mat-label
            >
            <mat-select hidden
              [(ngModel)]="selectedAppValue"
              placeholder="Select an App..."
              #custSelect formControlName="application"
              class="app-select-ctrl"
              (selectionChange)="onAppChange()"
            >
              <mat-option *ngFor="let item of apps" matTooltip="item.applicationName" [value]="item.applicationId">
                {{ item.applicationName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

     
        <br/>
          <button mat-flat-button type="submit"  class="local-accent-color-bg selectBtn" [disabled]="!clientForm.valid">
            <span i18n="@@SELECT">SELECT</span> 
      </button>
  </div>
  

</form>

