import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { AddeditAlertConfigurationComponent } from 'src/app/components/addedit-alertconfiguration/addedit-alertconfiguration.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ReplicateAlertConfigurationComponent } from 'src/app/components/replicate-alertconfiguration/replicate-alertconfiguration.component';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
import { IATAlertConfiguration } from 'src/app/models/IAT-alertconfiguration.model';
import { CommonService } from 'src/app/services/common.service';
import { IATConfigurationService } from 'src/app/services/iatconfiguration.service';

@Component({
  selector: 'app-iat-configuration-screen',
  templateUrl: './iat-configuration-screen.component.html',
  styleUrls: ['./iat-configuration-screen.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class IatConfiguartionScreenComponent implements OnInit {
  displayedColumns: string[] = ['deviceName','alertDescription', 'alertDefaultValue','maxValue', 'minValue', 'uom', 'action'];
  dataSource: MatTableDataSource<IATAlertConfiguration>;
  noData :string = 'No data found';
  Itemsperpage : string = 'Items per page';
  Nextpage : string = 'Next page';
  Previouspage : string = 'Previous page';
  of : string = 'of';
  placeholder:string ="Device, Alerts, Values etc.";
  confirmdelete : string  = "Confirm Delete!";
  areyouSuretoDelete :  string  = "Are you Sure to Delete?";
  deleteFailed : string  = "Delete Failed";
  recordDeletionFailed : string  = "Record Deletion Failed";
  IATclients : any [] = [];
  clientId : number = 0;
  clientName : string  = '';
  clientForm = new FormGroup({
    clientName: new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z][a-zA-Z0-9_ ]*$')])
  });
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  
  constructor(
    public dialog: MatDialog,
    public  iatconfigurationService : IATConfigurationService,
    private commonService: CommonService,
    public router: Router
  ) 
  { 

    this.dataSource = new MatTableDataSource<IATAlertConfiguration>();
  }

  ngOnInit(): void {  
    this.bindAlertsbyclient();
    this.bindIATclients();
  }

   //bind Alert Rule Data to table
   bindAlertsbyclient() {
    if(this.clientId==0)
    {
      this.clientId =  AppConstants.gblClientId;
      this.clientName = AppConstants.gblClientName;
    }
    this.iatconfigurationService.getIATAlertsByClient(this.clientId, this.clientName).then((data) => {
      this.dataSource = new MatTableDataSource(data as IATAlertConfiguration[]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  
  onClientChange(event: MatSelectChange) {
        this.clientId = (event.source.selected as MatOption).value;    
        this.clientName = event.source.triggerValue;
        this.iatconfigurationService.getIATAlertsByClient(this.clientId , this.clientName).then((data) => {
        this.dataSource = new MatTableDataSource(data as IATAlertConfiguration[]);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }
 
  bindIATclients() {
    this.iatconfigurationService
      .getIATClients()
      .then((data) => {
        this.IATclients = data as any[];
      })
      .catch((error) => {
        console.log('IU Promise rejected with ' + JSON.stringify(error));
      });
  }

  RefreshList() {
    this.ngOnInit();
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(AddeditAlertConfigurationComponent, {
      width: '600px',
      data: null
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  replicateAlertsDialog() {
    const dialogRef = this.dialog.open(ReplicateAlertConfigurationComponent, {
      width: '600px',
      data: null
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      const rangelabel = (page: number, pageSize: number, length: number) => {
        if (length == 0 || pageSize == 0) {
          return `0 ${filterPipe.transform(this.of)} ${length}`;
        }
      
        length = Math.max(length, 0);
      
        const startIndex = page * pageSize;
      
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex =
          startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;
      
        return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
      };
  
      this.dataSource.paginator = this.paginator;
       this.dataSource.sort = this.sort;
       const filterPipe = new TranslationPipe();
       this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
       this.paginator._intl.previousPageLabel  = filterPipe.transform(this.Previouspage);
       this.paginator._intl.nextPageLabel  = filterPipe.transform(this.Nextpage);
       this.paginator._intl.getRangeLabel = rangelabel;
    }
    
    
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }

    deleteAlertConfiguration(alertConfigurationID: number) {
      const filterPipe = new TranslationPipe();
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: filterPipe.transform(this.confirmdelete),
          text: filterPipe.transform(this.areyouSuretoDelete),
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const modifiedBy: number = AppConstants.gblLoggedInUserId;
          this.iatconfigurationService.deleteAlertConfiguration(alertConfigurationID, modifiedBy)
            .then((data) => {
              this.bindAlertsbyclient();
            })
            .catch((error) => {
              this.commonService.openMessageDialog(
                filterPipe.transform(this.deleteFailed),
                filterPipe.transform(this.recordDeletionFailed)
              );
            });
        }
      });
    }

    addeditAlertConfigurationDialog(row: IATAlertConfiguration) {
      const dialogRef = this.dialog.open(AddeditAlertConfigurationComponent, {
        width: '600px',
        data: row,
        
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.ngOnInit();
      });
    }


  }




