import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '../AppConstants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class QlikReportService {
  constructor(private httpService: HttpService, private http: HttpClient) { }

  getByModuleId(moduleId:number){
    return this.httpService.getModuleByModuleId(AppConstants.apiUrl+"QlikReport/GetByModuleId?ModuleId=" +moduleId);
  }

  checktomsonly(gblClientId : number){
    return this.httpService.get(AppConstants.apiUrl+"QlikReport/CheckTomsOnly?ClientId=" +gblClientId);
  }
}
