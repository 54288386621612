import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

import { AlertRuleService } from 'src/app/services/alert-rule.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertRule,ISeverity,ISignalTypes,IOperator  } from 'src/app/models/alert-rule.model';
import { MatDialog } from '@angular/material/dialog';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';


@Component({
  selector: 'app-manage-alert-rules',
  templateUrl: './manage-alert-rules.component.html',
  styleUrls: ['./manage-alert-rules.component.scss'],
  encapsulation:ViewEncapsulation.None,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ maxHeight: 0, opacity: 0 }),
        animate('.3s ease-out', style({ maxHeight: 55, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ maxHeight: 55, opacity: 1 }),
        animate('.3s ease-in', style({ maxHeight: 0, opacity: 0 })),
      ]),
    ]),
    trigger('inOutBlurAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.3s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('.3s ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('fadeInslideOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.6s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        //style({ opacity: 1 }),
        animate(
          '.2s ease-in',
          style({ opacity: 0, transform: 'translateX(100%)' })
        ),
      ]),
    ]),
  ],
})
export class ManageAlertRulesComponent implements OnInit {
  severities: ISeverity[] = [];
  signalTypes: ISignalTypes[] = [];
  operators: IOperator[] = [];
  selectedAlertRule: any = {};
  selectedRuleObj: any;

  manageAlertRuleForm = new FormGroup({
    Severity: new FormControl(0, [Validators.required]),
    SignalType: new FormControl('0', [Validators.required]),
    Operator: new FormControl('', [Validators.required]),
    AlertRuleName: new FormControl('', [Validators.required]),
    Description: new FormControl(''),
    Threshold: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]*'),
    ]),
    Frequency: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]*'),
    ]),
    Silenced: new FormControl(''),
    AlertRuleId: new FormControl(''),
  });

  constructor(private alertRuleService: AlertRuleService,
    public dialog: MatDialog,private router: Router ) {
    this.selectedRuleObj = this.router.getCurrentNavigation()?.extras.state;
  }

  ngOnInit(): void {
    this.bindSeverity();
    this.bindSignalTypes();
    this.bindOperators();

    if (this.selectedRuleObj != undefined && this.selectedRuleObj != null) {
      this.selectedAlertRule = this.selectedRuleObj;
    } 
    else {
      this.selectedAlertRule = {
        alertRuleId: 0,
        alertRuleName: '',
        silenced: false,            
        threshold: '',
        alertFrequency: '',
        description: ''       
      };
    
    }
  }

  bindSeverity() {
    this.alertRuleService.getSeverityList().then((data) => {
        this.severities = data as ISeverity[];
      })
      .catch((error) => {
        console.log('MR1 Promise rejected with ' + JSON.stringify(error));
      });
  }

  bindSignalTypes() {
    this.alertRuleService.getSignalTypes().then((data) => {
        this.signalTypes = data as ISignalTypes[];
      })
      .catch((error) => {
        console.log('MR2 Promise rejected with ' + JSON.stringify(error));
      });
  }

  bindOperators() {
    this.alertRuleService.getOperators().then((data) => {
        this.operators = data as IOperator[];
      })
      .catch((error) => {
        console.log('MR3 Promise rejected with ' + JSON.stringify(error));
      });
  }

  setFocusOnFirstControl() {
    const invalidControl = <HTMLElement>(
      document.querySelector('input[formControlName="AlertRuleName"]')
    );
    invalidControl?.focus();
  }

  async onSubmit() {
    if (this.manageAlertRuleForm.valid) {
      const ruleObj: AlertRule = {
        alertRuleId: Number(this.manageAlertRuleForm.value.AlertRuleId)  ,
        alertRuleName: this.manageAlertRuleForm.value.AlertRuleName as string,
        description: this.manageAlertRuleForm.value.Description as string,
        threshold: Number(this.manageAlertRuleForm.value.Threshold),
        alertFrequency: Number(this.manageAlertRuleForm.value.Frequency),
        operatorId: Number(this.manageAlertRuleForm.value.Operator) ,
        severityId: Number(this.manageAlertRuleForm.value.Severity),
        signalTypeId: Number(this.manageAlertRuleForm.value.SignalType),
        clientId: AppConstants.gblClientId,
        createdBy: AppConstants.gblLoggedInUserId,
        modifiedBy: AppConstants.gblLoggedInUserId,
        silenced: this.manageAlertRuleForm.value.Silenced ? 1 : 0,
      };
  
      if (ruleObj.alertRuleId == 0) {

        this.alertRuleService.postAlertRuleData(ruleObj).subscribe((response) => {                        
          this.openMessageDialog('Save Success','Alert Rule Created Successfully');
          this.router.navigateByUrl('/menu/alert-rules');        
        },
        (error) => {   
         
           if(error.status==409){             
            this.openMessageDialog('Duplicate','Duplicate Alert Rule');
           }
           else{
            this.openMessageDialog('Failed','There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.');
           }
        }
      )

         
      } else if (ruleObj.alertRuleId > 0) {
        await this.alertRuleService
          .putAlertRuleData(ruleObj.alertRuleId,ruleObj)
          .subscribe((data) => {            
            this.openMessageDialog('Update Success','Alert Rule Updated Successfully');
            this.router.navigateByUrl('/menu/alert-rules');
          },
          (error) => {
            if(error.status==409){              
              this.openMessageDialog('Duplicate','Duplicate Alert Rule');
             }
             else{              
              this.openMessageDialog('Failed','There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.');
             }
          });
      }
    }
  }

  openMessageDialog(dialogTitle:string,dialogText:string){
    this.dialog.open(MsgDialogComponent, {
      data: {
        title: dialogTitle,
        text: dialogText,
      },
    });
  }
   
}
