import { Injectable } from '@angular/core';

import { Device } from '../models/device.model';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from 'src/app/AppConstants';

const pairDeviceUrl= AppConstants.apiUrl+'/pairDevice';





@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private httpService: HttpService, private httpClient: HttpClient) {}

 
  pairDevice(device: Device) {
    return this.httpService.post(pairDeviceUrl, device);
  }

  getApplicationByUser(userId:number){
    return this.httpService.get(AppConstants.apiUrl+"SideBarMenuDdl/GetApplicationByUserList?UserId="+ userId);
  }

  getClients(applicationId:number,userId:number) {
    return this.httpService.get(AppConstants.apiUrl+"SideBarMenuDdl/GetClientByUserList?ApplicationId="+applicationId+"&UserId="+userId);
  }

  getModules(ApplicationId: number,UserId:number,clientId:number) {
    return this.httpService.get(AppConstants.apiUrl+"SideBarMenuDdl/GetModuleByUserList?ApplicationId="+ApplicationId+"&UserId="+UserId+"&clientId="+clientId);
  }

  getScreensByUserLevel(RoleId:number,screenGroup:number,moduleId:number){
    return this.httpService.get(AppConstants.apiUrl+"SideBarMenuDdl/GetScreenByUserLevelMenu?RoleId="+RoleId+"&ScreenGroup="+screenGroup+"&ModuleId="+moduleId);
  }
  
  getReportsByModuleId(userLevelId:number,moduleId:number){

    return this.httpService.get(AppConstants.apiUrl+"SideBarMenuDdl/GetReportByModule?ModuleId="+moduleId+"&UserLevelId="+userLevelId);

  }
  AddUserLoginDetailsAudit(gblLoggedInUserId:number){  
    return this.httpClient.get(AppConstants.apiUrl+"Application/AddUserLoginDetailsAudit?userId="+gblLoggedInUserId);     
  }                                         
}
