<mat-toolbar class="pair-new-device-toolbar">
  &nbsp;&nbsp;
  <span  i18n="@@Favorite Module">Favorite Module</span>
    <span class="flex-spacer"></span>
    <button mat-stroked-button (click)="RefreshList()">
      <span  i18n="@@Refreshmodulelist">Refresh Module List</span>
    </button>&nbsp; &nbsp;
    <button mat-stroked-button  (click)="$event.stopPropagation();openAddFavoriteModuleDialog()">
      <span  i18n="@@Add New">Add New</span>
      <mat-icon>add</mat-icon>
    </button>
  </mat-toolbar>
  
  <div class="table-container">
    <!--Data Table-->
    <mat-form-field class="filter-field">
      <mat-label i18n="@@filter">Filter</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="{{placeholder|translation}}"
        #input
      />
    </mat-form-field>
  
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" >
        <caption [hidden]="true"> Description </caption>
        <!-- Client -->
        <ng-container matColumnDef="Client">
          <th mat-header-cell *matHeaderCellDef i18n="@@Client Name">Client Name</th>
          <td mat-cell *matCellDef="let row">{{ row.clientName }}</td>
        </ng-container>
  
        <!-- Module -->
        <ng-container matColumnDef="Module">
          <th mat-header-cell *matHeaderCellDef i18n="@@Module Name">Module Name</th>
          <td mat-cell *matCellDef="let row">{{ row.moduleName | translation }}</td>
        </ng-container>
  
        <!-- Action Column -->
         <!-- Action Column -->
         <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div class="text-right">
            <button  mat-flat-button color="warn" (click)="
            $event.stopPropagation(); deleteFavoriteModule(row.favoriteModuleId)">
              <mat-icon>delete</mat-icon>
              <span i18n="@@Delete">Delete</span> 
            </button>
          </div>          
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
  
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            {{noData|translation}} {{ input.value }}
          </td>
        </tr>
      </table>
  
      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
  </div>
  
