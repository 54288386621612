import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RoleService } from 'src/app/services/role.service';
import { ScreenService } from 'src/app/services/screen.service';
import { AppConstants } from 'src/app/AppConstants';
import { RoleScreenService } from 'src/app/services/rolescreen.service';
import { MsgDialogComponent } from '../msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserrolesCvascreensComponent } from 'src/app/pages/menu/userroles-cvascreens/userroles-cvascreens.component';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { UserService } from 'src/app/services/user.service';
import { IBusinessGroup, IClient, UserBusinessGroup } from 'src/app/models/user.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TranslationPipe } from 'src/app/locale/translation.pipe';

interface IRole {
  roleId: number;
  roleName: string;
}

interface IRolebySCreen {
  roleId: number;
}

interface IScreen {
  screenId: number;
  screenName: string;
}

@Component({
  selector: 'app-add-userbgmap',
  templateUrl: './add-userbgmap.component.html',
  styleUrls: ['./add-userbgmap.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AddUserBGMapComponent implements OnInit {
  UnmappedClt: IClient[] = [];
  filteredUnmappedClt: IClient[] = [];
  selectedCltlist: string = "";
  selectedBGlist: string = "";
  SelectClient: string = 'Select Client';
  DuplicateRecords: string = 'Duplicate Records';
  BGMappingfailedfor: string = 'BG Mapping failed for: ';
  BGMappingdoneSuccessfully: string = 'BG Mapping done Successfully';
  Success: string = 'Success';
  DuplicateRecordPresent: string = 'Duplicate Record Present';
  DuplicateEntriesarenotallow: string = 'Duplicate Entries are not allow';
  Failed: string = 'Failed';
  BGMappingdeletionfailedfor: string = 'BG Mapping deletion failed for: ';
  BGMappingdeletionSuccessfully: string = 'BG Mapping deletion Successfully';
  BGMappingdeletionfailed: string = 'BG Mapping deletion failed';
  SelectBusinessGroup: string = 'Select Business Group';
  SelectAllItemsFromList: string = 'Select All Items From List';
  UnSelectAllItemsFromList: string = 'UnSelect All Items From List';
  Nodataavailable: string = 'No data available';
  Search: string = 'Search';
  globalerror: string = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
  UnmappedBg: IBusinessGroup[] = [];
  custsMaster: IClient[] = [];
  bgMaster: any[] = [];
  selectedClient: any;
  selectedObjects: any[] = [];
  selectedObjectsfromArray: any[] = [];
  gblSelectedClientId: number = 0;
  selectedClt: number = 0;
  searchTxt: any;
  dropdownSettings: IDropdownSettings = {};
  bgdropdownSettings: IDropdownSettings = {};
  RoleIdPristine: boolean = true;
  BGPristine: boolean = true;
  selected: any;
  flag: number;
  @ViewChild('select') select!: MatSelect;
  allSelected = false;
  userbgMapForm = new FormGroup({
    myItems: new FormControl("", [Validators.required]),
    bgItems: new FormControl("", [Validators.required]),
  });

  constructor(private roleService: RoleService,
    private screenService: ScreenService,
    private userService: UserService,
    private roleScreenService: RoleScreenService,
    public dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<UserrolesCvascreensComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.selectedClient = data;
    this.flag = data;
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    const filterPipe = new TranslationPipe();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'clientName',
      allowSearchFilter: true,
      enableCheckAll: true,
      selectAllText: filterPipe.transform(this.SelectAllItemsFromList),
      unSelectAllText: filterPipe.transform(this.UnSelectAllItemsFromList),
      itemsShowLimit: 3,
      maxHeight: 100,
      limitSelection: 10,
      searchPlaceholderText: filterPipe.transform(this.Search),
      noDataAvailablePlaceholderText: filterPipe.transform(this.Nodataavailable),
      noFilteredDataAvailablePlaceholderText: filterPipe.transform(this.Nodataavailable)
    };
    this.bgdropdownSettings = {
      singleSelection: false,
      idField: 'businessGroupId',
      textField: 'businessGroupName',
      allowSearchFilter: true,
      enableCheckAll: true,
      selectAllText: filterPipe.transform(this.SelectAllItemsFromList),
      unSelectAllText: filterPipe.transform(this.UnSelectAllItemsFromList),
      itemsShowLimit: 3,
      maxHeight: 100,
      searchPlaceholderText: filterPipe.transform(this.Search),
      noDataAvailablePlaceholderText: filterPipe.transform(this.Nodataavailable),
      noFilteredDataAvailablePlaceholderText: filterPipe.transform(this.Nodataavailable)
    };

    this.bindClient(AppConstants.glbSelectedUserId);





  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
      this.select.close();
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }

  }

  onClientSelect(item: any) {
    this.RoleIdPristine = false;
    this.selectedCltlist = "";
    const clist: IClient[] = [];
    Object.assign(clist, this.userbgMapForm.value.myItems);

    clist.forEach(element => {
      this.selectedCltlist = this.selectedCltlist + ',' + (element.clientId.toString());
    });
    this.selectedCltlist = this.selectedCltlist.substring(1, this.selectedCltlist.length);

    this.bindBGOnclientChange(this.selectedCltlist);

  }


  onBGSelect(item: any) {
    this.BGPristine = false;
    this.selectedBGlist = "";
    const bglist: IBusinessGroup[] = [];

    Object.assign(bglist, this.userbgMapForm.value.bgItems);
    bglist.forEach(element => {
      this.selectedBGlist = this.selectedBGlist + ',' + (element.businessGroupId.toString());
    });
    this.selectedBGlist = this.selectedBGlist.substring(1, this.selectedBGlist.length);
  }


  onSelectAllBG(items: any) {
    this.BGPristine = false;
    this.selectedBGlist = "";
    items.forEach((sid: any) => {
      this.selectedBGlist = this.selectedBGlist + ',' + sid.businessGroupId;
    });
    this.selectedBGlist = this.selectedBGlist.substring(1, this.selectedBGlist.length);
  }

  openMessageDialog(dialogTitle: string, dialogText: string) {
    this.dialog.open(MsgDialogComponent, {
      data: {
        title: dialogTitle,
        text: dialogText,
      },
    });
  }


  async onSubmit() {
    const filterPipe = new TranslationPipe();

    if (this.userbgMapForm.valid) {
      const userBusinessGroupObj: UserBusinessGroup = {
        BusinessGroupIds: this.selectedBGlist,
        UserId: AppConstants.glbSelectedUserId,
        LoggedinUserId: AppConstants.gblLoggedInUserId
      };


      if (this.flag == 1) {
        await this.userService.
          postBGUserData(userBusinessGroupObj)
          .subscribe(
            (response) => {
              if (response != null && response != "") {
                this.openMessageDialog(filterPipe.transform(this.DuplicateRecords), filterPipe.transform(this.BGMappingfailedfor) + String(response).replace(/,(\s+)?$/, ''));
              }
              else {
                this.openMessageDialog(filterPipe.transform(this.Success), filterPipe.transform(this.BGMappingdoneSuccessfully));
              }

            },
            (error) => {

              if (error.status == 409) {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.DuplicateRecordPresent),
                    text: filterPipe.transform(this.DuplicateEntriesarenotallow),
                  },
                });
              }
              else {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Failed),
                    text: filterPipe.transform(this.globalerror),
                  },
                });
              }
            });
      }
      else if (this.flag == 2) {
        await this.userService.
          deleteBGUserData(userBusinessGroupObj)
          .subscribe(
            (response) => {
              if (response != null && response != "") {
                this.openMessageDialog(filterPipe.transform(this.Failed), filterPipe.transform(this.BGMappingdeletionfailedfor) + String(response).replace(/,(\s+)?$/, ''));
              }
              else {
                this.openMessageDialog(filterPipe.transform(this.Success), filterPipe.transform(this.BGMappingdeletionSuccessfully));
              }

            },
            (error) => {

              if (error.status == 409) {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Failed),
                    text: filterPipe.transform(this.BGMappingdeletionfailed),
                  },
                });
              }
              else {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Failed),
                    text: filterPipe.transform(this.globalerror),
                  },
                });
              }
            });
      }

    }
  }
  bindClient(selectedUserId: number) {

    if (this.flag == 1) {
      this.userService
        .getUnMappedClientByUser(selectedUserId)
        .then((data) => {
          this.UnmappedClt = data as IClient[];
          this.custsMaster = this.UnmappedClt;

        })
        .catch((error) => {
          console.log('IU Promise rejected with ' + JSON.stringify(error));
        });
    }
    else if (this.flag == 2) {
      this.userService
        .getMappedClientByUser(selectedUserId)
        .then((data) => {
          this.UnmappedClt = data as IClient[];
          this.custsMaster = this.UnmappedClt;

        })
        .catch((error) => {
          console.log('IU Promise rejected with ' + JSON.stringify(error));
        });
    }


  }

  bindBGOnclientChange(selectedClients: string) {
    {
      if (this.flag == 1) {
        this.userService
          .getUnMappedBusinessGroupListByUser(AppConstants.glbSelectedUserId, selectedClients)
          .then((data) => {
            this.UnmappedBg = data as IBusinessGroup[];
          })
          .catch((error) => {
            console.log('IU Promise rejected with ' + JSON.stringify(error));
          });
      }

      else if (this.flag == 2) {
        this.userService
          .getMappedBusinessGroupListByUser(AppConstants.glbSelectedUserId, selectedClients)
          .then((data) => {
            this.UnmappedBg = data as IBusinessGroup[];
          })
          .catch((error) => {
            console.log('IU Promise rejected with ' + JSON.stringify(error));
          });
      }
    }

  }


}
