import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IFeedbackCategory } from 'src/app/models/feedback-category';
import { Feedback } from 'src/app/models/feedback.model';
import { FeedbackService } from 'src/app/services/feedback.service';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponent } from 'src/app/app.component';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class FeedbackComponent implements OnInit {
  categories?: IFeedbackCategory[];
  Provideaonelinesummary : string = 'Provide a one line summary';
  Provideamoredetaileddescription :  string = 'Provide a more detailed description';
  VerySatisfied : string  = "Very Satisfied";
  Satisfied: string  = "Satisfied";
  Neutral: string  = "Neutral";
  Dissatisfied: string  = "Dissatisfied";
  VeryDissatisfied: string  = "Very Dissatisfied";
  SaveSuccess : string = "Save Success";
  Feedbacksubmittedsuccessfully : string = "Feedback submitted successfully";
  Failed : string = "Failed";
  Failedtosubmitfeedback : string ="Failed to submit feedback";
  feedbackForm=new FormGroup({
    feedbackCategoryId:new FormControl(null, [Validators.required]),
    rating: new FormControl(null, [Validators.required]),
      summary: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      priority: new FormControl(null, [Validators.required])
  });

  
  constructor( private feedbackService: FeedbackService,public dialog: MatDialog,public dialogRef: MatDialogRef<any>) {    
  }

  ngOnInit(): void {
    this.bindCategories();
  }

  async bindCategories(){
    this.categories=await this.feedbackService.getFeedbackCategories() as IFeedbackCategory[];
  }

  onSubmit() {
    const filterPipe = new TranslationPipe();
    const feedback: Feedback = {
      rating:Number(this.feedbackForm.value.rating),
      screenId: AppConstants.glbScreenId,
      description: this.feedbackForm.value.description==null?"":this.feedbackForm.value.description,
      priority: String(this.feedbackForm.value.priority),
      summary:String(this.feedbackForm.value.summary) ,
      applicationId:1,
      feedbackCategoryId:Number(this.feedbackForm.value.feedbackCategoryId),
      createdBy:AppConstants.gblLoggedInUserId,
      modifiedBy:AppConstants.gblLoggedInUserId

    };
    

     this.feedbackService.postFeedback(feedback)
    .subscribe(
      (response) => {  
        this.dialog.open(MsgDialogComponent, {
          data: {
            title: filterPipe.transform(this.SaveSuccess),
            text: filterPipe.transform(this.Feedbacksubmittedsuccessfully),
          },
    });

this.dialogRef.close();

  },
  (error) => {   
            
    //  if(error.status==409){
    //   this.dialog.open(MsgDialogComponent, {
    //     data: {
    //       title: 'Duplicate',
    //       text: 'Duplicate User',
    //     },
    //   });
    //  }
    //  else{
      this.dialog.open(MsgDialogComponent, {
        data: {
          title: filterPipe.transform(this.Failed),
          text: filterPipe.transform(this.Failedtosubmitfeedback),
        },
      });
    // }
  }
)

   

  }
}
