export const environment = {
  production: false,
  clientId: "8e11c247-bff9-4200-b654-8ee7ab2ff70c",
  policy_signup_signin:  "B2C_1A_CSAAUAT_signup_signin",
  policy_profileedit:   "B2C_1A_CSAAUAT_ProfileEdit",
  policy_passwordreset:   "B2C_1A_CSAAUAT_PasswordReset",
  redirectUri:"https://analytics-dev.ups.com/",
  // apiUrl: "https://analyticsapi-dev.ups.com/api/",
  apiUrl: "https://analyticsapi-uat.ups.com/api/",
  cvdmRedirect_Url:"https://cvdm-admin-uat.inside.ams1907.com/",
  pickupRedirect_Url:"https://upsspm.ams1907.com/",
  returnsRedirect_Url:"https://row.ams1907.com/b2c/",
  trailerRedirect_Url:"https://cvt.ams1907.com/",
  cvsRedirect_Url:"https://CVSignature.ams1907.com/",
  access_deniedUrl:"https://learningcenter-ihub.ups.com/Analytics",
  rating_Url:"https://rating-ihub-uat.ups.com",
  authClientId: "VVTqshtb2CyZWUiKNRVfog5yigLhI0Qp",
  connection: "B2C",
  authLogout : "b2c_1a_csaauat_qlikauth0_signin",
  qlikClientId: "fae9306114b908bf430d6e36ef6cf63b",
  domain: "cs-ups.us.auth0.com",
  audience: "https://cs-ups.us.auth0.com/api/v2/",
  dataHost: 'https://upsanalytics-uat.us.qlikcloud.com',
  instrumentationKey:""
};

