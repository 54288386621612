<mat-toolbar class="pair-new-device-toolbar">
  <mat-icon class="icon-big">published_with_changes</mat-icon>
  &nbsp;&nbsp;
  <button mat-button class="icon-big">
    Mapping Account Numbers To Business Group
  </button>
  <span class="flex-spacer"></span>
</mat-toolbar>

<mat-card class="example-card">
  <mat-card-header>
    <mat-icon mat-card-avatar class="icon-big color-blue"
      >receipt_long</mat-icon
    >
    <mat-card-title class="color-blue">Account Numbers</mat-card-title>
    <mat-card-subtitle>Select Client & Business Group</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="grid-2c">
      <mat-form-field>
        <mat-label>Client</mat-label>
        <mat-select>
          <mat-option> Demo Client </mat-option>
          <mat-option> Addidas </mat-option>
          <mat-option> Roles Royal </mat-option>
          <mat-option> Other Client </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Business Group</mat-label>
        <mat-select>
          <mat-option> Demo BG1 </mat-option>
          <mat-option> Addidas BG1 </mat-option>
          <mat-option> Roles Royal BG1 </mat-option>
          <mat-option> Other Client BG1 </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <br />
  </mat-card-content>
</mat-card>

<mat-card class="example-card">
  <mat-card-header>
    <mat-icon mat-card-avatar class="icon-big color-blue"
      >people</mat-icon
    >
    <mat-card-title class="color-blue">Business Group</mat-card-title>
    <mat-card-subtitle>Select Account Numbers & Business Group</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="grid-2c">
      <mat-form-field>
        <mat-label>Account Numbers</mat-label>
        <mat-select>
          <mat-option> Demo Client </mat-option>
          <mat-option> Addidas </mat-option>
          <mat-option> Roles Royal </mat-option>
          <mat-option> Other Client </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Business Group</mat-label>
        <mat-select>
          <mat-option> Demo BG1 </mat-option>
          <mat-option> Addidas BG1 </mat-option>
          <mat-option> Roles Royal BG1 </mat-option>
          <mat-option> Other Client BG1 </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <br />
  </mat-card-content>
  <mat-card-actions class="text-center">
    <button mat-raised-button>Cancel</button>
    <button mat-raised-button color="primary" type="submit">
      Submit
    </button>
  </mat-card-actions>
</mat-card>
