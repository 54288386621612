<mat-toolbar class="pair-new-device-toolbar">
    &nbsp;&nbsp;
    <span  i18n="@@Feedbacks title">Feedbacks</span>
    <span class="flex-spacer"></span>
    
  </mat-toolbar>
  
  
  <div class="table-container">
    <!--Data Table-->
    <mat-form-field class="filter-field">
      <mat-label  i18n="@@filter">Filter</mat-label>
      <input 
        matInput
        (keyup)="applyFilter($event)"
         placeholder = {{placeholder|translation}} 
        #input
      />
    </mat-form-field>
  
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort  >
        <caption [hidden]="true"> Description </caption>
        <!-- Role Column -->
        <ng-container matColumnDef="summary">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@feedback">Feedback</th>
          <td mat-cell *matCellDef="let row">{{ row.summary }}</td>
        </ng-container>
        <ng-container matColumnDef="screenName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@screen name">Screen Name</th>
          <td mat-cell *matCellDef="let row">{{ row.screenName }}</td>
        </ng-container>
        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@customer name">Customer Name</th>
          <td mat-cell *matCellDef="let row">{{ row.userName }}</td>
        </ng-container>
       
        <ng-container matColumnDef="priority">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@priority">Priority</th>
          <td mat-cell *matCellDef="let row">{{ row.priority }}</td>
        </ng-container>
        
        <ng-container matColumnDef="rating">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@rating">Rating</th>
          <td mat-cell *matCellDef="let row">{{ row.rating }}</td>
        </ng-container>
  
        <!-- Action Column -->
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
           {{noData|translation}} {{ input.value }}
          </td>
        </tr>
      </table>
  
      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
  </div>