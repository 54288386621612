<form [formGroup]="editAlerconfigurationForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-title class="edituser-dialog-header color-green">
    <mat-icon *ngIf="selectedAlert.alertConfigurationID==0">add</mat-icon> &nbsp;&nbsp; <label
      *ngIf="selectedAlert.alertConfigurationID==0">
      <span i18n="@@addalerts">Add Alerts</span><span> : {{clientName}}</span>
    </label>
    <mat-icon *ngIf="selectedAlert.alertConfigurationID!=0">edit</mat-icon> &nbsp;&nbsp;<label
      *ngIf="selectedAlert.alertConfigurationID!=0">
      <span i18n="@@editalerts">Edit Alerts</span><span> : {{clientName}}</span>
    </label>
  </div>

  <mat-dialog-content class="mat-typography">
    <mat-divider></mat-divider>

    <mat-card>
      <input matInput [hidden]="true" formControlName="alertConfigurationID"
        [(ngModel)]="selectedAlert.alertConfigurationID" />

      <mat-form-field>
        <mat-label i18n="@@devicename">Device Names</mat-label>
        <mat-select formControlName="device" (selectionChange)="onDeviceChange($event)"
          [(ngModel)]="selectedAlert.deviceID" [disabled]="isReadOnly">
          <mat-option *ngFor="let devicename of devicenames" [value]="devicename.deviceID">
            {{ devicename.deviceName }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf=" editAlerconfigurationForm.get('alert')?.touched && editAlerconfigurationForm.get('alert')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@alertdescription">Alert Description</mat-label>
        <mat-select formControlName="alert" (selectionChange)="onAlertChange($event)"
          [(ngModel)]="selectedAlert.alertID" [disabled]="isReadOnly">
          <mat-option *ngFor="let alertsdefination of alertsdefinations" [value]="alertsdefination.alertID">
            {{ alertsdefination.alertDescription }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf=" editAlerconfigurationForm.get('alert')?.touched && editAlerconfigurationForm.get('alert')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@maxvalue">Max Value</mat-label>
        <input matInput matTooltip="{{maxValueValidation|translation}}" formControlName="maxValue" maxlength="50"  [(ngModel)]="selectedAlert.maxValue" />
        <mat-error
          *ngIf="editAlerconfigurationForm.get('maxValue')?.touched && editAlerconfigurationForm.get('maxValue')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@minvalue">Min Value</mat-label>
        <input matInput matTooltip="{{minValueValidation|translation}}"  formControlName="minValue" maxlength="50" [(ngModel)]="selectedAlert.minValue" />
        <mat-error
          *ngIf="editAlerconfigurationForm.get('minValue')?.touched && editAlerconfigurationForm.get('minValue')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@alertdefaultvalue">Alert Default Value</mat-label>
        <input  matInput matTooltip="{{defaultValueValidation|translation}}" formControlName="alertDefaultValue" maxlength="50"
          [(ngModel)]="selectedAlert.alertDefaultValue" />
        <mat-error
          *ngIf="editAlerconfigurationForm.get('alertDefaultValue')?.touched && editAlerconfigurationForm.get('alertDefaultValue')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@uom">UOM</mat-label>
        <input matInput formControlName="UOM" maxlength="50" [(ngModel)]="selectedAlert.uom" [readonly]="true" />
        <mat-error
          *ngIf="editAlerconfigurationForm.get('UOM')?.touched && editAlerconfigurationForm.get('UOM')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>
    </mat-card>
    <br />
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
      <span i18n="@@Cancel">Cancel</span>
    </button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial type="submit"
      [disabled]="!editAlerconfigurationForm.valid">
      <span i18n="@@Submit">Submit</span>
    </button>
  </mat-dialog-actions>
</form>