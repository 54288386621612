<form [formGroup]="editClientForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-title class="edituser-dialog-header color-green">
    <mat-icon *ngIf="selectedClient.clientId==0">add</mat-icon> &nbsp;&nbsp; <label *ngIf="selectedClient.clientId==0">
      <span i18n="@@Add Client">Add Client</span>
    </label>
    <mat-icon *ngIf="selectedClient.clientId!=0">edit</mat-icon> &nbsp;&nbsp;<label *ngIf="selectedClient.clientId!=0">
      <span i18n="@@Edit Client">Edit Client</span>
      </label>
  </div>

  <mat-dialog-content class="mat-typography">
    <mat-divider></mat-divider>

    <mat-card>
      <input matInput  [hidden]="true" formControlName="clientId" [(ngModel)]="selectedClient.clientId" />
      <mat-form-field>
        <mat-label i18n="@@Client Name">Client Name</mat-label>
        <input matInput formControlName="clientName" [readonly]="true" maxlength="50" [(ngModel)]="selectedClient.clientName" />
        <mat-error
          *ngIf="editClientForm.get('clientName')?.touched && editClientForm.get('clientName')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@Client Alias">Client Alias</mat-label>
        <input matInput formControlName="client_Alias" maxlength="200" [(ngModel)]="selectedClient.client_Alias" />
        <mat-error
          *ngIf="editClientForm.get('client_Alias')?.touched && editClientForm.get('client_Alias')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@Original Client Id">Original Client Id</mat-label>
        <input matInput formControlName="orginalClientId" maxlength="9" [(ngModel)]="selectedClient.orginalClientId" />
        <mat-error
          *ngIf="editClientForm.get('orginalClientId')?.touched && editClientForm.get('orginalClientId')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@KPI">KPI</mat-label>
        <input matInput formControlName="kpi" pattern="^((0\d?)(\.\d{1,2})?|1(\.00?)?)$"
          [(ngModel)]="selectedClient.kpi" />
        <mat-error *ngIf="editClientForm.get('kpi')?.touched && editClientForm.get('kpi')?.errors?.required">
          <span i18n="@@Enter valid KPI(0-1)(upto 2 decimals)">Enter valid KPI(0-1)(upto 2 decimals)</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="header-select">
        <mat-label i18n="@@Region">Region</mat-label>
        <mat-select class="customClass" formControlName="region"   [(ngModel)]="selectedClient.regionId" >
          <mat-option *ngFor="let regions of clientregion" [value]="regions.regionId" >
            {{ regions.regionName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf=" editClientForm.get('region')?.touched && editClientForm.get('region')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@Default Currency">Default Currency</mat-label>
        <mat-select   formControlName="currencyName"   [(ngModel)]="selectedClient.defaultCurrency" >
          <ngx-mat-select-search  class="search"  matInput placeholderLabel={{Search|translation}} #SearchCurrency (keyup)="applyFilterCurrency($event)" >
            </ngx-mat-select-search>
           

          <mat-option *ngFor="let currency of currencies" [value]="currency.symbol" >
            {{ currency.symbol }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf=" editClientForm.get('currencyName')?.touched && editClientForm.get('currencyName')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="escalation" [(ngModel)]="selectedClient.escalation">
        <span i18n="@@Escalation Package">Escalation Package</span>
      </mat-checkbox><br><br>
    </mat-card>
    <br />
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
      <span i18n="@@Cancel">Cancel</span>
    </button>
     <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial type="submit"
      [disabled]="!editClientForm.valid">
      <span i18n="@@Submit">Submit</span>
    </button>
    <!-- <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial type="submit">
      Save
    </button> -->
  </mat-dialog-actions>
</form>
