import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IFeedback } from 'src/app/models/feedback.model';
import { FeedbackService } from 'src/app/services/feedback.service';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';



@Component({
  selector: 'app-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class UserFeedbackComponent implements OnInit {
  displayedColumns: string[] = ['summary','screenName','userName','priority','rating'];
  dataSource: MatTableDataSource<IFeedback>;
  placeholder :string = 'Feedback,Screen Name,etc.';
  noData :string = 'No data found';
  Itemsperpage : string = 'Items per page';
  Nextpage : string = 'Next page';
  Previouspage : string = 'Previous page';
  of : string = 'of';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public dialog: MatDialog,public feedbackService:FeedbackService,private commonService:CommonService,public router: Router) {
    // Create 100 users
  //  const bgroups = Array.from({ length: 10 }, (_, k) => createNewBGroup(k + 1));

    // Assign the data to the data source for the table to render
        this.dataSource=new MatTableDataSource<IFeedback>();
      

  }
  ngOnInit() {
    this.bindFeedback();
  }

   
   //bind Alert Rule Data to table
   bindFeedback(){
    this.feedbackService.getFeedback().then((data)=>{ 
    this.dataSource = new MatTableDataSource(data as IFeedback[]);  
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;    
  })
}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const rangelabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${filterPipe.transform(this.of)} ${length}`;
      }
    
      length = Math.max(length, 0);
    
      const startIndex = page * pageSize;
    
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
    
      return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
    };

   
     this.dataSource.sort = this.sort;
     const filterPipe = new TranslationPipe();
     this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
     this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
     this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
     this.paginator._intl.getRangeLabel = rangelabel;
     this.dataSource.paginator = this.paginator;

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

