<div class="home-container">
  <!-- <div *ngIf="ReportData.length==0">
    <img src="../assets/images/ups-logo-big.png" class="logo-img" />
  <h1 class="primary-text">C V A</h1>
   <h4 class="secondary-text">UPS® Intelligent Analytics</h4>
  </div> -->
  <div class="col-4 cards" *ngIf="ReportData">
  <mat-card *ngFor="let report of ReportData">
    <mat-card-header>
      <div mat-card-avatar class="header-image-avatar"></div>
      <mat-card-title  >{{report.reportName}}</mat-card-title>
     </mat-card-header>
    <mat-card-content>
      <p>
        {{report.reportDescription}}
    </mat-card-content>
  </mat-card>
  </div>
</div>
