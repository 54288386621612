import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl,Validators ,FormGroup } from '@angular/forms';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BusinessGroupService} from 'src/app/services/businessgroup.service';
import { BusinessGroupsComponent } from 'src/app/pages/menu/business-groups/business-groups.component';
import { IBusinessGroup} from 'src/app/models/business-group';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';



@Component({
  selector: 'app-businessgroup-edit',
  templateUrl: './businessgroup-edit.component.html',
  styleUrls: ['./businessgroup-edit.component.scss'],
  encapsulation:ViewEncapsulation.None
})


export class BusinessgroupEditComponent implements OnInit {

  selectedBG: any;
  selectedClient:any;
  selectedClientName:any;
  UpdateSuccess: string = 'Update Success';
  Duplicate : string = 'Duplicate';
  SaveSuccess: string  = 'Save Success';
  BusinessGroupCreatedSuccessfully: string  = 'Business Group Created Successfully';	
  DuplicateBusinessgroup : string  = 'Duplicate Business Group';
  BusinessgroupUpdatedSuccessfully : string  = 'Business group Updated Successfully';
  Failed : string = 'Failed';
  internalFlag: boolean = false;
  globalerror : string  = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
  editBGForm = new FormGroup({   
    businessGroupId: new FormControl(0),
    businessGroupName:new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z][a-zA-Z0-9_ ]*$')]),
    isInternal : new FormControl(false)
  });

  constructor(private businessGroupService:BusinessGroupService,public dialog: MatDialog,
    private router: Router,@Inject(MAT_DIALOG_DATA) public data:any,
    public dialogRef: MatDialogRef<BusinessGroupsComponent>) {
    this.selectedBG = data;
    if (this.data.businessGroupId === 0) {
      this.selectedClient=this.data.clientId;
      this.selectedClientName=this.data.clientName;
      this.selectedBG=null;
    }
    else
    {
      this.selectedClient=this.data.clientId;
      this.selectedClientName=this.data.clientName;
    }
   
    dialogRef.disableClose = true; 
    }

  ngOnInit(): void {
    if (this.selectedBG == undefined && this.selectedBG == null) {
      this.selectedBG = {
        businessGroupId:0,
        businessGroupName:'',
        isInternal : false 
      };
    } 
    if(AppConstants.glbRoleId==8 || AppConstants.glbRoleId==9)
    {
      this.internalFlag =  false;
    }
    else{this.internalFlag = true;}

  }
  
  async onSubmit() {
    const filterPipe = new TranslationPipe();
    if (this.editBGForm.valid) {
      const bgObj: any = {
        businessGroupId: this.editBGForm.value.businessGroupId,
        businessGroupName: this.editBGForm.value.businessGroupName,             
        modifiedBy: AppConstants.gblLoggedInUserId,
        clientId: this.selectedClient ,
        isInternal : this.editBGForm.value.isInternal
      };
   if (bgObj.businessGroupId > 0) {
    bgObj.isInternal = this.selectedBG.isInternal;
         this.businessGroupService
          .putBusinessGroupData(bgObj.businessGroupId,bgObj)
          .subscribe(() => {
            
            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.UpdateSuccess),
                text: filterPipe.transform(this.BusinessgroupUpdatedSuccessfully),
              },
            });

          },
          (error) => {
            if(error.status==409){
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Duplicate),
                  text: filterPipe.transform(this.DuplicateBusinessgroup),
                },
              });
             }
             else{
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.globalerror),
                },
              });
             }
          });
      }
      else
      {
         this.businessGroupService
        .createBG(bgObj)
        .subscribe(() => {
          
          this.dialog.open(MsgDialogComponent, {
            data: {
              title: filterPipe.transform(this.SaveSuccess),
              text: filterPipe.transform(this.BusinessGroupCreatedSuccessfully),
            },
          });

        },
        (error) => {
          if(error.status==409){
            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.Duplicate),
                text: filterPipe.transform(this.DuplicateBusinessgroup),
              },
            });
           }
           else{
            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.Failed),
                text: filterPipe.transform(this.globalerror),
              },
            });
           }
        });
      }
    }
  }
}

