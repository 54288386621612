import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/AppConstants';


@Injectable({
  providedIn: 'root'
})
export class IATConfigurationService {

  constructor(private httpService: HttpService) { }

  getIATClients(){
    return this.httpService.get(AppConstants.apiUrl+"IATConfiguration/GetIATClients"); 
  }
  
  getIATAlertsByClient(ClientId:number , ClientName:string){
    AppConstants.gblIATClientId = ClientId;
    AppConstants.gblIATClientName = ClientName;
    return this.httpService.get(AppConstants.apiUrl+"IATConfiguration/GetIATAlertsByClient?ClientId="+ClientId); 
  }  

  getIATDeviceNameByClient(ClientId:number){
    return this.httpService.get(AppConstants.apiUrl+"IATConfiguration/GetIATDeviceNameByClient?ClientId="+ClientId); 
  }  

  deleteAlertConfiguration(AlertConfigurationID:number , ModifiedBy: number){  
    return this.httpService.delete(AppConstants.apiUrl+"IATConfiguration/DeleteAlertConfiguration?AlertConfigurationID="+AlertConfigurationID + "&ModifiedBy="+ModifiedBy);    
  }

  addAlertConfiguration(acObj:any): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"IATConfiguration/AddAlertConfiguration", JSON.stringify(acObj))  ; 
  }

  addInsertAlertsDeviceMapping(acObj:any): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"IATConfiguration/InsertAlertsDeviceMapping", JSON.stringify(acObj))  ; 
  }
  
  getIATAlertDefinitionByClientandDevices(ClientID : number , DeviceID: number){
    return this.httpService.get(AppConstants.apiUrl+"IATConfiguration/GetIATAlertDefinitionByClientandDevices?ClientID="+ClientID + "&DeviceID="+DeviceID);
  }


  getIATDevicesAlertDefinitionDifference(DeviceToID : number , DeviceFromID: number){
    return this.httpService.get(AppConstants.apiUrl+"IATConfiguration/GetIATDevicesAlertDefinitionDifference?DeviceToID="+DeviceToID + "&DeviceFromID="+DeviceFromID);
  }

  getGetDeviceFrom(ClientID : number , DeviceID: number){
    return this.httpService.get(AppConstants.apiUrl+"IATConfiguration/GetGetDeviceFrom?ClientID="+ClientID + "&DeviceID="+DeviceID);
  }

  getIATAlertDefinitions(){
    return this.httpService.get(AppConstants.apiUrl+"IATConfiguration/GetIATAlertDefinitions");
  }

  updateAlertConfiguration(acObj:any): Observable<any>{
    return this.httpService.put(AppConstants.apiUrl+"IATConfiguration/UpdateAlertConfiguration" , JSON.stringify(acObj)) ;   
  }

  
}
