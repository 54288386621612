import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {AlertRuleService} from 'src/app/services/alert-rule.service';
import { Router } from '@angular/router';
import {AlertRule,IBusinessGroup,IAlertRuleUser,IAlertRuleBusinessGroup,IUser} from 'src/app/models/alert-rule.model';
import { MatDialog } from '@angular/material/dialog';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { observable, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { AppConstants } from 'src/app/AppConstants';


@Component({
  selector: 'app-alert-rules',
  templateUrl: './alert-rules.component.html',
  styleUrls: ['./alert-rules.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ maxHeight: 0, opacity: 0 }),
        animate('.3s ease-out', style({ maxHeight: 55, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ maxHeight: 55, opacity: 1 }),
        animate('.3s ease-in', style({ maxHeight: 0, opacity: 0 })),
      ]),
    ]),
    trigger('inOutBlurAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.3s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('.3s ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('fadeInslideOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.6s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        //style({ opacity: 1 }),
        animate(
          '.2s ease-in',
          style({ opacity: 0, transform: 'translateX(100%)' })
        ),
      ]),
    ]),
  ],
})
export class AlertRulesComponent implements OnInit {
  selectedAlertRuleId:number=0;
  isEdit: boolean = false;
  allBusinessGroups: any[] = [
    // {
    //   id: 4,
    //   name: 'Business Group 4',
    //   updated: 'Email, SMS (19 Users)',
    // },
    // {
    //   id: 5,
    //   name: 'Business Group 5',
    //   updated: 'Email, SMS (7 Users)',
    // },
    // {
    //   id: 6,
    //   name: 'Business Group 6',
    //   updated: 'SMS (4 Users)',
    // },
  ];
  groupsCtrl = new FormControl();
  filteredGroups: Observable<any[]>;

  businessGroups: any[] = [
    // {
    //   id: 1,
    //   name: 'Business Group 1',
    //   updated: 'Email, SMS (6 Users)',
    // },
    // {
    //   id: 2,
    //   name: 'Business Group 2',
    //   updated: 'Email, SMS (10 Users)',
    // },
    // {
    //   id: 3,
    //   name: 'Business Group 3',
    //   updated: 'Email (8 Users)',
    // },
  ];

  usersCtrl = new FormControl();
  filteredUsers: Observable<any[]>;

  allUsers: any[] = [
    // {
    //   id: 1,
    //   name: 'Jagdish Chopde',
    //   updated: 'Email, SMS',
    // },
    // {
    //   id: 2,
    //   name: 'Rohn Xavior',
    //   updated: 'Email, SMS',
    // },
    // {
    //   id: 3,
    //   name: 'Ross Warewolf',
    //   updated: 'Email',
    // },
  ];
  users: any[] = [
    // {
    //   id: 4,
    //   name: 'Nitin Bhurbhure',
    //   updated: 'Email, SMS',
    // },
    // {
    //   id: 5,
    //   name: 'Niraj Bijwe',
    //   updated: 'Email',
    // },
  ];

  columnsToDisplay: string[] = [
    'name',
   // 'businessGroups',
    'type',
    'operator',
    'threshold',
    'frequency',
    'actions'
  ];
  dataSource: MatTableDataSource<AlertRule>;
  expandedElement: any | null | undefined;


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private alertRuleService:AlertRuleService,public router: Router,public dialog: MatDialog,
    private commonService:CommonService) {
    this.filteredGroups = new Observable<IBusinessGroup[]>();
    // this.filteredGroups = this.groupsCtrl.valueChanges.pipe(
    //   startWith(''),
    //   map((group) =>
    //     group ? this._filterGroups(group) : this.allBusinessGroups.slice()
    //   )
    // );
    this.filteredUsers=new Observable<IUser[]>(); 
    // this.filteredUsers = this.usersCtrl.valueChanges.pipe(
    //   startWith(''),
    //   map((user) => (user ? this._filterUsers(user) : this.allUsers.slice()))
    // );
   
    this.dataSource=new MatTableDataSource<AlertRule>();
  }

  private _filterGroups(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.allBusinessGroups.filter((group) =>
      group.businessGroupName.toLowerCase().includes(filterValue)
    );
  }

  private _filterUsers(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.allUsers.filter((user) =>
      user.firstName.toLowerCase().includes(filterValue)
    );
  }  

  ngOnInit() {   
    this.bindAlertRuleList();
  }

  //bind Alert Rule Data to table
  bindAlertRuleList(){
      this.alertRuleService.getAlertRules(AppConstants.gblClientId).then((data)=>{ 
      this.dataSource = new MatTableDataSource(data as AlertRule[]);      
    })
  }

  bindDetails(expandedElement:any,alertRuleId: number){
  if(expandedElement)
  {
    this.bindBusinessgroups(alertRuleId);
    this.bindUsers(alertRuleId);
    this.bindMappedBusinessGroups(alertRuleId);
    this.bindMappedUsers(alertRuleId);
    this.selectedAlertRuleId=alertRuleId;
  }
  else{
    this.selectedAlertRuleId=0;
    
  }
  if(this.isEdit ){
    this.isEdit=!this.isEdit;
  }
}
bindBusinessgroups(alertRuleId:number){
  this.alertRuleService
  .getUnmappedBusinessGroupsByAlertId(AppConstants.gblClientId,alertRuleId)
  .then((data) => {    
    this.allBusinessGroups=  data as IBusinessGroup[] ; 
    this.filteredGroups = this.groupsCtrl.valueChanges.pipe(
        startWith(''),
        map((group) =>
          group ? this._filterGroups(group) : this.allBusinessGroups.slice()
        )
      );
})
  .catch((error) => {
    console.log('AU1 Promise rejected with ' + JSON.stringify(error));
  });  
}
bindUsers(alertRuleId:number){
  
  this.alertRuleService
  .getUnmappedUsersByAlertId(AppConstants.gblClientId,alertRuleId)
  .then((data) => {  
    this.allUsers=  data as IUser[] ;    
       
    this.filteredUsers = this.usersCtrl.valueChanges.pipe(
      startWith(''),
      map((user) => (user ? this._filterUsers(user) : this.allUsers.slice()))
    );
   
  })
  .catch((error) => {
    console.log('AU2 Promise rejected with ' + JSON.stringify(error));
  });  
}
bindMappedBusinessGroups(alertRuleId:number){
  
  this.alertRuleService
  .getMappedBusinessGroupsByAlertId(alertRuleId)
  .then((data) => {
    this.businessGroups=  data as IBusinessGroup[] ;
  })
  .catch((error) => {
    console.log('AU3 Promise rejected with ' + JSON.stringify(error));
  });              
 
}
bindMappedUsers(alertRuleId:number){
  this.alertRuleService
  .getMappedUsersByAlertId(alertRuleId)
  .then((data) => {
    this.users=  data as IUser[] ;    

  })
  .catch((error) => {
    console.log('AU4 Promise rejected with ' + JSON.stringify(error));
  });  
}

  //Redirect to Manage Alert Rule component after clicking Edit button
  redirectToManageAlertRule( row:AlertRule){   
    const rule: AlertRule = row;
    this.router.navigateByUrl('/menu/manage-alert-rules', { state: rule});
  }

  deleteAlertRule(alertRuleId:number){
    const dialogRef=this.dialog.open(ConfirmDialogComponent,{
      data: {
        title: 'Confirm delete',
        text: 'Are you sure to delete rule?',
      },
    }); 
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        const modifiedBy:number=1;
    this.alertRuleService.deleteAlertRuleData(alertRuleId,modifiedBy).then((data)=>{
      
    //  this.openMessageDialog('Delete Success','Alert Rule Deleted Successfully');

      this.bindAlertRuleList();         
    }).catch((error)=>{      
      this.commonService.openMessageDialog('Delete failed','Alert Rule Deletion Failed');

    });
      }
    });

    
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onEditToggle() {
    this.isEdit = !this.isEdit;
  }

  removeBusinessGroup(businessGroupId: number,alertRuleId:number) {
    const dialogRef=this.dialog.open(ConfirmDialogComponent,{
      data: {
        title: 'Confirm delete',
        text: 'Are you sure to delete rule?',
      },
    }); 
    
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
    this.alertRuleService.deleteCompleteBusinessAlertMapping(businessGroupId,alertRuleId).then((data)=>{
        
     // this.openMessageDialog('Delete Success','Alert Rule Deleted Successfully');

    this.businessGroups = this.businessGroups.filter((x) => x.businessGroupId != businessGroupId);
        this.bindBusinessgroups(this.selectedAlertRuleId)  ;     
    }).catch((error)=>{
    this.commonService.openMessageDialog('Delete failed','Alert Rule Deletion Failed');     
    });

  }});
  }

  removeUser(userId: number,alertRuleId:number) {
    const dialogRef=this.dialog.open(ConfirmDialogComponent,{
      data: {
        title: 'Confirm delete',
        text: 'Are you sure to delete rule?',
      },
    }); 
    dialogRef.afterClosed().subscribe(result => {
      if(result) {

    this.alertRuleService.deleteCompleteUserAlertMapping(userId,alertRuleId).then((data)=>{
      
    //this.openMessageDialog('Delete Success','Alert Rule Deleted Successfully');

    this.users = this.users.filter((x) => x.userId != userId);
    this.bindUsers(this.selectedAlertRuleId)  ;    
             
    }).catch((error)=>{
    this.commonService.openMessageDialog('Delete failed','Alert Rule Deletion Failed');
    });
  }});
  }

   operateUserMapping(event:any,alertRuleId:number,userId:number ) {
      switch(event.value){
        case "email":  var mapObj: IAlertRuleUser = {
                      alertRuleId:alertRuleId,
                      notificationTypeId:1,
                      userId:userId
                     }
                      switch(event.source._checked){
                        case true:  this.alertRuleService
                                    .postAlertRuleUserByNotification(mapObj)
                                    .subscribe((data) => {    
                                      this.bindUsers(alertRuleId);
                                      this.bindMappedUsers(alertRuleId);                                
                                    },
                                    (error) => {                                                                       
                                                         
                                    })
                                   break;
                        case false: this.alertRuleService
                                    .deleteAlertRuleUserByNotification(alertRuleId,userId,1)
                                    .then((data) => {
                                      this.bindUsers(alertRuleId);
                                      this.bindMappedUsers(alertRuleId);
                                    })
                                    .catch((error) => {                                                                       
                                                                                  
                                          
                                    });
                                   break;
                      };
                      break;
        case "sms": 
                      var mapObj: IAlertRuleUser = {
                        alertRuleId:alertRuleId,
                        notificationTypeId:2,
                        userId:userId
                      }
                        switch(event.source._checked){
                          case true:   this.alertRuleService
                                      .postAlertRuleUserByNotification(mapObj)
                                      .subscribe((data) => {     
                                        this.bindUsers(alertRuleId);
                                      this.bindMappedUsers(alertRuleId);                                 
                                      },
                                      (error) => {                                                                       
                                                           
                                      })
                                    break;
                          case false:this.alertRuleService
                                      .deleteAlertRuleUserByNotification(alertRuleId,userId,2)
                                      .then((data) => {
                                        this.bindUsers(alertRuleId);
                                        this.bindMappedUsers(alertRuleId);
                                      })
                                      .catch((error) => {                                                                       
                                                                                    
                                            
                                      });
                                    break;
                        };
                        break;
      }
  }

  operateBusinessGroupMapping(event:any,alertRuleId:number,businessGroupId:number ) {
    
    switch(event.value){
      case "email":
                    var mapObj: IAlertRuleBusinessGroup = {
                    alertRuleId:alertRuleId,
                    notificationTypeId:1,
                    businessGroupId:businessGroupId
                   }
                    switch(event.source._checked){
                      case true:   this.alertRuleService
                                  .postAlertRuleBusinessGroupByNotification(mapObj)
                                  .subscribe((data) => { 
                                    this.bindBusinessgroups(alertRuleId);
                                    this.bindMappedBusinessGroups(alertRuleId);
                                  },
                                  (error) => {                                                                       
                                                       
                                  })
                                 break;
                      case false:  this.alertRuleService
                                  .deleteAlertRuleBusinessGroupByNotification(alertRuleId,businessGroupId,1)
                                  .then((data) => {
                                    this.bindBusinessgroups(alertRuleId);
                                    this.bindMappedBusinessGroups(alertRuleId);
                                  })
                                  .catch((error) => {                                                                       
                                                                                
                                        
                                  });
                                 break;
                    };
                    break;
      case "sms": 
                    var mapObj: IAlertRuleBusinessGroup = {
                      alertRuleId:alertRuleId,
                      notificationTypeId:2,
                      businessGroupId:businessGroupId
                    }
                      switch(event.source._checked){
                        case true:   this.alertRuleService
                                    .postAlertRuleBusinessGroupByNotification(mapObj)
                                    .subscribe((data) => {  
                                      this.bindBusinessgroups(alertRuleId);
                                      this.bindMappedBusinessGroups(alertRuleId);                                    
                                    },
                                    (error) => {                                                                       
                                                         
                                    })
                                  break;
                        case false:  this.alertRuleService
                                    .deleteAlertRuleBusinessGroupByNotification(alertRuleId,businessGroupId,2)
                                    .then((data) => {
                                      this.bindBusinessgroups(alertRuleId);
                                      this.bindMappedBusinessGroups(alertRuleId);
                                    })
                                    .catch((error) => {                                                                       
                                                                                  
                                          
                                    });
                                  break;
                      };
                      break;
    }
  }

 
  addBusinessGroup(event: any) {
    event.stopPropagation();
    const groupName = this.groupsCtrl.value;
    const businessGroup = this.allBusinessGroups.find(
      (x) => x.businessGroupName == groupName
    );
    if (businessGroup) {
      this.businessGroups.unshift(businessGroup);
    }
    this.groupsCtrl.reset();
  }

  addUser(event: any) {
    event.stopPropagation();
    const userName = this.usersCtrl.value;
    const user = this.allUsers.find(
      (x) => x.userName == userName
    );
    if (user) {
      this.users.unshift(user);
    }
    this.usersCtrl.reset();
  }

 
}

 
 