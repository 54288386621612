import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MsgDialogComponent } from '../msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { NotificationComponent } from 'src/app/pages/menu/notification/notification.component';
import { NotificationService } from 'src/app/services/notification.service';
import { INotification, INotificationClients } from 'src/app/models/notification.model';
import { MatOption } from '@angular/material/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
import { AppConstants } from 'src/app/AppConstants';


@Component({
  selector: 'app-delete-notificationclient',
  templateUrl: './delete-notificationclient.component.html',
  styleUrls: ['./delete-notificationclient.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteNotificationClientComponent implements OnInit {
  notifications: INotification[] = [];
  selectedClientList : string  = "";
  unMappedClient :  any[] = [];
  SelectAllItemsFromList : string = 'Select All Items From List';
  UnSelectAllItemsFromList : string = 'UnSelect All Items From List';
  Nodataavailable : string = 'No data available';
  selectClients : string = 'Select Clients';
  Search : string = 'Search';
  gblNotificationId : number = 0;
  Failed: string = 'Failed';
  Success: string = "Success";
  MappingdoneSuccessfully: string = "Mapping deleted Successfully";
  clientDropdownSettings:IDropdownSettings={};
  globalerror: string = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
  @ViewChild('select') select!: MatSelect;
  allSelected = false;
  notificationClientForm = new FormGroup({
    notification: new FormControl("", [Validators.required]),
    clients: new FormControl("", [Validators.required])
  });

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<NotificationComponent>
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.bindNotification();
    const filterPipe = new TranslationPipe();
    this.clientDropdownSettings= {
      singleSelection: false,
      idField: 'clientId',
      textField: 'clientName',
      allowSearchFilter: true,
      enableCheckAll: true,
      selectAllText: filterPipe.transform(this.SelectAllItemsFromList),
      unSelectAllText: filterPipe.transform(this.UnSelectAllItemsFromList),
      itemsShowLimit: 3,
      maxHeight: 100,
      searchPlaceholderText: filterPipe.transform(this.Search),
      noDataAvailablePlaceholderText : filterPipe.transform(this.Nodataavailable),
      noFilteredDataAvailablePlaceholderText: filterPipe.transform(this.Nodataavailable)
    };
  }

  bindNotification() {
    this.notificationService.getNotification().then((data) => {
        this.notifications =  data as INotification[];
    });
  }

  onNotificationChange(event: MatSelectChange){
    this.gblNotificationId = (event.source.selected as MatOption).value;
    this.notificationService.getMappedClients(this.gblNotificationId).then((data)=>{
        this.unMappedClient =  data as any[];
    });
  }

  onClientSelect(item: any) {
    this.selectedClientList="";
     const clientlist:any[]=[];
     Object.assign(clientlist, this.notificationClientForm.value.clients);
     clientlist.forEach(element  => {
      this.selectedClientList=this.selectedClientList+','+(element.clientId.toString());
    }); 
    this.selectedClientList=this.selectedClientList.substring(1,this.selectedClientList.length);
  }
  
  onSelectAllClients(items: any): void {
    this.selectedClientList="";
    items.forEach((sid: any) => {
      this.selectedClientList=this.selectedClientList+','+sid.clientId;
  
    });
    this.selectedClientList=this.selectedClientList.substring(1,this.selectedClientList.length);
  }

  openMessageDialog(dialogTitle: string, dialogText: string) {
    this.dialog.open(MsgDialogComponent, {
      data: {
        title: dialogTitle,
        text: dialogText,
      },
    });
  }


  async onSubmit() {
    const filterPipe = new TranslationPipe();
    if (this.notificationClientForm.valid) {
      const notificationCLientsObj: INotificationClients = {
        notificationId : Number(this.notificationClientForm.value.notification),        
        clientIds : this.selectedClientList,
        loggedinUserId: AppConstants.gblLoggedInUserId
      };
      
     this.notificationService
        .deleteNotificationClients(notificationCLientsObj)
        .subscribe(
          (response) => {
              this.openMessageDialog(filterPipe.transform(this.Success), filterPipe.transform(this.MappingdoneSuccessfully));
            this.router.navigateByUrl('/menu/notification');
          },
          () => {

              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.globalerror),
                },
              });
          });


        }
  }








}
