import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {EscalatePackage} from '../models/escalate-package.model'
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../AppConstants';

@Injectable({
  providedIn: 'root'
})
export class EscalatePackageService {

  constructor(private httpService: HttpService,private http: HttpClient) { }

  postEscalationData(escalatePackage: EscalatePackage) {
    const Url = 'PostTEscalationPackage';   
    return this.httpService.post(Url, JSON.stringify(escalatePackage));  
  }

  checkEscalationAccess(gblLoggedInUserId:number){  
    return  this.http.get(AppConstants.apiUrl+"FreshDesk/CheckEscalationAccess?UserId="+gblLoggedInUserId);     
  }

}
