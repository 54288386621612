import { NgModule } from '@angular/core';
import { AuthModule } from '@auth0/auth0-angular';
import { AppEnv } from './GlobalConstants';



@NgModule({
  imports: [
    AuthModule.forRoot({
      domain: AppEnv.domain,
      clientId: AppEnv.authClientId,
      useRefreshTokens: true,
      skipRedirectCallback: window.location.pathname === '/callback',
      authorizationParams: {
        "audience": AppEnv.audience,
        "redirect_uri": AppEnv.redirectUri,
        "connection": AppEnv.connection
      },
      httpInterceptor: {
        allowedList: [ 
          {
            uri: AppEnv.apiUrl+'Application/AddApplicationLog*',
            allowAnonymous: true
          },
          '*',
        AppEnv.dataHost+"/*",
      ]
      },
      legacySameSiteCookie: false
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule { }
