<form [formGroup]="editUserForm" (ngSubmit)="onSubmit()">

    <div mat-dialog-title class="inviteuser-dialog-header color-blue">
      <mat-icon>person_add</mat-icon> &nbsp;&nbsp;
      <label > Update Info</label>
    </div>
     
    <mat-dialog-content class="mat-typography">
      <mat-divider></mat-divider>
      <br />
    
      <mat-card>
        <div class="grid-2c">
    
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput type="text" formControlName="firstName" [(ngModel)]="selectedUser.firstName"/>
            <mat-error *ngIf=" editUserForm.get('firstName')?.touched && editUserForm.get('firstName')?.errors?.required">
              Required
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput type="text" formControlName="lastName" [(ngModel)]="selectedUser.lastName"/>
            <mat-error *ngIf=" editUserForm.get('lastName')?.touched && editUserForm.get('lastName')?.errors?.required">
              Required
            </mat-error>
          </mat-form-field>
        </div>    
      
      </mat-card>
    
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        mat-flat-button
        color="primary"
        [mat-dialog-close]="true"
        cdkFocusInitial
        type="submit"
        [disabled]="!editUserForm.valid"
      >      
    Submit
      </button>
    </mat-dialog-actions>
    </form>
    