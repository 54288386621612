import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FavoriteModuleList } from 'src/app/models/favorite-module';
import { FavoriteModuleService} from 'src/app/services/favorite-module.service';
import { AppConstants } from 'src/app/AppConstants';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { FavoriteModuleEditComponent } from 'src/app/components/favorite-module-edit/favorite-module-edit.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TranslationPipe } from 'src/app/locale/translation.pipe';

@Component({
  selector: 'app-favorite-module-list',
  templateUrl: './favorite-module-list.component.html',
  styleUrls: ['./favorite-module-list.component.scss'],
  encapsulation:ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
    trigger('fadeInAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class FavoriteModuleListComponent implements OnInit {

  dataSource: MatTableDataSource<FavoriteModuleList>;
  favModuleList:FavoriteModuleList[]=[];
  placeholder :string = 'Client,Module Name.';
  Itemsperpage : string = 'Items per page';
  Nextpage : string = 'Next page';
  Previouspage : string = 'Previous page';
  of : string = 'of';
  noData :string = 'No data found';
  Confirmdelete :  string  = 'Confirm delete';
  AreyousuretodeleteFavoriteModule :  string = "Are you sure to delete Favorite Module?";
  Deletefailed :  string = "Delete failed";
  FavoriteModuleDeletionFailed :  string = 'Favorite Module Deletion Failed';
  columnsToDisplay = [
    'Client',   
    'Module',
    'action'   
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private favModuleService:FavoriteModuleService,public dialog: MatDialog,private commonService:CommonService) { 
    this.dataSource=new MatTableDataSource<FavoriteModuleList>();
  }

  ngOnInit(): void {
    this.bindFavoriteModuleList();
  }

  bindFavoriteModuleList(){
    this.favModuleService.getFavoriteModuleList(AppConstants.gblClientId).then((data)=>{ 
    this.dataSource = new MatTableDataSource(data as FavoriteModuleList[]); 
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort; 
    this.favModuleList=data as FavoriteModuleList[];          
  })
}

ngAfterViewInit() {
  const rangelabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 ${filterPipe.transform(this.of)} ${length}`;
    }
  
    length = Math.max(length, 0);
  
    const startIndex = page * pageSize;
  
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
  
    return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
  };

   this.dataSource.sort = this.sort;
   const filterPipe = new TranslationPipe();
   this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
   this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
   this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
   this.paginator._intl.getRangeLabel = rangelabel;
   this.dataSource.paginator = this.paginator;
}

RefreshList() {
  this.ngOnInit();
  }

deleteFavoriteModule(favoriteModuleId:number){
  const filterPipe = new TranslationPipe();
  const dialogRef=this.dialog.open(ConfirmDialogComponent,{
    data: {
      title: filterPipe.transform(this.Confirmdelete),
      text: filterPipe.transform(this.AreyousuretodeleteFavoriteModule),
    },
  }); 
  dialogRef.afterClosed().subscribe(result => {
    if(result) {
       
    this.favModuleService.deleteFavoriteModule(favoriteModuleId).then((data)=>{    
    this.bindFavoriteModuleList();         
   }).catch((error)=>{      
    this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed),filterPipe.transform(this.FavoriteModuleDeletionFailed));
  });
    }
  });  
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}

openAddFavoriteModuleDialog()  {
  const dialogRef = this.dialog.open(FavoriteModuleEditComponent, {
    width: '600px',
    data: this.favModuleList,
  });
  dialogRef.afterClosed().subscribe((result) => {
    this.ngOnInit();
  });
}
}
