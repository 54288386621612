import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AppConstants } from 'src/app/AppConstants';
import { IRole } from '../models/Role.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpService: HttpService) { }

  getRoles(ApplicationId	:number){
    return this.httpService.get(AppConstants.apiUrl+"Role/GetRoleList?ApplicationId="+ApplicationId + "&UserId="+AppConstants.gblLoggedInUserId);     
 }
 
 postRoleData(userRoleObj:any): Observable<any>{
  
   return this.httpService.post(AppConstants.apiUrl+"Role/AddRole" ,JSON.stringify(userRoleObj));
 }
 putRoleData(roleId:number,userRoleObj:any): Observable<any>{
 
  return this.httpService.put(AppConstants.apiUrl+"Role/UpdateRole?RoleId="+roleId, JSON.stringify(userRoleObj)) ;   
}
deleteRole(roleId:number,modifiedBy:number){
  return this.httpService.delete(AppConstants.apiUrl+"Role/DeleteRole?RoleId="+roleId+"&ModifiedBy="+modifiedBy);
 
 }
}
