import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { IBusinessGroup} from 'src/app/models/business-group';
import { AppConstants } from 'src/app/AppConstants';

interface IModule{
  moduleId:number,
  moduleName:string,
  createdBy:number,
  modifiedBy:number,
  applicationId:number,
  moduleTypeId:number,
  QlikApplicationId:string, 
  QlikSheetId:string,
  tenantId:number,
  tenantName:string,
}


@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(private httpService: HttpService) { }

  getModuleList(ApplicationId: number,UserId:number,clientId:number) {
    return this.httpService.get(AppConstants.apiUrl+"Module/GetModuleList?ApplicationId="+ApplicationId+"&UserId="+UserId+"&clientId="+clientId);
  }

  
   createModule(moduleObj:any): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"Module/AddModule", JSON.stringify(moduleObj))  ; 
  }

  deleteModule(moduleId:number,modifiedBy:number){  
    return this.httpService.delete(AppConstants.apiUrl+"Module/DeleteModule?ModuleId="+moduleId+"&ModifiedBy="+modifiedBy)  ;    
  }
  putModule(moduleId:number,moduleObj:IModule): Observable<any>{     
    return this.httpService.put(AppConstants.apiUrl+"Module/UpdateModule?ModuleId="+moduleId, JSON.stringify(moduleObj)) ;   
  }

  getMappedBG(moduleId:number,clientId:number){
    return this.httpService.get(AppConstants.apiUrl+"ModuleBusinessGroup/GetModuleBusinessGroupByModule?ModuleId="+moduleId+"&ClientId="+clientId);   
  }

  getUnmappedBG(moduleId:number,clientId:number){
    return this.httpService.get(AppConstants.apiUrl+"ModuleBusinessGroup/GetUnMappedModuleBusinessGroupByModule?ModuleId="+moduleId+"&ClientId="+clientId);      
  }

  deleteSingleBGModuleMapping(moduleBusinessGroupId:number){
    return this.httpService.delete(AppConstants.apiUrl+"ModuleBusinessGroup/DeleteModuleBusinessGroup?ModuleBusinessGroupId="+moduleBusinessGroupId+"&LoggedinUserId="+ AppConstants.gblLoggedInUserId)  ;    
  }
  deleteAllBGModule(selectedModuleId:number){
    return this.httpService.delete(AppConstants.apiUrl+"ModuleBusinessGroup/DeleteAllModuleBusinessGroup?ModuleId="+selectedModuleId+"&LoggedinUserId="+ AppConstants.gblLoggedInUserId)  ;    
  }
   

  postSingleBGModuleMapping(mapObj:any): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"ModuleBusinessGroup/AddModuleBusinessGroup", JSON.stringify(mapObj)) ;   
  }

  postAllBGModuleMapping(mapObj:any): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"ModuleBusinessGroup/AddAllModuleBusinessGroup" ,JSON.stringify(mapObj)) ;   
  }

 
}
