<header class="app-header" *ngIf="isLoaded">
  <app-header [sidenavProfile]="sidenavProfile"></app-header>
</header>
<div class="ribbon row col-12 d-block d-md-none">

</div>
<header class="ribbon" Id="style-13">


  <div hidden>
    <mat-form-field appearance="fill" class="app-select">
      <mat-label *ngIf="custSelect.value" class="app-select-label">Application</mat-label>
      <mat-select hidden
                  [(ngModel)]="selectedAppValue"
                  placeholder="Select an App..."
                  #custSelect
                  class="app-select-ctrl"
                  (selectionChange)="onAppChange()">
        <mat-option *ngFor="let item of apps" matTooltip="item.applicationName" [value]="item.applicationId">
          {{ item.applicationName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row custSelect1">
      <mat-select id="custSelect" 
                  disableOptionCentering [disabled]="custs.length == 1"
                  [(ngModel)]="selectedClientValue" placeholder={{SelectClient|translation}}
                  #custSelect class="module-select col-auto style-55 client-select-ctrl mat-select-content mat-select-placeholder mat-list-item activeMenu submenu"
                  (selectionChange)="onClientChange()" 
                  class="client-dropdown">

        <input (keydown)="$event.stopPropagation()" class="client-input" [(ngModel)]="searchTxt" matInput placeholder={{Search|translation}} autocomplete="off">


        <mat-option *ngFor="let item of custs  | clientSearch : searchTxt" [value]="item.clientId"
                    >
          {{ item.clientName }}
        </mat-option>
      </mat-select>

      <mat-select  disableOptionCentering id="moduleSelect"
                  [(ngModel)]="selectedModuleValue" placeholder={{SelectModule|translation}}
                  #moduleSelect class="module-select col-auto style-55 module-select-ctrl mat-select-content mat-select-placeholder"
                  [disabled]="!custSelect?.value" (selectionChange)="onModuleChange($event)"
                  class="module-dropdown"  (openedChange)="moduleExpanded()" (closedChange)="moduleCollapsed()" [ngClass]="{'expandedModule': isModuleExpanded == true}">

        <mat-option *ngFor="let item of modules" [value]="item.moduleId" >
            {{ item.moduleName | translation }}
        </mat-option>
      </mat-select>



    <div appearance="fill" *ngIf="isAdmin" class="screen-select col-auto style-73">
      <mat-select #screenSelect id="screenSelect"
                  disableOptionCentering placeholder={{SelectScreen|translation}}
                  *ngIf="moduleSelect?.value" class="module-select-ctrl mat-select-content mat-select-placeholder"
                  #pageSlectionList
                  class="screen-dropdown">

        <div *ngIf="isAdmin">
          <mat-option *ngFor="let page of pageList; let i = index"
                      [value]="page.screenName" [routerLink]="page.routerLink"
                      (click)="setScreenId(page.screenId);">
            {{ page.screenName | translation}}
          </mat-option>
        </div>

      </mat-select>

    </div>

    

  </div>


</header>


<mat-sidenav-container>
  <!-- Profile Sidenav -->
  <mat-sidenav #sidenavProfile
               mode="over"
               opened="false"
               position="end"
               id="style-106">
    <mat-tab-group>
      <mat-tab label="{{profile|translation}}">
        <h1><span i18n="@@profile">Profile</span></h1>
        <button mat-raised-button color="primary" (click)="changePrf()">
          <span i18n="@@Edit Profile">Edit Profile</span>
        </button>
      </mat-tab>
      <mat-tab label="{{ChangePassword|translation}}">
        <h1><span i18n="@@Change password">Change password</span></h1>
        <button mat-raised-button color="primary" (click)="changePwd()">
          <span i18n="@@Change">Change</span>
          
        </button>

      </mat-tab>
    </mat-tab-group>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


