import { Component, OnInit } from '@angular/core';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConstants } from 'src/app/AppConstants';
import { DataService } from 'src/app/services/data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProcessAccountnumbersService } from 'src/app/services/process-accountnumbers.service';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslationPipe } from 'src/app/locale/translation.pipe';

@Component({
  selector: 'app-process-businessgroup',
  templateUrl: './process-businessgroup.component.html',
  styleUrls: ['./process-businessgroup.component.scss']
})
export class ProcessBusinessgroupComponent implements OnInit {
  clients: any[] = [];
  selectedClients: any[] = [];
  searchTxt: any;
  Nodataavailable : string = 'No data available';
  Search: string = "Search";
  Success: string = "Success";
  Processcompletedsuccessfully: string = "Process completed successfully"
  Failed: string = "Failed";
  Processfailed: string = "Process failed";
  processAccNumberForm = new FormGroup({
    client: new FormControl(0, [Validators.required])
  });

  constructor(private dataService: DataService, private processAccountnumbersService: ProcessAccountnumbersService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.bindClients();
  }
  async bindClients() {
    this.clients = [];

    this.clients = <any[]>await this.dataService.getClients(AppConstants.gblAppId, AppConstants.gblLoggedInUserId);
    this.selectedClients = this.clients;


  }

  processAccountNumbers() {
    if (this.processAccNumberForm.valid) {

      if (this.processAccNumberForm.value.client !== undefined)
        this.mapAccounts(this.processAccNumberForm.value.client as number);
    }
  }
  processAllAccountNumbers() {
    this.mapAccounts(0);
  }

  private mapAccounts(clientId: number) {
    const filterPipe = new TranslationPipe();
    this.processAccountnumbersService.processAccountNumbers(clientId, AppConstants.gblLoggedInUserId)
      .subscribe((data) => {
        this.dialog.open(MsgDialogComponent, {
          data: {
            title: filterPipe.transform(this.Success),
            text: filterPipe.transform(this.Processcompletedsuccessfully),
          },
        });
      },
        (error) => {
          this.dialog.open(MsgDialogComponent, {
            data: {
              title: filterPipe.transform(this.Failed),
              text: filterPipe.transform(this.Processfailed),
            },
          });
        });
  }



  onKey(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.selectedClients = this.search(filterValue);
  }


  search(value: string) {
    if (value) {

      let filter = value.trim().toLowerCase();


      return this.clients.filter(option => option.clientName.toLowerCase().startsWith(filter));
    }
    return this.clients;
  }



}
