import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl,Validators ,FormGroup } from '@angular/forms';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {UserRolesComponent} from 'src/app/pages/menu/user-roles/user-roles.component';
import { AppConstants } from 'src/app/AppConstants';
import { IRole} from 'src/app/models/Role.model';
import { RoleService } from 'src/app/services/role.service';
import { TranslationPipe } from 'src/app/locale/translation.pipe';

@Component({
  selector: 'app-user-role-add-edit',
  templateUrl: './user-role-add-edit.component.html',
  styleUrls: ['./user-role-add-edit.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class UserRoleAddEditComponent implements OnInit {

  selectedRole: any;
  InsertSuccess: string = "Insert Success";
  UserRoleInsertedSuccessfully : string = "User Role Inserted Successfully";
  Duplicate : string = "Duplicate";
  DuplicateUserRole : string = "Duplicate User Role";
  UpdateSuccess : string = "Update Success";
  UserRoleUpdatedSuccessfully : string  = "User Role Updated Successfully";
  Failed : string = 'Failed';
  globalerror : string  = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
  editUserRoleForm = new FormGroup({   
    roleId: new FormControl(0),
    roleName:new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z-._ ]*$')])
  });

  constructor(private roleService:RoleService,public dialog: MatDialog,
    private router: Router,@Inject(MAT_DIALOG_DATA) public data:IRole,
    public dialogRef: MatDialogRef<UserRolesComponent>) {
    this.selectedRole = data;
    dialogRef.disableClose = true; 
 
    }

    ngOnInit(): void {
      if (this.selectedRole == undefined && this.selectedRole == null) {
        this.selectedRole = {
          roleId:0,
          roleName:''    
        };
      } 
    }

  async onSubmit() {
    const filterPipe = new TranslationPipe();
    if (this.editUserRoleForm.valid) {
      const userRoleObj: any = {
        roleId: this.editUserRoleForm.value.roleId,
        roleName: this.editUserRoleForm.value.roleName,             
       
        modifiedBy: AppConstants.gblLoggedInUserId,
        applicationID:AppConstants.gblAppId,
        userLevelID:AppConstants.gbluserLevelId
      };
      
    
       if (userRoleObj.roleId == 0)
      {
       
        await this.roleService
            .postRoleData(userRoleObj)
            .subscribe((data) => {
              
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.InsertSuccess),
                  text: filterPipe.transform(this.UserRoleInsertedSuccessfully),
                },
              });
      this.router.navigateByUrl('/menu/userroles');
  
            },
            (error) => {
              if(error.status==409){
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Duplicate),
                    text: filterPipe.transform(this.DuplicateUserRole),
                  },
                });
               }
               else{
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.globalerror),
                  },
                });
               }
            });
        
      }
      
   else if (userRoleObj.roleId > 0) {

        await this.roleService
          .putRoleData(userRoleObj.roleId,userRoleObj)
          .subscribe((data) => {
            
            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.UpdateSuccess),
                text: filterPipe.transform(this.UserRoleUpdatedSuccessfully),
              },
            });
    this.router.navigateByUrl('/menu/userroles');

          },
          (error) => {
            if(error.status==409){
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Duplicate),
                  text: filterPipe.transform(this.DuplicateUserRole),
                },
              });
             }
             else{
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.globalerror),
                },
              });
             }
          });
      }
      
    }
  }

}
