import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {AlertRule,IBusinessGroup,IAlertRuleBusinessGroup,IAlertRuleUser} from '../models/alert-rule.model';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/AppConstants';


@Injectable({
  providedIn: 'root'
})
export class AlertRuleService {

  constructor(private httpService: HttpService) { }

  getSeverityList()  {
     return this.httpService.get(AppConstants.apiUrl+"AlertDdl/GetSeverityList");     
  }

  getSignalTypes(){
     return this.httpService.get(AppConstants.apiUrl+"AlertDdl/GetSignalTypeList");     
  }

  getOperators(){
    return this.httpService.get(AppConstants.apiUrl+"AlertDdl/GetOperatorList"); 
  }

  getAlertRules(gblClientId:number){
    return this.httpService.get(AppConstants.apiUrl+"Alert/GetAlertRuleByClientId?ClientId="+gblClientId); 
  }

  getMappedBusinessGroupsByAlertId(alertRuleId:number)  {
    return this.httpService.get(AppConstants.apiUrl+"Alert/GetAlertRuleBusinessGroupByAlertId?AlertId="+alertRuleId);     
  }

  getMappedUsersByAlertId(alertRuleId:number)  {
  return this.httpService.get(AppConstants.apiUrl+"Alert/GetAlertRuleUserByAlertId?AlertId="+alertRuleId);     
  }

  getUnmappedBusinessGroupsByAlertId(clientId:number,alertRuleId:number){
    return this.httpService.get(AppConstants.apiUrl+"Alert/GetUnMappedBusinessGroupByAlert?ClientId="+clientId+"&AlertRuleId="+alertRuleId);      
  }

  getUnmappedUsersByAlertId(clientId:number,alertRuleId:number)  {
    return this.httpService.get(AppConstants.apiUrl+"Alert/GetUnMappedUserByAlert?ClientId="+clientId+"&AlertRuleId="+alertRuleId);     
  }
  
  
  deleteAlertRuleData(alertRuleId:number,modifiedBy:number){  
    return this.httpService.delete(AppConstants.apiUrl+"Alert/DeleteAlertRule?AlertRuleId="+alertRuleId+"&ModifiedBy="+modifiedBy)  ;    
  }

  deleteCompleteBusinessAlertMapping(groupId:number,alertRuleId:number){  
    return this.httpService.delete(AppConstants.apiUrl+"Alert/DeleteAlertRuleBusinessGroupByBusinessGroup?AlertRuleId="+alertRuleId+"&BusinessGroupId="+groupId)  ;    
  }

  deleteCompleteUserAlertMapping(userId:number,alertRuleId:number){  
    return this.httpService.delete(AppConstants.apiUrl+"Alert/DeleteAlertRuleUserByUser?AlertRuleId="+alertRuleId+"&Userid="+userId)  ;    
  }

  deleteAlertRuleBusinessGroupByNotification(alertRuleId:number,groupId:number,NotificationTypeId:number){
    return this.httpService.delete(AppConstants.apiUrl+"Alert/DeleteAlertRuleBusinessGroup?AlertRuleId="+alertRuleId+"&BusinessGroupId="+groupId+"&NotificationTypeId="+NotificationTypeId)  ;    
  }

  deleteAlertRuleUserByNotification(alertRuleId:number,userId:number,NotificationTypeId:number){
    return this.httpService.delete(AppConstants.apiUrl+"Alert/DeleteAlertRuleUser?AlertRuleId="+alertRuleId+"&Userid="+userId+"&NotificationTypeId="+NotificationTypeId)  ;       
  }
  postAlertRuleBusinessGroupByNotification(mapObj:IAlertRuleBusinessGroup): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"Alert/AddAlertRuleBusinessGroup", JSON.stringify(mapObj)) ;   
  }

  postAlertRuleUserByNotification(mapObj:IAlertRuleUser): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"Alert/AddAlertRuleUser", JSON.stringify(mapObj))  ; 
  }

  postAlertRuleData(ruleObj:AlertRule): Observable<any> {          
    return this.httpService.post(AppConstants.apiUrl+"Alert/AddAlertRule", JSON.stringify(ruleObj))
  }
  putAlertRuleData(alertRuleId:number,ruleObj:AlertRule): Observable<any>{  
     return this.httpService.put(AppConstants.apiUrl+"Alert/UpdateAlertRule?AlertRuleId="+alertRuleId, JSON.stringify(ruleObj))  ;    
  }
}
