import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clientSearch'
})
export class ClientSearchPipe implements PipeTransform {

 
  transform(value: any[], args?: any): any {
   
    if (!args) {
      return value;
    }
   else if(value)
   { 
      return value.filter((x): any => {
       
        if(x){
          let rVal =(x.clientName.toLocaleUpperCase().includes(args.toLocaleUpperCase())) ;
          return rVal;
        }
    })}

  }

  

}
