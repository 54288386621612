import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { MsgDialogComponent } from '../components/msg-dialog/msg-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private dialog: MatDialog) {}

  openMessageDialog(dialogTitle: string, dialogText: string) {
   return this.dialog.open(MsgDialogComponent, {
      data: {
        title: dialogTitle,
        text: dialogText,
      },
    });
  }

  async openConfirmDialog(dialogTitle: string, dialogText: string) {
    await this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: dialogTitle,
        text: dialogText,
      },
    });
  }
}
