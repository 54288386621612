<form [formGroup]="editReportForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-title class="edituser-dialog-header color-green">
      <mat-icon *ngIf="selectedReport.reportId==0">add</mat-icon> &nbsp;&nbsp; <label *ngIf="selectedReport.reportId==0">
        <span i18n="@@Add Report">Add Report</span> 
        </label>
      <mat-icon *ngIf="selectedReport.reportId!=0">edit</mat-icon> &nbsp;&nbsp;<label *ngIf="selectedReport.reportId!=0"> 
        <span i18n="@@Edit Report">Edit Report</span>   
      </label>
    </div>
    
    <mat-dialog-content class="mat-typography">
      <mat-divider></mat-divider>
    
      <mat-card>
        <input matInput  [hidden]="true"formControlName="reportId" [(ngModel)]="selectedReport.reportId"/>
        <mat-form-field>
          <mat-label i18n="@@Report Name">Report Name</mat-label>
          <input matInput  formControlName="reportName" maxlength="50" [(ngModel)]="selectedReport.reportName"/>
            <mat-error *ngIf="editReportForm.get('reportName')?.touched && editReportForm.get('reportName')?.errors?.required">
              <span i18n="@@Required">Required</span>
            </mat-error>
          </mat-form-field>
        <mat-form-field>
          <mat-label i18n="@@Report Description">Report Description</mat-label>
          <input matInput  formControlName="reportDescription" maxlength="50" [(ngModel)]="selectedReport.reportDescription"/>
            <mat-error *ngIf="editReportForm.get('reportDescription')?.touched && editReportForm.get('reportDescription')?.errors?.required">
              <span i18n="@@Required">Required</span>
            </mat-error>
        </mat-form-field>
      </mat-card>
      <br />
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close>
        <span i18n="@@Cancel">Cancel</span>
      </button>
      <button
        mat-flat-button
        color="primary"
        [mat-dialog-close]="true"
        cdkFocusInitial
        type="submit"
        [disabled]="!editReportForm.valid"
      >
      <span i18n="@@Submit">Submit</span>
      </button>
    </mat-dialog-actions>
    </form>