<mat-toolbar class="pair-new-device-toolbar">
  <mat-icon>warning_amber</mat-icon>
  &nbsp;&nbsp;
  <span>Alert Rules</span>
  <span class="flex-spacer"></span>
  <button mat-stroked-button routerLink="/menu/manage-alert-rules">
    <mat-icon>add_circle_outline</mat-icon>
    New Alert Rule
  </button>
</mat-toolbar>

<div class="table-container">
  <!--Data Table-->
  <mat-form-field class="filter-field">
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder=" Device ID, etc."
      #input
    />
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      multiTemplateDataRows
      class="devices-table"
    >
    <caption [hidden]="true"> Description </caption>
      <!-- ALERT RULE NAME Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          ALERT RULE NAME
        </th>
        <td mat-cell *matCellDef="let row">{{ row.alertRuleName }}</td>
      </ng-container>

      <!-- BUSINESS GROUPS Column -->
      <!-- <ng-container matColumnDef="businessGroups">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          BUSINESS GROUPS
        </th>
        <td mat-cell *matCellDef="let row">{{ row.businessGroupName }}</td>
      </ng-container> -->

      <!-- TYPE Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TYPE</th>
        <td mat-cell *matCellDef="let row">{{ row.signalTypeName }}</td>
      </ng-container>

      <!-- OPERATOR Column -->
      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>OPERATOR</th>
        <td mat-cell *matCellDef="let row">{{ row.operator }}</td>
      </ng-container>

      <!-- THRESHOLD Column -->
      <ng-container matColumnDef="threshold">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>THRESHOLD</th>
        <td mat-cell *matCellDef="let row">{{ row.threshold }}</td>
      </ng-container>

      <!-- FREQUENCY Column -->
      <ng-container matColumnDef="frequency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>FREQUENCY</th>
        <td mat-cell *matCellDef="let row">{{ row.alertFrequency }}</td>
      </ng-container>
      <!-- ACTIONS Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div class="text-right">
            <button
              mat-flat-button
              (click)="$event.stopPropagation(); redirectToManageAlertRule(row)"
              class="edit-btn"
            >
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button
              mat-flat-button
              color="warn"
              (click)="
                $event.stopPropagation(); deleteAlertRule(row.alertRuleId)
              "
            >
              <mat-icon>delete</mat-icon>
              Delete
            </button>
          </div>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="expand-container">
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-icon mat-card-avatar class="icon-big color-voilet"
                    >checklist</mat-icon
                  >
                  <mat-card-title class="color-voilet"
                    >Subscribers
                    <mat-icon
                      matTooltip="Send notifications when the alert rule triggers by selecting or creating a new action group"
                      class="info-tooltip"
                      >info_outline</mat-icon
                    >
                  </mat-card-title>
                  <mat-card-subtitle
                    >Subscription Configuration</mat-card-subtitle
                  >

                  <span class="flex-spacer"></span>
                  <div class="align-right">
                    <button
                      mat-icon-button
                      class="edit-btn-round"
                      (click)="onEditToggle()"
                    >
                      <mat-icon>
                        {{ isEdit ? "checkmark" : "edit" }}
                      </mat-icon>
                    </button>
                  </div>
                </mat-card-header>
                <mat-card-content>
                  <!--Business Groups-->
                  <div class="grid-warpper">
                    <div>
                      <mat-list class="mt-md">
                        <div mat-subheader>Business Groups</div>

                        <mat-form-field
                          *ngIf="isEdit"
                          class="example-full-width"
                          appearance="fill"
                          [@inOutAnimation]
                        >
                          <mat-label>Business Group Name</mat-label>
                          <input
                            matInput
                            aria-label="State"
                            [matAutocomplete]="auto"
                            [formControl]="groupsCtrl"
                          />
                          <button
                            matSuffix
                            mat-icon-button
                            class="color-green"
                            (click)="addBusinessGroup($event)"
                          >
                            <mat-icon>add_circle</mat-icon>
                          </button>
                          <mat-autocomplete #auto="matAutocomplete">
                            <mat-option
                              *ngFor="let group of filteredGroups | async"
                              [value]="group.businessGroupName"
                            >
                              <mat-icon mat-list-icon>note</mat-icon>
                              <span>{{ group.businessGroupName }}</span> |
                              <small class="color-gray">{{
                                group.userCount
                              }}</small>
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>

                        <mat-list-item
                          *ngFor="let group of businessGroups"
                          [@fadeInslideOutAnimation]
                        >
                          <mat-icon mat-list-icon>note</mat-icon>
                          <div mat-line>{{ group.businessGroupName }}</div>
                          <div mat-line class="color-gray">
                            {{ group.userCount }} {{group.userCount==1?'User':'Users'}}
                          </div>
                          <mat-button-toggle-group  class="btn-toggle-group" multiple >
                          <mat-button-toggle
                            *ngIf="isEdit"
                            value="email"
                            aria-label="Text align left"
                            class="toggle-btn-mail"
                            [@inOutBlurAnimation]
                            (change)="operateBusinessGroupMapping($event,this.selectedAlertRuleId,group.businessGroupId)"
                            [checked]="group.email==1"

                          >
                            <mat-icon>mail</mat-icon>
                          </mat-button-toggle>
                          <mat-button-toggle
                            *ngIf="isEdit"
                            value="sms"
                            aria-label="Text align center"
                            class="toggle-btn-sms"
                            [@inOutBlurAnimation]
                            (change)="operateBusinessGroupMapping($event,this.selectedAlertRuleId,group.businessGroupId)"
                            [checked]="group.sms==1"

                          >
                            <mat-icon>sms</mat-icon>
                          </mat-button-toggle>
                          </mat-button-toggle-group>
                          <button
                            *ngIf="isEdit"
                            mat-icon-button
                            color="warn"
                            [@inOutBlurAnimation]
                            (click)="removeBusinessGroup(group.businessGroupId,this.selectedAlertRuleId)"
                          >
                            <mat-icon>delete</mat-icon>
                          </button>
                        </mat-list-item>
                      </mat-list>
                    </div>
                    <div>
                      <mat-list class="mt-md">
                        <div mat-subheader>Users</div>

                        <mat-form-field
                          *ngIf="isEdit"
                          appearance="fill"
                          [@inOutAnimation]
                        >
                          <mat-label>User Name</mat-label>
                          <input
                            matInput
                            aria-label="State"
                            [matAutocomplete]="auto1"
                            [formControl]="usersCtrl"
                          />
                          <button
                            matSuffix
                            mat-icon-button
                            class="color-green"
                            (click)="addUser($event)"
                          >
                            <mat-icon>add_circle</mat-icon>
                          </button>
                          <mat-autocomplete #auto1="matAutocomplete">
                            <mat-option
                              *ngFor="let user of filteredUsers | async"
                              [value]="user.userName"
                            >
                              <mat-icon mat-list-icon class="icon-color"
                                >person</mat-icon
                              >
                              <span>{{ user.userName }}  </span>
                              <!-- <small>{{ user.updated }}</small> -->
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>

                        <mat-list-item    
                          *ngFor="let user of users"
                          [@fadeInslideOutAnimation]  
                        >
                        
                          <mat-icon mat-list-icon class="icon-color" 
                            >person</mat-icon
                          >
                          <div mat-line>{{ user.userName }}</div>
                          <div mat-line class="color-gray">
                            {{ user.email==1 && user.sms==1?'Email,SMS':user.email==1?'Email':user.sms==1?'SMS':'' }} 
                          </div>
                          <mat-button-toggle-group class="btn-toggle-group" multiple >
                          <mat-button-toggle
                            *ngIf="isEdit"
                            value="email"
                            aria-label="Text align left"
                            class="toggle-btn-mail"
                            [@inOutBlurAnimation]
                            (change)="operateUserMapping($event,this.selectedAlertRuleId,user.userId)"
                            [checked]="user.email==1"
                          >
                            <mat-icon>mail</mat-icon>
                          </mat-button-toggle>
                          <mat-button-toggle
                            *ngIf="isEdit"
                            value="sms"
                            aria-label="Text align center"
                            class="toggle-btn-sms"
                            [@inOutBlurAnimation]
                            (change)="operateUserMapping($event,this.selectedAlertRuleId,user.userId)"
                            [checked]="user.sms==1"
                          >
                            <mat-icon>sms</mat-icon>
                          </mat-button-toggle>
                    </mat-button-toggle-group>
                          <button
                            *ngIf="isEdit"
                            mat-icon-button
                            color="warn"
                            [@inOutBlurAnimation]
                            (click)="removeUser(user.userId,this.selectedAlertRuleId)"
                          >
                            <mat-icon>delete</mat-icon>
                          </button>
                        </mat-list-item>
                      </mat-list>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element;bindDetails(expandedElement,element.alertRuleId);"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data matching the filter "{{ input.value }}"
        </td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
