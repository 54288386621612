import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationDialogComponent } from '../components/notification-dialog/notification-dialog.component';
import { AppConstants } from '../AppConstants';
import { NotificationService } from './notification.service';


@Injectable({
  providedIn: 'root',
})
export class NotificationDialogService {
  private dialogRef: MatDialogRef<NotificationDialogComponent> | null = null;
  constructor(private dialog: MatDialog,private notificationService : NotificationService) { }

  openNotification(messages: string[], showCheckbox: boolean): void {
    this.dialogRef = this.dialog.open(NotificationDialogComponent, {
      width: '800px',
      data: { messages, showCheckbox }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === false) {
        this.notificationService.putNotificationbyUser(AppConstants.gblLoggedInUserId);
      }
      this.dialogRef = null;
    });
  }

  closeNotification(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}

