import { Component, Inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RoleService } from 'src/app/services/role.service';
import { ScreenService } from 'src/app/services/screen.service';
import { AppConstants } from 'src/app/AppConstants';
import { IRoleScreen, RoleScreen, RolesScreen } from 'src/app/models/Rolescreen.model';
import { RoleScreenService } from 'src/app/services/rolescreen.service';
import { MsgDialogComponent } from '../msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserrolesCvascreensComponent } from 'src/app/pages/menu/userroles-cvascreens/userroles-cvascreens.component';
import { Router } from '@angular/router';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
interface IRole {
  roleId: number;
  roleName: string;
}

interface IRolebySCreen {
  roleId: number;
}

interface IScreen {
  screenId: number;
  screenName: string;
}

@Component({
  selector: 'app-add-rolescreen',
  templateUrl: './add-rolescreen.component.html',
  styleUrls: ['./add-rolescreen.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AddRolescreenComponent implements OnInit {

  roles: IRole[] = [];
  screens: IScreen[] = [];
  selectedRoleScreen: any;
  selectedObjects: any[] = [];
  selectedObjectsfromArray: any[] = [];
  gblScreenId: number = 0;
  DuplicateRecords: string = "Duplicate Records";
  RoleScreenMappingfailedfor: string = "Role Screen Mapping failed for";
  Success: string = "Success";
  MappingdoneSuccessfully: string = "Mapping done Successfully";
  DuplicateRecordPresent: string = "Duplicate Record Present";
  DuplicateEntriesarenotallow: string = "Duplicate Entries are not allow";
  Failed: string = 'Failed';
  globalerror: string = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
  @ViewChild('select') select!: MatSelect;
  allSelected = false;
  roleScreenForm = new FormGroup({
    role: new FormControl(0, [Validators.required]),
    screen: new FormControl(0, [Validators.required])
  });

  constructor(private roleService: RoleService,
    private screenService: ScreenService,
    private roleScreenService: RoleScreenService,
    public dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<UserrolesCvascreensComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RoleScreen,
  ) {
    this.selectedRoleScreen = data;
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.bindRoles();
    this.bindScreens();
    // this.selectedObjectsFromArray = [];
    this.GetRolebyScreenIds();

  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
      this.select.close();
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }

  }

  openMessageDialog(dialogTitle: string, dialogText: string) {
    this.dialog.open(MsgDialogComponent, {
      data: {
        title: dialogTitle,
        text: dialogText,
      },
    });
  }


  async onSubmit() {
    const filterPipe = new TranslationPipe();
    if (this.roleScreenForm.valid) {
      const roleScreenObj: RolesScreen = {        
        RoleIds: [this.roleScreenForm.value.role].join(','),
        ScreenId: Number(this.roleScreenForm.value.screen),
        LoggedinUserId: AppConstants.gblLoggedInUserId
      };
      await this.roleScreenService
        .postRoleScreenData(roleScreenObj)
        .subscribe(
          (response) => {
            if (response != null && response != "") {
              this.openMessageDialog(filterPipe.transform(this.DuplicateRecords), filterPipe.transform(this.RoleScreenMappingfailedfor) + String(response).replace(/,(\s+)?$/, ''));
            }
            else {
              this.openMessageDialog(filterPipe.transform(this.Success), filterPipe.transform(this.MappingdoneSuccessfully));
            }
            this.router.navigateByUrl('/menu/userroles-cvascreens');

          },
          (error) => {

            if (error.status == 409) {
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.DuplicateRecordPresent),
                  text: filterPipe.transform(this.DuplicateEntriesarenotallow),
                },
              });
            }
            else {
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.globalerror),
                },
              });
            }
          });


    }
  }


  onScreenChange(event: MatSelectChange) {
    this.gblScreenId = (event.source.selected as MatOption).value;

    this.screenService
      .getScreenbyRoleId(this.gblScreenId)
      .then((data) => {

        this.selectedObjects = data as any[];

        this.selectedObjectsfromArray = this.selectedObjects.map(function (el) {
          return el.roleId;
        });
      })
      .catch((error) => {
        console.log('IU Promise rejected with ' + JSON.stringify(error));
      });
  }

  bindRoles() {

    this.roleService
      .getRoles(AppConstants.gblAppId)
      .then((data) => {

        this.roles = data as IRole[];
      })
      .catch((error) => {
        console.log('IU Promise rejected with ' + JSON.stringify(error));
      });

  }

  async GetRolebyScreenIds() {

    const RoleIds = <any[]>await this.screenService.getScreenbyRoleId(this.gblScreenId);

  }


  bindScreens() {
    this.screenService.getScreensByApplication(AppConstants.gblscreenGroup, AppConstants.gblModuleId, AppConstants.gblLoggedInUserId)
      .then((data) => {

        this.screens = data as IScreen[];
      })
      .catch((error) => {
        console.log('IU Promise rejected with ' + JSON.stringify(error));
      });
  }
}
