<mat-toolbar class="pair-new-device-toolbar">
  &nbsp;&nbsp;
  <span i18n="@@notifications">Notifications</span>
  <span class="flex-spacer"></span>
  <button mat-stroked-button (click)="openDeleteNotificationDialog()">
    <mat-icon>delete</mat-icon>
    <span i18n="@@deleteNotificationMapping">Delete Notification Mapping</span>
  </button>&nbsp;&nbsp;
  <button mat-stroked-button (click)="openMapNotificationDialog()">
    <mat-icon>person_add</mat-icon>
    <span i18n="@@mapNotification">Map Notification</span>
  </button>&nbsp;&nbsp;
  <button mat-stroked-button (click)="openAddNotificationDialog()">
    <mat-icon>person_add</mat-icon>
    <span i18n="@@addNotification">Add Notification</span>
  </button>&nbsp;&nbsp;
  <button mat-stroked-button (click)="purgeNotification()">
    <mat-icon>delete</mat-icon>
    <span i18n="@@purgeExpiredNotification">Purge Expired Notification</span>
  </button>&nbsp;&nbsp;
  <button mat-stroked-button (click)="RefreshNotificationList()">
    <span i18n="@@refreshNotificationList">Refresh Notification List</span>
  </button>
</mat-toolbar>

<div class="table-container">
  <!--Data Table-->
  <mat-form-field class="filter-field">
    <mat-label i18n="@@filter">Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="{{placeholder | translation}}" #input />
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <caption [hidden]="true"> Description </caption>

      <ng-container matColumnDef="notificationTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@notificationTitle">Notification Title</th>
        <td mat-cell *matCellDef="let row">{{ row.notificationTitle}}</td>
      </ng-container>


      <ng-container matColumnDef="notificationDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@notificationDescription">Notification Description
        </th>
        <td mat-cell *matCellDef="let row">{{ row.notificationDescription }}</td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdDate(UTC)">Created Date(UTC)</th>
        <td mat-cell *matCellDef="let row">{{ row.createdDate }}</td>
      </ng-container>


      <ng-container matColumnDef="expireDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@expireDate(UTC)">expireDate(UTC)</th>
        <td mat-cell *matCellDef="let row">{{ row.expireDate }}</td>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@isActive">is Active</th>
        <td mat-cell *matCellDef="let row">{{ row.isActive }}</td>
      </ng-container>
      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div class="text-right">
            <button class="edit-btn" mat-flat-button (click)="$event.stopPropagation();openEditNotificationDialog(row)">
              <mat-icon>edit</mat-icon> <span i18n="@@Edit">Edit</span>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          {{noData|translation}}{{ input.value }}
        </td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>