<h1 mat-dialog-title class="title">
  <mat-icon class="title-icon">notifications_active</mat-icon>
  <span i18n="@@notifications">Notifications</span></h1>
<mat-card class="message-card" *ngFor="let message of messages">
  <mat-card-header class="card-header">
    <mat-card-title class="message-title">{{ message.notificationTitle }}</mat-card-title>
    <span class="date">{{ formatDate(message.createdDate) }}</span>
  </mat-card-header>
  <mat-card-content>
    <div [innerHTML]="sanitizeHtml(message.notificationDescription)"></div>
  </mat-card-content>
</mat-card>
<mat-card-actions align="end">
  <mat-checkbox *ngIf="showCheckbox" [(ngModel)]="hideNotification"><span i18n="@@dontShowThisAgain">Don't show this again</span></mat-checkbox>
  &nbsp;&nbsp;
  <button mat-flat-button mat-dialog-close  (click)="close()"><span i18n="@@close">Close</span></button>
</mat-card-actions>
