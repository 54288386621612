import { AppEnv } from "./GlobalConstants";


export class AppConstants {

//Api Url   
public static apiUrl:string = AppEnv.apiUrl; 

//Selected global values like Client,module id etc
public static gblAppId : number=0;
public static gblClientId : number;
public static gblIATClientId : number;
public static gblIATClientName : string;
public static gblModuleId : number;
public static LoggedInStatus : boolean = false;
public static logouttimer : number = 1200000;
public static ForceLogoutTime : number = 30000;
public static LanguageText :  string = "";
public static gblLoggedInUserId:number=0;
public static gblLoggedInUserFName:string="";
public static gblLoggedInUserLName:string="";
public static gblLoggedInUserEmailid:string="";
public static gbluserLevelId:number=5;
public static gbloperatorId:number=3;
public static gblscreenGroup:number=1;
public static gblRoleName:string="";
public static glbScreenId:number=0;
public static glbRoleId:number=0;
public static gblClientName:string="";
public  static gblForgotPasswrdURI: string [] = [];
public static gblOid:string="";
  public static glbSelectedUserId: number = 0;
  public static isValidUser: boolean = false;
  public static LogError:string="";
  public static LogDetails:string="";
}
