<form [formGroup]="editNotificationForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-title class="edituser-dialog-header color-green">
      <mat-icon *ngIf="selectedNotification.notificationId==0">add</mat-icon> &nbsp;&nbsp; <label *ngIf="selectedNotification.notificationId==0">
        <span i18n="@@addNotification">Add Notification</span> 
        </label>
      <mat-icon *ngIf="selectedNotification.notificationId!=0">edit</mat-icon> &nbsp;&nbsp;<label *ngIf="selectedNotification.notificationId!=0"> 
        <span i18n="@@editNotification">Edit Notification</span>   
      </label>
    </div>
    
    <mat-dialog-content class="mat-typography">
      <mat-divider></mat-divider>
    
      <mat-card>
        <input matInput  [hidden]="true" formControlName="notificationId" [(ngModel)]="selectedNotification.notificationId"/>
        <mat-form-field>
          <mat-label i18n="@@notificationTitle">Notification Title</mat-label>
          <input matInput  formControlName="notificationTitle"  [(ngModel)]="selectedNotification.notificationTitle"/>
            <mat-error *ngIf="editNotificationForm.get('notificationTitle')?.touched && editNotificationForm.get('notificationTitle')?.errors?.required">
              <span i18n="@@Required">Required</span>
            </mat-error>
            <mat-error  *ngIf="editNotificationForm.get('notificationTitle')?.errors?.maxlength">
              <span  i18n="@@MaxLengthExceeded">Maximum length is 50 characters</span>
            </mat-error>
          </mat-form-field>
        <mat-form-field appearance="outline" >
          <mat-label i18n="@@notificationDescription">Notification Description</mat-label>
          <textarea formControlName="notificationDescription" matInput rows="10"  [(ngModel)]="selectedNotification.notificationDescription" placeholder="{{descriptionPlaceholder | translation}}"></textarea>
            <mat-error *ngIf="editNotificationForm.get('notificationDescription')?.touched && editNotificationForm.get('notificationDescription')?.errors?.required">
              <span i18n="@@Required">Required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput [matDatepicker]="picker" formControlName="selectedDate" [(ngModel)]="selectedNotification.expireDate" placeholder="{{placeholder | translation}}">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="editNotificationForm.get('selectedDate')?.errors?.required"><span i18n="@@Required">Required</span></mat-error>
          <mat-error *ngIf="editNotificationForm.get('selectedDate')?.errors?.forbiddenDate"><span i18n="@@cannotSelectPastDates">Cannot select past dates</span></mat-error>
          <mat-error *ngIf="editNotificationForm.get('selectedDate')?.errors?.forbiddenTodayDate"><span i18n="@@cannotSelectTodaysDate">Expire date should be greater than today</span></mat-error>
        </mat-form-field>
      </mat-card>
      <br />
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close>
        <span i18n="@@Cancel">Cancel</span>
      </button>
      <button
        mat-flat-button
        color="primary"
        [mat-dialog-close]="true"
        cdkFocusInitial
        type="submit"
        [disabled]="!editNotificationForm.valid"
      >
      <span i18n="@@Submit">Submit</span>
      </button>
    </mat-dialog-actions>
    </form>