<h1 mat-dialog-title class="title">{{ data.title }}</h1>
<div mat-dialog-content class="messageText">{{ data.text }}</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" (click)="onYesClick()">
    <span  i18n="@@Yes">Yes</span>
  </button>
  <button mat-button (click)="onNoClick()">
    <span  i18n="@@No">No</span>
    
  </button>
</div>
