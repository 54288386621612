import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AccBusinessGroup } from 'src/app/models/business-group';
import { BusinessGroupService } from 'src/app/services/businessgroup.service';
import { MsgDialogComponent } from '../msg-dialog/msg-dialog.component';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';

@Component({
  selector: 'app-add-bg-acc-map-component',
  templateUrl: './add-bg-acc-map-component.component.html',
  styleUrls: ['./add-bg-acc-map-component.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AddBgAccMapComponentComponent implements OnInit {

  businessGroupId:number;
  businessGroupName:string;
  flag:number;
  accDropdownSettings:IDropdownSettings={};
  SelectAccountNos :  string  = 'Select Account Nos';
  SelectAllItemsFromList : string = 'Select All Items From List';
  UnSelectAllItemsFromList : string = 'UnSelect All Items From List';
  Nodataavailable : string = 'No data available';
  Search : string = 'Search';
  unMappedAccountByBG:any[]=[];
  unMappedAccountByBG_Master:any[]=[];
  mappedAccountByBG:any[]=[];
  mappedAccountByBG_Master:any[]=[];
  AccIdPristine: boolean = true;
  selectedAcclist : string="";
  Mappingfailed : string = 'Mapping failed';
  BGAccountnumbersMappingfailed : string  = 'BG, Account numbers Mapping failed';
  Success : string  = 'Success';
  BGAccountnumbersMappingdoneSuccessfully : string = 'BG, Account numbers Mapping done Successfully';
  DuplicateRecordPresent : string  = 'Duplicate Record Present';
  DuplicateEntriesarenotallow : string  = 'Duplicate Entries are not allow';
  Failed : string = 'Failed';
  globalerror : string  = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
  BGAccountnumbersmappingdeletionFailed : string = 'BG, Account numbers mapping deletion Failed';
  BGAccountnumbersmappingdeletedSuccessfully : string  = 'BG, Account numbers mapping deleted Successfully';
  bgAccMapForm = new FormGroup({
    // businessgrp: new FormControl(0, [Validators.required]),
    bgName: new FormControl(""),
    accItems: new FormControl("", [Validators.required]),
  });

  constructor(private router: Router, public businessGroupService:BusinessGroupService,
    @Inject(MAT_DIALOG_DATA) public data:any,public dialog: MatDialog) { 
      this.businessGroupId = data.businessGroupId;
      this.businessGroupName = data.businessGroupName;
      this.flag=data.flag;

    }
    
  ngOnInit(): void {

    const filterPipe = new TranslationPipe();
    this.accDropdownSettings= {
      singleSelection: false,
      idField: 'accountNumberId',
      textField: 'accountNumber',
      allowSearchFilter: true,
      enableCheckAll: true,
      selectAllText: filterPipe.transform(this.SelectAllItemsFromList),
      unSelectAllText: filterPipe.transform(this.UnSelectAllItemsFromList),
      itemsShowLimit: 3,
      maxHeight: 100,
      searchPlaceholderText: filterPipe.transform(this.Search),
      noDataAvailablePlaceholderText : filterPipe.transform(this.Nodataavailable),
      noFilteredDataAvailablePlaceholderText: filterPipe.transform(this.Nodataavailable)
    };
    
    if(this.flag==1)
     this.bindUnMappedAccountNumber(this.businessGroupId);
     else if(this.flag==2)
     this.bindMappedAccountNumber(this.businessGroupId);

  }

  async bindUnMappedAccountNumber(businessGroupId:number){
    this.unMappedAccountByBG=this.unMappedAccountByBG_Master= <any[]>await this.businessGroupService.getUnmappedAccountByBG(businessGroupId);     
 }

 async bindMappedAccountNumber(businessGroupId:number){
  this.mappedAccountByBG=this.mappedAccountByBG_Master= <any[]>await this.businessGroupService.getMappedAccountByBG(businessGroupId);     
}
 onAccSelect(item: any) {
  this.AccIdPristine = false;
  this.selectedAcclist="";
   const acclist:any[]=[];
   Object.assign(acclist, this.bgAccMapForm.value.accItems);
   acclist.forEach(element  => {
    this.selectedAcclist=this.selectedAcclist+','+(element.accountNumberId.toString());
  }); 
  this.selectedAcclist=this.selectedAcclist.substring(1,this.selectedAcclist.length) ;
}

onSelectAllAcc(items: any): void {
  this.selectedAcclist="";
  items.forEach((sid: any) => {
    this.selectedAcclist=this.selectedAcclist+','+sid.accountNumberId;

  });
  this.selectedAcclist=this.selectedAcclist.substring(1,this.selectedAcclist.length) ;
}

async onSubmit() {
  const filterPipe = new TranslationPipe();
if (this.bgAccMapForm.valid) {
  const userBusinessGroupObj: AccBusinessGroup = {
   AccountNumberIds : this.selectedAcclist,
   BusinessGroupId : this.businessGroupId,
   LoggedinUserId : AppConstants.gblLoggedInUserId
    
  };
  if(this.flag==1){
  await this.businessGroupService.
  postBGAccData(userBusinessGroupObj)
.subscribe(
      (response) => {
        if(response!=null && response!=""){
          this.dialog.open(MsgDialogComponent, {
            data: {
              title: filterPipe.transform(this.Mappingfailed),
              text: filterPipe.transform(this.BGAccountnumbersMappingfailed),
            },
          });
        }
        else{
        this.dialog.open(MsgDialogComponent, {
          data: {
            title: filterPipe.transform(this.Success),
            text: filterPipe.transform(this.BGAccountnumbersMappingdoneSuccessfully),
          },
        });
       }
       

      },
      (error) => {

        if (error.status == 409) {
          this.dialog.open(MsgDialogComponent, {
            data: {
              title: filterPipe.transform(this.DuplicateRecordPresent),
              text: filterPipe.transform(this.DuplicateEntriesarenotallow),
            },
          });
        }
        else {
          this.dialog.open(MsgDialogComponent, {
            data: {
              title: filterPipe.transform(this.Failed),
              text: filterPipe.transform(this.globalerror),
            },
          });
        }
      });
    }

    else if(this.flag==2){
      await this.businessGroupService.
      deleteBGAccData(userBusinessGroupObj)
    .subscribe(
          (response) => {
            if(response!=null && response!=""){
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.BGAccountnumbersmappingdeletionFailed),
                },
              });
            }
            else{
            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.Success),
                text: filterPipe.transform(this.BGAccountnumbersmappingdeletedSuccessfully),
              },
            });
           }
           
    
          },
          (error) => {
     {
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.globalerror),
                },
              });
            }
          });
    }

}

}
}
