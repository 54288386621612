import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl,Validators ,FormGroup } from '@angular/forms';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {MatDialogModule } from '@angular/material/dialog';
import { ModuleService} from 'src/app/services/module.service';
import { ModuleListComponent } from 'src/app/pages/menu/module-list/module-list.component';
import { AppConstants } from 'src/app/AppConstants';
import { ReportService } from 'src/app/services/report.service';
import { TenantService } from 'src/app/services/tenant.service';
import { IReport } from 'src/app/models/Report.model';
import { TranslationPipe } from 'src/app/locale/translation.pipe';

interface ITenant{
  tenantId:number,
  tenantName:string,
  tenantLink:string,
  theme:string,
  webIntegrationId:string,
  createdBy:number,
  modifiedBy:number,
  applicationId:number
}
@Component({
  selector: 'app-module-edit',
  templateUrl: './module-edit.component.html',
  styleUrls: ['./module-edit.component.scss'],
  encapsulation:ViewEncapsulation.None
})

export class ModuleEditComponent implements OnInit {

  selectedModule: any;
  reports:any;
  SaveSuccess: string  = "Save Success";
  ModuleSavedSuccessfully : string  = "Module Saved Successfully";
  Duplicate :  string  = 'Duplicate';
  DuplicateModule :  string  = 'Duplicate Module';
  UpdateSuccess : string  = 'Update Success';
  ModuleUpdatedSuccessfully :  string  = 'Module Updated Successfully';
  Failed : string = 'Failed';
  globalerror : string  = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';

  tenants:any;

  editModuleForm = new FormGroup({
    moduleId: new FormControl(0),
    qlikApplicationId:new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z0-9-]*$')]),
    qlikSheetId:new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z0-9-]*$')]),
    moduleDescription:new FormControl('', [Validators.required]),
    report: new FormControl(0, [Validators.required]),
    tenant: new FormControl(0, [Validators.required]),
    isInternal : new FormControl(false)
  });

  constructor(private moduleService:ModuleService,public dialog: MatDialog,public reportService:ReportService,public tenantService:TenantService,
    private router: Router,@Inject(MAT_DIALOG_DATA) public data:any,
    public dialogRef: MatDialogRef<ModuleListComponent>) {
    this.selectedModule = data;
    dialogRef.disableClose = true;
    }

  ngOnInit() {
    this.bindReport();
    this.bindTenants();
    if (this.selectedModule == undefined && this.selectedModule == null) {
      this.selectedModule = {
        moduleId:0,
        moduleName:'1',
        pbReportId:'1',
        pbGroupId:'1',
        pbDatasetId:'1',
        qlikApplicationId:'',
        qlikSheetId:'',
        moduleDescription:'',
        isInternal:false
      };
    }
  }

  bindReport(){
    this.reportService.getAllReport(AppConstants.gblAppId, AppConstants.gblClientId).then((data)=>{
      this.reports=data as IReport[];
    })
  }

  bindTenants(){
    this.tenantService.getTenantsByClientId(AppConstants.gblClientId).then((data)=>{
      this.tenants=data as ITenant[];
    })
  }

  async onSubmit() {
    const filterPipe = new TranslationPipe();
    if (this.editModuleForm.valid) {
      const moduleObj: any = {
        moduleId: this.editModuleForm.value.moduleId,
        moduleName: "1",
        modifiedBy: AppConstants.gblLoggedInUserId,
        applicationId:AppConstants.gblAppId,
        moduleTypeId:1,
        pbReportId:"1",
        pbDatasetId:"1",
        pbGroupId:"1",
        qlikApplicationId:this.editModuleForm.value.qlikApplicationId,
        qlikSheetId:this.editModuleForm.value.qlikSheetId,
        reportId:this.editModuleForm.value.report,
        moduleDescription:this.editModuleForm.value.moduleDescription,
        clientId:AppConstants.gblClientId,
        tenantId:this.editModuleForm.value.tenant,
        isInternal:this.editModuleForm.value.isInternal
      };

   if (moduleObj.moduleId == 0){
    await this.moduleService
    .createModule( moduleObj)
    .subscribe((data) => {

      this.dialog.open(MsgDialogComponent, {
        data: {
          title: filterPipe.transform(this.SaveSuccess),
          text: filterPipe.transform(this.ModuleSavedSuccessfully),
        },
      });
this.router.navigateByUrl('/menu/module');

    },
    (error) => {
      if(error.status==409){
        this.dialog.open(MsgDialogComponent, {
          data: {
            title: filterPipe.transform(this.Duplicate),
            text: filterPipe.transform(this.DuplicateModule),
          },
        });
       }
       else{
        this.dialog.open(MsgDialogComponent, {
          data: {
            title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.globalerror),
          },
        });
       }
    });
   }

   else{
    moduleObj.isInternal = this.selectedModule.isInternal;
        await this.moduleService
          .putModule(moduleObj.moduleId,moduleObj)
          .subscribe((data) => {

            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.UpdateSuccess),
                text:filterPipe.transform(this.ModuleUpdatedSuccessfully),
              },
            });
    this.router.navigateByUrl('/menu/module');

          },
          (error) => {
            if(error.status==409){
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Duplicate),
                  text: filterPipe.transform(this.DuplicateModule),
                },
              });
             }
             else{
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.globalerror),
                },
              });
             }
          });
      }
    }
  }
}

