import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AppConstants } from 'src/app/AppConstants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private httpService: HttpService) { }
  getClientRegion(){
    return this.httpService.get(AppConstants.apiUrl+"Client/GetClientRegion");   
  }
  getCurrency(){
    return this.httpService.get(AppConstants.apiUrl+"Client/GetCurrency");   
  }
  getClients(applicationId:number) {
    return this.httpService.get(AppConstants.apiUrl+"Client/GetClientByApplication?ApplicationId="+applicationId);
  }
 postClientData(ClientObj:any): Observable<any>{
  
   return this.httpService.post(AppConstants.apiUrl+"Client/AddClient" ,JSON.stringify(ClientObj));
 }
 putClientData(ClientId:number,ClientObj:any): Observable<any>{
 
  return this.httpService.put(AppConstants.apiUrl+"Client/UpdateClient?ClientId="+ClientId, JSON.stringify(ClientObj)) ;   
}
deleteClient(ClientId:number,modifiedBy:number){
  return this.httpService.delete(AppConstants.apiUrl+"Client/DeleteClient?ClientId="+ClientId+"&ModifiedBy="+modifiedBy);
 
 }
 
}
