import { Component, OnInit, Inject, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormControl,Validators ,FormGroup } from '@angular/forms';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {ClientComponent} from 'src/app/pages/menu/client/client.component';
import { AppConstants } from 'src/app/AppConstants';
import { IClient, IClientRegion, ICurrencies} from 'src/app/models/Client.model';
import { ClientService } from 'src/app/services/client.service';
import { TranslationPipe } from 'src/app/locale/translation.pipe';

@Component({
  selector: 'app-client-add-edit',
  templateUrl: './client-add-edit.compononent.html',
  styleUrls: ['./client-add-edit.compononent.scss'] ,
  encapsulation:ViewEncapsulation.None

})
export class AddeditClientComponent implements OnInit {
     InsertSuccess: string = 'Insert Success';
     ClientInsertedSuccessfully :  string  = 'Client Inserted Successfully';
     Duplicate : string  = 'Duplicate';
     DuplicateClient : string  = 'Duplicate Client';
     InsertFailed: string  = 'Insert Failed';
     ClientInsertionFailed :  string  = 'Client Insertion Failed';
     UpdateSuccess :  string  = 'Update Success';
     ClientUpdatedSuccessfully :  string  = 'Client Updated Successfully';
     Failed : string = 'Failed';
    globalerror : string  = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
    client: IClient[] = [];
    clientregion : IClientRegion [] = [];
    currencies : ICurrencies [] = [];
    _currencies:ICurrencies[]=[];
    selectedClient: any;
    searchTxt : any;
   Search: string = "Search";

    editClientForm = new FormGroup({   
    clientId: new FormControl(0),
    clientName:new FormControl('', [Validators.required,Validators.pattern("^[A-Za-z 0-9_@./#&`'+-]*$")]),
    client_Alias:new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z][a-zA-Z0-9_ ]*$')]),
    orginalClientId:new FormControl('', [Validators.required,Validators.pattern('[- +()0-9]*$')]),
    kpi: new FormControl(0, [Validators.required]),
    region: new FormControl(0, [Validators.required]),
    currencyName: new FormControl(0, [Validators.required]),
    escalation: new FormControl(false),
    tomsOnly:new FormControl(false)
  });

  constructor(private clientService:ClientService,public dialog: MatDialog,
    private router: Router,@Inject(MAT_DIALOG_DATA) public data:IClient,
    public dialogRef: MatDialogRef<ClientComponent>) {
    this.selectedClient = data;
    dialogRef.disableClose = true; 
  
    }

@ViewChild('SearchCurrency') SearchInput! : ElementRef;

    ngOnInit(): void {
     this.bindClientRegion();
     this.bindCurrency();
      if (this.selectedClient == undefined && this.selectedClient == null) {
        this.selectedClient = {
          clientId:0,
          clientName:'',
          client_Alias:'' ,
          kpi: 0,
          escalation: false,
          name:''
        };
      } 
    }
    bindClient() {
      this.clientService
        .getClients(AppConstants.gblAppId)
        .then((data) => {
          this.client = data as IClient[];
        })
        .catch((error) => {
          console.log('IU Promise rejected with ' + JSON.stringify(error));
        });
    }

    bindClientRegion() {
      this.clientService
        .getClientRegion()
        .then((data) => {
          
          this.clientregion = data as IClientRegion[];
        })
        .catch((error) => {
          console.log('IU Promise rejected with ' + JSON.stringify(error));
        });
    }
   
    bindCurrency() {
      this.clientService.getCurrency()  
        .then((data) => {   
          this.currencies = data as ICurrencies[];
          this._currencies = data as ICurrencies[];      
        })
        .catch((error) => {
          console.log('IU Promise rejected with ' + JSON.stringify(error));
        });
  }

  applyFilterCurrency(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();    
    this.currencies = this._currencies.filter( x => {
      const symbol :  string  = x.symbol.toLowerCase();
      return symbol.indexOf(filterValue) > -1;
    })
  }
  
     async onSubmit() {
      const filterPipe = new TranslationPipe();
    if (this.editClientForm.valid) {
      const clientObj: any = {
        clientId: this.editClientForm.value.clientId,
        clientName: this.editClientForm.value.clientName,             
        Client_Alias:this.editClientForm.value.client_Alias,
        OrginalClientId:this.editClientForm.value.orginalClientId,
        modifiedBy: AppConstants.gblLoggedInUserId,
        ApplicationId:AppConstants.gblAppId,
        kpi: this.editClientForm.value.kpi,
        regionId : this.editClientForm.value.region,
        regionName : "",
        defaultCurrency : this.editClientForm.value.currencyName,
        name : "",
        escalation: this.editClientForm.value.escalation
        
      };
      
    
       if (clientObj.clientId == 0)
      {
        await this.clientService
            .postClientData(clientObj)
            .subscribe((data) => {
              
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.InsertSuccess),
                  text: filterPipe.transform(this.ClientInsertedSuccessfully),
                },
              });
      this.router.navigateByUrl('/menu/client');
  
            },
            (error) => {
              if(error.status==409){
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Duplicate),
                    text: filterPipe.transform(this.DuplicateClient),
                  },
                });
               }
               else{
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.InsertFailed),
                    text: filterPipe.transform(this.ClientInsertionFailed),
                  },
                });
               }
            });
        
      }
      
   else if (clientObj.clientId > 0) {

        await this.clientService
          .putClientData(clientObj.clientId,clientObj)
          .subscribe((data) => {
            
            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.UpdateSuccess),
                text: filterPipe.transform(this.ClientUpdatedSuccessfully),
              },
            });
    this.router.navigateByUrl('/menu/client');

          },
          (error) => {
            if(error.status==409){
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Duplicate),
                  text: filterPipe.transform(this.DuplicateClient),
                },
              });
             }
             else{
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.globalerror),    
                },
              });
             }
          });
      }
      
    }
  }

}
