import { Component, OnInit } from '@angular/core';
import { AppEnv } from 'src/app/GlobalConstants';



@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html'

})
export class CallbackComponent implements OnInit {
  spMenuAttributes = {
    'crossorigin': 'anonymous',
    'data-host': AppEnv.dataHost
  }

  constructor() { }

  ngOnInit(): void {

  }

  onReady() {

  }

  onError() {

  }
}
