import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl,Validators ,FormGroup } from '@angular/forms';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {TenantComponent} from 'src/app/pages/menu/tenant/tenant.component';
import { AppConstants } from 'src/app/AppConstants';
import { ITenant} from 'src/app/models/tenant.model';
import { TenantService } from 'src/app/services/tenant.service';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
const urlRegx = /^https:\/*(?:\w+(?::\w+)?@)?[^\s\/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-\/]*)?$/;

@Component({
  selector: 'app-tenant-add-edit',
  templateUrl: './tenant-add-edit.compononent.html',
  styleUrls: ['./tenant-add-edit.compononent.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AddEditTenantComponent implements OnInit {
    tenant: ITenant[] = [];
    InsertSuccess: string = 'Insert Success';
    TenantInsertedSuccessfully : string  = 'Tenant Inserted Successfully';
    Duplicate :  string = 'Duplicate';
    DuplicateTenant :  string  = 'Duplicate Tenant';
    InsertFailed :  string  = 'Insert Failed';
    TenantInsertionFailed :  string  ='Tenant Insertion Failed';
    UpdateSuccess : string  = 'Update Success';
    TenantUpdatedSuccessfully :  string  = 'Tenant Updated Successfully';
    Tenantupdatefailed : string  = 'Tenant update failed'
    selectedTenant: any;
    editTenantForm = new FormGroup({       
    tenantId: new FormControl(0),
    tenantName:new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z-._ ]*$')]),
    tenantLink:new FormControl('', [Validators.required, Validators.pattern(urlRegx)]),
    webIntegrationId:new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+.*[a-zA-Z0-9]+$')]),
    theme: new FormControl(0, [Validators.required,Validators.pattern('[a-zA-Z][a-zA-Z0-9_\-]*$')])
  });

  constructor(private tenantService:TenantService,public dialog: MatDialog,
    private router: Router,@Inject(MAT_DIALOG_DATA) public data:ITenant,
    public dialogRef: MatDialogRef<TenantComponent>) {
    this.selectedTenant = data;
    dialogRef.disableClose = true; 
  
    }

    ngOnInit(): void {
      if (this.selectedTenant == undefined && this.selectedTenant == null) {
        this.selectedTenant = {
          tenantId:0,
          tenantName:'',
          tenantLink:'' ,
          webIntegrationId: '',
          theme: ''
        };
      } 
    }
    bindTenant() {
      this.tenantService
        .getTenants(AppConstants.gblAppId)
        .then((data) => {
          this.tenant = data as ITenant[];
        })
        .catch((error) => {
          console.log('Unable to get tenants');
        });
    }


  async onSubmit() {
    const filterPipe = new TranslationPipe();
    if (this.editTenantForm.valid) {
      const tenantObj: any = {
        tenantId: this.editTenantForm.value.tenantId,
        tenantName: this.editTenantForm.value.tenantName,             
        tenantLink:this.editTenantForm.value.tenantLink,
        webIntegrationId:this.editTenantForm.value.webIntegrationId,
        createdBy : AppConstants.gblLoggedInUserId,
        modifiedBy: AppConstants.gblLoggedInUserId,
        ApplicationId:AppConstants.gblAppId,
        theme: this.editTenantForm.value.theme
      };
      
    
       if (tenantObj.tenantId == 0)
      {
        await this.tenantService
            .postTenantData(tenantObj)
            .subscribe((data) => {
              
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.InsertSuccess),
                  text: filterPipe.transform(this.TenantInsertedSuccessfully),
                },
              });
  
            },
            (error) => {
              if(error.status==409){
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Duplicate),
                    text: filterPipe.transform(this.DuplicateTenant),
                  },
                });
               }
               else{
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.InsertFailed),
                    text: filterPipe.transform(this.TenantInsertionFailed),
                  },
                });
               }
            });
        
      }
      
   else if (tenantObj.tenantId > 0) {

        await this.tenantService
          .putTenantData(tenantObj.tenantId,tenantObj)
          .subscribe((data) => {
            
            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.UpdateSuccess),
                text: filterPipe.transform(this.TenantUpdatedSuccessfully),
              },
            });

          },
          (error) => {
            if(error.status==409){
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Duplicate),
                  text: filterPipe.transform(this.DuplicateTenant),
                },
              });
             }
             else{
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: error.Status,
                  text: filterPipe.transform(this.Tenantupdatefailed),
                },
              });
             }
          });
      }
      
    }
  }

}
