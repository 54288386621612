import {CUSTOM_ELEMENTS_SCHEMA,  NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ScriptLoaderModule} from "ngx-script-loader";
import { QlikReportComponent } from './qlik-report.component';

@NgModule({
  declarations: [ QlikReportComponent],
  imports: [
    CommonModule,
    ScriptLoaderModule
  ],
  exports: [QlikReportComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class QlikWrapperModule { }
