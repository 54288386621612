import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AppConstants } from 'src/app/AppConstants';
import { MatSelectChange } from '@angular/material/select';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { FavoriteModule, FavoriteModuleList } from 'src/app/models/favorite-module';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { FavoriteModuleService } from 'src/app/services/favorite-module.service';
import { Router } from '@angular/router';
import { TranslationPipe } from 'src/app/locale/translation.pipe';

@Component({
  selector: 'app-favorite-module-edit',
  templateUrl: './favorite-module-edit.component.html',
  styleUrls: ['./favorite-module-edit.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class FavoriteModuleEditComponent implements OnInit {
  searchTxt: any;
  clients: any[] = [];
  modules: any[] = [];
  favModuleList: FavoriteModuleList[];
  selectedClient: number = AppConstants.gblClientId;
  SaveSuccess: string = 'Save Success';
  Failed: string = 'Failed';
  UpdateSuccess: string = 'Update Success';
  Failedtosavefavoritemodule: string = 'Failed to save favorite module';
  FavoriteModuleSavedSuccessfully: string = 'Favorite Module Saved Successfully';
  Confirmsave: string = 'Confirm save';
  DuplicateRecordPresent: string = 'Duplicate Record Present';
  DuplicateEntriesarenotallow: string = 'Duplicate Entries are not allow';

  FavoritemoduleisalreadysettotheclientDoyouwanttochange: string = 'Favorite module is already set to the client.Do you want to change?';
  editFavModuleForm = new FormGroup({
    client: new FormControl("", [Validators.required]),
    module: new FormControl("", [Validators.required]),
    searchText: new FormControl("")
  });

  constructor(private router: Router, private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: FavoriteModuleList[], public dialog: MatDialog,
    private favModuleService: FavoriteModuleService) {

    this.favModuleList = data;
  }

  ngOnInit(): void {
    this.bindClients();
    if (this.selectedClient > 0) {
      this.bindModules(this.selectedClient);
      this.editFavModuleForm.controls.client.disable();
    }
  }
  bindClients() {
    this.dataService.getClients(AppConstants.gblAppId, AppConstants.gblLoggedInUserId).then((data) => {
      this.clients = data as any[];
    });
  }

  async onClientChange(event: MatSelectChange) {
    this.bindModules(event.value);
    this.selectedClient = AppConstants.gblClientId;
  }

  async bindModules(clientId: number) {
    this.modules = [];
    const clients =
      await this.dataService.getModules(AppConstants.gblAppId, AppConstants.gblLoggedInUserId, clientId) as any[];
    for (const item of clients) {
      if (!item.moduleName.toLowerCase().includes('admin')) {
        this.modules.push({
          moduleId: item.moduleId,
          moduleName: item.moduleName,
        });
      }
    }
  }

  async onSubmit() {
    const filterPipe = new TranslationPipe();
    let selectedClientId=0;
    if (this.selectedClient > 0) {
      selectedClientId=this.selectedClient;
    }
    else{
      selectedClientId=Number(this.editFavModuleForm.value.client);
    }
    if (this.favModuleList.find(obj => obj.clientId == Number(selectedClientId)) == undefined) {
      if (this.editFavModuleForm.valid) {
        const moduleObj: FavoriteModule = {
          clientId: selectedClientId,
          moduleId: Number(this.editFavModuleForm.value.module),
          createdBy: AppConstants.gblLoggedInUserId,
          modifiedBy: AppConstants.gblLoggedInUserId
        };

        await this.favModuleService
          .createFavoriteModule(moduleObj)
          .subscribe((data) => {

            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.SaveSuccess),
                text: filterPipe.transform(this.FavoriteModuleSavedSuccessfully),
              },
            });
            this.router.navigateByUrl('/menu/favmodule');

          },
            (error) => {

              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.Failedtosavefavoritemodule),
                },
              });

            });

      }
    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: filterPipe.transform(this.Confirmsave),
          text: filterPipe.transform(this.FavoritemoduleisalreadysettotheclientDoyouwanttochange),
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          if (this.favModuleList.find(obj => obj.clientId == Number(selectedClientId) && obj.moduleId == Number(this.editFavModuleForm.value.module))) {
            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.DuplicateRecordPresent),
                text: filterPipe.transform(this.DuplicateEntriesarenotallow),
              },
            });
          }
          else {
            var moduleObj: FavoriteModule;
            if (this.editFavModuleForm.valid) {
              moduleObj = {
                clientId: selectedClientId,
                moduleId: Number(this.editFavModuleForm.value.module),
                modifiedBy: AppConstants.gblLoggedInUserId,
              };
        

              this.favModuleService
                .putFavoriteModule(0, moduleObj)
                .subscribe((data) => {

                  this.dialog.open(MsgDialogComponent, {
                    data: {
                      title: filterPipe.transform(this.UpdateSuccess),
                      text: filterPipe.transform(this.FavoriteModuleSavedSuccessfully),
                    },
                  });
                  this.router.navigateByUrl('/menu/favmodule');

                },
                  (error) => {

                    this.dialog.open(MsgDialogComponent, {
                      data: {
                        title: filterPipe.transform(this.Failed),
                        text: filterPipe.transform(this.Failedtosavefavoritemodule),
                      },
                    });

                  });

            }
          }
        }
      });

    }
  }
}
