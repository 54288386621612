<mat-toolbar class="pair-new-device-toolbar">
    <!-- <mat-icon>people</mat-icon> -->
    &nbsp;&nbsp;
    <span i18n="@@Clients">Clients</span>
    <span class="flex-spacer"></span>

  </mat-toolbar>

  <div class="table-container">
    <!--Data Table-->
    <mat-form-field class="filter-field">
      <mat-label i18n="@@filter">Filter</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="{{ClientNameAliasetc | translation}}"
        #input
      />
    </mat-form-field>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
        <caption [hidden]="true"> Description </caption>
        <!-- CVA Screen -->
        <ng-container matColumnDef="clientName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Client Name">Client Name</th>
          <td mat-cell *matCellDef="let row">{{ row.clientName }}</td>
        </ng-container>

        <!-- Tab -->
        <ng-container matColumnDef="client_Alias">
          <th mat-header-cell *matHeaderCellDef mat-sort-header  i18n="@@Client Alias">Client Alias</th>
          <td mat-cell *matCellDef="let row">{{ row.client_Alias }}</td>
        </ng-container>
        <ng-container matColumnDef="orginalClientId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Original Client Id">Original Client Id</th>
          <td mat-cell *matCellDef="let row">{{ row.orginalClientId }}</td>
        </ng-container>
        <ng-container matColumnDef="regionName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header  i18n="@@Region">Region</th>
          <td mat-cell *matCellDef="let row">{{ row.regionName }}</td>
        </ng-container>
        <ng-container matColumnDef="kpi">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@KPI">KPI</th>
          <td mat-cell *matCellDef="let row">{{ row.kpi }}</td>
        </ng-container>
        <ng-container matColumnDef="defaultCurrency">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Default Currency">Default Currency</th>
          <td mat-cell *matCellDef="let row">{{ row.defaultCurrency }}</td>
        </ng-container>

        <!-- Action Column -->
        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div class="text-right">
              <button class="edit-btn" mat-flat-button (click)="$event.stopPropagation();EditClientDialog(row)">
                <mat-icon>edit</mat-icon> <span i18n="@@Edit">Edit</span>
              </button>
              <button mat-flat-button color="warn" (click)="
            $event.stopPropagation(); deleteClient(row.clientId)">
                <mat-icon>delete</mat-icon><span i18n="@@Delete">Delete</span>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            {{noData|translation}} {{ input.value }}
          </td>
        </tr>
      </table>

      <mat-paginator id="paginator" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
   
  </div>
</div>