import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/AppConstants';
import { INotificationClients } from '../models/notification.model';
import { HttpClient } from '@angular/common/http';


  @Injectable({
    providedIn: 'root'
  })
  export class NotificationService {
  
    constructor(private httpService: HttpService,private http: HttpClient) { }
    

    getNotification() {
      return this.httpService.get(AppConstants.apiUrl+"Notification/GetNotification");
    }

    postNotificationData(notification:any): Observable<any>{
      return this.httpService.post(AppConstants.apiUrl+"Notification/AddNotification" ,JSON.stringify(notification));
    }

    putNotificationData(notification:any): Observable<any>{
      return this.httpService.put(AppConstants.apiUrl+"Notification/UpdateNotification", JSON.stringify(notification)) ;   
    }

    purgeNotification(modifiedBy:number) {
      return this.httpService.delete(AppConstants.apiUrl+"Notification/PurgeNotification?modifiedBy="+modifiedBy);
    }

  getMappedClients(notificationId:number){
    return this.httpService.get(AppConstants.apiUrl+"Notification/GetMappedClientByNotification?NotificationId="+notificationId);   
  }

  getUnmappedClients(notificationId:number){
    return this.httpService.get(AppConstants.apiUrl+"Notification/GetUnmappedClientByNotification?notificationId="+notificationId);      
  }

  postNotificationClients(notificationClient: INotificationClients): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"Notification/AddNotificationClientsMapping" ,JSON.stringify(notificationClient));
  }

  deleteNotificationClients(notificationClient: INotificationClients): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"Notification/DeleteNotificationClientsMapping" ,JSON.stringify(notificationClient));
  }

  getNotificationbyUser(userId:number){
    return this.http.get(AppConstants.apiUrl+"Notification/GetNotificationbyUser?UserId="+userId);      
  }

  getFlagNotificationbyClient(clientId:number){  
    return  this.http.get(AppConstants.apiUrl+"Notification/GetFlagNotificationbyClient?ClientId="+clientId+"&UserId="+AppConstants.gblLoggedInUserId);     
  }

  putNotificationbyUser(userId:number){
    return this.httpService.get(AppConstants.apiUrl+"Notification/UpdateNotificationbyUser?UserId="+userId);   
  }

}