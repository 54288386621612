import { environment } from "src/environments/environment";

export const AppEnv = {
  redirectUri: environment.redirectUri,
  apiUrl: environment.apiUrl,
  clientId: environment.clientId,
  policy_signup_signin: environment.policy_signup_signin,
  policy_profileedit: environment.policy_profileedit,
  policy_passwordreset: environment.policy_passwordreset,
  domain: environment.domain,
  audience: environment.audience,
  qlikClientId: environment.qlikClientId,
  dataHost: environment.dataHost,
  connection : environment.connection,
  authClientId: environment.authClientId,
  resetPassword: "https://upsb2c.b2clogin.com/upsb2c.onmicrosoft.com/" + environment.policy_passwordreset,
  editProfile: "https://upsb2c.b2clogin.com/upsb2c.onmicrosoft.com/" + environment.policy_profileedit,
  signUpSignIn: 'https://upsb2c.b2clogin.com/upsb2c.onmicrosoft.com/' + environment.policy_signup_signin,
  scope: 'https://upsB2C.onmicrosoft.com/' + environment.clientId + '/user_impersonation',
  logoutUri: 'https://login.microsoftonline.com/' + environment.qlikClientId + '/oauth2/v2.0/logout',
  changePasswordURL:  "/oauth2/v2.0/authorize?client_id="+ environment.clientId + "&redirect_uri=" + environment.redirectUri + "&response_type=id_token&scope=openid%20profile",
  changeProfileURL  : "/oauth2/v2.0/authorize?client_id="+ environment.clientId + "&redirect_uri=" + environment.redirectUri + "&response_type=id_token&scope=openid%20profile",
  b2cLogoutURL : "https://upsb2c.b2clogin.com/upsb2c.onmicrosoft.com/"+ environment.authLogout +"/oauth2/v2.0/logout?post_logout_redirect_uri=" + environment.redirectUri
};
