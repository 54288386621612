import { Injectable } from '@angular/core';
import { AppConstants } from '../AppConstants';
import { EscalatePackage } from '../models/escalate-package.model';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { MsgDialogComponent } from '../components/msg-dialog/msg-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { TranslationPipe } from '../locale/translation.pipe';

@Injectable({
  providedIn: 'root'
})
export class FreshDeskService {
  
  constructor(public dialog: MatDialog,private httpService: HttpService ) { }
  TrackInfoLength! : number;
  TrackingNumberobj! : any ;
  ConfirmEscalation : string ="Confirm Escalation";
  ConfirmEscalationtxt : string  = "This tracking number was submitted in the past. Do you want to resubmit for additional support?";
  EscalationSuccessful : string = "Escalation Successful";
  EscalationReSuccessfultxt : string = "Tracking Number Re Escalated Succcessfully";
  EscalationFailed : string  = "Escalation Failed";
  EscalationFailedtxt : string  = "We have encountered a problem and couldnt complete your escalation submission, if the problem persists please submit your escalation by email using the following address";
  EscalationSuccessfultxt : string  = "Tracking Number Escalated Sucessfully";
  Escalationnotallowed: string  = "Escalation not allowed";
  Escalationnotallowedtxt : string  = "Escalation feature is not enabled for selected Client! Request you to select valid Client.";


  getTrackInfo1(TrackNumber: string ){
    return this.httpService.get(AppConstants.apiUrl+"FreshDesk/GetTrackingInfo?TrackingNumber="+TrackNumber);   
  }

  getEscalationCheck(){
    return this.httpService.get(AppConstants.apiUrl+"FreshDesk/CheckEscalationAccessonSubmit?ClientId="+AppConstants.gblClientId);   
  }
  
  openMessageDialog(dialogTitle:string,dialogText:string){
    this.dialog.open(MsgDialogComponent, {
      data: {
        title: dialogTitle,
        text: dialogText,
      },
    });
  }

  async GetTrackInfo(objEscalatePackage: EscalatePackage){
   var flag =  await this.getEscalationCheck();
    var res=await this.getTrackInfo1(objEscalatePackage.Subject);
    this.TrackingNumberobj = res;
    const filterPipe = new TranslationPipe();

   if(flag) 
   {
    if(this.TrackingNumberobj.length > 0)
    {
      const dialogRef=this.dialog.open(ConfirmDialogComponent,{
        data: {
          title: filterPipe.transform(this.ConfirmEscalation),
          text: filterPipe.transform(this.ConfirmEscalationtxt),
        },
      });


      dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.postEscalatedData(objEscalatePackage).
          subscribe({
            next: data => {
              {
                this.openMessageDialog(filterPipe.transform(this.EscalationSuccessful),filterPipe.transform(this.EscalationReSuccessfultxt));
                
              }
            },
            error: err => {
              this.openMessageDialog(filterPipe.transform(this.EscalationFailed),filterPipe.transform(this.EscalationFailedtxt));
              console.log(err);
            }
          })
      }
    });
    
    }
    else{
      this.postEscalatedData(objEscalatePackage).
      subscribe({
        next: data =>{
          {
            this.openMessageDialog(filterPipe.transform(this.EscalationSuccessful),filterPipe.transform(this.EscalationSuccessfultxt));
        }
      },
      error: err => {
        this.openMessageDialog(filterPipe.transform(this.EscalationFailed),filterPipe.transform(this.EscalationFailedtxt));
        console.log(err);
       
      }

      })

    }

  }
  else{
    this.openMessageDialog(filterPipe.transform(this.Escalationnotallowed),filterPipe.transform(this.Escalationnotallowedtxt));
  }
}


  



 postEscalatedData(mapObj:any): Observable<any>{
   return this.httpService.post(AppConstants.apiUrl+"FreshDesk/TEscalatedPackages", JSON.stringify(mapObj)) ;
   
  }
  
}
