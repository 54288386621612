<form [formGroup]="editTenantForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-title class="edituser-dialog-header color-green">
    <mat-icon *ngIf="selectedTenant.tenantId==0">add</mat-icon> &nbsp;&nbsp; <label *ngIf="selectedTenant.tenantId==0"><span i18n="@@Add Tenant">Add Tenant</span></label>
    <mat-icon *ngIf="selectedTenant.tenantId!=0">edit</mat-icon> &nbsp;&nbsp;<label *ngIf="selectedTenant.tenantId!=0"><span i18n="@@Edit Tenant">Edit Tenant</span></label>
  </div>

  <mat-dialog-content class="mat-typography">
    <mat-divider></mat-divider>

    <mat-card>
      <input matInput  [hidden]="true" formControlName="tenantId" [(ngModel)]="selectedTenant.tenantId" />
      <mat-form-field>
        <mat-label i18n="@@Tenant Name">Tenant Name</mat-label>
        <input matInput formControlName="tenantName" maxlength="50" [(ngModel)]="selectedTenant.tenantName" />
        <mat-error
          *ngIf="editTenantForm.get('tenantName')?.touched && editTenantForm.get('tenantName')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
        <mat-error  *ngIf="editTenantForm.get('tenantName')?.errors?.pattern ">
          <span i18n="@@Please enter valid tenant name.">Please enter valid tenant name.</span>
       </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@Tenant Link">Tenant Link</mat-label>
        <input matInput formControlName="tenantLink" maxlength="254" [(ngModel)]="selectedTenant.tenantLink" />
        <mat-error
          *ngIf="editTenantForm.get('tenantLink')?.touched && editTenantForm.get('tenantLink')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
        <mat-error  *ngIf="editTenantForm.get('tenantLink')?.errors?.pattern ">
          <span i18n="@@Please enter valid tenant link.">Please enter valid tenant link.</span>
      </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@Web Integration Id">Web Integration Id</mat-label>
        <input matInput formControlName="webIntegrationId" maxlength="120" [(ngModel)]="selectedTenant.webIntegrationId" />
        <mat-error
          *ngIf="editTenantForm.get('webIntegrationId')?.touched && editTenantForm.get('webIntegrationId')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
        <mat-error  *ngIf="editTenantForm.get('webIntegrationId')?.errors?.pattern ">
          <span i18n="@@Please enter valid web integration id.">Please enter valid web integration id.</span>
       </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@Theme">Theme</mat-label>
        <input matInput formControlName="theme" maxlength="50" [(ngModel)]="selectedTenant.theme" />
        <mat-error *ngIf="editTenantForm.get('theme')?.touched && editTenantForm.get('theme')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
        <mat-error  *ngIf="editTenantForm.get('theme')?.errors?.pattern ">
          <span i18n="@@Please enter valid theme.">Please enter valid theme.</span>
       </mat-error>
      </mat-form-field>
      
    </mat-card>
    <br />
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close><span i18n="@@Cancel">Cancel</span></button>
     <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial type="submit"
      [disabled]="!editTenantForm.valid">
      <span i18n="@@Submit">Submit</span>
    </button> 
    <!-- <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial type="submit">
      Save
    </button> -->
  </mat-dialog-actions>
</form>
