<form [formGroup]="editUserRoleForm" (ngSubmit)="onSubmit()">
<div mat-dialog-title class="edituser-dialog-header color-green">
  <mat-icon *ngIf="selectedRole.roleId==0">add</mat-icon> &nbsp;&nbsp; <label *ngIf="selectedRole.roleId==0" i18n="@@Add Role">Add Role</label>
  <mat-icon *ngIf="selectedRole.roleId!=0">edit</mat-icon> &nbsp;&nbsp; <label *ngIf="selectedRole.roleId!=0" i18n="@@Edit Role">Edit Role</label>
</div>

<mat-dialog-content class="mat-typography">
  <mat-divider></mat-divider>

  <mat-card>
    <input matInput  [hidden]="true" formControlName="roleId" [(ngModel)]="selectedRole.roleId"/>
    <mat-form-field>
      <mat-label  i18n="@@Role Name">Role Name</mat-label>
      <input matInput  formControlName="roleName" maxlength="20" [(ngModel)]="selectedRole.roleName"/>
        <mat-error *ngIf="editUserRoleForm.get('roleName')?.touched && editUserRoleForm.get('roleName')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
    </mat-form-field>
  </mat-card>
  <br />
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close><span i18n="@@Cancel">Cancel</span></button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    type="submit"
    [disabled]="!editUserRoleForm.valid"
  >
  <span i18n="@@Submit">Submit</span>
  </button>
</mat-dialog-actions>
</form>