import { HttpClient, HttpHeaders } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { retry, catchError } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { LoggingService } from './logging.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient,
    private router: Router
  ) {
    localStorage.setItem("retryCount","0");
   }

 
  handleError(error: any): Observable<Response> { 
    if (localStorage.getItem("retryCount")=="1") {
      localStorage.setItem("retryCount","0");
      let loggingService = new LoggingService;
      loggingService.logException(error);
      if(error.status==401 || error.status == 403)
        {
         window.open(environment.access_deniedUrl, '_self');
        }
        else{
        this.router.navigate(['redirecting']);
        }
    }

    else
    {
      localStorage.setItem("retryCount","1");
    }

    return EMPTY;    

  }



  getInitialUserData(url: string) {
    return this.http.get(url).pipe(retry(1), catchError(this.handleError));
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {

      return null;
    }
  }

  get(url: string) {
    return this.http.get(url).toPromise();
  }

  delete(url: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' })
    };
    return this.http.delete(url, httpOptions).toPromise();
  }

  post(url: string, body: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' })
    };
    return this.http.post(url, body, httpOptions);
  }

  put(url: string, body: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' })
    };
    return this.http.put(url, body, httpOptions);
  }

  getModuleByModuleId(url: string) {
    return this.http.get(url).toPromise();
  }




}



