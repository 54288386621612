<form [formGroup]="editBGForm" (ngSubmit)="onSubmit()">

  <div mat-dialog-title class="editbg-dialog-header color-green"><mat-icon *ngIf="selectedBG.businessGroupId==0">add</mat-icon> &nbsp;&nbsp; <label *ngIf="selectedBG.businessGroupId==0"><span i18n="@@Business Group">Business Group </span><span> : {{selectedClientName}}</span></label>
    <mat-icon *ngIf="selectedBG.businessGroupId!=0">edit</mat-icon> &nbsp;&nbsp; <label *ngIf="selectedBG.businessGroupId!=0"> <span i18n="@@Business Group">Business Group</span></label>
  </div>
  
  <mat-dialog-content class="mat-typography">
    
  
    <mat-card>   
        <input matInput [hidden]="true" formControlName="businessGroupId" [(ngModel)]="selectedBG.businessGroupId"/>
           
      <mat-form-field>
        <mat-label i18n="@@Business Group">Business Group</mat-label>
        <input matInput maxlength="100" formControlName="businessGroupName" [(ngModel)]="selectedBG.businessGroupName"/>
        <mat-error *ngIf="editBGForm.get('businessGroupName')?.touched && editBGForm.get('businessGroupName')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
        
      </mat-form-field >
      <mat-checkbox *ngIf="selectedBG.businessGroupId==0 && internalFlag" formControlName="isInternal" [(ngModel)]="selectedBG.isInternal">
        <span i18n="@@isInternal">isInternal</span>
      </mat-checkbox><br><br>
    </mat-card>
  
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close><span i18n="@@Cancel">Cancel</span></button>
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
      type="submit"
      [disabled]="!editBGForm.valid"
    >
    <span i18n="@@Submit">Submit</span>
      
    </button>
  </mat-dialog-actions>
  </form>