import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/AppConstants';

interface ITenant{
    tenantId:number,
    tenantName:string,
    tenantLink:string,
    theme:string,
    webIntegrationId:string,
    createdBy:number,
    modifiedBy:number,
    applicationId:number
  }

  @Injectable({
    providedIn: 'root'
  })
  export class TenantService {
  
    constructor(private httpService: HttpService) { }
    
    getTenantsByClientId(clientId:number) {
      return this.httpService.get(AppConstants.apiUrl+"Tenant/GetTenantsByClientId?clientId="+clientId);
    }

    getTenants(applicationId:number) {
      return this.httpService.get(AppConstants.apiUrl+"Tenant/GetTenantList?applicationId="+applicationId);
    }

    deleteTenant(tenantId:number,modifiedBy:number) {
      return this.httpService.delete(AppConstants.apiUrl+"Tenant/DeleteTenant?tenantId="+tenantId+"&modifiedBy="+modifiedBy);
    }

    postTenantData(TenantObj:any): Observable<any>{
  
      return this.httpService.post(AppConstants.apiUrl+"Tenant/AddTenant" ,JSON.stringify(TenantObj));
    }
    putTenantData(TenantId:number,TenantObj:any): Observable<any>{
    
     return this.httpService.put(AppConstants.apiUrl+"Tenant/UpdateTenant?TenantId="+TenantId, JSON.stringify(TenantObj)) ;   
   }

   
  getMappedClients(tenantId:number){
    return this.httpService.get(AppConstants.apiUrl+"Tenant/GetClientsByTenant?TenantId="+tenantId);   
  }

  getUnmappedClients(tenantId:number){
    return this.httpService.get(AppConstants.apiUrl+"Tenant/GetUnMappedClientsByTenant?TenantId="+tenantId);      
  }

  deleteSingleTenantClientMapping(tenantId:number,clientId:number){
    return this.httpService.delete(AppConstants.apiUrl+"Tenant/DeleteTenantClientMapping?tenantId="+tenantId+"&clientId="+clientId+"&loggedinUserId="+AppConstants.gblLoggedInUserId);    
  }
  deleteAllTenantClients(selectedTenantId:number){
    return this.httpService.delete(AppConstants.apiUrl+"Tenant/DeleteAllTenantClientsMapping?TenantId="+selectedTenantId+"&loggedinUserId="+AppConstants.gblLoggedInUserId);    
  }
   

  postSingleTenantClientMapping(request:any): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"Tenant/AddTenantClientMapping", JSON.stringify(request));   
  }

  postAllTenantClientsMapping(request:any): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"Tenant/AddAllTenantClientsMapping" ,JSON.stringify(request)) ;   
  }

}