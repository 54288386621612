<mat-toolbar class="pair-new-device-toolbar">
    &nbsp;&nbsp;
    <span i18n="@@Invitation Status">Invitation Status</span>
    <span class="flex-spacer"></span>

  </mat-toolbar>

  <div class="table-container">

    <mat-form-field class="filter-field">
      <mat-label  i18n="@@filter">Filter</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="{{InvitationIdentifierNumberAliasetc | translation}}"
        #input
      />
    </mat-form-field>

    <div class="mat-elevation-z8">
      <table mat-table multiTemplateDataRows [dataSource]="dataSource" matSort>
        <caption [hidden]="true"> Description </caption>
        <!-- CVA Screen -->
        <ng-container matColumnDef="emailAddressText">
            <th mat-header-cell *matHeaderCellDef mat-sort-header  i18n="@@Email Address">Email Address</th>
            <td mat-cell *matCellDef="let row">{{ row.emailAddressText }}</td>
          </ng-container>
        <ng-container matColumnDef="invitationIdentifierNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Invitation Identifier">Invitation Identifier</th>
          <td mat-cell *matCellDef="let row">{{ row.invitationIdentifierNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="errorText">
          <th mat-header-cell *matHeaderCellDef mat-sort-header  i18n="@@Error Text">Error Text</th>
          <td mat-cell *matCellDef="let row">{{ row.errorText }}</td>
        </ng-container>

        <ng-container matColumnDef="emailSuccessfulSendDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Email Sent Date(UTC)">Email Sent Date(UTC)</th>
          <td mat-cell *matCellDef="let row">{{ row.emailSuccessfulSendDate }}</td>
        </ng-container>
        <ng-container matColumnDef="invitationAcceptedIndicator">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Is Accepted">Is Accepted</th>
            <td mat-cell *matCellDef="let row">{{ row.invitationAcceptedIndicator }}</td>
        </ng-container>
        <ng-container matColumnDef="deliverInvitationIndicator">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Is Delivered">Is Delivered</th>
            <td mat-cell *matCellDef="let row">{{ row.deliverInvitationIndicator }}</td>
        </ng-container>


        <!-- Action Column -->
         <!-- Action Column -->
         <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="row.invitationAcceptedIndicator==false" class="text-right">
            <button  class="edit-btn" mat-flat-button
            (click)="$event.stopPropagation();ReSendInvite(row.emailAddressText)">
              <mat-icon>send</mat-icon> Invite
            </button>
            </div>
          </td>
        </ng-container>

<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
<ng-container matColumnDef="expandedDetail">
  <td mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length">
    <div class="example-element-detail"
         [@detailExpand]="
      element == expandedElement ? 'expanded' : 'collapsed'
    ">
      <div class="expand-container">
        <mat-card class="example-card">
          <mat-card-content>
              <div>
                <div class="flex-space-between">
                  <span class="header-text color-voilet">Count ({{detailUsers_Master.length>0?detailUsers_Master.length :0}})</span>
                </div>

                    <table class="detail-table">
                      <caption [hidden]="true"> Description </caption>
                    <tr >
                      <th  i18n="@@Email Address">Email Address</th>
                      <th i18n="@@Invitation Identifier">Invitation Identifier</th>
                      <th i18n="@@Error Text">Error Text</th>
                      <th i18n="@@Email Sent Date(UTC)">Email Sent Date(UTC)</th>
                      <th i18n="@@Is Accepted">Is Accepted</th>
                      <th i18n="@@Is Delivered">Is Delivered</th>
                    </tr>
                    <tr *ngFor="let item of detailUsers">
                      <td>{{item.emailAddressText}}</td>
                      <td>{{item.invitationIdentifierNumber}}</td>
                      <td>{{item.errorText}}</td>
                      <td>{{item.emailSuccessfulSendDate}}</td>
                      <td>{{item.invitationAcceptedIndicator}}</td>
                      <td>{{item.deliverInvitationIndicator}}</td>
                    </tr>
                  </table>
              </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </td>
</ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element;bindUserDetails(expandedElement,element.emailAddressText);"></tr>
    <tr mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            {{noData|translation}}{{ input.value }}
          </td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>
