<form [formGroup]="editScreenForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-title class="edituser-dialog-header color-green">
    <mat-icon *ngIf="selectedScreen.screenId==0">add</mat-icon> &nbsp;&nbsp; <label *ngIf="selectedScreen.screenId==0"> 
      <span i18n="@@Add Screen">Add Screen</span> 
    </label>
    <mat-icon *ngIf="selectedScreen.screenId!=0">edit</mat-icon> &nbsp;&nbsp;<label *ngIf="selectedScreen.screenId!=0">
      <span i18n="@@Edit Screen">Edit Screen</span>  
      </label>
  </div>
  
  <mat-dialog-content class="mat-typography">
    <mat-divider></mat-divider>
  
    <mat-card>
      <input matInput [hidden]="true"formControlName="screenId" [(ngModel)]="selectedScreen.screenId"/>
      <mat-form-field>
        <mat-label i18n="@@Screen Name">Screen Name</mat-label>
        <input matInput  formControlName="screenName" maxlength="50" [(ngModel)]="selectedScreen.screenName"/>
          <mat-error *ngIf="editScreenForm.get('screenName')?.touched && editScreenForm.get('screenName')?.errors?.required">
            <span i18n="@@Required">Required</span>
          </mat-error>
        </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@Router Link">Router Link</mat-label>
        <input matInput  formControlName="routerLink" maxlength="50" [(ngModel)]="selectedScreen.routerLink"/>
          <mat-error *ngIf="editScreenForm.get('routerLink')?.touched && editScreenForm.get('routerLink')?.errors?.required">
            <span i18n="@@Required">Required</span>
          </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@Sequence">Sequence</mat-label>
        <input matInput  formControlName="sequenceId" pattern="[0-9]*"  [(ngModel)]="selectedScreen.sequenceId"/>
          <mat-error *ngIf="editScreenForm.get('sequenceId')?.touched && editScreenForm.get('sequenceId')?.errors?.required">
            <span i18n="@@Required">Required</span>
          </mat-error>
      </mat-form-field>
    </mat-card>
    <br />
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
      <span i18n="@@Cancel">Cancel</span>
    </button>
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
      type="submit"
      [disabled]="!editScreenForm.valid"
    >
    <span i18n="@@Submit">Submit</span>
    </button>
  </mat-dialog-actions>
  </form>
