import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AppConstants } from 'src/app/AppConstants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  constructor(private httpService: HttpService) { }

  getScreens(){
    return this.httpService.get(AppConstants.apiUrl+"Screen/GetAllScreen");     
 }

 getScreenbyRoleId(ScreenId: number){
  return this.httpService.get(AppConstants.apiUrl+"RoleScreen/GetRoleIdbyScreenId?ScreenId="+ScreenId+"&UserId="+AppConstants.gblLoggedInUserId);     
}
 getScreensByApplication(screenGroup:number,moduleId:number,UserId:number){
  return this.httpService.get(AppConstants.apiUrl+"Screen/GetScreensByApplication?ScreenGroupId="+screenGroup+"&ModuleId="+moduleId+"&UserId="+UserId);
}     

 postScreensData(screenObj:any): Observable<any>{
  
   return this.httpService.post(AppConstants.apiUrl+"Screen/AddScreen" ,JSON.stringify(screenObj));
 }
 putScreensData(screenId:number,screenObj:any): Observable<any>{
 
  return this.httpService.put(AppConstants.apiUrl+"Screen/UpdateScreen?ScreenId="+screenId, JSON.stringify(screenObj)) ;   
}
deleteScreens(screenId:number,modifiedBy:number){
  return this.httpService.delete(AppConstants.apiUrl+"Screen/DeleteScreen?ScreenId="+screenId+"&ModifiedBy="+modifiedBy);
 
 }
 
}
