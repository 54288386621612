<mat-toolbar class="pair-new-device-toolbar">
  &nbsp;&nbsp;
  <span i18n="@@Screens">Screens</span>
  <span class="flex-spacer"></span>
  <button mat-stroked-button (click)="$event.stopPropagation();addAddCVAscreenDialog()">
    
    <mat-icon>add</mat-icon><span i18n="@@Add New">Add New</span>
  </button>
</mat-toolbar>

<div class="table-container">
  <!--Data Table-->
  <mat-form-field class="filter-field">
    <mat-label i18n="@@filter">Filter</mat-label>
    <input 
      matInput
      (keyup)="applyFilter($event)"
      placeholder="{{ScreenNameRouterLinket | translation}}"
      #input
    />
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <caption [hidden]="true"> Description </caption>
      <!-- CVA Screen -->
      <ng-container matColumnDef="screenName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Screen Name">Screen Name</th>
        <td mat-cell *matCellDef="let row">{{ row.screenName | translation}}</td>
      </ng-container>

      <!-- Tab -->
      <ng-container matColumnDef="routerLink">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Router Link">Router Link</th>
        <td mat-cell *matCellDef="let row">{{ row.routerLink }}</td>
      </ng-container>

      <!-- Sequence -->
<ng-container matColumnDef="sequenceId">
  <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Sequence">Sequence</th>
  <td mat-cell *matCellDef="let row">{{ row.sequenceId }}</td>
</ng-container>
      <!-- Action Column -->
       <!-- Action Column -->
       <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div class="text-right">
          <button  class="edit-btn" mat-flat-button (click)="$event.stopPropagation();addAddEditCVAscreenDialog(row)">
            <mat-icon>edit</mat-icon> <span i18n="@@Edit">Edit</span> 
          </button>
          <button  mat-flat-button color="warn" (click)="
          $event.stopPropagation(); deleteScreens(row.screenId)">
            <mat-icon>delete</mat-icon><span i18n="@@Delete">Delete</span>
          </button>
        </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          {{noData|translation}}{{ input.value }}
        </td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
