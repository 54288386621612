import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddeditClientComponent } from 'src/app/components/client-add-edit/client-add-edit.compononent';
import { IClient } from 'src/app/models/Client.model';
import { ClientService } from 'src/app/services/client.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';


@Component({
  selector: 'app-cva-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  encapsulation:ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
    trigger('fadeInAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],

})
export class ClientComponent implements OnInit {
  displayedColumns: string[] = ['clientName','client_Alias','orginalClientId','kpi','regionName','defaultCurrency', 'action'];
  dataSource: MatTableDataSource<IClient>;
  ClientNameAliasetc :  string = "Client Name,Alias,etc.";
  noData :string = 'No data found';
  Itemsperpage : string = 'Items per page';
  Nextpage : string = 'Next page';
  Previouspage : string = 'Previous page';
  Confirmdelete : string = 'Confirm delete';
  Confirmtxt: string ='Are you sure to delete Client?';
  Deletefailed :string = 'Delete failed';
  Deletetxt : string  = 'Client Deletion Failed';
  of : string = 'of';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public dialog: MatDialog,
    public clientService: ClientService,
    private commonService: CommonService,
    public router: Router
  ) {
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource<IClient>();
  }

  ngOnInit() {
    this.bindClient();
  }

  //bind Alert Rule Data to table
  bindClient() {
    this.clientService.getClients(AppConstants.gblAppId).then((data) => {
      this.dataSource = new MatTableDataSource(data as IClient[]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const rangelabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${filterPipe.transform(this.of)} ${length}`;
      }
    
      length = Math.max(length, 0);
    
      const startIndex = page * pageSize;
    
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
    
      return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
    };

  
     this.dataSource.sort = this.sort;
     const filterPipe = new TranslationPipe();
     this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
    this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
    this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
    this.paginator._intl.getRangeLabel = rangelabel;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteClient(clientId: number) {
    const filterPipe = new TranslationPipe();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: filterPipe.transform(this.Confirmdelete),
        text: filterPipe.transform(this.Confirmtxt),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const modifiedBy: number = AppConstants.gblLoggedInUserId;
        this.clientService
        .deleteClient(clientId, modifiedBy)
          .then((data) => {
            this.bindClient();
          })
          .catch((error) => {
            this.commonService.openMessageDialog(
              filterPipe.transform(this.Deletefailed),
              filterPipe.transform(this.Deletetxt)
            );
          });
      }
    });
  }

  AddClientDialog() {
    const dialogRef = this.dialog.open(AddeditClientComponent, {
      width: '600px',
      data: null,
      panelClass:'custom'
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  EditClientDialog(row: IClient) {
    const dialogRef = this.dialog.open(AddeditClientComponent, {
      width: '600px',
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  
}

