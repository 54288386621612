
<mat-toolbar color="primary" > 
   
    <a href="{{redirect_url}}" class="redirecturl-class">
    <img alt="UPS_Flat_Shield_2Color_RGB" src="../assets/logos/UPS_Flat_Shield_2Color_RGB.svg" class="img-class" />
    </a>&nbsp;&nbsp;
    <span class="appTitle">{{ appTitle }}</span>
    <span class="example-spacer"></span>
  
     <mat-menu #appMenu="matMenu" yPosition="above" xPosition="before" id="App-Menu" class="menu-class ">  
     <div class="mat-grid-list">
  
    </div>
    </mat-menu>  
  </mat-toolbar>  
<br>
<h2>
  <p class="center">
  Unable to complete your request.Please try again after some time.If the issue persists,please contact PSI support!
</p>
</h2>
<mat-card>
    <mat-card-content><h3>{{ ErrorDetails }}</h3></mat-card-content>
</mat-card>

