<mat-toolbar color="primary" class="row ribbon" id="headerRibbon">
  <div color="primary" class="col-md-6 col-sm-12" id="logo-padding">
    <a href="{{redirect_url}}" class="anchor-tag">
      <img alt="UPS" src="../assets/logos/UPS_Flat_Shield_2Color_RGB.svg" class="ups-logo" />
    </a>
    <span class="appTitle text-sm-left">{{ appTitle }}</span>
    <span class="example-spacer"></span>

  </div>
  <div color="primary" id="icons" class="col-md-6 col-sm-12 text-right ribbonDiv"> 


    <img  alt="Ninedots" id="menuApplication" mat-icon-button [matMenuTriggerFor]="appMenu" [src]="NinedotsimageSource"
      (mouseover)="NinedotsimageSource='../assets/logos/applications-hover.svg'"
      (mouseout)="NinedotsimageSource='../assets/logos/applications.svg'" class="ninedots-applications"
      (menuOpened)="applicationExpanded()" (menuClosed)="applicationCollapsed()"
      [ngClass]="{'activeApplication': isApplicationExpanded == true}" />


    <mat-menu #appMenu="matMenu" yPosition="above" xPosition="before" id="App-Menu" class="menu-class">
      <div class="mat-grid-list">

        <img alt="IA" [src]="CVAimageSource" (click)="redirectUrl('cva')"
          (mouseover)="CVAimageSource='../assets/logos/app-cva-icon-brown.svg'"
          (mouseout)="CVAimageSource='../assets/logos/app-cva-icon-white.svg'"
          matTooltip="UPS&reg; Intelligent Analytics" matTooltipClass="mat-tooltip custom-tooltip mat-tooltip-show" 
          class="Fourth-IconStyle">
        <img alt="CVDM" [src]="CVDMimageSource" (click)="redirectUrl('cvdm')" class="Second-Third-Fifth-SixthIconStyle"
          (mouseover)="CVDMimageSource='../assets/logos/app-cvdm-icon-brown.svg'"
          (mouseout)="CVDMimageSource='../assets/logos/app-cvdm-icon-white.svg'"
          matTooltip="UPS&reg; Intelligent Data Management" matTooltipClass="custom-tooltip">
        <img alt="Pickup" [src]="PickupimageSource" (click)="redirectUrl('pickup')" class="Second-Third-Fifth-SixthIconStyle"
          (mouseover)="PickupimageSource='../assets/logos/app-pickup-manager-icon-brown.svg'"
          (mouseout)="PickupimageSource='../assets/logos/app-pickup-manager-icon-white.svg'"
          matTooltip="UPS&reg; Intelligent Pickup Manager" matTooltipClass="custom-tooltip">
        <br />
        <img alt="Returns" [src]="ReturnsimageSource" (click)="redirectUrl('returns')" class="Fourth-IconStyle"
          (mouseover)="ReturnsimageSource='../assets/logos/app-returns-icon-brown.svg'"
          (mouseout)="ReturnsimageSource='../assets/logos/app-returns-icon-white.svg'"
          matTooltip="UPS&reg; Intelligent Reverse Logistics" matTooltipClass="custom-tooltip">
        <img alt="Trailer" [src]="TrailerimageSource" (click)="redirectUrl('trailer')" class="Second-Third-Fifth-SixthIconStyle"
          (mouseover)="TrailerimageSource='../assets/logos/app-trailer-icon-brown.svg'"
          (mouseout)="TrailerimageSource='../assets/logos/app-trailer-icon-white.svg'"
          matTooltip="UPS&reg; Intelligent Trailer Logistics" matTooltipClass="custom-tooltip">
        <img alt="IDH" [src]="CVSimageSource" (click)="redirectUrl('cvs')" class="Second-Third-Fifth-SixthIconStyle"
          (mouseover)="CVSimageSource='../assets/logos/app-signature-icon-brown.svg'"
          (mouseout)="CVSimageSource='../assets/logos/app-signature-icon-white.svg'"
          matTooltip="UPS&reg; Intelligent Delivery History" matTooltipClass="custom-tooltip">
        <br />
        <img alt="Rating" [src]="RatingimageSource" (click)="redirectUrl('rating')" class="Seventh-IconStyle"
          matTooltip="UPS&reg; Intelligent Rating" matTooltipClass="custom-tooltip"
          (mouseover)="RatingimageSource='../assets/logos/icon-app-rating-hover.svg'"
          (mouseout)="RatingimageSource='../assets/logos/icon-app-rating-white.svg'">

      </div>


    </mat-menu>

    <!-- Language  -->
    <img id="menuLanguage" alt="Globe" mat-icon-button  matTooltip="{{'language'|translation}}" [matMenuTriggerFor]="localeMenu"
      [src]="LanguageimageSource" (mouseover)="LanguageimageSource='../assets/logos/globe-solid-hover.svg'"
      (mouseout)="LanguageimageSource='../assets/logos/globe-solid.svg'"
      ng-click="this.src='../assets/logos/globe-solid-on.svg'" (menuOpened)="globeExpanded()"
      (menuClosed)="globeCollapsed()" [ngClass]="{'activeGlobe': isGlobeExpanded == true}" class="header-icon" />

    <mat-menu #localeMenu="matMenu" yPosition="above" xPosition="before" class="menu" >
           <mat-option *ngFor="let item of language" [value]="item.languageCode"
        (onSelectionChange)="changeLocale(item.languageCode);" value="item">
        {{ item.languageName }}
      </mat-option>
    </mat-menu>


    <!-- Settings  -->
    <img id="menuSettings" mat-icon-button  [src]="SettingsimageSource"  matTooltip="{{'settings'|translation}}" 
      alt="Settings" (mouseover)="SettingsimageSource='../assets/logos/settings-hover.svg'"
      (mouseout)="SettingsimageSource='../assets/logos/settings.svg'"
      ng-click="this.src='../assets/logos/settings-on.svg'" (menuOpened)="settingExpanded()"
      (menuClosed)="settingCollapsed()" [ngClass]="{'activeSetting': isSettingExpanded == true}" class="header-icon" />

    <!-- Help  -->

    <img alt="Help" id="menuHelp" mat-icon-button matTooltip="{{'help'|translation}}" [matMenuTriggerFor]="helpMenu"
      [src]="HelpimageSource" (mouseover)="HelpimageSource='../assets/logos/help-hover.svg'"
      (mouseout)="HelpimageSource='../assets/logos/help.svg'" (menuOpened)="helpExpanded()"
      (menuClosed)="helpCollapsed()" [ngClass]="{'activeHelp': isHelpExpanded == true}" class="header-icon" />
    <mat-menu #helpMenu="matMenu" yPosition="above" xPosition="before" >
      <button *ngIf="hasAccess" mat-menu-item class="class-Outline" (click)="openEscalatePackageDialog()">
        <span i18n="@@escalatePackages">Escalate Packages</span>
      </button>
      <button mat-menu-item class="class-Outline" (click)="openFeedbackDialog()">
        <span i18n="@@submitFeedback">Submit Feedback</span>
      </button>
      <button mat-menu-item class="class-Outline" (click)="openUserGuide()">
        <span i18n="@@userGuide">User Guide</span>
      </button>
      <button mat-menu-item class="class-Outline" (click)="openReleaseNotes()">
        <span i18n="@@releaseNotes">Release Notes</span>
      </button>

    </mat-menu>
    <!-- Profile  -->
    <img id="menuProfile" alt="Profile" mat-icon-button [matMenuTriggerFor]="profileMenu" (click)="openProfileDialog()"
      [src]="ProfileimageSource" (mouseover)="ProfileimageSource='../assets/logos/profile-hover.svg'"
      (mouseout)="ProfileimageSource='../assets/logos/profile.svg'" class="Profile" (menuOpened)="profileExpanded()"
      (menuClosed)="profileCollapsed()" [ngClass]="{'activeProfile': isProfileExpanded == true}" />
    <!-- margin-right: 22px; -->
    <mat-menu #profileMenu="matMenu" yPosition="above" xPosition="before">
      <button mat-menu-item class="class-outlinepointer">
        <b><span> {{ FullName }}</span>
        <small> ({{ RoleName }})</small></b>
      </button>
      <button mat-menu-item class="class-Outline" (click)="sidenavProfile.toggle()">
        <span i18n="@@profile">Profile</span>
      </button>
      <button mat-menu-item (click)="logout()" class="menuBlueBack" class="class-Outline">
        <span i18n="@@logout">Logout</span>
      </button>

    </mat-menu>
  </div>
</mat-toolbar>

