<div class="margin-x">
        <br><br>
        <h1>Release Notes</h1>
        <ul>
                <li>
                        <div class="list-header-class">Release Updates(3.0.1) - August 29, 2024</div>
                        <ul class="list-class">
                                <br>
                                <li>
                                        <div class="list-header-class">Updates</div>
                                        <br>
                                        <ul>
                                                <li>Updated  <a href="../assets/User_Guide/HTML5/Content/1-Select%20Module/Modules.htm"
                                                                title="User Guide" target="_blank"
                                                                rel="noopener noreferrer" class="achorlink">User
                                                                Guide</a> with detailed information on the new modules, Address corrections and GPS Tracking.</li>
                                                <li>Introduced new filters on "Spend Details by Tracking Number" sheet in billing report.</li>
                                        </ul>
                                </li>

                        </ul>
                </li>
        </ul>
        &nbsp;
        &nbsp;
        &nbsp;
        <ul>
                <li>
                        <div class="list-header-class">Release Updates - May 30, 2024</div>
                        <ul class="list-class">
                                <br>
                                <li>
                                        <div class="list-header-class">New Features</div>
                                        <br>
                                        <ul>
                                                <li><b>*New*</b> Multi Language Support : Italian and French Canadian
                                                        have
                                                        been added to the list of supported languages:

                                                        <ul>
                                                                <li>English, French,French (Canadian), German, Italian,
                                                                        Polish, Portuguese, Spanish.
                                                                </li>
                                                        </ul>
                                                </li>
                                                <li>All reports are now available in these languages.
                                                </li>
                                        </ul>
                                </li>
                                &nbsp;
                                &nbsp;
                                <li>
                                        <div class="list-header-class">Updates</div>
                                        <br>
                                        <ul>
                                                <li>Updated <a href="../assets/User_Guide/HTML5/Content/Application/Introduction.htm"
                                                                title="User Guide" target="_blank"
                                                                rel="noopener noreferrer" class="achorlink">User
                                                                Guide</a>.</li>
                                                <li>Regular security audit and update completed.</li>
                                        </ul>
                                </li>

                        </ul>
                </li>
        </ul>
        &nbsp;
        &nbsp;
        &nbsp;
        <ul>
                <li>
                        <div class="list-header-class">Release Updates - March 12, 2024</div>
                        <ul class="list-class">
                                <br>
                                <li>
                                        <div class="list-header-class">New Features</div>
                                        <br>
                                        <ul>
                                                <li>Introducing Multi Language Support : In this release, along with
                                                        English, Spanish, French, German Polish languages, the below
                                                        reports will be available in Portuguese language as well.
                                                        <ul>
                                                                <li>Package Status By Date.</li>
                                                                <li>Billing.</li>
                                                                <li>Performance.</li>
                                                        </ul>
                                                </li>
                                                <li>Updated User guide with latest application improvements and security
                                                        guidelines.</li>
                                                <li>Other bug fixes.</li>
                                        </ul>
                                </li>
                                &nbsp;
                                &nbsp;
                                <p><b>Note:</b> User needs to provide consent for Application Authentication. The new as
                                        well as existing users will get this one-time consent pop-up. Refer <a
                                                href="../assets/User_Guide/HTML5/Content/Application/Log%20in.htm"
                                                title="User Guide" target="_blank" rel="noopener noreferrer"
                                                class="achorlink">User Guide</a> for more details.</p>
                        </ul>
                </li>
        </ul>
        &nbsp;
        &nbsp;
        &nbsp;
        <ul>
                <li>
                        <div class="list-header-class">Release Updates - January 11, 2024</div>
                        <ul class="list-class">
                                <br>
                                <li>
                                        <div class="list-header-class">New Features</div>
                                        <br>
                                        <ul>
                                                <li>Introducing Multi Language Support : In this release, along with
                                                        English and Spanish languages, the below reports will be
                                                        available in French, German and Polish language as well.
                                                        <ul>
                                                                <li>Package Status By Date.</li>
                                                                <li>Billing.</li>
                                                                <li>Performance.</li>
                                                        </ul>
                                                </li>
                                                <li>Introducing below reports in English & Spanish language.
                                                        <ul>
                                                                <li>Claims.</li>
                                                                <li>Smart-Stop.</li>
                                                        </ul>
                                                </li>
                                                <li>Added Refresh button on Manage Module screen to Refresh data changes
                                                        on the screen with entire page refresh.</li>
                                                <li>Added multiple currency selection option in Billing report.</li>
                                        </ul>
                                </li>
                        </ul>
                </li>
        </ul>
        &nbsp;
        &nbsp;
        &nbsp;

        <ul>
                <li>
                        <div class="list-header-class">Release Updates - November 29, 2023</div>
                        <ul class="list-class">
                                <br>
                                <li>
                                        <div class="list-header-class">New Features</div>
                                        <br>
                                        <ul>
                                                <li>Introducing Multi Language Support : In this release, support for
                                                        English and Spanish languages is available for below three
                                                        modules only.
                                                        <ul>
                                                                <li>Package Status By Date.</li>
                                                                <li>Billing.</li>
                                                                <li>Performance.</li>
                                                        </ul>
                                                </li>
                                                <li>Introducing Release Notes Feature. (IA << Help << Release
                                                                Notes)</li>
                                                <li>User Guide is updated with newer module details.</li>
                                        </ul>
                                </li>
                        </ul>
                </li>
        </ul>

        <br>
</div>