<form [formGroup]="roleScreenForm" (ngSubmit)="onSubmit()">
<div mat-dialog-title class="edituser-dialog-header color-green">
  <mat-icon >add</mat-icon> &nbsp;&nbsp;<label>
    <span i18n="@@Add Rolescreen Mapping">Add Rolescreen Mapping</span> 
  </label>
  
</div>

<mat-dialog-content class="mat-typography">
  <mat-divider></mat-divider>

  <mat-card>
    <mat-form-field>
      <mat-label i18n="@@Screen Name">Screen Name</mat-label>
      <mat-select 
      formControlName="screen"  
      [(ngModel)]="selectedRoleScreen.ScreenId" 
      (selectionChange)="onScreenChange($event)">
        <mat-option *ngFor="let screen of screens" [value]="screen.screenId">
          {{ screen.screenName | translation }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf=" roleScreenForm.get('screen')?.touched && roleScreenForm.get('screen')?.errors?.required">
        <span i18n="@@Required">Required</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label  i18n="@@Role Name">Role Name</mat-label>
      <mat-select 
      formControlName="role" #select multiple [(ngModel)]="selectedObjectsfromArray"  multiple>
        <div class="mat-option" >
          <mat-checkbox i18n="@@Select All"  [(ngModel)]="allSelected" class="selectAll"
                          [ngModelOptions]="{standalone: true}"
                          (change)="toggleAllSelection()"> <mat-option id="roles">Select All</mat-option></mat-checkbox>
      </div>
        <mat-option id="roles" *ngFor="let role of roles" [value]="role.roleId" >
          {{ role.roleName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf=" roleScreenForm.get('role')?.touched && roleScreenForm.get('role')?.errors?.required">
        <span i18n="@@Required">Required</span>
        </mat-error>
    </mat-form-field>
  </mat-card>
  <br />
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close><span i18n="@@Cancel">Cancel</span>

  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    type="submit"
    [disabled]="!roleScreenForm.valid"
  >
  <span i18n="@@Submit">Submit</span>
  </button>
</mat-dialog-actions>
</form>