<mat-toolbar class="pair-new-device-toolbar">
  <mat-icon>add_to_queue</mat-icon>
  &nbsp;&nbsp;
  <span>Pair a New Device with a Shipment</span>
  <span class="flex-spacer"></span>
</mat-toolbar>

<div class="card-container">
  <form
    (keydown.enter)="onKeyEnter()"
    [formGroup]="pairNewDeviceForm"
    (ngSubmit)="onSubmit()"
  >
    <mat-card class="pair-new-device-card">
      <mat-card-header>
        <mat-icon mat-card-avatar class="icon-big local-accent-color"
          >add_to_queue</mat-icon
        >
        <mat-card-title class="local-accent-color"
          >Pair a New Device with a Shipment</mat-card-title
        >
        <mat-card-subtitle>Scan or Enter Tracking Information</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <!-- <mat-form-field>
        <mat-label>Service Level</mat-label>
        <mat-select>
          <mat-option value="1"> Parcel </mat-option>
          <mat-option value="2"> LTL </mat-option>
        </mat-select>
      </mat-form-field> -->
        <br />
        <mat-form-field>
          <mat-label>Scout Device ID</mat-label>
          <input matInput formControlName="scoutId" />
          <mat-error
            *ngIf="
              pairNewDeviceForm.get('scoutId')?.errors?.required
            "
            >Required.
          </mat-error>
          <mat-error
            *ngIf="
              pairNewDeviceForm.get('scoutId')?.errors?.pattern
            "
            >Invalid. Should be a number between 6-8 digits. You entered {{ pairNewDeviceForm.get('scoutId')?.value?.length }} digits.
          </mat-error>
        </mat-form-field>
        <br /><br />
        <mat-form-field>
          <mat-label>Pro Number</mat-label>
          <input matInput formControlName="proNo" />
          <mat-error
            *ngIf="
              pairNewDeviceForm.get('proNo')?.touched &&
              pairNewDeviceForm.get('proNo')?.errors?.required
            "
            >Required.
          </mat-error>
          <mat-error
            *ngIf="
              pairNewDeviceForm.get('proNo')?.touched &&
              pairNewDeviceForm.get('proNo')?.errors?.pattern
            "
            >Invalid. Should be a number between 10-11 digits. You entered {{ pairNewDeviceForm.get('proNo')?.value?.length }} digits.
          </mat-error>
        </mat-form-field>
        <br /><br />
        <mat-form-field>
          <mat-label>SSCC Number</mat-label>
          <input matInput formControlName="ssccNo" />
          <mat-error
            *ngIf="
              pairNewDeviceForm.get('ssccNo')?.touched &&
              pairNewDeviceForm.get('ssccNo')?.errors?.required
            "
            >Required.
          </mat-error>
          <mat-error
            *ngIf="
              pairNewDeviceForm.get('ssccNo')?.touched &&
              pairNewDeviceForm.get('ssccNo')?.errors?.pattern
            "
            >Invalid. Should be a number of 20 digits. You entered {{ pairNewDeviceForm.get('ssccNo')?.value?.length }} digits.
          </mat-error>
        </mat-form-field>
        <br />
        <!-- <mat-form-field>
        <mat-label>Estimated Departure</mat-label>
        <input matInput type="datetime-local" />
      </mat-form-field> -->
      </mat-card-content>
      <mat-card-actions>
        <!-- <button mat-button mat-dialog-close>Cancel</button>-->
        <button
          mat-flat-button
          type="submit"
          class="local-accent-color-bg"
          [disabled]="!pairNewDeviceForm.valid"
        >
          Pair Device
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
