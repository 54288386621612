import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { InviteNewUserComponent } from 'src/app/components/invite-new-user/invite-new-user.component';
import { User, IUser, IUserBusinessGroup, IUserBusinessGroupMaster, IBusinessGroup } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { AppConstants } from 'src/app/AppConstants';
import { AddUserBGMapComponent } from 'src/app/components/add-userbgmap/add-userbgmap.component';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
import { DataService } from '../../../services/data.service';
import { MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
/**
 * @title Table with expandable rows
 */
@Component({
  selector: 'app-user-list',
  styleUrls: ['./user-list.component.scss'],
  templateUrl: './user-list.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
    trigger('fadeInAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class UserListComponent implements OnInit {
  searchTxt: any;
  Search: string = "Search";
  SelectClient: string = "Select Client";
  Nodataavailable : string = 'No data available';
  selectedClientValue: number | null = null;
  selectedClientName: any;
  custs: any[] = [];
  filterName: string = "";
  selectedUserId: number = 0;
  isEdit: boolean = false;
  showInternalUser = true;
  dataSource: any;
  mappedBGByUser: IUserBusinessGroupMaster[] = [];
  placeholder: string = 'Ex. Name, Email, etc.';
  AllBusinessGroups: string = 'All Business Groups';
  Itemsperpage: string = 'Items per page';
  Nextpage: string = 'Next page';
  Previouspage: string = 'Previous page';
  MappedBusinessGroups: string = 'Mapped Business Groups';
  noData: string = 'No data found';
  BusinessGroups: string = 'Business Groups';
  Confirmdelete: string = 'Confirm delete';
  Areyousuretodeleteuser: string = 'Are you sure to delete user?';
  Deletefailed: string = 'Delete failed';
  UserDeletionFailed: string = 'User Deletion Failed';
  confirmDeleteAllBusinessGroup: string = 'Are you sure to delete all Business group mapping?';
  confirmDeleteBusinessGroup: string = 'Are you sure to delete Business group mapping?';
  Savefailed: string = 'Save failed';
  mappingdeletionfailed: string = 'Mapping Deletion Failed'
  MappingFailed: string = 'Mapping Failed';
  Attention: string = 'Attention!';
  InternalMsg: string = 'You are about to delete an Internal User please ensure to raise role removal request in IIQ, OR Please connect with the Application Owner for removal of the role from IIQ.';
  of: string = 'of';
  mapedbg: IBusinessGroup[] = [];
  mappedBGByUser_Master: IUserBusinessGroupMaster[] = [];
  bgString: string = '';
  allBGString: string = '';
  unMappedBGByUser: IUserBusinessGroupMaster[] = [];
  unMappedBGByUser_Master: IUserBusinessGroupMaster[] = [];
  mappedBGCount: number = 0;
  unMappedBGCount: number = 0;
  AddAllRemoveAllFlag: boolean = true;
  AddSelectedFlag: boolean = true;


  columnsToDisplay = [
    'fullName',
    'email',
    'roleName',
    'clientCount',
    'businessGroupCount',
    'actions',
  ];
  expandedElement: User | null | undefined;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public dialog: MatDialog, private userService: UserService, private commonService: CommonService, private dataService: DataService) {
    this.dataSource = new MatTableDataSource<User>();
  }
  ngOnInit() {
    this.getClientDetails();



    if (AppConstants.glbRoleId == 7 || AppConstants.glbRoleId == 8 || AppConstants.glbRoleId == 9) {
      this.showInternalUser = false;
      this.AddAllRemoveAllFlag = false;
      this.AddSelectedFlag = false;
    }
    else {
      this.AddAllRemoveAllFlag = true;
      this.AddSelectedFlag = true;
    }
    this.bindUserList(AppConstants.gblClientId);

  }

  async onClientChange(event: MatSelectChange) {

    let id = (event.source.selected as MatOption).value;
    this.selectedClientValue = id;
    this.selectedClientName = (event.source.selected as MatOption).viewValue;
    this.filterName = '';
    this.bindUserList(this.selectedClientValue);

  }


  async getClientDetails() {
    const clients = <any[]>await this.dataService.getClients(AppConstants.gblAppId, AppConstants.gblLoggedInUserId);

    if (clients != null) {

      if (clients && clients.length > 0) {
        for (const item of clients) {
          this.custs.push({
            clientId: item.clientId,
            clientName: item.clientName,
          });
        }
        
        this.selectedClientValue = AppConstants.gblClientId;        
        var temp = this.custs.find(item =>item.clientId===AppConstants.gblClientId);         
       this.selectedClientName = temp.clientName;
      }
    }
  }

  //bind mapped and unmapped BG lists after expanding user 
  bindDetails(expandedElement: any, userId: number, roleName: string) {
    if (roleName.includes("UPS")) {
      this.AddSelectedFlag = true;
    }
   else if (roleName.includes("Client") || roleName.includes("Company")) {     
      this.AddSelectedFlag = false;
    }
    else
    {
      this.AddSelectedFlag = true;
    }
    this.bgString = '';
    this.allBGString = '';
    if (expandedElement) {
      this.bindMappedBusinessGroup(userId);
      this.bindUnMappedBusinessGroup(userId);
      this.selectedUserId = userId;
    }
    else {
      this.selectedUserId = 0;

    }
    if (this.isEdit) {
      this.isEdit = !this.isEdit;
    }
  }
  //bind mapped BG list
  async bindMappedBusinessGroup(userId: number) {
    this.mappedBGCount = 0;
    this.mappedBGByUser = this.mappedBGByUser_Master = <IUserBusinessGroupMaster[]>await this.userService.getMappedBusinessGroup(userId);
    this.mappedBGByUser.forEach(element => {
      this.mappedBGCount += element.businessGroups.length;
    });
  }

  //bind unmapped BG list
  async bindUnMappedBusinessGroup(userId: number) {
    this.unMappedBGCount = 0;
    this.unMappedBGByUser = this.unMappedBGByUser_Master = <IUserBusinessGroupMaster[]>await this.userService.getUnmappedMappedBusinessGroup(userId);
    this.unMappedBGByUser.forEach(element => {
      this.unMappedBGCount += element.businessGroups.length;
    });
  }

  //bind user list
  async bindUserList(clientId: any) {
    let result = await this.userService.getUserDataByClient(clientId, AppConstants.gblLoggedInUserId);
    this.dataSource = new MatTableDataSource(result as User[]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    const rangelabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${filterPipe.transform(this.of)} ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
    };


    this.dataSource.sort = this.sort;
    const filterPipe = new TranslationPipe();
    this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
    this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
    this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
    this.paginator._intl.getRangeLabel = rangelabel;
    this.dataSource.paginator = this.paginator;
  }

  RefreshUserList() {
    this.bindUserList(this.selectedClientValue)
  }


  //open a dialog on invite user
  openInviteNewUserDialog() {
    const dialogRef = this.dialog.open(InviteNewUserComponent, {
      width: '600px',
      data: {
        clientId: this.selectedClientValue,
        clientName: this.selectedClientName
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.RefreshUserList();
    });
  }


  //open a dialog on edit
  openUserEditDialog(row: IUser) {

    const dialogRef = this.dialog.open(InviteNewUserComponent, {
      width: '600px',
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.RefreshUserList();
    });
  }



  //open a dialog on Add Selective

  openAddSelective(selectedUserId: number, flag: number) {

    AppConstants.glbSelectedUserId = selectedUserId;


    const dialogRef = this.dialog.open(AddUserBGMapComponent, {
      width: '500px',
      height: '300px',
      data: flag

    });

    dialogRef.afterClosed().subscribe((result) => {
      this.bindMappedBusinessGroup(this.selectedUserId);
      this.bindUnMappedBusinessGroup(this.selectedUserId);


    });
  }


  //delete user
  deleteUser(userId: number, objectId: string, roleName: string) {
    const filterPipe = new TranslationPipe();
    if (objectId.length <= 0) {
      objectId = "1";         //handling empty object id string
    }

    if (roleName.includes("Super") || roleName.includes("Power") || roleName.includes("Standard") || roleName.includes("UPS")) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: filterPipe.transform(this.Attention),
          text: filterPipe.transform(this.InternalMsg),
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.userService.deleteUserData(userId, AppConstants.gblLoggedInUserId, objectId).then((data) => {

            this.bindUserList(this.selectedClientValue);
          }).catch((error) => {
            this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed), filterPipe.transform(this.UserDeletionFailed));

          });
        }
      });

    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: filterPipe.transform(this.Confirmdelete),
          text: filterPipe.transform(this.Areyousuretodeleteuser),
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.userService.deleteUserData(userId, AppConstants.gblLoggedInUserId, objectId).then((data) => {
            this.bindUserList(this.selectedClientValue);
          }).catch((error) => {
            this.commonService.openMessageDialog('Delete failed', 'User Deletion Failed');

          });
        }
      });
    }
  }

  //delete single mapping of user and BG
  deleteSingleBGMapping(selectedUserId: number, businessGroupId: number) {
    const filterPipe = new TranslationPipe();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: filterPipe.transform(this.Confirmdelete),
        text: filterPipe.transform(this.confirmDeleteBusinessGroup),
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deleteSingleBGMapping(selectedUserId, businessGroupId).then((data) => {
          this.bindMappedBusinessGroup(this.selectedUserId);
          this.bindUnMappedBusinessGroup(this.selectedUserId);
        }).catch((error) => {
          this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed), filterPipe.transform(this.mappingdeletionfailed));
        });
      }
    });
  }


  //Map single BG to user
  mapSingleBG(selectedUserId: number, businessGroupId: number) {
    const filterPipe = new TranslationPipe();
    let mapObj: IUserBusinessGroup = {
      userId: selectedUserId,
      businessGroupId: businessGroupId,
      loggedinUserId: AppConstants.gblLoggedInUserId
    }
    this.userService.postSingleUserBusinessGroupMapping(mapObj).subscribe((data) => {
      this.bindMappedBusinessGroup(this.selectedUserId);
      this.bindUnMappedBusinessGroup(this.selectedUserId);

    },
      (error) => {
        this.commonService.openMessageDialog(filterPipe.transform(this.Savefailed), filterPipe.transform(this.MappingFailed));

      })

  }

  //Map all BG to user at once
  mapAllBG(selectedUserId: number) {
    const filterPipe = new TranslationPipe();
    let mapObj: any = {
      userId: selectedUserId,
      loggedinUserId: AppConstants.gblLoggedInUserId
    }

    this.userService.postAllUserBusinessGroupMapping(mapObj).subscribe((data) => {
      this.bindMappedBusinessGroup(this.selectedUserId);
      this.bindUnMappedBusinessGroup(this.selectedUserId);

    },
      (error) => {
        this.commonService.openMessageDialog(filterPipe.transform(this.Savefailed), filterPipe.transform(this.MappingFailed));

      })
  }


  //delete all mapping of user and BG at once
  deleteAllBGMapping(selectedUserId: number) {
    const filterPipe = new TranslationPipe();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: filterPipe.transform(this.Confirmdelete),
        text: filterPipe.transform(this.confirmDeleteAllBusinessGroup),
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deleteAllUserBusinessGroup(selectedUserId).then((data) => {
          this.bindMappedBusinessGroup(this.selectedUserId);
          this.bindUnMappedBusinessGroup(this.selectedUserId);
        }).catch((error) => {
          this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed), filterPipe.transform(this.mappingdeletionfailed));
        });
      }
    });
  }

  //filter for users
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  //filter mapped BG
  applyMappedBGFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let mappedBGByUser_MasterOuterArray = this.mappedBGByUser_Master;
    this.mappedBGByUser = this.applyBGFilter(filterValue, mappedBGByUser_MasterOuterArray);
  }

  //filter unmapped BG
  applyUnMappedBGFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let unMappedBGByUser_MasterOuterArray = this.unMappedBGByUser_Master;
    this.unMappedBGByUser = this.applyBGFilter(filterValue, unMappedBGByUser_MasterOuterArray);
  }

  //common filter function for mapped and unmapped BG
  applyBGFilter(filterValue: string, outerArr: IUserBusinessGroupMaster[]) {
    let filteredBG: any[] = [];

    outerArr.forEach(element => {
      let innerArray = (<IBusinessGroup[]><unknown>element.businessGroups);
      if (innerArray.filter(x => (x.businessGroupName).toLowerCase().includes(filterValue.toLowerCase())).length > 0) {
        let match = innerArray.filter(x => (x.businessGroupName).toLowerCase().includes(filterValue.toLowerCase()));
        filteredBG.push({
          clientId: element.clientId,
          clientName: element.clientName,
          userId: element.userId,
          businessGroups: match
        });
      }
    });
    return filteredBG;
  }

  clearFilter() {
    this.bgString = '';
    this.allBGString = '';
    let mappedBGByUser_MasterOuterArray = this.mappedBGByUser_Master;
    this.mappedBGByUser = this.applyBGFilter('', mappedBGByUser_MasterOuterArray);
    let unMappedBGByUser_MasterOuterArray = this.unMappedBGByUser_Master;
    this.unMappedBGByUser = this.applyBGFilter('', unMappedBGByUser_MasterOuterArray);
  }
}


