import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AccBusinessGroup } from 'src/app/models/business-group';
import { BusinessGroupService } from 'src/app/services/businessgroup.service';
import { MsgDialogComponent } from '../msg-dialog/msg-dialog.component';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
import { debug } from 'console';

@Component({
  selector: 'app-delete-bg-acc-map',
  templateUrl: './delete-bg-acc-map.component.html',
  styleUrls: ['./delete-bg-acc-map.component.scss'],
  encapsulation:ViewEncapsulation.None

})
export class DeleteBgAccMapComponent implements OnInit {

  businessGroupId:number;
  businessGroupName:string;
  accDropdownSettings:IDropdownSettings={};
  SelectAccountNos :  string  = 'Select Account Nos';
  SelectAllItemsFromList : string = 'Select All Items From List';
  UnSelectAllItemsFromList : string = 'UnSelect All Items From List';
  Nodataavailable : string = 'No data available';
  Success : string  = 'Success';
  BGAccountnumbersmappingdeletedSuccessfully : string  = 'BG, Account numbers mapping deleted Successfully';
  Failed : string = 'Failed';
  globalerror : string  = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
  Search : string = 'Search';
  mappedAccountByBG:any[]=[];
  mappedAccountByBG_Master:any[]=[];
  AccIdPristine: boolean = true;
  selectedAcclist : string="";

  bgDelAccMapForm = new FormGroup({
    // businessgrp: new FormControl(0, [Validators.required]),
    bgName: new FormControl(""),
    accItems: new FormControl("", [Validators.required]),
  });

  constructor(private router: Router, public businessGroupService:BusinessGroupService,
    @Inject(MAT_DIALOG_DATA) public data:any,public dialog: MatDialog) { 
      this.businessGroupId = data.businessGroupId;
      this.businessGroupName = data.businessGroupName;

    }
    
  ngOnInit(): void {

    const filterPipe = new TranslationPipe();

    this.accDropdownSettings= {
      singleSelection: false,
      idField: 'accountNumberId',
      textField: 'accountNumber',
      allowSearchFilter: true,
      enableCheckAll: true,
      selectAllText: filterPipe.transform(this.SelectAllItemsFromList),
      unSelectAllText: filterPipe.transform(this.UnSelectAllItemsFromList),
      itemsShowLimit: 3,
      maxHeight: 100,
      searchPlaceholderText: filterPipe.transform(this.Search),
      noDataAvailablePlaceholderText : filterPipe.transform(this.Nodataavailable),
      noFilteredDataAvailablePlaceholderText: filterPipe.transform(this.Nodataavailable)
    };   
    this.bindMappedAccountNumber(this.businessGroupId);
  }
  
 async bindMappedAccountNumber(businessGroupId:number){
  this.mappedAccountByBG=this.mappedAccountByBG_Master= <any[]>await this.businessGroupService.getMappedAccountByBG(businessGroupId); 
     
}
 onAccSelect(item: any) {
  this.AccIdPristine = false;
  this.selectedAcclist="";
   
  
   const acclist:any[]= this.bgDelAccMapForm.value.accItems as any ;
 
   acclist.forEach(element  => {
    this.selectedAcclist=this.selectedAcclist+','+(element.accountNumberId.toString());
  }); 
  this.selectedAcclist=this.selectedAcclist.substring(1,this.selectedAcclist.length) ;

}

onSelectAllAcc(items: any){  
    this.AccIdPristine = false;  
    this.selectedAcclist="";
    items.forEach((sid: any) => {
    this.selectedAcclist=this.selectedAcclist+','+sid.accountNumberId;

  });
  this.selectedAcclist=this.selectedAcclist.substring(1,this.selectedAcclist.length) ;
}

async onSubmit() {
  const filterPipe = new TranslationPipe();
if (this.bgDelAccMapForm.valid) {
  const userBusinessGroupObj: AccBusinessGroup = {
   AccountNumberIds : this.selectedAcclist,
   BusinessGroupId : this.businessGroupId,
   LoggedinUserId : AppConstants.gblLoggedInUserId    
  };
  {
      await this.businessGroupService.
      deleteBGAccData(userBusinessGroupObj)
    .subscribe(
          (response) => {
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Success),
                  text:filterPipe.transform(this.BGAccountnumbersmappingdeletedSuccessfully),
                },
              });           
          },
          (error) => {
     {
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.globalerror),
                },
              });
            }
          });
    }

}

}

}
