import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ModuleEditComponent } from 'src/app/components/module-edit/module-edit.component';
import { BusinessGroup } from 'src/app/models/business-group';
import { ModuleService } from 'src/app/services/module.service';
import { DataService } from 'src/app/services/data.service';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';


interface IModuleList{
  moduleId:number,
  moduleName:string, 
  businessGroupCount:number,
  pbReportId:string,
  pbGroupId:string,
  pbDatasetId:string,
  qlikApplicationId:string,
  qlikSheetId:string,
  moduleDescription:string,
  reportId:number,
  clientId:number,
  tenantId:number,
  tenantName:string,
  isInternal:boolean
}
interface IModule{
  moduleId:number,
  moduleName:string,
  createdBy:number,
  modifiedBy:number,
  applicationId:number,
  moduleTypeId:number ,
  pbReportId:string,
  pbGroupId:string,
  pbDatasetId:string,
  qlikApplicationId:string,
  qlikSheetId:string,
  moduleDescription:string,
  reportId:number,
  tenantId:number,
  tenantName:string,  
}


@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.scss'],
  encapsulation:ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
    trigger('fadeInAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class ModuleListComponent implements OnInit {

  isEdit = false;
  clientDisabling = false;
  placeholder :string = 'Module Name,Qlik Application Id,etc.';
  BusinessGroup : string = 'Business Group';
  AllBusinessGroups :string = 'All Business Groups';
  MappedBusinessGroups: string ='Mapped Business Groups';
  noData :string = 'No data found';
  of : string = 'of';
  Itemsperpage : string = 'Items per page';
  Nextpage : string = 'Next page';
  Previouspage : string = 'Previous page';
  Confirmdelete :  string  = 'Confirm delete';
  AreyousuretodeleteModule :  string  = "Are you sure to delete Module?";
  ModuleDeletionFailed :  string  = 'Module Deletion Failed';
  Deletefailed :  string  = 'Delete failed';
  AreyousuretodeleteallBusinessgroupmapping :  string  = 'Are you sure to delete all Business group mapping?';
  AreyousuretodeleteBusinessgroupmapping : string  = 'Are you sure to delete Business group mapping?';
  MappingDeletionFailed :  string  = 'Mapping Deletion Failed';
  Savefailed  : string  = 'Save failed';
  Mappingfailed :  string  = 'Mapping failed';
  showInternalUser = true; 
  selectedModuleId:number=0;  
  selectedClientId:number=0;
  dataSource: MatTableDataSource<IModuleList>;
  mappedBG:any[]=[];
  mappedBG_Master:any[]=[];
  bgString:string='';
  allBgString:string='';
  unMappedBG:any[]=[];
  unMappedBG_Master:any[]=[];

  public records: any[] = [];

  columnsToDisplay = [
    'moduleName', 
    'tenantName',  
    'qlikApplicationId',
    'qlikSheetId',
    'isinternal',
    'actions'
  ];
  expandedElement: BusinessGroup | null | undefined;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public dialog: MatDialog,public moduleService:ModuleService,private dataService:DataService,private commonService:CommonService,public router: Router) {
   
    // Assign the data to the data source for the table to render
        this.dataSource=new MatTableDataSource<IModuleList>();

  } 
  

  ngOnInit() {
    if(AppConstants.glbRoleId==8 || AppConstants.glbRoleId==9)
    {
      this.showInternalUser = false;
      this.columnsToDisplay = [
        'moduleName', 
        'tenantName',  
        'qlikApplicationId',
        'qlikSheetId',
        'actions'
      ];
    }
    else
    {
    this.columnsToDisplay = [
      'moduleName', 
      'tenantName',  
      'qlikApplicationId',
      'qlikSheetId',
      'isInternal',
      'actions'
    ];
  }
    this.bindModuleList();
  
  }

  
   bindModuleList(){
    this.moduleService.getModuleList(AppConstants.gblAppId,AppConstants.gblLoggedInUserId,AppConstants.gblClientId).then((data)=>{ 
    
    
      this.dataSource = new MatTableDataSource(data as IModuleList[]); 
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;        
  })
}

ngAfterViewInit() {
  const rangelabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 ${filterPipe.transform(this.of)} ${length}`;
    }
  
    length = Math.max(length, 0);
  
    const startIndex = page * pageSize;
  
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
  
    return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
  };


   this.dataSource.sort = this.sort;
   const filterPipe = new TranslationPipe();
    this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
    this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
    this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
    this.paginator._intl.getRangeLabel = rangelabel;
    this.dataSource.paginator = this.paginator;
}
  
 
  RefreshList() {
  this.ngOnInit();
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  applyMappedFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.mappedBG =this.mappedBG_Master.filter( i => i.businessGroupName.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 );
   
  }
  applyUnMappedFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.unMappedBG =this.unMappedBG_Master.filter( i => i.businessGroupName.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 );
   
  }


   
   openModuleAddDialog() {
    const dialogRef = this.dialog.open(ModuleEditComponent, {
      width: '600px',
      data: null,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }


  deleteModule(moduleId:number){
    const filterPipe = new TranslationPipe();
    const dialogRef=this.dialog.open(ConfirmDialogComponent,{
      data: {
        title: filterPipe.transform(this.Confirmdelete),
        text: filterPipe.transform(this.AreyousuretodeleteModule),
      },
    }); 
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
         
    this.moduleService.deleteModule(moduleId,AppConstants.gblLoggedInUserId).then((data)=>{
      
      this.bindModuleList();         
    }).catch((error)=>{      
      this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed),filterPipe.transform(this.ModuleDeletionFailed));

    });
      }
    });

    
  }


   
  //open a dialog on edit
  openModuleEditDialog( row:IModule) {
    const dialogRef = this.dialog.open(ModuleEditComponent, {
      width: '600px',
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {     
      this.ngOnInit();
    });
  }


  openMessageDialog(dialogTitle:string,dialogText:string){
    this.dialog.open(MsgDialogComponent, {
      data: {
        title: dialogTitle,
        text: dialogText,
      },
    });
  }

  //bind mapped and unmapped BG lists after expanding user 
  bindDetails(expandedElement:any,moduleId: number,clientId:number){
    
    this.bgString='';
    this.allBgString='';
    if(expandedElement)
    {
      this.bindMappedBG(moduleId,clientId);
      this.bindUnMappedBG(moduleId,clientId);
      this.selectedModuleId=moduleId;
      this.selectedClientId=clientId;
    }
    else{
      this.selectedModuleId=0;
      this.selectedClientId=0;
      
      
    }
    if(this.isEdit ){
      this.isEdit=!this.isEdit;
    }
  }
//bind mapped BG list
  async bindMappedBG(moduleId:number,clientId:number){
     this.mappedBG=this.mappedBG_Master= <any[]>await this.moduleService.getMappedBG(moduleId,clientId); 
  }

//bind unmapped BG list
  async bindUnMappedBG(moduleId:number,clientId:number){
    this.unMappedBG=this.unMappedBG_Master= <any[]>await this.moduleService.getUnmappedBG(moduleId,clientId);     
 }
 //delete all mapping of BG and BG at once
 deleteAllBGModuleMapping(selectedModuleId:number){
  const filterPipe = new TranslationPipe();
  const dialogRef=this.dialog.open(ConfirmDialogComponent,{
    data: {
      title: filterPipe.transform(this.Confirmdelete),
      text: filterPipe.transform(this.AreyousuretodeleteallBusinessgroupmapping),
    },
  }); 
  
  dialogRef.afterClosed().subscribe(result => {
    if(result) {
  this.moduleService.deleteAllBGModule(selectedModuleId).then((data)=>{       
  this.bindMappedBG(this.selectedModuleId,this.selectedClientId)  ; 
  this.bindUnMappedBG(this.selectedModuleId,this.selectedClientId)  ;    
  }).catch((error)=>{
  this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed),filterPipe.transform(this.MappingDeletionFailed));     
  });
}});
}

//delete single mapping of user and BG
deleteSingleBGModuleMapping(moduleBusinessGroupId:number){
  const filterPipe = new TranslationPipe();
  const dialogRef=this.dialog.open(ConfirmDialogComponent,{
    data: {
      title: filterPipe.transform(this.Confirmdelete),
      text: filterPipe.transform(this.AreyousuretodeleteBusinessgroupmapping),
    },
  }); 
  
  dialogRef.afterClosed().subscribe(result => {
    if(result) {
  this.moduleService.deleteSingleBGModuleMapping(moduleBusinessGroupId).then((data)=>{       
  this.bindMappedBG(this.selectedModuleId,this.selectedClientId)  ; 
  this.bindUnMappedBG(this.selectedModuleId,this.selectedClientId)  ;    
  }).catch((error)=>{
  this.commonService.openMessageDialog(filterPipe.transform(this.Deletefailed),filterPipe.transform(this.MappingDeletionFailed));     
  });
}});
}


  //Map single BG to user
  mapSingleBG(selectedModuleId:number ,businessGroupId:number){
    const filterPipe = new TranslationPipe();
    let mapObj: any = {
      moduleId:selectedModuleId,
      businessGroupId:businessGroupId,
      loggedinUserId: AppConstants.gblLoggedInUserId      
     }
    this.moduleService.postSingleBGModuleMapping(mapObj).subscribe((data) => {    
      this.bindMappedBG(this.selectedModuleId,this.selectedClientId)  ; 
      this.bindUnMappedBG(this.selectedModuleId,this.selectedClientId)  ;   
                                        
       },
        (error) => {                                                                       
    this.commonService.openMessageDialog(filterPipe.transform(this.Savefailed),filterPipe.transform(this.Mappingfailed));     
                                                         
       })
                                  
  }
  
//Map all BG to user at once
  mapAllBG(selectedModuleId:number){
    const filterPipe = new TranslationPipe();
    let mapObj: any = {
      moduleId:selectedModuleId,
      clientId:AppConstants.gblClientId,
      loggedinUserId :  AppConstants.gblLoggedInUserId
     }

    this.moduleService.postAllBGModuleMapping(mapObj).subscribe((data) => {    
      this.bindMappedBG(this.selectedModuleId,this.selectedClientId)  ; 
      this.bindUnMappedBG(this.selectedModuleId,this.selectedClientId)  ;   
                                        
                                    },
                                    (error) => {                                                                       
    this.commonService.openMessageDialog(filterPipe.transform(this.Savefailed),filterPipe.transform(this.Mappingfailed));     
                                                         
                                    })
  }
  


clearFilter(){
  this.bgString='';
  this.allBgString='';
  this.mappedBG=this.mappedBG_Master.filter( i => i.businessGroupName.toLowerCase().indexOf('') >= 0 );
  this.unMappedBG=this.unMappedBG_Master.filter( i => i.businessGroupName.toLowerCase().indexOf('') >= 0 );
 }
  

}
