import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AppConstants } from 'src/app/AppConstants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcessAccountnumbersService {

  constructor(private httpService: HttpService) { }
  processAccountNumbers(clientId:number,userId:number):Observable<any>{
        
      return this.httpService.post(AppConstants.apiUrl+"AccountNumberBusinessGroup/ProcessAccountNumbers?ClientId="+clientId+"&UserId="+userId,null)  ; 
    }
    
}
