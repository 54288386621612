import { Component, OnInit, Inject,ViewEncapsulation } from '@angular/core';
import { FormControl,Validators ,FormGroup } from '@angular/forms';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {MatDialogModule } from '@angular/material/dialog';
import {ReportComponent} from 'src/app/pages/menu/Report/report.component';
import { AppConstants } from 'src/app/AppConstants';
import { IReport} from 'src/app/models/Report.model';
import { ReportService } from 'src/app/services/report.service';
import { TranslationPipe } from 'src/app/locale/translation.pipe';

@Component({
  selector: 'app-report-add-edit',
  templateUrl: './report-add-edit.component.html',
  styleUrls: ['./report-add-edit.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ReportAddEditComponent implements OnInit {
  reports: IReport[] = [];
  selectedReport: any;
  InsertSuccess :  string  = "Insert Success";
  ReportInsertedSuccessfully:  string  = "Report Inserted Successfully";
  Duplicate:  string  = "Duplicate";
  DuplicateReport:  string  = "Duplicate Report";
  UpdateSuccess:  string  = "Update Success";
  ReportUpdatedSuccessfully:  string  = "Report Updated Successfully";
  Failed : string = 'Failed';
  globalerror : string  = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
  editReportForm = new FormGroup({   
    reportId: new FormControl(0),
    reportName:new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z][a-zA-Z0-9_ ]*$')]),
    reportDescription:new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z][a-zA-Z0-9_ ]*$')])
  });

  constructor(private reportService:ReportService,public dialog: MatDialog,
    private router: Router,@Inject(MAT_DIALOG_DATA) public data:IReport,
    public dialogRef: MatDialogRef<ReportComponent>) {
    this.selectedReport = data;//this.router.getCurrentNavigation()?.extras.state;
    dialogRef.disableClose = true; 
  
    }

    ngOnInit(): void {
      this.bindReport();
      if (this.selectedReport == undefined && this.selectedReport == null) {
        this.selectedReport = {
          reportId:0,
          reportName:'',
          reportDescription:''
        };
      } 
    }
    bindReport() {
      this.reportService
        .getReport(AppConstants.gblAppId, AppConstants.gblClientId)
        .then((data) => {
          this.reports = data as IReport[];
        })
        .catch((error) => {
          console.log('IU Promise rejected with ' + JSON.stringify(error));
        });
    }
   

  async onSubmit() {
    const filterPipe = new TranslationPipe();
    if (this.editReportForm.valid) {
      const reportObj: any = {
        reportId: this.editReportForm.value.reportId,
        reportName: this.editReportForm.value.reportName,             
        reportDescription:this.editReportForm.value.reportDescription,
        modifiedBy: AppConstants.gblLoggedInUserId,
      };
      
    
       if (reportObj.reportId == 0)
      {
        
        await this.reportService
            .postRoleData(reportObj)
            .subscribe((data) => {
              
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.InsertSuccess),
                  text: filterPipe.transform(this.ReportInsertedSuccessfully),
                },
              });
      this.router.navigateByUrl('/menu/managereport');
  
            },
            (error) => {
              if(error.status==409){
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Duplicate),
                    text: filterPipe.transform(this.DuplicateReport),
                  },
                });
               }
               else{
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Failed),
                    text: filterPipe.transform(this.globalerror),
                  },
                });
               }
            });
        
      }
      
   else if (reportObj.reportId > 0) {

        await this.reportService
          .putReportData(reportObj.reportId,reportObj)
          .subscribe((data) => {
            
            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.UpdateSuccess),
                text: filterPipe.transform(this.ReportUpdatedSuccessfully),
              },
            });
    this.router.navigateByUrl('/menu/managereport');

          },
          (error) => {
            if(error.status==409){
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Duplicate),
                  text: filterPipe.transform(this.DuplicateReport),
                },
              });
             }
             else{
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.Failed),
                  text: filterPipe.transform(this.globalerror),
                },
              });
             }
          });
      }
      
    }
  }

}
