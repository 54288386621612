import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { AccBusinessGroup, IBusinessGroup} from 'src/app/models/business-group';
import { AppConstants } from 'src/app/AppConstants';


@Injectable({
  providedIn: 'root'
})
export class BusinessGroupService {

  constructor(private httpService: HttpService) { }

  
  getBusinessGroupListByClient(gblClientId:number){
    return this.httpService.get(AppConstants.apiUrl+"BusinessGroup/GetBusinessGroupByClient?ClientId="+gblClientId+ "&LoggedInUserId=" + AppConstants.gblLoggedInUserId); 
  }  
  
  createBG(bgObj:any): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"BusinessGroup/AddBusinessGroup", JSON.stringify(bgObj))  ; 
  }

  deleteBusinessGroup(businessGroupId:number,modifiedBy:number){  
    return this.httpService.delete(AppConstants.apiUrl+"BusinessGroup/DeleteBusinessGroup?BusinessGroupId="+businessGroupId+"&ModifiedBy="+modifiedBy)  ;    
  }
  putBusinessGroupData(businessGroupId:number,bgObj:IBusinessGroup): Observable<any>{
    return this.httpService.put(AppConstants.apiUrl+"BusinessGroup/UpdateBusinessGroup?BusinessGroupId="+businessGroupId, JSON.stringify(bgObj)) ;   
  }

  getMappedAccountByBG(businessGroupId:number){
    return this.httpService.get(AppConstants.apiUrl+"AccountNumberBusinessGroup/GetAccountNumberBusinessGroupByBusinessGroup?BusinessGroupId="+businessGroupId);   
  }

  getUnmappedAccountByBG(businessGroupId:number){
    return this.httpService.get(AppConstants.apiUrl+"AccountNumberBusinessGroup/GetUnMapAccountNumberBusinessGroupByBusinessGroup?BusinessGroupId="+businessGroupId);   
  }

  deleteSingleBGAccMapping(AccountNumberBusinessGroupId:number){
    return this.httpService.delete(AppConstants.apiUrl+"AccountNumberBusinessGroup/DeleteAccountNumberBusinessGroup?AccountNumberBusinessGroupId="+AccountNumberBusinessGroupId+"&LoggedinUserId="+AppConstants.gblLoggedInUserId)  ;    
  }
  deleteAllBGAcc(selectedBusinessGroupId:number){
    return this.httpService.delete(AppConstants.apiUrl+"AccountNumberBusinessGroup/DeleteAllAccountNumberBusinessGroup?BusinessGroupId="+selectedBusinessGroupId+"&LoggedinUserId="+AppConstants.gblLoggedInUserId)  ;    
  }
   

  postSingleBGAccMapping(mapObj:any): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"AccountNumberBusinessGroup/AddAccountNumberBusinessGroup", JSON.stringify(mapObj)) ;   
  }

  postAllBGAccMapping(mapObj:any): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"AccountNumberBusinessGroup/AddAllAccountNumberBusinessGroup" ,JSON.stringify(mapObj)) ;   
  }

  postCSVData(mapObj:any): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"AccountNumberBusinessGroup/AddCSVAccountNumberBusinessGroup" ,JSON.stringify(mapObj)) ;   
  }
  postBGAccData(mapObj:AccBusinessGroup): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"AccountNumberBusinessGroup/AddMultipleAccBusinessGroup", JSON.stringify(mapObj)) ;   
  }
  deleteBGAccData(mapObj:AccBusinessGroup): Observable<any>{
   
    return this.httpService.post(AppConstants.apiUrl+"AccountNumberBusinessGroup/DeleteMultipleAccBusinessGroup", JSON.stringify(mapObj)) ;   
  }
}
