<form [formGroup]="manageAlertRuleForm" (ngSubmit)="onSubmit()">
  <mat-toolbar class="pair-new-device-toolbar">
    <mat-icon class="icon-big">warning_amber</mat-icon>
    &nbsp;&nbsp;
    <button
      mat-button
      class="color-blue icon-big"
      routerLink="/menu/alert-rules"
    >
      Alert Rules
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <button mat-button class="icon-big">Create/Edit Alert Rule</button>
    <span class="flex-spacer"></span>
  </mat-toolbar>

  <div class="grid-container">
    <mat-card class="example-card">
      <mat-card-header>
        <mat-icon mat-card-avatar class="icon-big color-blue"
          >receipt_long</mat-icon
        >
        <mat-card-title class="color-blue">Details</mat-card-title>
        <mat-card-subtitle>Instance Details</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <input
          matInput
          type="hidden"
          formControlName="AlertRuleId"
          [(ngModel)]="selectedAlertRule.alertRuleId"
        />

        <mat-form-field appearance="fill">
          <mat-label>Alert rule name</mat-label>
          <input
            matInput
            formControlName="AlertRuleName"
            [(ngModel)]="selectedAlertRule.alertRuleName"
          />
          <mat-icon
            matSuffix
            matTooltip="Provide an alert rule name that is unique"
            class="info-tooltip"
            >info_outline</mat-icon
          >
          <mat-error
            *ngIf="
              manageAlertRuleForm.get('AlertRuleName')?.touched &&
              manageAlertRuleForm.get('AlertRuleName')?.errors?.required
            "
          >
            Required.
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            rows="16"
            formControlName="Description"
            [(ngModel)]="selectedAlertRule.description"
          ></textarea>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Severity</mat-label>
          <mat-select
            formControlName="Severity"
            [(ngModel)]="selectedAlertRule.severityId"
          >
            <mat-option
              *ngFor="let type of severities"
              [value]="type.severityId"
            >
              {{ type.severityName }}
            </mat-option>
          </mat-select>
          <mat-error
          *ngIf="
            manageAlertRuleForm.get('Severity')?.touched &&
            manageAlertRuleForm.get('Severity')?.errors?.required
          "
        >
          Required.
        </mat-error>
          <mat-icon
            matSuffix
            matTooltip="Select severity for this alert rule"
            class="info-tooltip"
            >info_outline</mat-icon
          >
        </mat-form-field>
        <div class="text-center mt-md">
          <mat-slide-toggle
            formControlName="Silenced"
            [(ngModel)]="selectedAlertRule.silenced"
            >Silenced</mat-slide-toggle
          >
        </div>
        <br />
      </mat-card-content>
    </mat-card>

    <mat-card class="example-card">
      <mat-card-header>
        <mat-icon mat-card-avatar class="icon-big color-green">build</mat-icon>
        <mat-card-title class="color-green"
          >Conditions
          <mat-icon
            matTooltip="Configure when the alert rule should trigger by selecting a signal and defining its logic"
            class="info-tooltip"
            >info_outline</mat-icon
          >
        </mat-card-title>
        <mat-card-subtitle>Condition Configuration </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field appearance="fill">
          <mat-label>Signal Type</mat-label>
          <mat-select
            formControlName="SignalType"
            [(ngModel)]="selectedAlertRule.signalTypeId"
          >
            <mat-option
              *ngFor="let type of signalTypes"
              [value]="type.signalTypeId"
            >
              {{ type.signalTypeName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              manageAlertRuleForm.get('SignalType')?.touched &&
              manageAlertRuleForm.get('SignalType')?.errors?.required
            "
          >
            Required.
          </mat-error>
          <mat-icon
            matSuffix
            matTooltip="Choose that type of trigger for the alert rule"
            class="info-tooltip"
            >info_outline</mat-icon
          >
        </mat-form-field>
        <div class="mt-md">
          <mat-card-subtitle>Based on Signal Measurement</mat-card-subtitle>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Operator</mat-label>
          <mat-select
            formControlName="Operator"
            [(ngModel)]="selectedAlertRule.operatorId"
          >
            <mat-option
              *ngFor="let operator of operators"
              [value]="operator.operatorId"
            >
              {{ operator.operatorName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              manageAlertRuleForm.get('Operator')?.touched &&
              manageAlertRuleForm.get('Operator')?.errors?.required
            "
          >
            Required.
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Threshold value</mat-label>
          <input
            matInput
            formControlName="Threshold"
            [(ngModel)]="selectedAlertRule.threshold"
          />
          <mat-error
            *ngIf="
              manageAlertRuleForm.get('Threshold')?.touched &&
              manageAlertRuleForm.get('Threshold')?.errors?.required
            "
          >
            Required.
          </mat-error>
          <mat-error
            *ngIf="manageAlertRuleForm.get('Threshold')?.errors?.pattern"
          >
            Invalid.Value should be a number.
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Frequency of Evaluation (in minutes)</mat-label>
          <input
            matInput
            formControlName="Frequency"
            [(ngModel)]="selectedAlertRule.alertFrequency"
          />
          <mat-icon
            matSuffix
            matTooltip="Select the frequency of how often the evaluation should be run"
            class="info-tooltip"
            >info_outline</mat-icon
          >
          <mat-error
            *ngIf="
              manageAlertRuleForm.get('Frequency')?.touched &&
              manageAlertRuleForm.get('Frequency')?.errors?.required
            "
          >
            Required.
          </mat-error>
          <mat-error
            *ngIf="manageAlertRuleForm.get('Frequency')?.errors?.pattern"
          >
            Invalid.Value should be a number.
          </mat-error>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="fx-center">
    <div class="grid-container action-btns">
      <button mat-stroked-button routerLink="/menu/alert-rules"
      ><mat-icon>arrow_back</mat-icon> Cancel</button>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        class="local-accent-color-bg"
        [disabled]="!manageAlertRuleForm.valid"
      >
        <mat-icon>add</mat-icon> Create
      </button>
    </div>
  </div>

  <br />
  <br />
  <br />
  <br />
  {{selectedAlertRule.operatorId}}
</form>
