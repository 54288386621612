
<mat-toolbar class="pair-new-device-toolbar">
  &nbsp;&nbsp;
  <span i18n="@@Process Account Numbers">Process Account Numbers</span>
</mat-toolbar>
<mat-card id="card" class="classalignright">
	
	<form [formGroup]="processAccNumberForm">
	<mat-card-content>
    <div class="row">
      <div class="col-md-6">
       

      <mat-form-field>
        <mat-label i18n="@@Client">Client</mat-label>
        <mat-select formControlName="client" class="ddlClient" >
    <ngx-mat-select-search id="search"  class="client-dropdown" matInput noEntriesFoundLabel={{Nodataavailable|translation}} placeholderLabel={{Search|translation}} autocomplete="off"  (keyup)=onKey($event) >
    </ngx-mat-select-search>

          <mat-option *ngFor="let client of selectedClients" [value]="client.clientId">
              {{client.clientName}}
            </mat-option>
      </mat-select> 
      <mat-error *ngIf=" processAccNumberForm.get('client')?.touched && processAccNumberForm.get('client')?.errors?.required">
        <span i18n="@@Required">Required</span>
        </mat-error>    
      </mat-form-field>
      
      </div>
      </div>

  
  <br>

  
		<button mat-raised-button color="primary" (click)="processAccountNumbers()"
    [disabled]="!processAccNumberForm.valid"  class="classMarginrigh5px" >
    <span i18n="@@Set up default BG">Set up default BG</span>
    
    </button>
		<button mat-raised-button color="primary" (click)="processAllAccountNumbers()"
    class="classMarginrigh5px">
    <span i18n="@@Set up default BG for all clients">Set up default BG for all clients</span>
    </button>
	</mat-card-content>
</form>
