import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IInvitationstatus } from 'src/app/models/invitationstatus.model';
import { UserService } from 'src/app/services/user.service';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-redemption',
  templateUrl: './redemption.component.html',
  styleUrls: ['./redemption.component.scss'],
  encapsulation:ViewEncapsulation.None,
  animations:  [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
    trigger('fadeInAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})

export class RedemptionComponent implements OnInit {
  displayedColumns: string[] = ['emailAddressText','invitationAcceptedIndicator', 'deliverInvitationIndicator','action'];
  expandedElement: IInvitationstatus | null | undefined;
  dataSource: MatTableDataSource<IInvitationstatus>;
  InvitationIdentifierNumberAliasetc : string  = "Invitation IdentifierNumber,Alias,etc.";
  noData :string = 'No data found';
  Itemsperpage : string = 'Items per page';
  Nextpage : string = 'Next page';
  Previouspage : string = 'Previous page';
  of : string = 'of';
  selectedEmailId:  string  = '';
  confirm : string  = 'Confirm';
  confirmmsg :  string  = 'Are you sure to Re Invite the user?';
  failed :  string  = 'Failed';
  failedmsg : string  = 'Invitation Failed';
  success :  string  = 'Success';
  successmsg : string  = 'User Re Invited Sucessfully';
  attention : string  = 'Attention!';
  attentionmsg: string  = 'It seems like User is Active and has logged in Portal. Please delete and Re Invite User or Please conatct PSI Support.';
  invitefailed: string  = 'Invite Failed';
  invitefailedmsg : string  = 'Invitation Failed. Please contact PSI support.';
  detailUsers : any []= [];
  detailUsers_Master:any[]=[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public dialog: MatDialog,
    public userService: UserService,
    private commonService: CommonService,
    public router: Router
    ) {
      this.dataSource = new MatTableDataSource<IInvitationstatus>();
     }

  ngOnInit(): void {
    this.bindInvitationstatus();
  }

  bindInvitationstatus() {
    this.userService.GetInvitationStatus().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data as IInvitationstatus[]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ReSendInvite(EmailId:string){
    const filterPipe = new TranslationPipe();
    const dialogRef=this.dialog.open(ConfirmDialogComponent,{
      data: {
        title: filterPipe.transform(this.confirm),
        text: filterPipe.transform(this.confirmmsg),
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
         this.userService.
        ReSendInvite(EmailId)
      .then(
            (response) => {
              if(response!=null && response !=""){
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.failed),
                    text: filterPipe.transform(this.failedmsg),
                  },
                });
              }
              else{
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.success),
                  text: filterPipe.transform(this.successmsg),
                },
              });
             }
              //this.router.navigateByUrl('/menu/userroles-cvascreens');

            },
            (response) => {

              if (response.status == 409) {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.attention),
                    text: filterPipe.transform(this.attentionmsg),
                  },
                });
              }
              else {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.invitefailed),
                    text: filterPipe.transform(this.invitefailedmsg),
                  },
                });
              }
            });
  }});
  }

//bind mapped client list
async bindUserDetailed(emailId:string){
  this.detailUsers=this.detailUsers_Master= <IInvitationstatus[]>await this.userService.getUserDetailsfromCSAA(emailId);
}

       //bind user lists after expanding user
       bindUserDetails(expandedElement:any,emailId: string){
        if(expandedElement)
        {
          this.bindUserDetailed(emailId);
          this.selectedEmailId=emailId;
          expandedElement.expanded = !expandedElement.expanded
        }
        else{
          this.selectedEmailId='';
        }
      }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const rangelabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${filterPipe.transform(this.of)} ${length}`;
      }
    
      length = Math.max(length, 0);
    
      const startIndex = page * pageSize;
    
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
    
      return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
    };

 
     this.dataSource.sort = this.sort;
     const filterPipe = new TranslationPipe();
     this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
     this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
     this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
     this.paginator._intl.getRangeLabel = rangelabel;
     this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
