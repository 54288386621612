import { Component, Inject, } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent  {
  messages: any[];
  showCheckbox: boolean;
  hideNotification: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.messages = data.messages;    
    this.showCheckbox = data.showCheckbox;
  }

  formatDate(dateString: string): string {
    if (!dateString) return ''; // Handle null or undefined case
    return dateString.split('T')[0]; // Split by 'T' and take the first part (date part)
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  close(): void {
    if (this.hideNotification) {
      this.dialogRef.close(false); // Send false as result if checkbox is checked
    } else {
      this.dialogRef.close(); // Close the dialog without any specific result
    }
  }

}
