import { Component, OnInit } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { DataService } from 'src/app/services/data.service';
import { AppConstants } from 'src/app/AppConstants';

interface IScreen{
  screenId: number;
  screenName: string;
  routerLink:string;
  icon: string;
}

@Component({
  selector: 'app-admin-guide',
  templateUrl: './admin-guide.component.html',
  styleUrls: ['./admin-guide.component.scss']
})
export class AdminGuideComponent implements OnInit {
  pageList: IScreen[] = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.bindScreens();
  }

  async bindScreens(){
    this.pageList=[];
    this.pageList=  await this.dataService.getScreensByUserLevel(AppConstants.glbRoleId,AppConstants.gblscreenGroup,AppConstants.gblModuleId) as IScreen[];
    }
}
