<footer color="primary">
  <span>
    <span i18n="@@Copyright1">&copy;Copyright ©1994</span> - {{currentYearLong()}}<span i18n="@@Copyright2"> United Parcel Service of America, Inc. All rights reserved.</span>
  </span>

  <span>
    <a href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions/privacy-notice.page" target="_blank" rel="noopener"><span i18n="@@Privacy Policy">Privacy Policy</span></a>
    &nbsp; | &nbsp;
    <a href=""><span i18n="@@About">About</span></a>
  </span>
</footer>
