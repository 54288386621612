import {  ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { AppConstants } from 'src/app/AppConstants';
import { MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { UserService } from 'src/app/services/user.service';
import { IInitialUserData } from 'src/app/models/user.model';
import { FavoriteModuleService } from 'src/app/services/favorite-module.service';
import { FavoriteModuleList } from 'src/app/models/favorite-module';
import { MatDialog } from '@angular/material/dialog';
import { ClientDialogComponent } from 'src/app/components/client-dialog/client-dialog.component';
import { UserNameDialogComponent} from 'src/app/components/user-name-dialog/user-name-dialog.component';
import { MsgDialogComponent } from '../../components/msg-dialog/msg-dialog.component';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
import { AppEnv } from 'src/app/GlobalConstants';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationDialogService } from 'src/app/services/notification-dialog.service';

interface Option {
  clientId: string;
  clientName: string;
}

interface IApplication {
  applicationId: number;
  applicationName: string;
}

interface IModules {
  moduleId: number;
  moduleName: string;
}

interface IScreen {
  screenId: number;
  screenName: string;
  routerLink: string;
  icon: string;
}
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})



export class MenuComponent implements OnInit {
  searchTxt: any;
  screenWidth: number;
  isAdmin: boolean = true;
  selectedAppValue: number | null = null;
  apps: IApplication[] = [
  ];

  selectedClientValue: number | null = null;
  custs: any[] = [];
  notifications : any [] = [];
  flag : boolean =  false;
  profile : string = "Profile";
  SelectClient : string = "Select Client";
  ChangePassword : string  = "Change password";
  SelectModule : string = "Select Module";
  SelectScreen :  string = "Select Screen";
  Search : string = "Search";
  SucessLogin : string ="Successfully Logged In";
  Welcomemsg: string ="Welcome Intelligent Analytics User!! <br><br> Your invitation redeem process is successful. Your Analytics Portal is now being Setup.<br>It is expected to take 6 hours for initial one time set-up to complete. Until the setup is completed the user is expected to see “Access Denied” on the report. Once the setup is completed the report will load automatically.<br><br>Thank You for your Patience."
  selectedModuleValue: number | null = null;
  modules: IModules[] = [];
  selectedPageIndex = 0;
  pageList: IScreen[] = [];
  gblModuleName: string | null = null;
  reportList: any[] = [];
  isClientExpanded:boolean=false;
  isModuleExpanded:boolean=false;
  isLoaded=false;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private userService: UserService, 
    private favoriteModuleService: FavoriteModuleService,
    private notificationService : NotificationService,
    private notificationDialogService  : NotificationDialogService,
    private dataService: DataService
  ) {
    this.screenWidth = window.innerWidth;
    router.events.subscribe((val) => {
    });
  }

  initialUserData: any;
  async ngOnInit() {
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };

    this.userService.getInitialUserData().subscribe((data) => {
    
      if(data == null || data === 'undefined')
      {
        setTimeout(() => {
          window.open(environment.access_deniedUrl, '_self');
    
        }, 500);  
      }
        this.initialUserData = data as IInitialUserData;
        AppConstants.gblLoggedInUserId = this.initialUserData.loggedInUserId;
        AppConstants.gbluserLevelId = this.initialUserData.userLevelId;
        AppConstants.gblLoggedInUserFName = this.initialUserData.loggedInUserFName;
        AppConstants.gblLoggedInUserLName = this.initialUserData.loggedInUserLName;
        AppConstants.gblLoggedInUserEmailid = this.initialUserData.loggedInUserEmpId;
        AppConstants.LanguageText = this.initialUserData.languageText;
        AppConstants.gblRoleName = this.initialUserData.roleName;
        AppConstants.glbRoleId = this.initialUserData.roleId;
        this.bindApplications();
         this.isLoaded=true;
        if (AppConstants.LanguageText != localStorage.getItem('locale')) {
            if ('changelocale' in localStorage) {
                console.log("Language Selected: " + localStorage.getItem('locale'));
            }
            else {
                localStorage.setItem('locale', AppConstants.LanguageText);
                location.reload();
            }


        }

      if(this.initialUserData.firstTimeUser==1)
      {
        const filterPipe = new TranslationPipe();
        let MessageText: string = filterPipe.transform(this.Welcomemsg);
        this.dialog.open(MsgDialogComponent, {
          data: {
            title: filterPipe.transform(this.SucessLogin),
            text: MessageText,
          },
        });
      }

    },
    (error)=>{
      console.log('Menu Promise rejected with ' + JSON.stringify(error));
    });

     


    try {
      // Set drop down values from localStorage
      this.selectedAppValue = Number(localStorage.getItem('applicationId'));
      AppConstants.gblAppId = Number(this.selectedAppValue);

      this.selectedClientValue = Number(localStorage.getItem('clientId'));
      AppConstants.gblClientId = Number(this.selectedClientValue);

      this.selectedModuleValue = Number(localStorage.getItem('moduleId'));
      AppConstants.gblModuleId = Number(this.selectedModuleValue);

    } catch (error) {
      console.log(error);
    }
  }


  async bindApplications() {
    this.apps = await this.dataService.getApplicationByUser(AppConstants.gblLoggedInUserId) as IApplication[];   
    this.apps=this.apps.filter(x=>x.applicationName=="CVA")
    if (this.apps.length == 1) {
      this.onAppChange();
    }
  }

  onAppChange() {
    this.router.navigateByUrl('/menu/homescreen');
    AppConstants.glbScreenId = 0;

    try {
      if (this.apps.length == 1) {
        this.selectedAppValue = this.apps[0].applicationId;
      }
      AppConstants.gblAppId = Number(this.selectedAppValue);
      localStorage.setItem('applicationId', String(this.selectedAppValue));


      AppConstants.gblClientId = Number(null);
      localStorage.setItem('clientId', "");
      this.selectedClientValue = Number(localStorage.getItem('clientId'));

      AppConstants.gblModuleId = Number(null);
      localStorage.setItem('moduleId', "");
      this.selectedModuleValue = Number(localStorage.getItem('moduleId'));


      this.bindClients();

      this.pageList = [];
      this.reportList = [];

    } catch (error) {
      console.log(error);
    }
  }

  async bindClients() {
    this.custs = [];

    const clients = <any[]>await this.dataService.getClients(AppConstants.gblAppId, AppConstants.gblLoggedInUserId);

    
    if (clients != null) {
      
      if (clients && clients.length > 0) {
        for (const item of clients) {
          this.custs.push({
            clientId: item.clientId,
            clientName: item.clientName,
          });
        }
      }
    }
    this.modules = [];

    this.pageList = [];
    this.reportList = [];
    if (this.custs.length == 1) {

         const dialogRef = this.dialog.open(ClientDialogComponent,{panelClass:'custom', disableClose: true,data:this.custs[0].clientId });
    
          dialogRef.afterClosed().subscribe(result => {       
            this.selectedClientValue =result;
            this.onClientChange();
          });   
    }
    else
    {          
    
      if( AppConstants.gblLoggedInUserLName=="" && AppConstants.gblLoggedInUserFName==""){
    

        const dialogRef = this.dialog.open(UserNameDialogComponent,{ disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
          
         const dialogRef = this.dialog.open(ClientDialogComponent,{ disableClose: true });
    
          dialogRef.afterClosed().subscribe(result => {
            this.selectedClientValue =result;
            this.onClientChange();
          });   
        }); 
      }
    else{
          const dialogRef = this.dialog.open(ClientDialogComponent,{ disableClose: true });
    
          dialogRef.afterClosed().subscribe(result => {
            this.selectedClientValue =result;
            this.onClientChange();
          });       
        }
    }
  }
  clientExpanded(event: any){
    this.isClientExpanded=true;
  }
  clientCollapsed(){
    this.isClientExpanded=false;    
  }
  moduleExpanded(){
    this.isModuleExpanded=true;
  }
  moduleCollapsed(){
    this.isModuleExpanded=false;    
  }
  async onClientChange() {
    AppConstants.glbScreenId = 0;
    try {
      if (this.custs.length == 1) {
        this.selectedClientValue = this.custs[0].clientId;
        AppConstants.gblClientName = this.custs[0].clientName;
      }
      AppConstants.gblClientId = Number(this.selectedClientValue);
      localStorage.setItem('clientId', String(this.selectedClientValue));
      AppConstants.gblClientName = this.custs.filter(r=>r.clientId === Number(this.selectedClientValue))[0].clientName;
      AppConstants.gblModuleId = Number(null);
      localStorage.setItem('moduleId', "");
      this.selectedModuleValue = Number(localStorage.getItem('moduleId'));


      this.modules = [];
      this.pageList = [];
      this.reportList = [];


      this.bindModules();
      if (AppConstants.gblClientId > 0)
      {
        this.router.navigateByUrl('/menu/homescreen');
        this.viewFavoriteReport();
      }
      else
        this.router.navigateByUrl('/menu/homescreen');

    } catch (error) {
      console.log(error);
    }
  }

  checkNotification(){
    this.flag= false;
    this.notificationService.getFlagNotificationbyClient(AppConstants.gblClientId).subscribe((data) => {
      this.flag = data as boolean;
      if(this.flag === true)
      {
       this.notificationService.getNotificationbyUser(AppConstants.gblLoggedInUserId).subscribe((data) => {
          this.notifications = data as any [];
              this.notificationDialogService.openNotification(this.notifications , true)
        });
      }
    });
      
    
  }

  async viewFavoriteReport() {
    this.checkNotification();
    const objModule = (await this.favoriteModuleService.getFavoriteModuleByClient(AppConstants.gblAppId, AppConstants.gblClientId , AppConstants.gblLoggedInUserId ) as FavoriteModuleList);
    if (objModule.moduleId > 0)
    {
      
    
      this.redirectToQlikReport(objModule.moduleId);
      this.selectedModuleValue=objModule.moduleId;
      this.gblModuleName=objModule.moduleName;
      this.isAdmin =false;
  }
  }
  async bindModules() {
    this.modules = [];
    this.modules =
      await this.dataService.getModules(AppConstants.gblAppId, AppConstants.gblLoggedInUserId, AppConstants.gblClientId) as IModules[];
   
   
  }

  changePwd() {
    window.location.href = AppEnv.resetPassword + AppEnv.changePasswordURL;
  }

  changePrf(){
    window.location.href = AppEnv.editProfile + AppEnv.changeProfileURL;
  }

  onModuleChange(event: MatSelectChange) {
    AppConstants.glbScreenId = 0;

    AppConstants.gblClientId = Number(this.selectedClientValue);
    localStorage.setItem('clientId', String(this.selectedClientValue));

    AppConstants.gblModuleId = Number(this.selectedModuleValue);

    this.gblModuleName = (event.source.selected as MatOption).viewValue;
    try {
      localStorage.setItem('moduleId', String(this.selectedModuleValue));
      this.pageList = [];
      this.reportList = [];
      this.isAdmin =false;
      if (this.gblModuleName.toLowerCase().includes("admin") || this.gblModuleName.toLowerCase().includes("amministratore") || this.gblModuleName.toLowerCase().includes("管理者") || this.gblModuleName.toLowerCase().includes("관리자") || this.gblModuleName.toLowerCase().includes("管理")) {
        this.bindScreens();
        this.isAdmin = true;

      }
      else {
        this.isAdmin = false;

        this.redirectToQlikReport(AppConstants.gblModuleId);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async bindScreens() {

 
    this.pageList = [];
    this.reportList = [];
    this.pageList = await this.dataService.getScreensByUserLevel(AppConstants.glbRoleId, AppConstants.gblscreenGroup, AppConstants.gblModuleId) as IScreen[];
  }




  redirectToQlikReport(moduleId: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl('/menu/qlik-report', { state: moduleId }));
  }

  setScreenId(screenId: number) {
    AppConstants.glbScreenId = screenId;
  }

}
