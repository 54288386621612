import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserRoleAddEditComponent } from 'src/app/components/user-role-add-edit/user-role-add-edit.component';
import { IRole } from 'src/app/models/Role.model';
import { RoleService } from 'src/app/services/role.service';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class UserRolesComponent implements OnInit {
  displayedColumns: string[] = ['roleName', 'action'];
  dataSource: MatTableDataSource<IRole>;
  RoleName : string = "Role Name";
  noData :string = 'No data found';
  Itemsperpage : string = 'Items per page';
  Nextpage : string = 'Next page';
  Previouspage : string = 'Previous page';
  of : string = 'of';
  Confirmdelete : string  = "Confirm delete";
  Areyousuretodeleterole :  string  = "Are you sure to delete role?";
  Failed: string  = "Failed";
  Success :  string  = "Success";
  RoleDeletedSuccessfully :  string  = "Role Deleted Successfully";
  Deletefailed: string  = "Delete failed";
  RoleDeletionFailed :  string  = "Role Deletion Failed";
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    public dialog: MatDialog,
    public roleService: RoleService,
    private commonService: CommonService,
    public router: Router
  ) {
    // Create 100 users
    //  const bgroups = Array.from({ length: 10 }, (_, k) => createNewBGroup(k + 1));

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource<IRole>();
  }
  ngOnInit() {
    this.bindRole();
  }

  //bind Alert Rule Data to table
  bindRole() {
    this.roleService.getRoles(AppConstants.gblAppId).then((data) => {
      this.dataSource = new MatTableDataSource(data as IRole[]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const rangelabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${filterPipe.transform(this.of)} ${length}`;
      }
    
      length = Math.max(length, 0);
    
      const startIndex = page * pageSize;
    
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
    
      return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
    };

  
     this.dataSource.sort = this.sort;
     const filterPipe = new TranslationPipe();
     this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
     this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
     this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
     this.paginator._intl.getRangeLabel = rangelabel;
     this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteRole(roleId: number) {
    const filterPipe = new TranslationPipe();  
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: filterPipe.transform(this.Confirmdelete),
        text: filterPipe.transform(this.Areyousuretodeleterole),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const modifiedBy: number = AppConstants.gblLoggedInUserId;
        this.roleService
          .deleteRole(roleId, modifiedBy)
          .then((response) => {
            if(response!=null && response!=""){
              this.openMessageDialog(filterPipe.transform(this.Failed), filterPipe.transform(this.RoleDeletionFailed));       
            }
            else{
            this.openMessageDialog(filterPipe.transform(this.Success),filterPipe.transform(this.RoleDeletedSuccessfully));
            this.bindRole();
           }
            
          })
          .catch((error) => {
            this.commonService.openMessageDialog(
              filterPipe.transform(this.Deletefailed),
              filterPipe.transform(this.RoleDeletionFailed)
            );
          });
      }
    });
  }
  openMessageDialog(dialogTitle:string,dialogText:string){
    this.dialog.open(MsgDialogComponent, {
      data: {
        title: dialogTitle,
        text: dialogText,
      },
    });
  }
  openUserRoleAddDialog() {
    
    const dialogRef = this.dialog.open(UserRoleAddEditComponent, {
      width: '600px',
      data: null,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  //open a dialog on UserRoleAddEditComponent
  openUserRoleAddEditDialog(row: IRole) {
    
    const dialogRef = this.dialog.open(UserRoleAddEditComponent, {
      width: '600px',
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }
}

