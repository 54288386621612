<form [formGroup]="editModuleForm" (ngSubmit)="onSubmit()">

    <div mat-dialog-title class="editmodule-dialog-header">
      <mat-icon *ngIf="selectedModule.moduleId==0">add</mat-icon> &nbsp;&nbsp; <label *ngIf="selectedModule.moduleId==0">
        <span i18n="@@Add Module">Add Module</span> 
        </label>
      <mat-icon *ngIf="selectedModule.moduleId!=0">edit</mat-icon> &nbsp;&nbsp; <label *ngIf="selectedModule.moduleId!=0"> <span i18n="@@Edit Module">Edit Module</span> </label>
    </div>
    
    <mat-dialog-content class="mat-typography">
      
    
      <mat-card>   
          <input matInput  [hidden]="true" formControlName="moduleId" [(ngModel)]="selectedModule.moduleId"/>
         

         <mat-form-field >
          <mat-label i18n="@@Tenant">Tenant</mat-label>
          <mat-select  formControlName="tenant"  [(ngModel)]="selectedModule.tenantId">          
          <mat-option *ngFor="let tenant of tenants"  [value]="tenant.tenantId" >
              {{ tenant.tenantName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf=" editModuleForm.get('tenant')?.touched && editModuleForm.get('tenant')?.errors?.required">
            <span i18n="@@Required">Required</span>
          </mat-error>
        </mat-form-field>

          <mat-form-field>
            <mat-label i18n="@@Qlik Application Id">Qlik Application Id</mat-label>
            <input matInput maxlength="50" formControlName="qlikApplicationId" [(ngModel)]="selectedModule.qlikApplicationId"/>
            <mat-error *ngIf="editModuleForm.get('qlikApplicationId')?.touched && editModuleForm.get('qlikApplicationId')?.errors?.required">
              <span i18n="@@Required">Required</span>
            </mat-error>          
          </mat-form-field>

          <mat-form-field>
            <mat-label  i18n="@@Qlik Sheet Id">Qlik Sheet Id</mat-label>
            <input matInput maxlength="50" formControlName="qlikSheetId" [(ngModel)]="selectedModule.qlikSheetId"/>
            <mat-error *ngIf="editModuleForm.get('qlikSheetId')?.touched && editModuleForm.get('qlikSheetId')?.errors?.required">
              <span i18n="@@Required">Required</span>
            </mat-error>          
          </mat-form-field>

          <mat-form-field>
          <mat-label i18n="@@Description">Description</mat-label>
          <input matInput maxlength="200" formControlName="moduleDescription" [(ngModel)]="selectedModule.moduleDescription"/>
          <mat-error *ngIf="editModuleForm.get('moduleDescription')?.touched && editModuleForm.get('moduleDescription')?.errors?.required">
            <span i18n="@@Required">Required</span>
          </mat-error>          
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n="@@Module Name">Module Name</mat-label>
          <mat-select  formControlName="report"   [(ngModel)]="selectedModule.reportId" >
            <mat-option *ngFor="let report of reports" [value]="report.reportId" >
              {{ report.reportName | translation }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf=" editModuleForm.get('report')?.touched && editModuleForm.get('report')?.errors?.required">
            <span i18n="@@Required">Required</span>
          </mat-error>
        </mat-form-field>
        <mat-checkbox *ngIf="selectedModule.moduleId==0" formControlName="isInternal" [(ngModel)]="selectedModule.isInternal">
          <span i18n="@@isInternal">isInternal</span>
        </mat-checkbox><br><br>
      </mat-card>
    
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close> <span i18n="@@Cancel">Cancel</span></button>
      <button
        mat-flat-button
        color="primary"
        [mat-dialog-close]="true"
        cdkFocusInitial
        type="submit"
        [disabled]="!editModuleForm.valid"
      >
      <span i18n="@@Submit">Submit</span>
        
      </button>
    </mat-dialog-actions>
    </form>