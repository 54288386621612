import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { RoleService } from 'src/app/services/role.service';
import { IUser } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserListComponent } from 'src/app/pages/menu/user-list/user-list.component';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
interface IRole {
  roleId: number;
  roleName: string;
  roleGuid: string;
}

@Component({
  selector: 'app-invite-new-user',
  templateUrl: './invite-new-user.component.html',
  styleUrls: ['./invite-new-user.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class InviteNewUserComponent implements OnInit {
  roles: IRole[] = [];
  selectedUser: any;
  selectedRole: any;
  selectedClient:any;
  selectedClientName:any;
  objSelectedRole: any;
  SaveSuccess: string = 'Save Success';
  UserCreatedSuccessfully: string = 'User Created Successfully';
  Duplicate: string = 'Duplicate';
  DuplicateUser: string = 'Duplicate User';
  UpdateSuccess: string = 'Update Success';
  UserUpdatedSuccessfully: string = 'User Updated Successfully';
  Failed: string = 'Failed';
  globalerror: string = 'There seems to be an issue currently with the process, Please try after sometime. If the problem persists, Feel free to reach psi support for further assistance.';
  isReadOnly = false;
  selectedRoleId:number=0;
  selectedEmail:string="";
  inviteUserForm = new FormGroup({
    userId: new FormControl(0, [Validators.required]),
    role: new FormControl(0, [Validators.required]),
    firstName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*$')]),
    lastName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*$')]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,9}$')]),
    mobile: new FormControl('', [Validators.required, Validators.pattern('^(\\+\\d{1,3}( )?)?((\\(\\d{1,3}\\))|\\d{1,3})[- .]?\\d{3,4}[- .]?\\d{4}$')])

  });

  constructor(private roleService: RoleService, private userService: UserService, public dialog: MatDialog,
    private router: Router, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserListComponent>) {
      
    this.selectedUser = data;

  
    if (this.data != undefined && this.data.clientId != null) {
      this.selectedClient=this.data.clientId;
      this.selectedClientName=this.data.clientName;
      this.selectedUser=null;
    }
    else
    {
      this.selectedClientName=AppConstants.gblClientName;
      this.selectedClient=AppConstants.gblClientId;
    }
   
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.bindRoles();
    if (this.selectedUser != undefined && this.selectedUser != null) {
      this.isReadOnly = !this.isReadOnly;
      this.selectedRoleId=this.selectedUser.roleId;
      this.selectedEmail=this.selectedUser.email;
      this.inviteUserForm.controls.role.disable();
      this.inviteUserForm.controls.email.disable();
    }
    else {
      this.selectedUser = {
        userId: 0,
        firstName: '',
        lastName: '',
        email: '',
        mobile: ''
      };
      this.inviteUserForm.controls.role.enable();
      this.inviteUserForm.controls.email.enable();
    }

  }
  bindRoles() {
    this.roleService
      .getRoles(AppConstants.gblAppId)
      .then((data) => {

        this.roles = data as IRole[];
      })
      .catch((error) => {
        console.log('IU Promise rejected with ' + JSON.stringify(error));
      });
  }
  async onSubmit() {
    const filterPipe = new TranslationPipe();
    if (this.inviteUserForm.valid) {

      this.objSelectedRole = this.inviteUserForm.value.role;
      if(this.selectedUser.userId==0)
   {
        this.selectedRoleId=Number(this.inviteUserForm.value.role);
        this.selectedEmail= String(this.inviteUserForm.value.email );
      }
      else{

        this.selectedRoleId=Number(this.selectedUser.roleId);
        this.selectedEmail= String(this.selectedUser.email );
      }
      const userObj: IUser = {
        userId: Number(this.inviteUserForm.value.userId),
        firstName: this.inviteUserForm.value.firstName as string,
        lastName: this.inviteUserForm.value.lastName as string,
        fullName: this.inviteUserForm.value.lastName as string,
        roleId: this.selectedRoleId,
        email: this.selectedEmail,
        phoneno: String(this.inviteUserForm.value.mobile),
        createdBy: AppConstants.gblLoggedInUserId,
        modifiedBy: AppConstants.gblLoggedInUserId,
        regionId:  this.selectedClient,
        objectId: ""
      };
      console.log(JSON.stringify(userObj));


      if (userObj.userId == 0) {

        await this.userService
          .postUserData(userObj)
          .subscribe(
            (response) => {
              this.dialog.open(MsgDialogComponent, {
                data: {
                  title: filterPipe.transform(this.SaveSuccess),
                  text: filterPipe.transform(this.UserCreatedSuccessfully),
                },
              });

              this.dialogRef.close();

            },
            (error) => {

              if (error.status == 409) {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Duplicate),
                    text: filterPipe.transform(this.DuplicateUser),
                  },
                });
              }
              else {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Failed),
                    text: filterPipe.transform(this.globalerror),
                  },
                });
              }
            }
          )


      } else if (userObj.userId > 0) {
        await this.userService
          .putUserData(userObj.userId, userObj)
          .subscribe((data) => {

            this.dialog.open(MsgDialogComponent, {
              data: {
                title: filterPipe.transform(this.UpdateSuccess),
                text: filterPipe.transform(this.UserUpdatedSuccessfully),
              },
            });
            this.router.navigateByUrl('/menu/user-list');

          },
            (error) => {
              if (error.status == 409) {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Duplicate),
                    text: filterPipe.transform(this.DuplicateUser),
                  },
                });
              }
              else {
                this.dialog.open(MsgDialogComponent, {
                  data: {
                    title: filterPipe.transform(this.Failed),
                    text: filterPipe.transform(this.globalerror),
                  },
                });
              }
            });
      }
    }
  }
}


