import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AppConstants } from 'src/app/AppConstants';
import { Feedback } from 'src/app/models/feedback.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private httpService: HttpService) {}

  getFeedbackCategories() {
    return this.httpService.get(AppConstants.apiUrl+"Feedback/GetFeedbackCategory");
  }
  getFeedback() {
    return this.httpService.get(AppConstants.apiUrl+"Feedback/GetFeedback?UserId=" + AppConstants.gblLoggedInUserId + "&ClientId=" + AppConstants.gblClientId);
  }

  postFeedback(feedback:Feedback): Observable<any>{
      return this.httpService.post(AppConstants.apiUrl+"Feedback/AddFeedback", JSON.stringify(feedback)) ;   
    
  }
}
