<form [formGroup]="inviteUserForm" (ngSubmit)="onSubmit()">

<div mat-dialog-title class="inviteuser-dialog-header color-green">
  <mat-icon>person_add</mat-icon> &nbsp;&nbsp;<label *ngIf="selectedUser.userId==0">
    <span i18n="@@Invite New User">Invite New User</span><span> : {{selectedClientName}}</span>
    </label>
  <label *ngIf="selectedUser.userId!=0">
    <span i18n="@@Edit User">Edit User</span>
    </label>
</div>
 
<mat-dialog-content class="mat-typography">
  <mat-divider></mat-divider>
  <br />

  <mat-card>
    <div class="row">
      <input matInput  hidden="true" formControlName="userId" [(ngModel)]="selectedUser.userId"/>
<div class="col-5 nameSpacing">
      <mat-form-field>
        <mat-label  i18n="@@First Name">First Name</mat-label>
        <input matInput type="text" formControlName="firstName" [(ngModel)]="selectedUser.firstName"/>
        <mat-error *ngIf=" inviteUserForm.get('firstName')?.touched && inviteUserForm.get('firstName')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>
    </div>
<div class="col-5">

      <mat-form-field >
        <mat-label i18n="@@Last Name">Last Name</mat-label>
        <input matInput type="text" formControlName="lastName" [(ngModel)]="selectedUser.lastName"/>
        <mat-error *ngIf=" inviteUserForm.get('lastName')?.touched && inviteUserForm.get('lastName')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>
    </div>
</div>
    <mat-form-field>
      <mat-label i18n="@@EMAIL">Email</mat-label>
      <input matInput type="email" formControlName="email" [(ngModel)]="selectedUser.email" email  [readonly]="isReadOnly"/>
      <mat-error *ngIf=" inviteUserForm.get('email')?.touched && inviteUserForm.get('email')?.errors?.required">
        <span i18n="@@Required">Required</span>
      </mat-error>
      <mat-error *ngIf="inviteUserForm.get('email')?.errors?.email">
        <span i18n="@@Email must be a valid email address">Email must be a valid email address</span>
      </mat-error>
      
    </mat-form-field>

    <mat-form-field>
      <mat-label i18n="@@Mobile">Mobile</mat-label>
      <input matInput formControlName="mobile" [(ngModel)]="selectedUser.phoneno"/>
      <mat-error *ngIf=" inviteUserForm.get('mobile')?.touched && inviteUserForm.get('mobile')?.errors?.required">
        <span i18n="@@Required">Required</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label  i18n="@@USER ROLE">User Role</mat-label>
      <mat-select  formControlName="role"   [(ngModel)]="selectedUser.roleId"  [disabled]="isReadOnly" >
        <mat-option *ngFor="let role of roles" [value]="role.roleId" >
          {{ role.roleName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf=" inviteUserForm.get('role')?.touched && inviteUserForm.get('role')?.errors?.required">
        <span i18n="@@Required">Required</span>
      </mat-error>
    </mat-form-field>
  </mat-card>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>
    <span i18n="@@Cancel">Cancel</span>
    
    </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    type="submit"
    [disabled]="!inviteUserForm.valid"
  >
  <span i18n="@@Submit">Submit</span>

  </button>
</mat-dialog-actions>
</form>
