import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { FavoriteModule } from '../models/favorite-module';
import { AppConstants } from 'src/app/AppConstants';


@Injectable({
  providedIn: 'root'
})
export class FavoriteModuleService {

  
  constructor(private httpService: HttpService) {}

  getFavoriteModuleList(clientId:number) {
    return this.httpService.get(AppConstants.apiUrl+"FavoriteModule/GetFavoriteModuleList?ClientId="+clientId);
  }
  
   createFavoriteModule(favModuleObj:FavoriteModule): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"FavoriteModule/AddFavoriteModule", JSON.stringify(favModuleObj))  ; 
  }

  deleteFavoriteModule(favoriteModuleId:number){  
    return this.httpService.delete(AppConstants.apiUrl+"FavoriteModule/DeleteFavoriteModule?FavoriteModuleId="+favoriteModuleId)  ;    
  }

  putFavoriteModule(favoriteModuleId:number,favModuleObj:FavoriteModule): Observable<any>{  
    return this.httpService.put(AppConstants.apiUrl+"FavoriteModule/UpdateFavoriteModule?FavoriteModuleId="+0, JSON.stringify(favModuleObj)) ;   
  }

  getFavoriteModuleByClient(applicatioId:number,clientId:number,userId : number){
    return this.httpService.get(AppConstants.apiUrl+"FavoriteModule/GetFavoriteModuleByClient?ApplicationId="+applicatioId+"&ClientId="+clientId+"&UserId="+userId);

  }
}
