  
  <mat-toolbar class="pair-new-device-toolbar">
    &nbsp;&nbsp;
    <span i18n="@@Modules">Modules</span>
    <span class="flex-spacer"></span>
    <button mat-stroked-button (click)="RefreshList()">
      <span  i18n="@@Refreshmodulelist">Refresh Module List</span>
    </button>&nbsp; &nbsp;
    <button mat-stroked-button (click)="openModuleAddDialog()" *ngIf="showInternalUser">
      <mat-icon>add</mat-icon>
      <span  i18n="@@Add New">Add New</span>
    </button>  
  </mat-toolbar>
  
  <div class="table-container">
    <!--Filter-->
    <mat-form-field class="filter-field">
      <mat-label i18n="@@filter">Filter</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder={{placeholder|translation}} 
        #input
      />
    </mat-form-field>
  
    <!--Data Table-->
    <table
      mat-table
      matSort id="moduleTable"
      [dataSource]="dataSource"
      multiTemplateDataRows
      class="mat-elevation-z8"
    >
    <caption [hidden]="true"> Description </caption>
      <!-- Module Column -->
      <ng-container matColumnDef="moduleName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Module">Module</th>
        <td mat-cell *matCellDef="let row">{{ row.moduleName | translation}}</td>
      </ng-container>
      <ng-container matColumnDef="tenantName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  i18n="@@Tenant">Tenant</th>
        <td mat-cell *matCellDef="let row">{{ row.tenantName }}</td>
      </ng-container>
    
    <ng-container matColumnDef="qlikApplicationId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Qlik Application Id">Qlik Application Id</th>
      <td mat-cell *matCellDef="let row">{{ row.qlikApplicationId }}</td>
    </ng-container>

    <ng-container matColumnDef="qlikSheetId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Qlik Sheet Id">Qlik Sheet Id</th>
      <td mat-cell *matCellDef="let row">{{ row.qlikSheetId }}</td>
    </ng-container>
    <ng-container matColumnDef="isInternal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@isInternal ">isInternal</th>
      <td mat-cell *matCellDef="let row">{{ row.isInternal  }}</td>
    </ng-container>
      <!-- ACTIONS Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div class="text-right">
            <button
              mat-flat-button
              (click)="$event.stopPropagation(); openModuleEditDialog(row)"
              class="edit-btn" 
              *ngIf="showInternalUser"
            >
              <mat-icon>edit</mat-icon>
              <span i18n="@@Edit">Edit</span>
            </button>
            <button
              mat-flat-button
              color="warn"
              (click)="
                $event.stopPropagation(); deleteModule(row.moduleId)"
                *ngIf="showInternalUser"
            >
              <mat-icon>delete</mat-icon>
              <span i18n="@@Delete">Delete</span> 
            </button>
          </div>
        </td>
      </ng-container>
  
      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="expand-container">
              <mat-card class="example-card">
                <mat-card-content>
                  <div
                    class="grid-warpper"
                    [ngClass]="isEdit ? 'grid-2-col' : 'grid-1-col'"
                  >
                    <div>
                      <div class="flex-space-between">
                        <span class="header-text color-voilet"
                          >{{MappedBusinessGroups|translation}} ({{mappedBG_Master.length>0?mappedBG_Master.length :0}})</span
                        >
                        <button
                          mat-icon-button
                          color="accent"
                          class="edit-class"
                          *ngIf="!isEdit"
                          (click)="isEdit = true"
                        >
                          <mat-icon>edit</mat-icon>
                        </button>
                      </div>
                      <!--Filter-->
                      <div class="margin-x" >
                        <mat-form-field *ngIf="mappedBG_Master.length>0">
                          <mat-label i18n="@@filter">Filter</mat-label>
                          <input matInput placeholder={{BusinessGroup|translation}} [(ngModel)]="bgString" (keyup)="applyMappedFilter($event)" />
                        </mat-form-field>
                      </div>
                      <div class="flex-space-between">
                        <span></span>
                        <button *ngIf="isEdit &&  mappedBG.length>0" class="reverse" mat-raised-button (click)="deleteAllBGModuleMapping(selectedModuleId)">
                          <span i18n="@@Remove All">Remove All</span>   &nbsp;
                          <mat-icon color="warn">delete</mat-icon>
                        </button>
                      </div>
  
                      <mat-list class="group-list">
                        <mat-list-item *ngFor="let item of mappedBG">
                          <mat-icon matListItemIcon class="color-voilet"
                            >work</mat-icon
                          >
                          <div  matListItemLine class="color-voilet">{{ item.businessGroupName }}</div>
                          <button *ngIf="isEdit" class="color-warn "  matListItemMeta (click)="deleteSingleBGModuleMapping(item.moduleBusinessGroupId)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </mat-list-item>
                        <div class="no-search-results" *ngIf=" isEdit && mappedBG.length==0">
                          {{noData|translation}} 
                       </div>
                       <div class="no-search-results" *ngIf="!isEdit && mappedBG.length==0">
                        {{noData|translation}} 
                     </div>
                      </mat-list>
  
                      
                    </div>
                    <div *ngIf="isEdit" [@fadeInAnimation]>
                      <div class="flex-space-between">
                        <span class="header-text color-blue">{{AllBusinessGroups|translation}}({{unMappedBG_Master.length}})</span>
  
                        <button
                          mat-icon-button
                          class="check-class"
                          (click)="isEdit = false;clearFilter();"
                        >
                          <mat-icon>check</mat-icon>
                        </button>
                      </div>
                      
                          <!--Filter-->
                          <div class="margin-x ">
                            <mat-form-field *ngIf="unMappedBG_Master.length>0">
                              <mat-label  i18n="@@filter">Filter</mat-label>
                              <input matInput placeholder={{BusinessGroup|translation}} [(ngModel)]="allBgString"  (keyup)="applyUnMappedFilter($event)" />
                            </mat-form-field>
                          </div>
                          <div class="flex-space-between">
                            <span></span>
                            <button class="reverse" mat-raised-button  (click)="mapAllBG(selectedModuleId)" *ngIf="unMappedBG.length>0">
                              <span i18n="@@Add All">Add All</span>   &nbsp;
                              <mat-icon class="color-green">add_circle</mat-icon>
                            </button>
                          </div>
                          <mat-list class="module-list">
                            <mat-list-item
                              *ngFor="let item of unMappedBG"
                            >
                              <mat-icon matListItemIcon class="color-blue"
                                >work</mat-icon
                              >
                              <div matListItemLine class="color-blue">{{ item.businessGroupName }}</div>
                              <button matListItemMeta  class="color-green"  (click)="mapSingleBG(selectedModuleId,item.businessGroupId);">
                                <mat-icon>add_circle</mat-icon>
                              </button>
                            </mat-list-item>
                            <div class="no-search-results" *ngIf=" isEdit && unMappedBG.length==0">
                              {{noData|translation}} 
                           </div>
                           <div class="no-search-results" *ngIf="!isEdit && unMappedBG.length==0">
                            {{noData|translation}}
                         </div>
                          </mat-list>
                      
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element;bindDetails(expandedElement,element.moduleId,element.clientId);"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          {{noData|translation}} {{ input.value }}
        </td>
      </tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
  
  