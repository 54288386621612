import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface PairedDevice {
  tripId: string;
  serviceLevel: string;
  deviceId: string;
  carrier: string;
  trackingNumber: string;
  ssccId: string;
  lastUpdate: string;
  tripStage: string;
  eta: string;
}

@Component({
  selector: 'app-paired-devices',
  templateUrl: './paired-devices.component.html',
  styleUrls: ['./paired-devices.component.scss'],
})
export class PairedDevicesComponent implements OnInit {
  displayedColumns: string[] = [
    'tripId',
    'serviceLevel',
    'deviceId',
    'carrier',
    'trackingNumber',
    'ssccId',
    'lastUpdate',
    'tripStage',
    'eta',
  ];
  dataSource: MatTableDataSource<PairedDevice>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public dialog: MatDialog) {
    // Create 100 users
    //const users = Array.from({ length: 100 }, (_, k) => createNewDevice(k + 1));

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource<PairedDevice>();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

const TRIP_STAGES: string[] = [
  'Not Started',
  'Pending Departure',
  'In Transit',
  'Pending Arrival',
  'Completed',
];


