<form [formGroup]="notificationClientForm" (ngSubmit)="onSubmit()">
<div mat-dialog-title class="editnotifcation-dialog-header">
  <mat-icon >add</mat-icon> &nbsp;&nbsp;<label>
    <span i18n="addNotificationToClients">Add Notification to Clients</span> 
  </label>
  
</div>

<mat-dialog-content class="mat-typography">
  <mat-divider></mat-divider>

  <mat-card class="mapNotificationClient">
    <mat-form-field>
      <mat-label i18n="notificationTitle">Notification title</mat-label>
      <mat-select formControlName="notification" (selectionChange)="onNotificationChange($event)">
        <mat-option *ngFor="let notification of notifications" [value]="notification.notificationId">
          {{ notification.notificationTitle }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf=" notificationClientForm.get('notification')?.touched && notificationClientForm.get('notification')?.errors?.required">
        <span i18n="@@Required">Required</span>
        </mat-error>
    </mat-form-field>


    <ng-multiselect-dropdown
        [placeholder]= selectClients|translation
        [data]="unMappedClient" 
        [settings]="clientDropdownSettings"
        formControlName="clients" 
        (onSelect)="onClientSelect($event)" 
        (onDeSelect)="onClientSelect($event)" 
        (onSelectAll)="onSelectAllClients($event)"      
        (onDeSelectAll)="onSelectAllClients($event)"     
        required>
    </ng-multiselect-dropdown>
  </mat-card>
  <br />
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close><span i18n="@@Cancel">Cancel</span>

  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    type="submit"
    [disabled]="!notificationClientForm.valid"
  >
  <span i18n="@@Submit">Submit</span>
  </button>
</mat-dialog-actions>
</form>