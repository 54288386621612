<form [formGroup]="replicateAlerconfigurationForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-title class="edituser-dialog-header color-green">
    <mat-icon >add</mat-icon> &nbsp;&nbsp; <label> 
      <span i18n="@@replicatealerts">Replicate Alerts</span><span> : {{clientName}}</span>  
    </label> 
  </div>
  
  <mat-dialog-content class="mat-typography">
    <mat-divider></mat-divider>
  
    <mat-card>
      <mat-form-field>
        <mat-label i18n="@@devicetoname">Device To Name</mat-label>
        <mat-select  formControlName="devicetoID"  (selectionChange)="onDeviceToChange($event)" >
          <mat-option *ngFor="let devicename of toDevicenames" [value]="devicename.deviceID" >
            {{ devicename.deviceName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf=" replicateAlerconfigurationForm.get('devicetoID')?.touched && replicateAlerconfigurationForm.get('devicetoID')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@devicefromname">Device From Name</mat-label>
        <mat-select  formControlName="devicefromID" (selectionChange)="onDeviceFromChange($event)" >
          <mat-option *ngFor="let devicename of fromDevicenames" [value]="devicename.deviceID" >
            {{ devicename.deviceName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf=" replicateAlerconfigurationForm.get('devicefromID')?.touched && replicateAlerconfigurationForm.get('devicefromID')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@alertdescription">Alert Description</mat-label>
        <mat-select  formControlName="alerts"   multiple>
          <mat-option *ngFor="let alertsdefination of alertsdefinations" [value]="alertsdefination.alertID" >
            {{ alertsdefination.alertDescription }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf=" replicateAlerconfigurationForm.get('alerts')?.touched && replicateAlerconfigurationForm.get('alerts')?.errors?.required">
          <span i18n="@@Required">Required</span>
        </mat-error>
      </mat-form-field>

      
    </mat-card>
    <br />
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
      <span i18n="@@Cancel">Cancel</span>
    </button>
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
      type="submit"
      [disabled]="!replicateAlerconfigurationForm.valid"
    >
    <span i18n="@@Submit">Submit</span>
    </button>
  </mat-dialog-actions>
  </form>
