<mat-toolbar class="pair-new-device-toolbar">
  &nbsp;&nbsp;
  <span i18n="@@Business Groups">Business Groups</span>
  <span class="flex-spacer"></span>
</mat-toolbar>


<div class="table-container">
  <mat-form-field class="filter-field col-auto" *ngIf="showInternalUser">
    <mat-label i18n="@@Clients">Clients</mat-label>
    <mat-select class="selectClient" id="selectClient" disableOptionCentering [disabled]="custs.length == 1" [(ngModel)]="selectedClientValue"
      placeholder={{SelectClient|translation}} #custSelect (selectionChange)="onClientChange($event)">
      <ngx-mat-select-search id="search" class="search-color" [(ngModel)]="searchTxt" matInput  noEntriesFoundLabel={{Nodataavailable|translation}} placeholderLabel={{Search|translation}} autocomplete="off" >
      </ngx-mat-select-search>
      <mat-option *ngFor="let item of custs  | clientSearch : searchTxt" [value]="item.clientId">
        {{ item.clientName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  &nbsp;&nbsp;
  <mat-form-field class="filter-field">
    <mat-label i18n="@@filter">Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filterName" placeholder="{{BusinessGroup | translation}}"
      #input />
  </mat-form-field>
  &nbsp;&nbsp;
  <div class="right">
    <button mat-stroked-button (click)="RefreshList()">
      <span i18n="@@RefreshBGlist">Refresh Business Group List</span>
    </button>&nbsp; &nbsp;
    <button mat-stroked-button (click)="openBGAddDialog()">
      <mat-icon>add</mat-icon>
      <span i18n="@@Create Business Group">Create Business Group</span>
    </button>
  </div>
</div>

<div class="table-container">


  <!--Data Table-->
  <table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
    <caption [hidden]="true"> Description </caption>
    <!-- NAME Column -->
    <ng-container matColumnDef="businessGroupName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@NAME">NAME</th>
      <td mat-cell *matCellDef="let row">{{ row.businessGroupName }}</td>
    </ng-container>

    <!-- ACCOUNTS Column -->
    <ng-container matColumnDef="accountCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@ACCOUNTS Count">ACCOUNTS Count</th>
      <td mat-cell *matCellDef="let row">{{ row.accountCount }}</td>
    </ng-container>

    <!-- USERS Column -->
    <ng-container matColumnDef="userCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@USERS Count">USERS Count</th>
      <td mat-cell *matCellDef="let row">{{ row.userCount }}</td>
    </ng-container>

    <!-- Is Internal Column -->
    <ng-container matColumnDef="isInternal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@isInternal ">isinternal</th>
      <td mat-cell *matCellDef="let row">{{ row.isInternal }}</td>
    </ng-container>

    <!-- ACTIONS Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div class="text-right">
          <button mat-flat-button (click)="$event.stopPropagation(); openBGEditDialog(row)" class="edit-btn"
            [hidden]="isActionDisabled(row)">
            <mat-icon>edit</mat-icon>
            <span i18n="@@Edit">Edit</span>
          </button>
          <button mat-flat-button color="warn" (click)="
                $event.stopPropagation(); deleteBG(row.businessGroupId)" [hidden]="isActionDisabled(row)">
            <mat-icon>delete</mat-icon>
            <span i18n="@@Delete">Delete</span>
          </button>
        </div>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail" [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            " [ngClass]="{'disabled': isActionDisabled(element)}">
          <div class="expand-container">
            <mat-card class="example-card">
              <mat-card-content>
                <div class="grid-warpper" [ngClass]="isEdit ? 'grid-2-col' : 'grid-1-col'">
                  <div>
                    <div class="flex-space-between">
                      <span class="header-text color-voilet">{{MappedAccounts|translation}}
                        ({{mappedAccountByBG_Master.length>0?mappedAccountByBG_Master.length :0}})</span>
                      <button mat-icon-button color="accent" class="class-124" *ngIf="!isEdit" (click)="isEdit = true">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </div>
                    <!--Filter-->
                    <div class="margin-x" [class.margin-top-to-match]="isEdit">
                      <mat-form-field *ngIf="mappedAccountByBG_Master.length>0">
                        <mat-label i18n="@@filter">Filter</mat-label>
                        <input matInput placeholder="{{AccountNumber|translation}}" [(ngModel)]="accString"
                          (keyup)="applyMappedFilter($event)" />
                      </mat-form-field>
                    </div>
                    <div class="class-141">

                      <button *ngIf="isEdit &&  mappedAccountByBG.length>0" class="reverse" mat-raised-button
                        (click)="deleteAllBGAccMapping(selectedBusinessGroupId)">
                        <span i18n="@@Remove All">Remove All</span>
                        <mat-icon color="warn" class="right">delete</mat-icon>
                      </button>&nbsp;
                      <button mat-raised-button class="reverse" (click)="openAddSelective(selectedBusinessGroupId,2)"
                        *ngIf="isEdit && AddAllRemoveAllFlag &&  mappedAccountByBG.length>0">
                        <span i18n="@@Remove Selected">Remove Selected</span>
                        &nbsp; <mat-icon color="warn">delete</mat-icon> </button>

                    </div>

                    <mat-list class="group-list">
                      <mat-list-item *ngFor="let item of mappedAccountByBG">
                        <mat-icon matListItemIcon class="color-voilet">work</mat-icon>
                        <div mat-line matListItemLine class="color-voilet">{{ item.accountNumber }}</div>
                        <button *ngIf="isEdit" class="color-warn " matListItemMeta color="warn"
                          (click)="deleteSingleBGAccMapping(item.accountNumberBusinessGroupId)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </mat-list-item>
                      <div class="no-search-results" *ngIf=" isEdit && mappedAccountByBG.length==0">
                        {{noData|translation}}
                      </div>
                      <div class="no-search-results" *ngIf="!isEdit && mappedAccountByBG.length==0">
                        {{noData|translation}}
                      </div>
                    </mat-list>


                  </div>
                  <div *ngIf="isEdit" [@fadeInAnimation]>
                    <div class="flex-space-between">
                      <span
                        class="header-text color-blue">{{AllAccounts|translation}}({{unMappedAccountByBG_Master.length}})</span>

                      <button mat-icon-button class="class-180" (click)="isEdit = false;clearFilter();">
                        <mat-icon>check</mat-icon>
                      </button>
                    </div>
                    <mat-tab-group mat-align-tabs="start">
                      <mat-tab label="{{AllAccounts|translation}}">
                        <!--Filter-->
                        <div class="margin-x mt-md">
                          <mat-form-field *ngIf="unMappedAccountByBG_Master.length>0">
                            <mat-label i18n="@@filter">Filter</mat-label>
                            <input matInput placeholder="{{AccountNumber|translation}}" [(ngModel)]="allAccString"
                              (keyup)="applyUnMappedFilter($event)" />
                          </mat-form-field>
                        </div>
                        <div class="class-194">

                          <button mat-raised-button class="reverse" (click)="mapAllAcc(selectedBusinessGroupId)"
                            *ngIf="unMappedAccountByBG.length>0">
                            <span i18n="@@Add All">Add All</span>
                            <mat-icon class="color-green">add_circle</mat-icon>
                          </button>&nbsp;
                          <button mat-raised-button class="reverse"
                            (click)="openAddSelective(selectedBusinessGroupId,1)"
                            *ngIf="AddAllRemoveAllFlag && unMappedAccountByBG.length>0">
                            <span i18n="@@Add Selected">Add Selected</span>
                            &nbsp; <mat-icon class="color-green">add_circle</mat-icon> </button>

                        </div>
                        <mat-list class="group-list">
                          <mat-list-item *ngFor="let item of unMappedAccountByBG">
                            <mat-icon matListItemIcon class="color-blue">work</mat-icon>
                            <div matListItemLine class="color-blue">{{ item.accountNumber }}</div>
                            <button matListItemMeta class="color-green"
                              (click)="mapSingleAcc(selectedBusinessGroupId,item.accountNumberId);">
                              <mat-icon>add_circle</mat-icon>
                            </button>
                          </mat-list-item>
                          <div class="no-search-results" *ngIf="isEdit && unMappedAccountByBG.length==0">
                            {{noData|translation}}
                          </div>
                          <div class="no-search-results" *ngIf="!isEdit && unMappedAccountByBG.length==0">
                            {{noData|translation}}
                          </div>
                        </mat-list>
                      </mat-tab>
                      <mat-tab label="{{Import|translation}}">
                        <br />
                        <br />
                        <div id="upload_button">

                          <label>
                            <input color="primary" type="file" #csvReader (change)="uploadListener($event)" required>
                            <span class="btn btn-primary" i18n="@@Upload files">Upload files</span>
                            &nbsp;&nbsp;
                            <span *ngIf="fileName">{{fileName}}</span>
                            <p *ngIf="fileUploadInputAccDataErrorMessage" class="my-0 py-0 disabled text-danger"
                              class="class-240">Input file contains special character's, Please remove special
                              character's</p>
                          </label>
                        </div>

                        <button mat-raised-button color="primary" class="right" (click)="submitCSVData();">
                          <span i18n="@@Import from CSV">Import from CSV</span> &nbsp;
                          <mat-icon>download</mat-icon>
                        </button>




                        <mat-list class="group-list">
                          <mat-list-item *ngFor="let item of element.accountList">
                            <mat-icon mat-list-icon class="color-blue">work</mat-icon>
                            <div mat-line class="color-blue">{{ item }}</div>
                            <button mat-icon-button class="color-green">
                              <mat-icon>add_circle</mat-icon>
                            </button>
                          </mat-list-item>
                        </mat-list>
                      </mat-tab>
                    </mat-tab-group>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element;bindDetails(expandedElement,element.businessGroupId,element.businessGroupName);">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        {{noData|translation}} {{ input.value }}
      </td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>