import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddeditCvascreenComponent } from 'src/app/components/addedit-cvascreen/addedit-cvascreen.component';

import { IScreen } from 'src/app/models/screen.model';
import { ScreenService } from 'src/app/services/screen.service';
import { MsgDialogComponent } from 'src/app/components/msg-dialog/msg-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/AppConstants';
import { TranslationPipe } from 'src/app/locale/translation.pipe';
export interface CVAScreen {
  id: string;
  screen: string;
  tab: string;
}

/** Constants used to fill up our data base. */
const SCREENS: string[] = [
  'Manifest Module (Visibility)',
  'Create/Edit Welcome Messages',
  'Create/Edit Alert Messages',
  'Submit Feedback',
  'Create/Edit User Roles',
];
const TABS: string[] = [
  'Modules',
  'Admin',
  'Admin',
  'Help',
  'Modules',
];

@Component({
  selector: 'app-cva-screens',
  templateUrl: './cva-screens.component.html',
  styleUrls: ['./cva-screens.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CvaScreensComponent implements OnInit {
  displayedColumns: string[] = ['screenName', 'routerLink','sequenceId', 'action'];
  dataSource: MatTableDataSource<IScreen>;
  noData :string = 'No data found';
  Itemsperpage : string = 'Items per page';
  Nextpage : string = 'Next page';
  Previouspage : string = 'Previous page';
  of : string = 'of';
  Confirmdelete:  string = "Confirm delete";
  AreyousuretodeleteScreen :  string  = "Are you sure to delete Screen?";
  Deletefailed:  string  = "Delete failed";
  ScreenDeletionFailed :  string  = "Screen Deletion Failed";
  ScreenNameRouterLinket : string = "Screen Name,Router Link,etc."
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public dialog: MatDialog,
    public screenService: ScreenService,
    private commonService: CommonService,
    public router: Router
  ) {
    // Create 100 users
    //  const bgroups = Array.from({ length: 10 }, (_, k) => createNewBGroup(k + 1));

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource<IScreen>();
  }

  ngOnInit() {
    this.bindScreen();
  }
  //bind Alert Rule Data to table
  bindScreen() {
    this.screenService.getScreensByApplication(AppConstants.gblscreenGroup,AppConstants.gblModuleId, AppConstants.gblLoggedInUserId).then((data) => {
      
      this.dataSource = new MatTableDataSource(data as IScreen[]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const rangelabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${filterPipe.transform(this.of)} ${length}`;
      }
    
      length = Math.max(length, 0);
    
      const startIndex = page * pageSize;
    
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
    
      return `${startIndex + 1} - ${endIndex} ${filterPipe.transform(this.of)} ${length}`;
    };


     this.dataSource.sort = this.sort;
     const filterPipe = new TranslationPipe();
     this.paginator._intl.itemsPerPageLabel = filterPipe.transform(this.Itemsperpage);
     this.paginator._intl.previousPageLabel = filterPipe.transform(this.Previouspage);
     this.paginator._intl.nextPageLabel = filterPipe.transform(this.Nextpage);
     this.paginator._intl.getRangeLabel = rangelabel;
     this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteScreens(screenId: number) {
    const filterPipe = new TranslationPipe();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: filterPipe.transform(this.Confirmdelete),
        text: filterPipe.transform(this.AreyousuretodeleteScreen),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const modifiedBy: number = AppConstants.gblLoggedInUserId;
        this.screenService
        .deleteScreens(screenId, modifiedBy)
          .then((data) => {
            this.bindScreen();
          })
          .catch((error) => {
            this.commonService.openMessageDialog(
              filterPipe.transform(this.Deletefailed),
              filterPipe.transform(this.ScreenDeletionFailed)
            );
          });
      }
    });
  }

  addAddCVAscreenDialog() {
    const dialogRef = this.dialog.open(AddeditCvascreenComponent, {
      width: '600px',
      data: null,
      
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  addAddEditCVAscreenDialog(row: IScreen) {
    const dialogRef = this.dialog.open(AddeditCvascreenComponent, {
      width: '600px',
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  
}

