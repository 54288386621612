import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-escalationguide-dialog',
  templateUrl: './escalationguide-dialog.component.html',
  styleUrls: ['./escalationguide-dialog.component.scss']
})
export class EscalationguideDialogComponent implements OnInit {
path : string = '';
  constructor() { }

  ngOnInit(): void {
    const userLocale = localStorage.getItem('locale');
    this.path = "../assets/images/TrackingNumberGuide_"+userLocale+".png";
  }

}
