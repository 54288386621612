<form [formGroup]="bgAccMapForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-title class="color-green" *ngIf="flag==1">
      <mat-icon>add</mat-icon> &nbsp;&nbsp; 
      <span i18n="@@BusinessGroup Account Nos Mapping">BusinessGroup Account Nos Mapping</span>
    </div>
    
    <div mat-dialog-title class="color-green" *ngIf="flag==2">
      <mat-icon>delete</mat-icon> &nbsp;&nbsp; 
      <span i18n="@@BusinessGroup Account Nos Mapping">BusinessGroup Account Nos Mapping</span>
    </div>
    
    <div>
    <mat-dialog-content  class="class-15">
      <mat-divider></mat-divider>
    
      <mat-card  class="class-15">
       
        <mat-form-field>
            <mat-label i18n="@@Business Group">Business Group</mat-label>
            <input matInput formControlName="bgName" [(ngModel)]="businessGroupName" readonly/>           
          </mat-form-field>
          <mat-error *ngIf=" bgAccMapForm.get('bgName')?.touched && bgAccMapForm.get('bgName')?.errors?.required">
            <span i18n="@@Required">Required</span>
          </mat-error>
    
    
    
    <ng-multiselect-dropdown *ngIf="flag==1"
        [placeholder]= SelectAccountNos|translation
        [settings]="accDropdownSettings"
        [data]="unMappedAccountByBG"  
        formControlName="accItems"
        (onSelect)="onAccSelect($event)" 
        (onDeSelect)="onAccSelect($event)" 
        (onSelectAll)="onSelectAllAcc($event)"      
        (onDeSelectAll)="onSelectAllAcc($event)"     
    
        required>
    </ng-multiselect-dropdown>   
    <ng-multiselect-dropdown *ngIf="flag==2"
    [placeholder]=SelectAccountNos|translation
    [settings]="accDropdownSettings"
    [data]="mappedAccountByBG"  
    formControlName="accItems"
    (onSelect)="onAccSelect($event)" 
    (onDeSelect)="onAccSelect($event)" 
    (onSelectAll)="onSelectAllAcc($event)"   
    (onDeSelectAll)="onSelectAllAcc($event)"      


    required>
</ng-multiselect-dropdown>  
<mat-error *ngIf=" bgAccMapForm.get('accItems')?.touched && bgAccMapForm.get('accItems')?.errors?.required">
  <span i18n="@@Required">Required</span>
</mat-error>
      </mat-card>
      <br />
    </mat-dialog-content>
  </div>
    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close>
        <span i18n="@@Cancel">Cancel</span>
      </button>
      <button
        mat-flat-button
        color="primary"
        [mat-dialog-close]="true"
        cdkFocusInitial
        type="submit"
        [disabled]="!bgAccMapForm.valid"
      >
      <span i18n="@@Submit">Submit</span>
      </button>
    </mat-dialog-actions>
    </form>