import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AppConstants } from 'src/app/AppConstants';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface IApplication {
  applicationId: number;
  applicationName: string;
}

@Component({
  selector: 'app-client-dialog',
  templateUrl: './client-dialog.component.html',
  styleUrls: ['./client-dialog.component.scss'],
})
export class ClientDialogComponent implements OnInit {

  custs: any[] = [];
  custsMaster:any[]=[];
  apps: IApplication[] = [];
  selectedAppValue: number | null = null;
  selectedClientValue: number | null = null;
  selectedModuleValue: number | null = null;
  searchTxt: any;
  selectedClientId:number| null = null;
  clientForm=new FormGroup({   
      client: new FormControl(null, [Validators.required])
  });

  constructor(private router: Router,private dataService: DataService,public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data:any) { 
      if(data){     
        this.dialogRef.close(data);
       }
    }

  ngOnInit(): void {
    this.bindApplications();
  }

  async bindApplications() {
    this.apps = await this.dataService.getApplicationByUser(AppConstants.gblLoggedInUserId) as IApplication[];   
    this.apps=this.apps.filter(x=>x.applicationName=="CVA")
    //console.log(this.apps);
    if (this.apps.length == 1) {
      this.onAppChange();
    }
  }

  onAppChange() {
    this.router.navigateByUrl('/menu/homescreen');
    AppConstants.glbScreenId = 0;

    try {
      if (this.apps.length == 1) {
        this.selectedAppValue = this.apps[0].applicationId;
      }
      AppConstants.gblAppId = Number(this.selectedAppValue);
      localStorage.setItem('applicationId', String(this.selectedAppValue));


      AppConstants.gblClientId = Number(null);
      localStorage.setItem('clientId', "");
      this.selectedClientValue = Number(localStorage.getItem('clientId'));

      AppConstants.gblModuleId = Number(null);
      localStorage.setItem('moduleId', "");
      this.selectedModuleValue = Number(localStorage.getItem('moduleId'));

      this.bindClients();
   

    } catch (error) {
      console.log(error);
    }
  }

  async bindClients() {
    this.custs = [];

    const clients = <any[]>await this.dataService.getClients(AppConstants.gblAppId, AppConstants.gblLoggedInUserId);

   
    if (clients != null) {
      
      if (clients && clients.length > 0) {
        for (const item of clients) {
          this.custs.push({
            clientId: item.clientId,
            clientName: item.clientName,
          });
        }

        this.custsMaster=this.custs;
      }
    }
    
  }
  displayFn(client: any): string {
    return client && client.clientName ? client.clientName : '';
  }
  onSelFunc(option:any){
    
    this.selectedClientId=option.clientId;
    
  }
  onSubmit() {    
    this.dialogRef.close(this.selectedClientId);
  }
  applyFilter(event: Event) {    

    const filterValue = (event.target as HTMLInputElement).value;
    this.custs =this.custsMaster.filter( i => i.clientName.toLowerCase().indexOf(filterValue.trim().toLowerCase()) >= 0 );
   
  }
}
