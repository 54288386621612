import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {IUser,IUserBusinessGroup, UserBusinessGroup} from '../models/user.model';
import { ApplicationLog } from '../models/applicationlog.model';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/AppConstants';
import { HttpClient } from '@angular/common/http';
import { UserEventLog } from '../models/userEventLog';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpService: HttpService,  private http : HttpClient) { }

  getInitialUserData(){

    return this.httpService.getInitialUserData(AppConstants.apiUrl+"User/GetInitialUserData");

  }

  getUserDataByClient(clientId:number, userId: number){
    return this.httpService.get(AppConstants.apiUrl+"User/GetUserByClient?ClientId="+clientId + "&UserId=" + userId);
  }

  getMappedBusinessGroup(userId:number){
    return this.httpService.get(AppConstants.apiUrl+"UserBusinessGroup/GetUserBusinessGroup?UserId="+userId + "&ClientId=" +  AppConstants.gblClientId + "&LoggedinUserId=" +AppConstants.gblLoggedInUserId);
  }

  getUnmappedMappedBusinessGroup(userId:number){
    return this.httpService.get(AppConstants.apiUrl+"UserBusinessGroup/GetUnMappedBusinessGroupByUser?UserId="+userId + "&LoggedinUserId=" + AppConstants.gblLoggedInUserId);
  }

  getUnMappedBusinessGroupListByUser(userId:number, ClientId : string){
    return this.httpService.get(AppConstants.apiUrl+"UserBusinessGroup/GetUnMappedBusinessGroupListByUser?UserId="+userId + "&ClientId="+ClientId+"&LoggedinUserId=" + AppConstants.gblLoggedInUserId);
  }

  getMappedBusinessGroupListByUser(userId:number, ClientId : string){
    return this.httpService.get(AppConstants.apiUrl+"UserBusinessGroup/GetMappedBusinessGroupListByUser?UserId="+userId + "&ClientId="+ClientId+"&LoggedinUserId=" + AppConstants.gblLoggedInUserId);
  }

  postBGUserData(mapObj:UserBusinessGroup): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"UserBusinessGroup/AddMultipleUserBusinessGroup", JSON.stringify(mapObj)) ;
  }

  getUnMappedClientByUser(userId:number){
    return this.httpService.get(AppConstants.apiUrl+"UserBusinessGroup/GetUnMappedClientByUser?UserId="+userId + "&LoggedinUserId=" + AppConstants.gblLoggedInUserId);
  }
  getMappedClientByUser(userId:number){
    return this.httpService.get(AppConstants.apiUrl+"UserBusinessGroup/GetMappedClientByUser?UserId="+userId + "&LoggedinUserId=" + AppConstants.gblLoggedInUserId);
  }
  postUserData(mapObj:IUser): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"User/AddUser", JSON.stringify(mapObj)) ;
  }
  postSingleUserBusinessGroupMapping(mapObj:IUserBusinessGroup): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"UserBusinessGroup/AddUserBusinessGroup", JSON.stringify(mapObj)) ;
  }

  postAllUserBusinessGroupMapping(mapObj:any): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"UserBusinessGroup/AddAllUserBusinessGroup" ,JSON.stringify(mapObj)) ;
  }
  putUserData(userId:number,mapObj:IUser): Observable<any>{
    return this.httpService.put(AppConstants.apiUrl+"User/UpdateUser?UserId="+userId, JSON.stringify(mapObj)) ;
  }

  deleteUserData(userId:number ,modifiedBy:number ,objectId : string){
    return this.httpService.delete(AppConstants.apiUrl+"User/DeleteUser?UserId="+userId+"&ModifiedBy="+modifiedBy +"&ObjectId="+objectId)  ;
  }

  deleteSingleBGMapping(selectedUserId:number,businessGroupId:number){
    return this.httpService.delete(AppConstants.apiUrl+"UserBusinessGroup/DeleteUserBusinessGroup?BusinessGroupId="+businessGroupId+"&userid="+selectedUserId + "&LoggedinUserId="+AppConstants.gblLoggedInUserId)  ;
  }
  deleteAllUserBusinessGroup(selectedUserId:number){
    return this.httpService.delete(AppConstants.apiUrl+"UserBusinessGroup/DeleteAllUserBusinessGroup?userId="+selectedUserId + "&LoggedinUserId="+AppConstants.gblLoggedInUserId)  ;
  }
  putUserName(mapObj:any): Observable<any>{
    return this.httpService.put(AppConstants.apiUrl+"User/UpdateFirstLastName", JSON.stringify(mapObj)) ;
  }
  deleteBGUserData(mapObj:UserBusinessGroup): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"UserBusinessGroup/DeleteMultipleUserBusinessGroup", JSON.stringify(mapObj)) ;
  }
  GetInvitationStatus(){
    return this.http.get(AppConstants.apiUrl+"csaa/GetInvitationStatus");
  }

  postApplicationLogData(mapObj:ApplicationLog): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"Application/AddApplicationLog", JSON.stringify(mapObj)) ;
  }

  getUserDetailsfromCSAA(emailId: string){
    return this.httpService.get(AppConstants.apiUrl+"csaa/GetUserDetailsfromCSAA?EmailId="+emailId);
  }

  ReSendInvite(emailId: string){
    return this.httpService.get(AppConstants.apiUrl+"csaa/SendInvite?EmailId="+emailId);
  }
  logUserEvent(mapObj:UserEventLog): Observable<any>{
    return this.httpService.post(AppConstants.apiUrl+"User/LogUserEvents", JSON.stringify(mapObj)) ;
  }

}
