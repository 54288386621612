import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { HomeComponent } from './pages/home/home.component';
import { AlertRulesComponent } from './pages/menu/alert-rules/alert-rules.component';
import { ManageAlertRulesComponent } from './pages/menu/manage-alert-rules/manage-alert-rules.component';
import { MenuComponent } from './pages/menu/menu.component';
import { PairNewDeviceComponent } from './pages/menu/pair-new-device/pair-new-device.component';
import { PairedDevicesComponent } from './pages/menu/paired-devices/paired-devices.component';
import { UserListComponent } from './pages/menu/user-list/user-list.component';
import { BusinessGroupsComponent } from './pages/menu/business-groups/business-groups.component';
import { UserRolesComponent } from './pages/menu/user-roles/user-roles.component';
import { CvaScreensComponent } from './pages/menu/cva-screens/cva-screens.component';
import { ReportComponent } from './pages/menu/Report/report.component';
import { HomeScreenComponent } from './pages/menu/home-screen/home-screen.component';
import { AccountnoBusinessgroupComponent } from './pages/menu/accountno-businessgroup/accountno-businessgroup.component';
import { UserrolesCvascreensComponent } from './pages/menu/userroles-cvascreens/userroles-cvascreens.component';
import { ModuleListComponent } from './pages/menu/module-list/module-list.component';
import { ClientComponent } from './pages/menu/client/client.component';
import { TenantComponent } from './pages/menu/tenant/tenant.component';
import { ProcessBusinessgroupComponent } from './pages/menu/process-businessgroup/process-businessgroup.component';
import { UserFeedbackComponent } from './pages/menu/user-feedback/user-feedback.component';
import { FavoriteModuleListComponent } from './pages/menu/favorite-module-list/favorite-module-list.component';
import { AdminGuideComponent } from './pages/menu/admin-guide/admin-guide.component';
import { RedemptionComponent } from './pages/menu/redemption/redemption.component';
import { QlikReportComponent } from './components/qlik-report/qlik-report.component';
import { CustomerrorComponent } from './pages/menu/customerror/customerror.component';
import { ReleaseNotesComponent } from './pages/menu/release-notes/release-notes.component';
import { IatConfiguartionScreenComponent } from './pages/menu/iat-configuration-screen/iat-configuration-screen.component';
import { CallbackComponent } from './components/callback/callback.component';
import { ForbiddenComponent } from './pages/menu/forbidden/forbidden.component';
import { NotificationComponent } from './pages/menu/notification/notification.component';

const routes: Routes = [
  { path: 'redirecting', component: CustomerrorComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: '', redirectTo: '/menu/homescreen', pathMatch: 'full' },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'callback', component: CallbackComponent },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'menu',
    component: MenuComponent,
    children: [
      { path: '', redirectTo: 'homescreen', pathMatch: 'full' },
      { path: 'paired-devices', component: PairedDevicesComponent },
      { path: 'pair-new-device', component: PairNewDeviceComponent },
      { path: 'alert-rules', component: AlertRulesComponent },
      { path: 'manage-alert-rules', component: ManageAlertRulesComponent },
      { path: 'user-list', component: UserListComponent },
      { path: 'businessgroup', component: BusinessGroupsComponent },
      { path: 'userroles', component: UserRolesComponent },
      { path: 'qlik-report', component: QlikReportComponent },
      { path: 'cvascreens', component: CvaScreensComponent },
      { path: 'managereport', component: ReportComponent },
      { path: 'homescreen', component: HomeScreenComponent },
      { path: 'accountno-businessgroup', component: AccountnoBusinessgroupComponent },
      { path: 'userroles-cvascreens', component: UserrolesCvascreensComponent },
      { path: 'module', component: ModuleListComponent },
      { path: 'client', component: ClientComponent },
      { path: 'tenants', component: TenantComponent },
      { path: 'processacc', component: ProcessBusinessgroupComponent },
      { path: 'user-feedback', component: UserFeedbackComponent },
      { path: 'favmodule', component: FavoriteModuleListComponent },
      { path: 'redemption', component: RedemptionComponent },
      { path: 'adminguide', component: AdminGuideComponent },
      { path: 'releasenotes', component: ReleaseNotesComponent },
      { path: 'iatadmin' , component: IatConfiguartionScreenComponent},
      { path: 'notification' , component: NotificationComponent},
      { path: '*', redirectTo: 'menu', pathMatch: 'full' },
    ],
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
