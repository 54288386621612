<form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content class="mat-typography">
    <h2 mat-dialog-title class="class-3">
      <span  i18n="@@Please Select a Feedback Category">Please Select a Feedback Category</span>
    </h2>
    <div class="feedback">
      <mat-button-toggle-group
      name="feedbackCategory"
      aria-label="Feedback Category"
      formControlName="feedbackCategoryId"
      class="category-btngroup"
    >
      <mat-button-toggle *ngFor="let category of categories" [value]="category.feedbackCategoryId">
        {{ category.feedbackCategoryName | translation }}
      </mat-button-toggle>

    </mat-button-toggle-group>

     
      <h2 mat-dialog-title class="class-4"><span  i18n="@@General Sentiment">General Sentiment</span></h2>

      <mat-button-toggle-group
        #group="matButtonToggleGroup"
        formControlName="rating"
        class="sentiment"
      >
        <mat-button-toggle
          value="1"
          title="{{VeryDissatisfied | translation}}"
          class="icon-btn icon_very_dissatisfied"
        >
          <mat-icon [ngStyle]="{ color: group.value === '1' ? 'red' : '' }">
            sentiment_very_dissatisfied</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle
          value="2"
          title="{{Dissatisfied| translation}}"
          class="icon-btn icon_ok_dissatisfied"
        >
          <mat-icon [ngStyle]="{ color: group.value === '2' ? 'tomato' : '' }">
            sentiment_dissatisfied
          </mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          value="3"
          title="{{Neutral| translation}}"
          class="icon-btn icon_ok_satisfied"
        >
          <mat-icon [ngStyle]="{ color: group.value === '3' ? 'gray' : '' }">
            sentiment_satisfied</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle
          value="4"
          title="{{Satisfied| translation}}"
          class="icon-btn icon_satisfied"
        >
          <mat-icon [ngStyle]="{ color: group.value === '4' ? '#35ce35' : '' }">
            sentiment_satisfied_alt</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle
          value="5"
          title="{{VerySatisfied| translation}}"
          class="icon-btn icon_very_satisfied"
        >
          <mat-icon [ngStyle]="{ color: group.value === '5' ? 'green' : '' }">
            sentiment_very_satisfied
          </mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <br />

      <mat-form-field appearance="outline" class="feedback__text-input" id="summary">
        <mat-label i18n="@@Summary">Summary</mat-label>
        <input
          matInput
          placeholder="{{Provideaonelinesummary | translation}}"
          formControlName="summary"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="feedback__text-input" id="description">
        <mat-label  i18n="@@Description (Optional)">Description (Optional)</mat-label>
        <textarea
          matInput
          rows="3"
          placeholder="{{Provideamoredetaileddescription | translation}}"
          formControlName="description"
        ></textarea>
      </mat-form-field>

      <h2 mat-dialog-title class="class-3"><span  i18n="@@Priority">Priority</span></h2>

      <mat-button-toggle-group
        #groupPriority="matButtonToggleGroup"
        formControlName="priority"
      >
        <mat-button-toggle
          value="1"
          title="{{VeryDissatisfied | translation}}"
          class="icon_very_dissatisfied"
          [ngStyle]="{
            'background-color':
              groupPriority.value === '1' ? 'rgba(26,190,131,.8)' : ''
          }"
        >
        <span  i18n="@@Low">Low</span>
          
        </mat-button-toggle>
        <mat-button-toggle
          value="2"
          title="{{Dissatisfied| translation}}"
          class="icon_ok_dissatisfied"
          [ngStyle]="{
            'background-color':
              groupPriority.value === '2' ? 'rgba(245,154,35,.8)' : ''
          }"
        >
        <span  i18n="@@Medium">Medium</span>
          
        </mat-button-toggle>
        <mat-button-toggle
          value="3"
          title="{{Neutral| translation}}"
          class="icon_ok_satisfied"
          [ngStyle]="{
            'background-color':
              groupPriority.value === '3' ? 'rgba(209,0,25,.7)' : ''
          }"
        >
        <span  i18n="@@High">High</span>
          
        </mat-button-toggle>
      </mat-button-toggle-group>
      <br />
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><span i18n="@@Cancel">Cancel</span></button>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      [mat-dialog-close]="true"
      [disabled]="!feedbackForm.valid"
    >
    <span i18n="@@Submit">Submit</span>
    </button>
  </mat-dialog-actions>
</form>
